import type { WorkflowNode } from 'types-shared';
import { NodeTypesEnum } from 'types-shared';
import React, { useMemo } from 'react';
import { FlagIcon } from 'ui-kit';

interface Props {
  node: WorkflowNode;
  onClick?: () => void;
}

export function EndingNodeCore({ node, onClick }: Props) {
  const nodeStatus = useMemo(() => {
    if (node.type !== NodeTypesEnum.Ending) {
      return null;
    }

    if (node.data.status === 'retry') {
      return 'Retry';
    }

    if (node.data.status === 'failure') {
      return 'Stop and mark as failure';
    }

    return null;
  }, [node]);

  return (
    <>
      <button
        className="flex-1 rounded-lg border border-indigo-light flex justify-center items-center bg-info-dark text-white"
        onClick={onClick}
        type="button"
      >
        <FlagIcon className="!h-12 !w-12 text-indigo-light" />
      </button>
      <span className="text-sm font-bold truncate">{nodeStatus}</span>
    </>
  );
}
