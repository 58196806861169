import React, { useMemo } from 'react';
import type { EdgeProps } from 'types-shared/reactflow';
import {
  BaseEdge,
  EdgeLabelRenderer,
  getBezierPath,
} from 'types-shared/reactflow';
import { Add } from 'assets-shared';
import { NodeTypesEnum } from 'types-shared';
import type { WorkflowEdge, WorkflowNode } from 'types-shared';
import { insertNodeAfter } from '../../utils/helper';
import { CustomMarker } from './CustomMarker';

interface Props {
  nodes: WorkflowNode[];
  edges: WorkflowEdge[];
  setEdges: (edges: WorkflowEdge[]) => void;
  setNodes: (nodes: WorkflowNode[]) => void;
  edgeProps: EdgeProps;
  onAddNode?: (nodeId: string) => void;
}
export function CustomEdgeCore({
  nodes,
  edges,
  setEdges,
  setNodes,
  edgeProps: {
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    selected,
  },
  onAddNode,
}: Props) {
  const [edgePath, labelX, labelY] = useMemo(
    () =>
      getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
      }),
    [sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition],
  );
  const currentEdge = edges.find((edge) => edge.id === id);

  const insertNode = () => {
    const edge = edges.find((e) => e.id === id);
    if (!edge) {
      throw new Error('edge not found');
    }
    const nodeId = insertNodeAfter(edge, nodes, edges, {
      setNodes,
      setEdges,
    });
    onAddNode?.(nodeId);
  };

  const showAddButton = useMemo(() => {
    return (
      nodes.find((node) => node.id === currentEdge?.source)?.type !==
      NodeTypesEnum.Datasource
    );
  }, [currentEdge?.source, nodes]);

  return (
    <>
      <CustomMarker />
      <BaseEdge
        markerEnd="url(#triangle)"
        path={edgePath}
        style={{ strokeWidth: '2px', stroke: selected ? '#8c8c8c' : '#000' }}
      />
      <EdgeLabelRenderer>
        <div
          className="nodrag nopan"
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            pointerEvents: 'all',
          }}
        >
          {currentEdge?.label ? (
            <p
              className="absolute bg-white p-1 -top-8 left-1/2 -translate-x-1/2 -translate-y-1/2 max-w-[20ch] text-center truncate"
              style={currentEdge.labelStyle}
            >
              {currentEdge.label}
            </p>
          ) : null}
          {showAddButton ? (
            <button className="bg-flow-view" onClick={insertNode} type="button">
              <Add />
            </button>
          ) : null}
        </div>
      </EdgeLabelRenderer>
    </>
  );
}
