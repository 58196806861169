"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TriggerType = exports.TriggerTypeEnum = void 0;
var zod_1 = require("zod");
var TriggerTypeEnum;
(function (TriggerTypeEnum) {
    TriggerTypeEnum["Datasource"] = "Datasource";
    TriggerTypeEnum["API"] = "API";
})(TriggerTypeEnum || (exports.TriggerTypeEnum = TriggerTypeEnum = {}));
exports.TriggerType = zod_1.z.nativeEnum(TriggerTypeEnum);
