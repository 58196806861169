import { useMemo, useState, useEffect } from 'react';
import { WorkflowImageNode } from 'types-shared';

import { EditorStore } from '../../store/EditorState';
import type { SelectedAction } from './ActionsList';
import ActionsList from './ActionsList';
import EditAction from './EditAction';
import { useOnEscape } from 'editor-shared';

interface Props {
  setHasSelectedAction: (status: boolean) => void;
}

function ActionsManager({ setHasSelectedAction }: Props) {
  const editorData = EditorStore();
  const { selectedNode, setSelectedNode, nodes } = editorData;
  const [selectedAction, setSelectedAction] = useState<SelectedAction | null>(
    null,
  );

  useOnEscape((e) => {
    e.stopPropagation();
    if (selectedAction) {
      setSelectedAction(null);
      return;
    }

    if (selectedNode) {
      setSelectedNode(null);
    }
  });

  useEffect(() => {
    if (selectedAction) {
      setHasSelectedAction(true);
    } else {
      setHasSelectedAction(false);
    }
  }, [selectedAction, setHasSelectedAction]);

  const node = useMemo(() => {
    const _selectedNode = nodes.find((_node) => _node.id === selectedNode);
    const parsedImageNode = WorkflowImageNode.safeParse(_selectedNode);
    if (parsedImageNode.success) {
      return parsedImageNode.data;
    }
    return null;
  }, [nodes, selectedNode]);

  const onClose = () => {
    setSelectedNode(null);
    setSelectedAction(null);
  };

  if (!node) {
    return null;
  }

  return (
    <div className="min-w-125 max-w-125 max-h-screen overflow-y-auto p-8 flex flex-col space-y-6 h-full bg-white relative">
      {selectedAction ? (
        <EditAction
          action={selectedAction}
          i={selectedAction.i}
          setSelectedAction={setSelectedAction}
        />
      ) : (
        <ActionsList
          node={node}
          onClose={onClose}
          setSelectedAction={setSelectedAction}
        />
      )}
    </div>
  );
}

export default ActionsManager;
