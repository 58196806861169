import React from 'react';

export function DocumentNodeCore() {
  return (
    <>
      <div className="flex-1 rounded-lg border border-indigo-light bg-gray-100 flex justify-center items-center">
        <img alt="logo" className="w-16" src="/logo-blue.png" />
      </div>
      <span className="text-sm font-bold truncate">Document</span>
    </>
  );
}
