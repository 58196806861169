import type { ReactNode } from 'react';
import React, { useEffect, useState } from 'react';

import type { EventMap } from '../../utils/eventBus';
import createEventBus from '../../utils/eventBus';
import Alert from './index';
import type { Props, AlertVariant } from './index';
import { useEnvironment } from '../../contexts/environment';
import { clsx } from 'clsx';

type AlertProps = Omit<Props, 'children'> & {
  message: string | ReactNode;
  variant: AlertVariant;
  debug?: boolean;
};

interface AlertEvents extends EventMap {
  open: (props: AlertProps) => void;
  close: () => void;
}

const alertEventChannel = createEventBus<AlertEvents>();

export const notify = (props: AlertProps): void => {
  alertEventChannel.emit('open', props);
};

export default function AlertWrapper({ className }: { className?: string }) {
  const { selectedEnv } = useEnvironment();
  const [open, setOpen] = useState<boolean>(false);
  const [props, setProps] = useState<AlertProps>({} as AlertProps);
  const showNotifications = !props.debug || selectedEnv !== 'production';

  const handleOpen = (options: AlertProps) => {
    setProps(options);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setProps({} as AlertProps);
  };

  useEffect(() => {
    const unsubscribeOpen = alertEventChannel.on('open', handleOpen);
    const unsubscribeClose = alertEventChannel.on('close', handleClose);

    return () => {
      unsubscribeOpen();
      unsubscribeClose();
    };
  }, []);

  return showNotifications ? (
    <Alert
      className={clsx(
        'fixed top-[10%] left-[50%] -translate-x-1/2 z-[99999] shadow-2xl',
        className,
      )}
      onClose={handleClose}
      open={open}
      {...props}
    >
      {props.message}
    </Alert>
  ) : null;
}
