import { default as dagre, graphlib } from '@dagrejs/dagre';
import type { WorkflowEdge, WorkflowNode } from 'types-shared';

export const HEIGHT_BETWEEN_NODES = 200;
export const WIDTH_BETWEEN_NODES = 200;

export const autoFormat = (
  nodes: WorkflowNode[],
  edges: WorkflowEdge[],
  setNodes?: (nodes: WorkflowNode[]) => void,
) => {
  // Create a new directed graph
  const g = new graphlib.Graph();

  // Set an object for the graph label
  g.setGraph({
    rankdir: 'LR', // Left to Right layout
    marginx: 0,
    marginy: 0,
    nodesep: HEIGHT_BETWEEN_NODES,
    ranksep: WIDTH_BETWEEN_NODES,
  });

  // Default to assigning a new object as a label for each new edge.
  g.setDefaultEdgeLabel(() => ({}));

  // Add nodes to the graph. The first argument is the node's ID.
  // The second argument is metadata about the node (label, width, height).
  nodes.forEach((node) => {
    g.setNode(node.id, {
      label: node.id,
      width: node.width || 200,
      height: node.height || 100,
    });
  });

  const nodeIds = nodes.map((node) => node.id);

  // Add edges to the graph.
  edges.forEach((edge) => {
    if (!nodeIds.includes(edge.source) || !nodeIds.includes(edge.target)) {
      return;
    }
    g.setEdge(edge.source, edge.target);
  });

  // Layout the graph using the Dagre layout algorithm.
  dagre.layout(g);

  // Create a mapping of nodeId to its new position
  const nodePositions: Record<string, { x: number; y: number }> = {};
  g.nodes().forEach((nodeId) => {
    const node = g.node(nodeId);
    nodePositions[nodeId] = { x: node.x, y: node.y };
  });

  if (setNodes) {
    setNodes(
      nodes.map((node) => {
        const newNodePosition = nodePositions[node.id];
        return {
          ...node,
          position: {
            x: newNodePosition.x,
            y: newNodePosition.y,
          },
        };
      }),
    );
  }

  return nodePositions;
};
