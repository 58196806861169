import type { UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type {
  ExecutionDocument,
  AddWorkflowRequest,
  GetWorkflowMetadataResponse,
  WorkflowMetadataType,
} from 'api-types-shared';
import isNil from 'lodash/isNil';
import { useCallback, useEffect, useState } from 'react';
import { loadExtensionData, uploadBlobToS3 } from 'dashboard-shared';
import { handleException } from 'sentry-browser-shared';
import type {
  WorkflowNode,
  ExecutionBase,
  ExecutionVariables,
} from 'types-shared';
import { WorkflowImageNode, ExtensionData } from 'types-shared';
import { AlertVariant, notify, useEnvironment } from 'ui-kit';

import { useAPI } from '../../hooks/useApi';

import { KNOWN_WORKFLOW_URLS_TO_WORKFLOW_IDS } from '../Editor/utils/constants';
import { DEFAULT_REFETCH_INTERVAL } from '../../constants';
import { isUUID } from '../Editor/utils/helper';
import { sendToBackgroundViaRelay } from '@plasmohq/messaging';

export const useImportWorkflowFromExtension = () => {
  const [lastMsg, setLastMsg] = useState<ExtensionData | undefined>();

  const messageListener = useCallback((event: MessageEvent) => {
    const extensionData = ExtensionData.safeParse(event.data);
    const { success } = extensionData;
    if (success) {
      setLastMsg(extensionData.data);
      notify({
        message: 'Workflow imported successfully',
        variant: AlertVariant.SUCCESS,
      });
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', messageListener);

    return () => {
      window.removeEventListener('message', messageListener);
    };
  }, [messageListener]);

  return { lastMsg };
};

const filterDeletedWorkflows = (workflows: WorkflowMetadataType[]) =>
  workflows.filter((workflow) => isNil(workflow.deletedAt));

export const useFetchWorkflowsList = (
  filter?: string,
): UseQueryResult<WorkflowMetadataType[]> => {
  const { workflowSDK: sdk } = useAPI();
  return useQuery<WorkflowMetadataType[]>({
    queryKey: ['workflows', filter],
    queryFn: () => {
      // const user = await getAuthUser();
      return sdk.fetchWorkflowsList();
    },
    select: filterDeletedWorkflows,
  });
};

export function useFetchWorkflowData(
  workflowId: string,
  type: 'json' | 'text' = 'json',
): UseQueryResult<string> {
  const { workflowSDK: sdk } = useAPI();
  return useQuery<string>({
    queryKey: ['workflowData', workflowId],
    queryFn: () => sdk.fetchWorkflowData(workflowId, type),
  });
}

export function useFetchWorkflowMetadata(
  workflowId: string,
): UseQueryResult<GetWorkflowMetadataResponse | null> {
  const { workflowSDK: sdk } = useAPI();
  return useQuery<GetWorkflowMetadataResponse | null>({
    queryKey: ['workflows', workflowId],
    queryFn: () => sdk.fetchWorkflowMetadata(workflowId),
    enabled: isUUID(workflowId),
  });
}

export function useCreateWorkflow(): UseMutationResult<
  string,
  Error,
  { workflowName: string; extensionData: ExtensionData }
> {
  const { selectedEnv } = useEnvironment();
  const { workflowSDK: sdk } = useAPI();
  const queryClient = useQueryClient();
  return useMutation<
    string,
    Error,
    { workflowName: string; extensionData: ExtensionData }
  >({
    mutationFn: async ({ workflowName, extensionData }) => {
      try {
        const { workflowData, newImages, variableStore, targetStore } =
          await loadExtensionData(extensionData);

        const imageHashes = newImages.map((image) => image.imageId);
        const addWorkflowReq: AddWorkflowRequest['body'] = {
          workflowName,
          imageHashes,
        };
        const {
          workflowId,
          imageUploadMap,
          stateUploadUrl,
          variableStoreUploadUrl,
          targetStoreUploadUrl,
          videoUpload,
        } = await sdk.createWorkflow(addWorkflowReq, selectedEnv);

        void sendToBackgroundViaRelay({
          name: 'onUploadVideo.handler' as never,
          body: {
            workflowId,
            videoUpload,
          },
        });

        await queryClient.invalidateQueries({ queryKey: ['workflows'] });

        // Upload workflow data
        const promises = [];

        for (const image of newImages) {
          const { imageId, blob, thumbnailBlob } = image;
          const { imagePresignedS3Url, thumbnailPresignedS3Url } =
            imageUploadMap[imageId];

          promises.push(uploadBlobToS3(blob, imagePresignedS3Url));
          promises.push(uploadBlobToS3(thumbnailBlob, thumbnailPresignedS3Url));
        }
        // Wait for all promises to resolve and check if any of them are false
        const results = await Promise.allSettled(promises);
        const failed = results.filter(({ status }) => status === 'rejected');

        // TODO: Handle failed uploads with Sentry
        if (failed.length) {
          notify({
            message: 'Error while uploading workflow images',
            variant: AlertVariant.ERROR,
            debug: true,
          });
        }

        // Upload workflow state, variable store, and target store
        const uploadItems = [
          { url: stateUploadUrl, data: workflowData, name: 'state.json' },
          {
            url: variableStoreUploadUrl,
            data: variableStore,
            name: 'variable.json',
          },
          { url: targetStoreUploadUrl, data: targetStore, name: 'target.json' },
        ];

        const uploadPromises = uploadItems.map(({ url, data, name }) =>
          uploadBlobToS3(
            new Blob([JSON.stringify(data)], { type: 'application/json' }),
            url,
          ).catch(() => {
            notify({
              message: `Error while uploading ${name}`,
              variant: AlertVariant.ERROR,
              debug: true,
            });
          }),
        );
        await Promise.all(uploadPromises);
        return workflowId;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        const preRecordedWorkflowId = getPreRecordedWorkflowId(
          [],
          extensionData.capturedUrls,
        );
        if (!preRecordedWorkflowId) {
          handleException(e, {
            userMessage: {
              title: 'Something went wrong :(',
            },
            source: 'extension import',
          });

          throw e;
        }

        return preRecordedWorkflowId;
      }
    },
  });
}

export function useDeleteWorkflow(): UseMutationResult<unknown, Error, string> {
  const { workflowSDK: sdk } = useAPI();
  const queryClient = useQueryClient();
  return useMutation<unknown, Error, string>({
    mutationFn: async (id: string) => {
      await sdk.deleteWorkflow(id);
      await queryClient.invalidateQueries({ queryKey: ['workflows'] });
    },
    onSuccess: () => {
      notify({
        message: 'Workflow deleted successfully',
        variant: AlertVariant.SUCCESS,
      });
    },
  });
}

const getPreRecordedWorkflowId = (
  nodes: WorkflowNode[],
  capturedUrls: string[],
): string | null => {
  let knownWorkflowKey = capturedUrls
    .flatMap((url) => {
      return Object.keys(KNOWN_WORKFLOW_URLS_TO_WORKFLOW_IDS).filter(
        (knownURL) => url.startsWith(knownURL),
      );
    })
    .at(0);

  if (knownWorkflowKey) {
    return KNOWN_WORKFLOW_URLS_TO_WORKFLOW_IDS[knownWorkflowKey];
  }

  const parsedFirstNode = WorkflowImageNode.safeParse(nodes[0]);
  if (!parsedFirstNode.success) {
    return null;
  }
  const firstNode = parsedFirstNode.data;
  const firstNodeUrls: string[] = firstNode.data.nodeUrls;
  knownWorkflowKey = firstNodeUrls
    .flatMap((url) => {
      return Object.keys(KNOWN_WORKFLOW_URLS_TO_WORKFLOW_IDS).filter(
        (knownURL) => url.startsWith(knownURL),
      );
    })
    .at(0);

  if (knownWorkflowKey) {
    return KNOWN_WORKFLOW_URLS_TO_WORKFLOW_IDS[knownWorkflowKey];
  }

  return null;
};

export const useFetchWorkflowExecutionDetails = (
  workflowId: string,
  setId?: string | undefined,
  refetchInterval = DEFAULT_REFETCH_INTERVAL,
) => {
  const { executionSDK: sdk } = useAPI();
  return useQuery<ExecutionBase[]>({
    refetchInterval,
    queryKey: ['workflowExecutions', workflowId, setId],
    queryFn: () => {
      // const user = await getAuthUser();
      return sdk
        .listExecutions({
          query: { workflowId, setId },
        })
        .catch((e) => {
          handleException(e, { source: sdk.endpoint });
          return [];
        });
    },
    select: (data) =>
      data.map((execution) => ({
        ...execution,
        createdAt: new Date(execution.createdAt).getTime().toString(),
      })),
  });
};

const executionDetailsRefetchFrequency = 5 * 1000;

export const useFetchExecutionDetail = (executionId: string) => {
  const { executionSDK: sdk } = useAPI();
  return useQuery<{
    metadata: ExecutionBase;
    variablesUrls: string[];
    imageUrls: string[];
    artifacts: ExecutionDocument[];
  }>({
    refetchInterval: executionDetailsRefetchFrequency,
    queryKey: ['executions', executionId],
    queryFn: () => {
      // const user = await getAuthUser();
      return sdk.getExecution(executionId);
    },
  });
};

export const useFetchExecutionVariables = (
  executionId: string,
  variablesUrls: string[],
) => {
  const { executionSDK: sdk } = useAPI();
  return useQuery<ExecutionVariables>({
    refetchInterval: executionDetailsRefetchFrequency,
    queryKey: ['executionId-variables', executionId],
    queryFn: () => {
      // const user = await getAuthUser();
      return sdk.getExecutionVariables(variablesUrls);
    },
    enabled: variablesUrls.length > 0,
  });
};

export const useFetchExecutionScreenshots = (
  executionId: string,
  imageUrls: string[],
) => {
  const { executionSDK: sdk } = useAPI();
  return useQuery<[string, Blob][]>({
    refetchInterval: executionDetailsRefetchFrequency,
    queryKey: ['executionId-screenshots', executionId],
    queryFn: () => {
      // const user = await getAuthUser();
      return sdk.getExecutionScreenshots(imageUrls);
    },
    enabled: imageUrls.length > 0,
  });
};

export function useUpdateWorkflowName(): UseMutationResult<
  unknown,
  Error,
  Pick<WorkflowMetadataType, 'workflowId' | 'workflowName'>
> {
  const { workflowSDK: sdk } = useAPI();
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    Error,
    Pick<WorkflowMetadataType, 'workflowId' | 'workflowName'>
  >({
    mutationFn: async ({ workflowId, workflowName }) => {
      if (workflowName) {
        await sdk.updateWorkflowName(workflowId, {
          workflowName,
        });
        await queryClient.invalidateQueries({ queryKey: ['workflows'] });
      }
    },
    onSuccess: () => {
      notify({
        message: 'Workflow updated successfully',
        variant: AlertVariant.SUCCESS,
      });
    },
    onError: () => {
      notify({
        message: 'Failed to update workflow',
        variant: AlertVariant.ERROR,
      });
    },
  });
}

export function useDuplicateWorkflow(): UseMutationResult<
  unknown,
  Error,
  string
> {
  // const { workflowSDK: sdk } = useAPI();
  return useMutation<unknown, Error, string>({
    // eslint-disable-next-line @typescript-eslint/require-await
    mutationFn: async (type) => {
      // eslint-disable-next-line no-console
      console.log(type);
    },
    onSuccess: () => {
      notify({
        message: 'Workflow duplicated successfully',
        variant: AlertVariant.SUCCESS,
      });
    },
  });
}

export function useDownloadExecutionData(): UseMutationResult<
  string,
  Error,
  string[]
> {
  const { executionSDK: sdk } = useAPI();
  return useMutation<string, Error, string[]>({
    mutationFn: (executionIds) =>
      sdk
        .getZippedOutputs({
          body: { executionIds },
        })
        .then((res) => res.url),
    onSuccess: () => {
      notify({
        message: 'Zip downloaded successfully',
        variant: AlertVariant.SUCCESS,
      });
    },
  });
}
