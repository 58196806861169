import NavigationItem from './NavigationItem';
import { Profile } from 'ui-kit';
import { FeatureFlag } from 'dashboard-shared';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useMemo } from 'react';

function NavigationPanel() {
  const integrationsPageEnabled = useFeatureFlagEnabled(
    FeatureFlag.IntegrationsPage,
  );

  const menuItems = useMemo(
    () => [
      { text: 'Workflows', to: '/', disabled: false },
      {
        text: 'Integrations',
        to: '/integrations',
        disabled: !integrationsPageEnabled,
      },
    ],
    [integrationsPageEnabled],
  );

  return (
    <div className="p-4 min-w-[300px] border-r-2 border-primary-blue-light flex flex-col">
      <div className="pt-[9px] h-14 mb-14 px-2">
        <img alt="Logo" className="w-8" src="/logo.png" />
      </div>
      <ul>
        {menuItems.map((menuItem) => (
          <NavigationItem
            className="mb-1"
            disabled={menuItem.disabled}
            href={menuItem.to}
            key={menuItem.to}
            text={menuItem.text}
          />
        ))}
      </ul>
      <Profile />
    </div>
  );
}

export default NavigationPanel;
