import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export default function CameraIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.48947 7H6.20068C5.08058 7 4.52011 7 4.09229 7.21799C3.71596 7.40973 3.41022 7.71547 3.21848 8.0918C3.00049 8.51962 3.00049 9.08009 3.00049 10.2002V15.8002C3.00049 16.9203 3.00049 17.4796 3.21848 17.9074C3.41022 18.2837 3.71596 18.5905 4.09229 18.7822C4.51969 19 5.07948 19 6.1974 19H17.8036C18.9215 19 19.4805 19 19.9079 18.7822C20.2842 18.5905 20.591 18.2837 20.7827 17.9074C21.0005 17.48 21.0005 16.921 21.0005 15.8031V10.1969C21.0005 9.07899 21.0005 8.5192 20.7827 8.0918C20.591 7.71547 20.2842 7.40973 19.9079 7.21799C19.4801 7 18.9208 7 17.8007 7H14.5113M9.48947 7H9.55127M9.48947 7C9.50199 7.00001 9.51517 7 9.52906 7L9.55127 7M9.48947 7C9.38335 6.99995 9.32388 6.99941 9.27686 6.99414C8.68927 6.92835 8.28627 6.36908 8.40967 5.79084C8.42115 5.73703 8.44385 5.66894 8.48879 5.53412L8.49072 5.52841C8.54205 5.37443 8.56772 5.29743 8.59607 5.22949C8.88635 4.53389 9.54371 4.06083 10.2954 4.00541C10.3688 4 10.4495 4 10.6118 4H13.3891C13.5514 4 13.6327 4 13.7061 4.00541C14.4578 4.06083 15.1145 4.53389 15.4048 5.22949C15.4331 5.29743 15.4589 5.37434 15.5103 5.52832C15.5565 5.66699 15.5796 5.73636 15.5912 5.79093C15.7146 6.36917 15.3123 6.92835 14.7247 6.99414C14.6777 6.99941 14.6176 6.99995 14.5113 7M9.55127 7H14.4495M14.4495 7H14.5113M14.4495 7L14.4717 7C14.4856 7 14.4988 7.00001 14.5113 7M12.0005 16C10.3436 16 9.00049 14.6569 9.00049 13C9.00049 11.3431 10.3436 10 12.0005 10C13.6573 10 15.0005 11.3431 15.0005 13C15.0005 14.6569 13.6573 16 12.0005 16Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </SvgIcon>
  );
}
