import type { ExtensionData, RecordingAction } from 'types-shared';
import {
  Actions,
  ExtensionAction,
  WorkflowAction,
  handleZodCheck,
} from 'types-shared';
import type { ParseActionsReturnType } from './parseActions';
import deepClone from 'lodash/cloneDeep';
import { v4 as uuidv4 } from 'uuid';

export const combineDownloadActions = (
  actionsArray: ParseActionsReturnType['actionDataArray'],
): ParseActionsReturnType['actionDataArray'] => {
  let lastExtensionAction: { nodeIndex: number; actionId: string } | null =
    null;
  const clonedActionsArray = deepClone(actionsArray);

  clonedActionsArray.forEach((subArray, subArrayIndex) => {
    const actionIdsToRemove = new Set<string>();

    subArray.actionOrder.forEach((actionId: string) => {
      const action = subArray.actionData[actionId];

      handleZodCheck(action, WorkflowAction, (parsedAction) => {
        if (parsedAction.actionType !== Actions.enum.Download) {
          lastExtensionAction = { nodeIndex: subArrayIndex, actionId };
        } else if (lastExtensionAction) {
          const lastActionData =
            clonedActionsArray[lastExtensionAction.nodeIndex].actionData[
              lastExtensionAction.actionId
            ];
          lastActionData.options = {
            ...lastActionData.options,
            download: [
              ...(lastActionData.options?.download || []),
              ...(parsedAction.options?.download || []),
            ],
          };
          actionIdsToRemove.add(lastExtensionAction.actionId);
        }
      });
    });
    subArray.actionOrder = subArray.actionOrder.filter(
      (actionId) => !actionIdsToRemove.has(actionId),
    );
    actionIdsToRemove.forEach((actionId) => {
      Reflect.deleteProperty(subArray.actionData, actionId);
    });
  });

  return clonedActionsArray;
};

export const deduplicateActions = (
  actionsArray: ExtensionData['actions'],
): ExtensionData['actions'] => {
  const actionMap = new Map<string, RecordingAction>();
  const firstOccurrenceMap = new Map<
    string,
    { outerIndex: number; innerIndex: number }
  >();

  actionsArray.forEach((subArray, outerIndex) => {
    subArray.forEach((action, innerIndex) => {
      const parsedAction = ExtensionAction.safeParse(action);
      if (parsedAction.success) {
        const subAction = parsedAction.data;
        actionMap.set(subAction.id, subAction);
        if (!firstOccurrenceMap.has(subAction.id)) {
          firstOccurrenceMap.set(subAction.id, { outerIndex, innerIndex });
        }
      } else {
        const id = uuidv4();
        actionMap.set(id, action);
        firstOccurrenceMap.set(id, { outerIndex, innerIndex });
      }
    });
  });

  const newActionsArray: RecordingAction[][] = actionsArray.map(() => []);

  firstOccurrenceMap.forEach(({ outerIndex, innerIndex }, actionId) => {
    const action = actionMap.get(actionId);
    if (action) {
      newActionsArray[outerIndex][innerIndex] = action;
    }
  });

  return newActionsArray;
};
