import {
  ActionsEnum,
  ActionValueCriteriaEnum,
  type Variable,
  type WorkflowAction,
  type WorkflowActionsOptions,
} from 'types-shared';
import { clsx } from 'clsx';
import type { EventBus, EventMap } from 'ui-kit';
import { Chip, IconButton, Tooltip, Input } from 'ui-kit';
import {
  ConditionalIcon,
  EditIcon,
  InfoOutlined,
  Person,
  VisibilityOff,
} from 'assets-shared';

import ActionMenu from './Menu';
import React, { useEffect, useMemo, useState } from 'react';
import { ActionRenderer } from './ActionRenderer';
import startCase from 'lodash/startCase';
import { lowerCase } from 'lodash';

interface ImageNodeEvents extends EventMap {
  onActionHover: (targetId: string | null) => void;
  onTargetHover: (targetId: string | null) => void;
}

interface Props {
  variablesMap: Record<string, Variable>;
  onEditClick?: (action: WorkflowAction) => void;
  onUpdateActionOptions: (
    action: WorkflowAction,
    options: WorkflowActionsOptions,
  ) => void;
  action: WorkflowAction;
  i: number;
  imageNodeEventChannel: EventBus<ImageNodeEvents>;
  isDragging?: boolean;
  showAdminToggles?: boolean;
  allowDeleteAction: boolean;
  onEditTarget?: () => void;
  onMoveAction?: () => void;
  onDeleteAction?: () => void;
  onAddApprovalStep?: (position: 'before' | 'after') => void;
  showManualHandleOption?: boolean;
}

const actionTypeToPreviewTitleMapping: Record<string, string> = {
  [ActionsEnum.Input]: 'Input',
  [ActionsEnum.Select]: 'Select',
};

export function Action({
  action,
  onEditClick,
  onEditTarget,
  onMoveAction,
  onDeleteAction,
  allowDeleteAction,
  onUpdateActionOptions,
  onAddApprovalStep,
  variablesMap,
  i,
  imageNodeEventChannel,
  isDragging = false,
  showAdminToggles = false,
  showManualHandleOption,
}: Props) {
  const {
    actionType,
    options: {
      hidden,
      hitl,
      sitl,
      captcha,
      mfa,
      adminOnly,
      download,
      chooseFromList,
    } = {},
    title,
    description = '',
    variableId = '',
    targetId,
    criteria,
    keyType,
  } = action;
  const isDownloadAction = Boolean(download);
  const variable = variablesMap[variableId];
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const { name } = variable ?? {};
  const [selectedTargetId, setSelectedTargetId] = useState<string | null>(null);
  const [isEditingDescription, setIsEditingDescription] =
    useState<boolean>(false);
  const [descriptionText, setDescriptionText] = useState<string>(description);

  const highlightAnnotation = () => {
    if (targetId) {
      imageNodeEventChannel.emit('onActionHover', targetId);
    }
  };

  const removeAnnotationHighlight = () => {
    imageNodeEventChannel.emit('onActionHover', null);
  };

  const updateDescription = () => {
    setIsEditingDescription(false);
    onUpdateActionOptions(
      { ...action, description: descriptionText },
      { ...action.options },
    );
  };

  const actionTitle = useMemo(() => {
    if (isDownloadAction || actionType === ActionsEnum.Download) {
      return 'Download';
    }

    if (actionType === ActionsEnum.MultiChoice) {
      return 'Multiple Choice';
    }

    if (actionType === ActionsEnum.KeyPress) {
      return 'Press';
    }

    if (actionType === ActionsEnum.ManualApproval) {
      return 'Manual approval step';
    }

    if (mfa) {
      return 'Two-factor authentication';
    }

    if (chooseFromList) {
      return 'Pick from a list';
    }

    if (captcha) {
      return 'Captcha';
    }

    return actionType;
  }, [actionType, captcha, chooseFromList, isDownloadAction, mfa]);

  const editAllowed =
    actionType !== ActionsEnum.Click &&
    actionType !== ActionsEnum.KeyPress &&
    !(actionType === ActionsEnum.Download || isDownloadAction) &&
    !mfa &&
    !captcha &&
    !hidden &&
    !hitl &&
    Boolean(onEditClick);

  useEffect(() => {
    imageNodeEventChannel.on('onTargetHover', setSelectedTargetId);
  }, [imageNodeEventChannel]);

  return (
    <div
      className={clsx(
        'px-3 py-5 bg-gray-100 border rounded hover:border-primary-blue cursor-pointer hover:shadow-primary transition',
        {
          'border-yellow-300 !bg-yellow-50': hitl,
          'opacity-50': hidden,
          'border-gray-800': isDragging,
          'border-primary-blue shadow-primary': targetId === selectedTargetId,
        },
      )}
      onBlur={removeAnnotationHighlight}
      onClick={() => {
        if (editAllowed) {
          onEditClick?.(action);
        }
      }}
      onFocus={highlightAnnotation}
      onMouseLeave={removeAnnotationHighlight}
      onMouseOver={highlightAnnotation}
      role="presentation"
    >
      <div className="flex justify-between items-center">
        <div className="flex space-x-4 items-center">
          <span className="text-xs bg-gray-300 rounded-full h-6 w-6 flex justify-center items-center">
            {i}
          </span>

          <div className="flex flex-col">
            <div className="flex space-x-2 items-center">
              <span className="font-semibold text-sm">{actionTitle}</span>
              {keyType ? (
                <span className="text-sm">
                  "{startCase(lowerCase(keyType))}"
                </span>
              ) : null}
              {hitl || sitl ? (
                <Tooltip
                  title={
                    hitl
                      ? 'Your flow will be paused and you will have to handle this step manually'
                      : 'Sola’s manual workers will handle this step for you!'
                  }
                >
                  <InfoOutlined className="text-yellow-500 !w-4 !h-4" />
                </Tooltip>
              ) : null}
            </div>

            {hitl || sitl ? (
              <span className="text-xs text-color-grey">
                {hitl ? 'Action manually handled' : 'Action handled by Sola'}
              </span>
            ) : null}
          </div>
        </div>
        <div className="flex space-x-4 items-center">
          {adminOnly ? (
            <Chip
              color="primary"
              label="Admin Only"
              size="small"
              sx={{ height: 20, fontSize: '.65rem' }}
              variant="outlined"
            />
          ) : null}
          {editAllowed ? (
            <IconButton
              className="!p-0"
              onClick={() => {
                onEditClick?.(action);
              }}
            >
              <EditIcon className="!w-5 !h-5 text-black" />
            </IconButton>
          ) : null}
          {!hitl && hidden ? (
            <Tooltip title="Hidden Action">
              <VisibilityOff className="!w-5 !h-5" />
            </Tooltip>
          ) : null}
          {hitl ? (
            <Tooltip title="Action handled manually">
              <Person className="!w-5 !h-5" />
            </Tooltip>
          ) : null}
          <ActionMenu
            action={action}
            allowDeleteAction={allowDeleteAction}
            onAddApprovalStep={onAddApprovalStep}
            onDeleteAction={onDeleteAction}
            onEditTarget={onEditTarget}
            onMoveAction={onMoveAction}
            onUpdateActionOptions={onUpdateActionOptions}
            showAdminToggles={showAdminToggles}
            showManualHandleOption={showManualHandleOption}
          />
        </div>
      </div>
      {(actionType !== ActionsEnum.Click || chooseFromList) &&
      actionType !== ActionsEnum.KeyPress &&
      !(actionType === ActionsEnum.Download || isDownloadAction) &&
      !mfa &&
      !captcha &&
      actionType !== ActionsEnum.ManualApproval ? (
        <div
          className={clsx(
            'mt-8 rounded-lg flex text-xs',
            'border border-gray-300',
          )}
        >
          <span className={clsx('p-3', 'border-r border-gray-300')}>
            {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
            {title ?? actionTypeToPreviewTitleMapping[actionType] ?? 'Value'}
          </span>
          {criteria === ActionValueCriteriaEnum.Condition ? (
            <Chip
              className="my-auto ml-3"
              color="primary"
              label={
                <div className="flex items-center">
                  Determined by conditionals
                  <ConditionalIcon className="!w-4 !ml-1" />
                </div>
              }
              size="small"
            />
          ) : (
            <ActionRenderer
              chooseFromList={chooseFromList}
              label={name}
              variable={variable}
              variablesMap={variablesMap}
            />
          )}
        </div>
      ) : null}
      {mfa && description && !isEditingDescription ? (
        <p
          className="!mt-4 text-xs text-color-grey"
          onClick={() => {
            if (showAdminToggles) {
              setIsEditingDescription(true);
            }
          }}
          role="presentation"
        >
          {description}
        </p>
      ) : null}

      {(isEditingDescription || !description) && showAdminToggles && mfa ? (
        <div className="!mt-4">
          <Input
            floatingLabel
            inputProps={{ style: { fontSize: '0.75rem' } }}
            multiline
            onBlur={updateDescription}
            onChange={setDescriptionText}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
                updateDescription();
              }
            }}
            placeholder="Enter Description"
            rows={2}
            value={descriptionText}
          />
        </div>
      ) : null}
    </div>
  );
}
