import { clsx } from 'clsx';
import type { ChangeEvent } from 'react';
import React, { useEffect, useRef } from 'react';
import { getCaretPosition } from './variable.helpers';

interface Props {
  id?: string;
  className?: string;
  value: string;
  disabled?: boolean;
  onChange: (val: string) => void;
  placeholder?: string;
  onBlur: (val: string) => void;
  onDeletePrevious?: (currentItemValue: string) => void;
  onMoveLeft?: () => void;
  onMoveRight?: () => void;
}

function Input({
  id,
  className,
  onChange,
  placeholder,
  value,
  disabled = false,
  onBlur,
  onDeletePrevious,
  onMoveLeft,
  onMoveRight,
}: Props) {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (ref.current && ref.current.textContent !== value) {
      ref.current.textContent = value;
    }
  }, [value]);

  const saveValue = (e: ChangeEvent<HTMLDivElement>) => {
    onChange(e.target.textContent ?? '');
  };

  const handleOnBlur = (e: ChangeEvent<HTMLDivElement>) => {
    const strVal = e.target.textContent ?? '';
    onBlur(strVal);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const { cursorAtEnd, cursorAtStart } = getCaretPosition();

    switch (e.key) {
      case 'Backspace':
        if (cursorAtStart) {
          onDeletePrevious?.(e.currentTarget.textContent ?? '');
        }
        break;
      case 'ArrowLeft':
        if (cursorAtStart) {
          onMoveLeft?.();
        }
        break;
      case 'ArrowRight':
        if (cursorAtEnd) {
          onMoveRight?.();
        }
        break;
      default:
        break;
    }
  };

  return (
    <>
      <span className="absolute left-0 top-1/2 -translate-y-1/2 text-gray-400">
        {placeholder}
      </span>
      <div
        className={clsx(
          'relative flex items-center outline-0 bg-transparent',
          className,
          {
            'text-gray-300': disabled,
            '!bg-white': value,
          },
        )}
        contentEditable={!disabled}
        id={id}
        onBlur={handleOnBlur}
        onInput={saveValue}
        onKeyDown={handleKeyDown}
        ref={ref}
        role="textbox"
        tabIndex={0}
      />
    </>
  );
}

export default Input;
