import { Toolbar } from 'dashboard-shared';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Chip,
  Flex,
  ScrollableContainer,
  Spinner,
  Typography,
} from 'ui-kit';
import type { ExecutionBase } from 'types-shared';
import { ExecutionTriggerEnum, ExecutionStatusEnum } from 'types-shared';
import type { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid';
import { getStatusColor } from '../pages/WorkflowDetail';
import {
  useDownloadExecutionData,
  useFetchExecutionDetail,
  useFetchExecutionScreenshots,
  useFetchExecutionVariables,
} from '../hooks';
import dayjs from '../../../config/dayjs';
import { downloadLinkData } from '../utils/helper';
import { Download } from 'assets-shared';
import React, { useMemo } from 'react';

interface ExecutionDetailProps {
  metadata: ExecutionBase;
}

const statusTitleMapping: Record<string, string> = {
  [ExecutionStatusEnum.PendingUser]: 'Running',
  [ExecutionStatusEnum.PendingSystem]: 'Running',
};

const columns: GridColDef<ExecutionDetailProps>[] = [
  {
    field: 'recordId',
    headerName: 'Record ID',
    headerClassName: '!pl-0',
    valueGetter: ({ row: { metadata } }) =>
      metadata.trigger === ExecutionTriggerEnum.API
        ? 'N/A'
        : Number(metadata.recordId) + 1,
  },
  {
    field: 'executionId',
    headerName: 'Run ID',
    flex: 2,
    valueGetter: (params) => params.row.metadata.executionId,
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    flex: 2,
    valueGetter: (params) => params.row.metadata.lastUpdatedAt,
    valueFormatter: (params: GridValueFormatterParams<string | undefined>) => {
      if (!params.value) {
        return 'No updates yet';
      }
      return dayjs(new Date(params.value)).format('MM/DD/YY -  h:mm A');
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    renderCell: ({ row: { metadata } }) => {
      const title =
        statusTitleMapping[metadata.status] ??
        metadata.status.toLowerCase().replace('_', ' ');
      return (
        <Chip
          className="!h-6"
          color={getStatusColor(metadata.status)}
          label={title}
          sx={{ '& .MuiChip-label': { textTransform: 'capitalize' } }}
          variant="filled"
        />
      );
    },
  },
];

export function ExecutionDetailContentOld() {
  const { executionId } = useParams();
  if (!executionId) {
    throw Error('executionId not found!');
  }

  const navigate = useNavigate();
  const { data: executionDetail } = useFetchExecutionDetail(executionId);
  const { data: imageArray = [] } = useFetchExecutionScreenshots(
    executionId,
    executionDetail?.imageUrls ?? [],
  );
  const { data: variables = {} } = useFetchExecutionVariables(
    executionId,
    executionDetail?.variablesUrls ?? [],
  );
  const { mutateAsync: getExecutionZippedFile, status } =
    useDownloadExecutionData();

  const downloadZippedOutput = async () => {
    const link = await getExecutionZippedFile([executionId]);
    downloadLinkData(link);
  };

  const executionDetailVariables = useMemo(() => {
    return Object.entries(variables);
  }, [variables]);

  return (
    <ScrollableContainer>
      <Toolbar
        onBack={() => {
          if (executionDetail?.metadata) {
            navigate(`/workflows/${executionDetail.metadata.workflowId}`, {
              replace: true,
            });
          }
        }}
        pageInfo={`Execution ID: ${executionId}`}
      />
      <div className="pt-12 pb-8 px-8 overflow-y-auto relative flex-grow h-[calc(100vh_-_92px)]">
        <Typography className="font-medium" variant="h5">
          Executed record details
        </Typography>
        {executionDetail ? (
          <>
            <DataGrid<ExecutionDetailProps>
              className="max-h-40 mt-10"
              columns={columns}
              getRowId={(row) => row.metadata.executionId}
              hideFooterPagination
              rows={[executionDetail]}
            />
            <Flex className="gap-7 mt-14">
              <Flex className="space-y-5 flex-1" flexDirection="column">
                <Flex className="gap-4 items-center mb-0.5">
                  <span className="font-semibold text-base">Outputs</span>
                  <Button
                    className="!border-none"
                    color="secondary"
                    disabled={status === 'pending'}
                    onClick={downloadZippedOutput}
                    variant="outlined"
                  >
                    <Download className="mr-1" fontSize="small" />
                    Export
                  </Button>
                </Flex>
                {executionDetailVariables.length ? (
                  <div className="rounded bg-gray-100 p-9 min-h-[20rem]">
                    <pre className="whitespace-pre-wrap">
                      {executionDetailVariables.map(([key, value]) => (
                        <div className="text-xs" key={key}>
                          <span className="font-semibold">{key}:</span>{' '}
                          {JSON.stringify(value, null, 2)}
                        </div>
                      ))}
                    </pre>
                  </div>
                ) : (
                  <div className="rounded bg-gray-100 p-9 min-h-[20rem] flex justify-center items-center">
                    No outputs from the run
                  </div>
                )}
              </Flex>
              <Flex className="space-y-8 flex-1" flexDirection="column">
                <span className="font-semibold text-base">Screenshots</span>

                {imageArray.length ? (
                  <div className="flex flex-col space-y-4">
                    {[...imageArray]
                      .reverse()
                      .map((image, index, { length }) => {
                        const imageUrl = URL.createObjectURL(image[1]);
                        return (
                          <img
                            alt={`Execution detail ${length - index}`}
                            className="block object-contain rounded border"
                            key={imageUrl.slice(0, 16)} // TODO(neil): Fix this hack for unique key
                            src={imageUrl}
                          />
                        );
                      })}
                  </div>
                ) : (
                  <div className="rounded bg-gray-100 p-9 text-blue-900 min-h-[20rem] flex justify-center items-center">
                    <Spinner size={30} />
                  </div>
                )}
              </Flex>
            </Flex>
          </>
        ) : null}
      </div>
    </ScrollableContainer>
  );
}
