"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.elementText = exports.getDatasources = exports.parseVariablesFromWorkflow = exports.parseVariables = exports.stringifyVariable = exports.stringifyTemplateString = exports.StringifyVariableMode = void 0;
var logic_schema_1 = require("../logicTypes/logic.schema");
var triggerTypes_1 = require("../triggerTypes");
var variable_schema_1 = require("./variable.schema");
var workflow_schema_1 = require("./workflow.schema");
var StringifyVariableMode;
(function (StringifyVariableMode) {
    StringifyVariableMode["Dashboard"] = "Dashboard";
    StringifyVariableMode["Execution"] = "Execution";
})(StringifyVariableMode || (exports.StringifyVariableMode = StringifyVariableMode = {}));
var stringifyTemplateString = function (data, variableMap, recordData, transformFunction, mode) {
    if (mode === void 0) { mode = StringifyVariableMode.Dashboard; }
    return __awaiter(void 0, void 0, void 0, function () {
        var stringifiedVariable, stringifiedParts;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    stringifiedVariable = '';
                    return [4 /*yield*/, Promise.all(data.map(function (item) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (typeof item === 'string') {
                                    return [2 /*return*/, item];
                                }
                                return [2 /*return*/, (0, exports.stringifyVariable)(variableMap[item.id], variableMap, recordData, transformFunction, mode)];
                            });
                        }); }))];
                case 1:
                    stringifiedParts = _a.sent();
                    stringifiedVariable += stringifiedParts.join('');
                    return [2 /*return*/, stringifiedVariable];
            }
        });
    });
};
exports.stringifyTemplateString = stringifyTemplateString;
var stringifyVariable = function (variable, variableMap, recordData, transformFunction, mode) {
    if (mode === void 0) { mode = StringifyVariableMode.Dashboard; }
    return __awaiter(void 0, void 0, void 0, function () {
        var _a, recordDataValue, transformResult;
        var _b, _c, _d, _e, _f, _g;
        return __generator(this, function (_h) {
            switch (_h.label) {
                case 0:
                    _a = variable.type;
                    switch (_a) {
                        case variable_schema_1.VariableTypeEnum.Template: return [3 /*break*/, 1];
                        case variable_schema_1.VariableTypeEnum.Datasource: return [3 /*break*/, 2];
                        case variable_schema_1.VariableTypeEnum.Scrape: return [3 /*break*/, 8];
                        case variable_schema_1.VariableTypeEnum.Select: return [3 /*break*/, 9];
                        case variable_schema_1.VariableTypeEnum.MultiChoice: return [3 /*break*/, 10];
                    }
                    return [3 /*break*/, 11];
                case 1: return [2 /*return*/, (0, exports.stringifyTemplateString)(variable.data, variableMap, recordData, transformFunction, mode)];
                case 2:
                    if (mode === StringifyVariableMode.Dashboard &&
                        variable.data.triggerType === triggerTypes_1.TriggerTypeEnum.API) {
                        return [2 /*return*/, (((_b = variable.transformData) === null || _b === void 0 ? void 0 : _b.transformedValue) ||
                                ((_c = variable.transformData) === null || _c === void 0 ? void 0 : _c.initialValue) ||
                                '')];
                    }
                    if (!recordData) {
                        throw new Error("No record data provided for datasource variable: ".concat(JSON.stringify(variable)));
                    }
                    recordDataValue = recordData[variable.data.key];
                    if (!(transformFunction && ((_d = variable.transformData) === null || _d === void 0 ? void 0 : _d.query))) return [3 /*break*/, 7];
                    transformResult = void 0;
                    if (!(mode === StringifyVariableMode.Dashboard &&
                        variable.transformData.transformedValue)) return [3 /*break*/, 3];
                    transformResult = variable.transformData.transformedValue;
                    return [3 /*break*/, 6];
                case 3:
                    if (!(mode === StringifyVariableMode.Execution &&
                        ((_e = variable.executionData) === null || _e === void 0 ? void 0 : _e.transformedValue))) return [3 /*break*/, 4];
                    transformResult = variable.executionData.transformedValue;
                    return [3 /*break*/, 6];
                case 4: return [4 /*yield*/, transformFunction({
                        variable: variable,
                        preTransformValue: recordDataValue,
                    })];
                case 5:
                    transformResult = _h.sent();
                    _h.label = 6;
                case 6:
                    if (!transformResult) {
                        throw new Error('Transform result is undefined');
                    }
                    return [2 /*return*/, transformResult];
                case 7: return [2 /*return*/, recordDataValue];
                case 8:
                    if (mode === StringifyVariableMode.Dashboard) {
                        if (variable.data.scrapedText === undefined) {
                            throw new Error("No scraped text found for scrape variable: ".concat(JSON.stringify(variable)));
                        }
                        return [2 /*return*/, variable.data.scrapedText];
                    }
                    return [2 /*return*/, (((_f = variable.executionData) === null || _f === void 0 ? void 0 : _f.transformedValue) ||
                            ((_g = variable.executionData) === null || _g === void 0 ? void 0 : _g.initialValue) ||
                            '')];
                case 9: return [2 /*return*/, (0, exports.stringifyTemplateString)(variable.data, variableMap, recordData, transformFunction, mode)];
                case 10: return [2 /*return*/, String(variable.selectedChoiceIx || 0)];
                case 11: throw new Error("Unsupported variable type ".concat(JSON.stringify(variable)));
            }
        });
    });
};
exports.stringifyVariable = stringifyVariable;
var parseVariables = function (variableId, variableMap) {
    var mapValue = variableMap[variableId];
    var parsedVariable = variable_schema_1.TemplateVariable.safeParse(mapValue);
    if (!parsedVariable.success) {
        return [variableId];
    }
    var variable = parsedVariable.data;
    var variableIds = variable.data.flatMap(function (item) {
        var subVariable = variable_schema_1.VariableRef.safeParse(item);
        if (subVariable.success) {
            return (0, exports.parseVariables)(subVariable.data.id, variableMap);
        }
        return [];
    });
    return __spreadArray([variableId], variableIds, true);
};
exports.parseVariables = parseVariables;
var parseVariablesForGroup = function (group, variableMap) {
    var variableIds = new Set();
    for (var _i = 0, _a = group.elements; _i < _a.length; _i++) {
        var element = _a[_i];
        if (logic_schema_1.Group.safeParse(element).success) {
            var subGroup = element;
            parseVariablesForGroup(subGroup, variableMap).forEach(function (id) {
                return variableIds.add(id);
            });
        }
        else {
            var _b = element, field = _b.field, value = _b.value;
            for (var _c = 0, _d = [field, value]; _c < _d.length; _c++) {
                var comparisonItem = _d[_c];
                if (!comparisonItem) {
                    continue;
                }
                var data = comparisonItem.data;
                data.forEach(function (item) {
                    if (variable_schema_1.VariableRef.safeParse(item).success) {
                        variableIds.add(item.id);
                    }
                });
            }
        }
    }
    return variableIds;
};
var parseVariablesFromWorkflow = function (nodes, variableMap) {
    var variableIds = new Set();
    for (var _i = 0, nodes_1 = nodes; _i < nodes_1.length; _i++) {
        var node = nodes_1[_i];
        if (node.type === workflow_schema_1.NodeTypesEnum.Image) {
            for (var _a = 0, _b = Object.values(node.data.actionData); _a < _b.length; _a++) {
                var action = _b[_a];
                if (action.variableId) {
                    (0, exports.parseVariables)(action.variableId, variableMap).forEach(function (id) {
                        return variableIds.add(id);
                    });
                }
            }
        }
        else if (node.type === workflow_schema_1.NodeTypesEnum.Conditional) {
            var branchesDataRules = node.data.branchesData;
            if (branchesDataRules) {
                branchesDataRules.forEach(function (branch) {
                    if (!branch.rule) {
                        return;
                    }
                    var _a = branch.rule, data = _a.data, output = _a.output;
                    parseVariablesForGroup(data, variableMap).forEach(function (id) {
                        return variableIds.add(id);
                    });
                    output.forEach(function (subOutput) {
                        if (variable_schema_1.Variable.safeParse(subOutput).success) {
                            (0, exports.parseVariables)(subOutput.id, variableMap).forEach(function (id) {
                                return variableIds.add(id);
                            });
                            variableIds.delete(subOutput.id);
                        }
                    });
                });
            }
        }
    }
    return variableIds;
};
exports.parseVariablesFromWorkflow = parseVariablesFromWorkflow;
var getDatasources = function (variableIds, variableMap) {
    var datasources = [];
    for (var _i = 0, variableIds_1 = variableIds; _i < variableIds_1.length; _i++) {
        var variableId = variableIds_1[_i];
        var variable = variableMap[variableId];
        if (variable.type === variable_schema_1.VariableTypeEnum.Datasource) {
            datasources.push(variable.data.datasourceId);
        }
    }
    return datasources;
};
exports.getDatasources = getDatasources;
var elementText = function (element) {
    if (element.innerText) {
        return element.innerText;
    }
    else if (element.ariaLabel) {
        return element.ariaLabel;
    }
    else if (element.labelText) {
        return (0, exports.elementText)(element.labelText);
    }
    return null;
};
exports.elementText = elementText;
