import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export default function EditBranchIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99998 6.66676L3.33331 13.3334V16.6668L6.66665 16.6667L13.3333 10.0001M9.99998 6.66676L12.3905 4.27622L12.3919 4.2748C12.721 3.94573 12.8858 3.78091 13.0758 3.71917C13.2432 3.66479 13.4235 3.66479 13.5909 3.71917C13.7807 3.78086 13.9454 3.9455 14.274 4.2741L15.7238 5.72393C16.0538 6.05395 16.2189 6.21903 16.2807 6.4093C16.3351 6.57667 16.3351 6.75696 16.2807 6.92433C16.2189 7.11447 16.0541 7.2793 15.7246 7.60884L15.7238 7.60955L13.3333 10.0001M9.99998 6.66676L13.3333 10.0001"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </SvgIcon>
  );
}
