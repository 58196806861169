import React from 'react';
import { APICallIcon } from 'assets-shared';

export function APICallNodeCore() {
  return (
    <>
      <div className="flex-1 rounded-lg border border-indigo-light bg-info-dark text-white flex justify-center items-center">
        <APICallIcon className="!w-[3.75rem] !h-[3.75rem]" />
      </div>
      <span className="text-sm font-bold truncate">API Call</span>
    </>
  );
}
