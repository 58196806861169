"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueryPaths = exports.MultiChoiceQueryPaths = exports.SelectQueryPaths = exports.BaseQueryPaths = exports.SelectOption = exports.RelativeCoordinates = exports.RawCoordinates = void 0;
var zod_1 = require("zod");
exports.RawCoordinates = zod_1.z.object({
    x: zod_1.z.number(),
    y: zod_1.z.number(),
    width: zod_1.z.number(),
    height: zod_1.z.number(),
});
exports.RelativeCoordinates = zod_1.z.object({
    xPercent: zod_1.z.number(),
    yPercent: zod_1.z.number(),
    widthPercent: zod_1.z.number(),
    heightPercent: zod_1.z.number(),
});
exports.SelectOption = zod_1.z.object({
    value: zod_1.z.string(),
    text: zod_1.z.string(),
});
var baseQueryPathsSchema = zod_1.z.object({
    id: zod_1.z.string().optional(),
    coordinates: exports.RawCoordinates,
    ariaId: zod_1.z.string().optional(),
    role: zod_1.z.string().optional(),
    innerText: zod_1.z.string().optional(),
    cssPath: zod_1.z.string(),
    xpath: zod_1.z.string().optional(),
    xpathFull: zod_1.z.string().optional(),
    placeholder: zod_1.z.string().optional(),
    directInnerText: zod_1.z.string().optional(),
    alt: zod_1.z.string().optional(),
    title: zod_1.z.string().optional(),
    name: zod_1.z.string().optional(),
    dataTooltip: zod_1.z.string().optional(),
    ariaLabel: zod_1.z.string().optional(),
    selectOptions: zod_1.z.array(exports.SelectOption).optional(),
});
var baseSelectQueryPaths = baseQueryPathsSchema.required({
    selectOptions: true,
});
exports.BaseQueryPaths = baseQueryPathsSchema
    .extend({
    labelText: zod_1.z.lazy(function () { return exports.BaseQueryPaths; }).optional(),
    iframeElements: zod_1.z.lazy(function () { return zod_1.z.array(exports.BaseQueryPaths); }).optional(),
})
    .passthrough();
exports.SelectQueryPaths = baseSelectQueryPaths
    .extend({
    labelText: exports.BaseQueryPaths.optional(),
    iframeElements: zod_1.z.array(exports.BaseQueryPaths).optional(),
})
    .passthrough();
exports.MultiChoiceQueryPaths = baseQueryPathsSchema
    .extend({
    multiChoiceOptions: zod_1.z.array(exports.BaseQueryPaths),
    labelText: exports.BaseQueryPaths.optional(),
    iframeElements: zod_1.z.array(exports.BaseQueryPaths).optional(),
})
    .passthrough();
exports.QueryPaths = zod_1.z.union([
    exports.BaseQueryPaths,
    exports.SelectQueryPaths,
    exports.MultiChoiceQueryPaths,
]);
