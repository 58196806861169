import type { Comparator, WorkflowData } from 'types-shared';
import {
  NodeStatusEnum,
  NodeTypesEnum,
  WorkflowEdge,
  WorkflowNode,
} from 'types-shared';
import isNil from 'lodash/isNil';
import startCase from 'lodash/startCase';
import { v4 as uuid } from 'uuid';
import { autoFormat } from './autoformat';

export const getAllNodesAfter = (
  node: WorkflowNode,
  nodes: WorkflowNode[],
  edges: WorkflowEdge[],
): string[] => {
  const visitedNodeIds = new Set<string>();
  const result: string[] = [];

  const traverseNodes = (currentNode: WorkflowNode) => {
    if (!visitedNodeIds.has(currentNode.id)) {
      visitedNodeIds.add(currentNode.id);
      result.push(currentNode.id);
      const nextNodeIds = edges
        .filter((edge) => edge.source === currentNode.id)
        .map((edge) => edge.target);

      nextNodeIds.forEach((nextNodeId) => {
        const nextNode = nodes.find((item) => item.id === nextNodeId);
        if (nextNode) traverseNodes(nextNode);
      });
    }
  };

  traverseNodes(node);
  return result;
};

export const findSiblingNodeIds = (
  sourceNode: WorkflowNode,
  _edges: WorkflowEdge[],
) => {
  return _edges
    .filter((edge) => edge.source === sourceNode.id)
    .map((edge) => edge.target)
    .filter((e) => !isNil(e));
};

export const comparatorToLabel = (comparator: Comparator) => {
  const comparatorLabel = startCase(comparator);
  return comparatorLabel.charAt(0) + comparatorLabel.substring(1).toLowerCase();
};

export const insertNodeAfter = (
  source: WorkflowNode | WorkflowEdge,
  nodes: WorkflowNode[],
  edges: WorkflowEdge[],
  setFunctions: {
    setNodes: (nodes: WorkflowNode[]) => void;
    setEdges: (edges: WorkflowEdge[]) => void;
  },
  branch = false,
) => {
  let sourceNode: WorkflowNode | undefined;
  let intermediateNode: WorkflowNode;
  let targetNode: WorkflowNode | undefined;
  const sourceEdgeParse = WorkflowEdge.safeParse(source);
  const nodeLabelMap: Record<string, string> = {};
  const labelProps = {
    label: 'Branch 1',
    labelStyle: { display: 'none' },
  };

  let updatedEdges = [...edges];
  if (sourceEdgeParse.success) {
    const sourceEdge = sourceEdgeParse.data;
    sourceNode = nodes.find((node) => node.id === sourceEdge.source);
    if (!sourceNode) {
      throw new Error('sourceNode not found');
    }
    targetNode = nodes.find((node) => node.id === sourceEdge.target);
    intermediateNode = {
      id: uuid(),
      position: { ...sourceNode.position },
      type: NodeTypesEnum.New,
      data: { nodeStatus: NodeStatusEnum.NotViewed },
      width: 256,
      height: 232,
    };
    const newEdgesToAdd = [
      { ...sourceEdge, target: intermediateNode.id },
      ...(targetNode
        ? [
            {
              id: uuid(),
              source: intermediateNode.id,
              target: targetNode.id,
              ...labelProps,
            },
          ]
        : []),
    ];
    updatedEdges = updatedEdges
      .filter((edge) => edge.id !== sourceEdge.id)
      .concat(newEdgesToAdd);
  } else {
    sourceNode = WorkflowNode.parse(source);
    intermediateNode = {
      id: uuid(),
      position: { ...sourceNode.position },
      type: NodeTypesEnum.New,
      data: { nodeStatus: NodeStatusEnum.NotViewed },
      width: 256,
      height: 232,
    };

    const newEdge: WorkflowEdge = {
      id: uuid(),
      source: sourceNode.id,
      target: intermediateNode.id,
    };

    if (!branch) {
      const outgoingEdges = updatedEdges.filter(
        (edge) => edge.source === sourceNode?.id,
      );
      const newEdgesToAdd = outgoingEdges.map((edge) => ({
        ...edge,
        source: intermediateNode.id,
        ...labelProps,
      }));
      updatedEdges = updatedEdges
        .filter(
          (edge) =>
            !outgoingEdges.some((outgoingEdge) => outgoingEdge.id === edge.id),
        )
        .concat(newEdgesToAdd);
    } else {
      const siblingNodeIds = findSiblingNodeIds(sourceNode, edges);
      if (siblingNodeIds.length === 1) {
        const siblingEdge = edges.find(
          (edge) =>
            edge.source === sourceNode?.id && edge.target === siblingNodeIds[0],
        );
        if (!siblingEdge) {
          throw new Error('siblingEdge not found');
        }
        nodeLabelMap[siblingEdge.id] = `Branch 1`;
      }
      nodeLabelMap[newEdge.id] = `Branch ${siblingNodeIds.length + 1}`;
    }
    updatedEdges.push(newEdge);
  }
  const updatedNodes = [...nodes, intermediateNode];
  autoFormat(updatedNodes, updatedEdges, setFunctions.setNodes);
  setFunctions.setEdges(
    updatedEdges.map((edge) =>
      nodeLabelMap[edge.id]
        ? {
            ...edge,
            label: nodeLabelMap[edge.id],
            labelStyle: { display: 'block' },
          }
        : edge,
    ),
  );
  return intermediateNode.id;
};

export const appendDatasourceNode = (
  workflowData: WorkflowData,
): WorkflowData => {
  const { nodes, edges } = workflowData;
  if (nodes.some((node) => node.type === NodeTypesEnum.Datasource)) {
    return workflowData;
  }
  const dataSourceNodeId = uuid();

  const newNode = {
    id: dataSourceNodeId,
    position: {
      x: 0,
      y: 0,
    },
    type: NodeTypesEnum.Datasource,
    width: 256,
    height: 232,
    data: {
      nodeStatus: NodeStatusEnum.NotViewed,
    },
  };
  const newEdges: WorkflowEdge[] = [
    {
      id: uuid(),
      source: dataSourceNodeId,
      target: nodes[0]?.id,
    },
    ...edges,
  ];

  let newNodes = [newNode, ...nodes] as WorkflowNode[];
  const newNodePositions = autoFormat(newNodes, newEdges);

  newNodes = newNodes.map((node) => ({
    ...node,
    position: newNodePositions[node.id],
  }));

  return {
    ...workflowData,
    nodes: newNodes,
    edges: newEdges,
  };
};

export const extractDaysHoursMinutes = (minutes: number) => {
  const days = Math.floor(minutes / (24 * 60));
  const remainingMinutes = minutes % (24 * 60);
  const hours = Math.floor(remainingMinutes / 60);
  const minutesLeft = remainingMinutes % 60;
  return { days, hours, minutes: minutesLeft };
};

export const getTotalMinutes = ({
  days,
  hours,
  minutes,
}: {
  days: number;
  hours: number;
  minutes: number;
}) => {
  const totalMinutes: number = days * 24 * 60 + hours * 60 + minutes;
  return totalMinutes;
};
