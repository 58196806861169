import { axios } from 'api-types-shared';
export const uploadBlobToS3 = async (blob, url) => {
    return axios.put(url, blob, {
        headers: {
            'Content-Type': blob.type,
            'Content-Length': blob.size.toString(),
        },
    });
};
const getFileUrlWithoutQueryParams = (url) => url.split('?')[0] ?? url;
export const getBlobFromS3 = async (url) => {
    const response = await axios.get(url, {
        responseType: 'blob',
    });
    const blob = new Blob([response.data]);
    return [getFileUrlWithoutQueryParams(url), blob];
};
