import Modal from '@mui/material/Modal';
import React, { type ReactNode } from 'react';
import { clsx } from 'clsx';
import Box from '@mui/material/Box';
import IconButton from './Button/IconButton';
import Close from '@mui/icons-material/Close';

interface Props {
  children?: ReactNode;
  open: boolean;
  onClose: () => void;
  className?: string;
  showCloseIcon?: boolean;
}

function SolaModal({
  children,
  open,
  onClose,
  className,
  showCloseIcon = true,
}: Props) {
  return (
    <Modal
      className="p-4 flex justify-center items-center"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onClose={onClose}
      open={open}
    >
      <div
        className={clsx(
          'flex bg-white rounded-2xl p-4 overflow-auto w-full max-w-screen-xl relative',
          className,
        )}
      >
        {showCloseIcon ? (
          <Box className="absolute right-4 top-4 border border-info rounded-lg">
            <IconButton color="secondary" onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        ) : null}
        <Box className="p-12 w-full !pb-8">{children}</Box>
      </div>
    </Modal>
  );
}

export default SolaModal;
