import type { EdgeProps } from 'types-shared/reactflow';
import { EditorStore } from '../../store/EditorState';
import { CustomEdgeCore, useEditingNodeId } from 'editor-shared';

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  selected,
  source,
  target,
}: EdgeProps) {
  const edgeProps = {
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    selected,
    source,
    target,
  };
  const { nodes, edges, setEdges, setNodes } = EditorStore();
  const { setEditingNodeId } = useEditingNodeId();

  return (
    <CustomEdgeCore
      edgeProps={edgeProps}
      edges={edges}
      nodes={nodes}
      onAddNode={(nodeId: string) => {
        setEditingNodeId(nodeId);
      }}
      setEdges={setEdges}
      setNodes={setNodes}
    />
  );
}
