"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getWorkflowSchema = void 0;
var types_shared_1 = require("types-shared");
var utils_schema_1 = require("../utils.schema");
exports.getWorkflowSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z.object({
            workflowId: types_shared_1.z.string(),
        }),
        query: types_shared_1.z.object({
            imageOriginal: utils_schema_1.booleanStringTransform,
            imageIds: types_shared_1.z
                .string()
                .transform(function (valList) { return valList.split(','); })
                .optional(),
            stateReq: utils_schema_1.booleanStringTransform,
            targetReq: utils_schema_1.booleanStringTransform,
            variableReq: utils_schema_1.booleanStringTransform,
            videoReq: utils_schema_1.booleanStringTransform,
        }),
        body: types_shared_1.z.object({}),
    }),
    response: types_shared_1.z.object({
        stateUrl: types_shared_1.z.string().optional(),
        imageUrls: types_shared_1.z.record(types_shared_1.z.string()).optional(),
        variableUrl: types_shared_1.z.string().optional(),
        targetUrl: types_shared_1.z.string().optional(),
        videoUrl: types_shared_1.z.string().optional(),
    }),
};
