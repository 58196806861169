"use strict";
// String functions
Object.defineProperty(exports, "__esModule", { value: true });
exports.doesNotExistFunc = exports.existsFunc = exports.lessThanOrEqualFunc = exports.lessThanFunc = exports.greaterThanOrEqualFunc = exports.greaterThanFunc = exports.notEqualFunc = exports.equalFunc = exports.notContainsFunc = exports.containsFunc = void 0;
var containsFunc = function (a, b) {
    if (!(typeof a === 'string') || !(typeof b === 'string')) {
        throw new Error("Invalid type for contains function: ".concat(typeof a, " ").concat(typeof b));
    }
    return a.includes(b);
};
exports.containsFunc = containsFunc;
var notContainsFunc = function (a, b) {
    if (!(typeof a === 'string') || !(typeof b === 'string')) {
        throw new Error("Invalid type for notContains function: ".concat(typeof a, " ").concat(typeof b));
    }
    return !a.includes(b);
};
exports.notContainsFunc = notContainsFunc;
// General functions
var equalFunc = function (a, b) {
    if (a instanceof Date && b instanceof Date) {
        return a.getTime() === b.getTime();
    }
    return a === b;
};
exports.equalFunc = equalFunc;
var notEqualFunc = function (a, b) {
    if (a instanceof Date && b instanceof Date) {
        return a.getTime() !== b.getTime();
    }
    return a !== b;
};
exports.notEqualFunc = notEqualFunc;
var greaterThanFunc = function (a, b) {
    return a > b;
};
exports.greaterThanFunc = greaterThanFunc;
var greaterThanOrEqualFunc = function (a, b) {
    return a >= b;
};
exports.greaterThanOrEqualFunc = greaterThanOrEqualFunc;
var lessThanFunc = function (a, b) {
    return a < b;
};
exports.lessThanFunc = lessThanFunc;
var lessThanOrEqualFunc = function (a, b) {
    return a <= b;
};
exports.lessThanOrEqualFunc = lessThanOrEqualFunc;
// Note: B is unused in these functions, but we need to pass it in to satisfy the type
var existsFunc = function (a, _b) {
    return Boolean(a);
};
exports.existsFunc = existsFunc;
var doesNotExistFunc = function (a, _b) {
    return !a;
};
exports.doesNotExistFunc = doesNotExistFunc;
