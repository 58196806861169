import React, { useEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { useGetOriginalImageData } from '../../hooks';
import type { EditorStoreProps } from '../../store/EditorState';
import { EditorStore } from '../../store/EditorState';
import type { NodeData } from 'types-shared/workflowTypes';
import type { EventMap } from 'ui-kit';
import { createEventBus } from 'ui-kit';
import { SelectedImageNodeContentCore } from 'editor-shared';

interface ImageNodeEvents extends EventMap {
  onActionHover: (targetId: string | null) => void;
  onTargetHover: (targetId: string | null) => void;
}

export const imageNodeEventChannel = createEventBus<ImageNodeEvents>();

interface Props {
  nodeData: NodeData;
  className?: string;
}

export default function SelectedImageNodeContent({
  nodeData,
  className,
}: Props) {
  const { imageData } = nodeData;

  const { selectedNode, targets, workflowId, setNodeImage } = EditorStore(
    useShallow((state: EditorStoreProps) => ({
      selectedNode: state.selectedNode,
      targets: state.targets,
      workflowId: state.workflowId,
      setNodeImage: state.setImage,
    })),
  );
  const [activeTarget, setActiveTarget] = useState<string | null>(null);

  const { data: fullImageData, status } = useGetOriginalImageData(
    workflowId,
    imageData.imageId,
    true,
  );

  const emitTargetHover = (targetId: string | null) => {
    imageNodeEventChannel.emit('onTargetHover', targetId);
  };

  useEffect(() => {
    const unsubscribeActionHover = imageNodeEventChannel.on(
      'onActionHover',
      setActiveTarget,
    );

    return () => {
      unsubscribeActionHover();
    };
  }, []);

  return (
    <SelectedImageNodeContentCore
      activeTarget={activeTarget}
      className={className}
      fullImageData={fullImageData}
      loading={status === 'pending'}
      nodeData={nodeData}
      onTargetHover={emitTargetHover}
      onTargetLeave={() => {
        emitTargetHover(null);
      }}
      selectedNode={selectedNode}
      setNodeImage={setNodeImage}
      targets={targets}
    />
  );
}
