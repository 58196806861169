import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export default function StopIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.00006 12C3.00006 16.9706 7.0295 21 12.0001 21C16.9706 21 21.0001 16.9706 21.0001 12C21.0001 7.02944 16.9706 3 12.0001 3C7.0295 3 3.00006 7.02944 3.00006 12Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <path
        d="M15.0001 13.3996V10.5996C15.0001 10.0396 14.9997 9.75981 14.8907 9.5459C14.7948 9.35774 14.6424 9.20487 14.4542 9.10899C14.2403 9 13.9597 9 13.3997 9H10.5997C10.0396 9 9.75987 9 9.54596 9.10899C9.3578 9.20487 9.20493 9.35774 9.10905 9.5459C9.00006 9.75981 9.00006 10.0396 9.00006 10.5996V13.3996C9.00006 13.9597 9.00006 14.2403 9.10905 14.4542C9.20493 14.6423 9.3578 14.7948 9.54596 14.8906C9.75987 14.9996 10.0396 15 10.5997 15H13.3997C13.9597 15 14.2403 14.9996 14.4542 14.8906C14.6424 14.7948 14.7948 14.6423 14.8907 14.4542C14.9997 14.2403 15.0001 13.9597 15.0001 13.3996Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
    </SvgIcon>
  );
}
