import { Bordered, ConditionalLabel, Padded, Separator } from './UiElements';
import React, { useState } from 'react';
import type {
  Condition,
  DatasourceMetadata,
  DatasourceTable,
  Rule,
  Variable,
  TriggerTypeEnum,
} from 'types-shared';
import { OperatorEnum } from 'types-shared';
import { Button, IconButton } from 'ui-kit';
import GroupBlock from './GroupBlock';
import {
  addConditionHelper,
  deleteConditionHelper,
  extractLabel,
  initialGroup,
  updateConditionHelper,
} from './conditions.helpers';
import { v4 as uuid } from 'uuid';
import ConditionalInput from './ConditionalInput';
import { CloseCircle } from 'assets-shared';
import { clsx } from 'clsx';

interface Props {
  variable: Variable;
  variablesMap: Record<string, Variable>;
  datasourceMetadata: DatasourceMetadata | null;
  tableData: DatasourceTable | null;
  triggerType?: TriggerTypeEnum;
  addVariable: (variable: Variable) => void;
  updateVariable: (variable: Variable) => void;
  defaultRules?: Rule[];

  onCancel: () => void;
  onSave: (rules: Rule[]) => void;
  transformDataStatus: 'error' | 'idle' | 'pending' | 'success' | 'loading';
  onTransformData: (
    prompt: string,
    textToTransform: string,
  ) => Promise<string | undefined>;
  preview?: boolean;
}

export function ConditionalField({
  variable,
  variablesMap,
  datasourceMetadata,
  tableData,
  addVariable,
  updateVariable,
  defaultRules,
  onSave,
  onCancel: _onCancel,
  transformDataStatus,
  onTransformData,
  preview,
  triggerType,
}: Props) {
  const [showingVariableModal, setShowingVariableModal] =
    useState<boolean>(false);
  const label = extractLabel(variable);

  const [rules, setRules] = useState(
    defaultRules?.slice(0, -1) ?? [
      {
        data: initialGroup,
        output: [variable],
      },
    ],
  );

  const [elseRule, setElseRule] = useState<Rule>(
    defaultRules?.slice(-1)[0] ?? {
      data: {
        id: uuid(),
        operator: OperatorEnum.Or,
        elements: [],
      },
      output: [variable],
    },
  );

  const addGroup = () => {
    setRules((r) => [...r, { data: initialGroup, output: [variable] }]);
  };

  const addCondition = (i: number, groupId: string, operator: OperatorEnum) => {
    setRules((r) => {
      return r.map((rule, index) => {
        if (index === i) {
          return {
            ...rule,
            data: addConditionHelper(rule.data, groupId, operator),
          };
        }
        return rule;
      });
    });
  };

  const deleteCondition = (i: number, conditionId: string) => {
    setRules((r) => {
      return r.map((rule, index) => {
        if (index === i) {
          return {
            ...rule,
            data: deleteConditionHelper(rule.data, conditionId),
          };
        }
        return rule;
      });
    });
  };

  const updateCondition = (
    i: number,
    conditionId: string,
    condition: Partial<Condition>,
  ) => {
    setRules((r) => {
      return r.map((rule, index) => {
        if (index === i) {
          return {
            ...rule,
            data: updateConditionHelper(rule.data, conditionId, condition),
          };
        }
        return rule;
      });
    });
  };

  const updateRuleOutput = (i: number, updatedVariable: Variable) => {
    setRules((r) => {
      return r.map((rule, index) => {
        if (index === i) {
          return {
            ...rule,
            output: [updatedVariable],
          };
        }
        return rule;
      });
    });
  };

  const saveChanges = () => {
    onSave([...rules, elseRule]);
  };

  const onDeleteRule = (i: number) => {
    setRules((r) => r.filter((_, index) => index !== i));
  };

  const onCancel = () => {
    setRules(
      defaultRules ?? [
        {
          data: initialGroup,
          output: [variable],
        },
      ],
    );
    _onCancel();
  };

  return (
    <div
      className={clsx(
        { 'pointer-events-none opacity-60': preview },
        { 'h-0 overflow-hidden': showingVariableModal },
      )}
    >
      <p className="text-color-secondary-text mb-6">
        Determine selection using conditional logic
      </p>
      <Bordered>
        {rules.map((r, index) => {
          return (
            <React.Fragment key={r.data.id}>
              <Padded>
                <ConditionalLabel>
                  <span>{label}</span>
                  {rules.length > 1 ? (
                    <IconButton
                      className="!text-info-dark cursor-pointer"
                      onClick={() => {
                        onDeleteRule(index);
                      }}
                    >
                      <CloseCircle />
                    </IconButton>
                  ) : null}
                </ConditionalLabel>
                <ConditionalInput
                  addVariable={addVariable}
                  datasourceMetadata={datasourceMetadata}
                  onChange={(updatedVariable) => {
                    updateRuleOutput(index, updatedVariable);
                  }}
                  onTransformData={onTransformData}
                  rule={r}
                  setShowingModal={setShowingVariableModal}
                  tableData={tableData}
                  transformDataStatus={transformDataStatus}
                  triggerType={triggerType}
                  updateVariable={updateVariable}
                  variablesMap={variablesMap}
                />
                <ConditionalLabel className="mb-4">When</ConditionalLabel>
                <GroupBlock
                  addVariable={addVariable}
                  datasourceMetadata={datasourceMetadata}
                  group={r.data}
                  onAddCondition={(...props) => {
                    addCondition(index, ...props);
                  }}
                  onDeleteCondition={(...props) => {
                    deleteCondition(index, ...props);
                  }}
                  onTransformData={onTransformData}
                  onUpdateCondition={(...props) => {
                    updateCondition(index, ...props);
                  }}
                  setShowingModal={setShowingVariableModal}
                  tableData={tableData}
                  transformDataStatus={transformDataStatus}
                  triggerType={triggerType}
                  updateVariable={updateVariable}
                  variablesMap={variablesMap}
                />
              </Padded>
              <Separator />
            </React.Fragment>
          );
        })}

        <Padded>
          <ConditionalLabel className="mb-4">{`Or ${label.toLowerCase()}`}</ConditionalLabel>
          <Button
            color="secondary"
            onClick={() => {
              addGroup();
            }}
            variant="text"
          >
            Add value
          </Button>
        </Padded>

        <Separator />
        <Padded>
          <ConditionalLabel>{`Else ${label.toLowerCase()}`}</ConditionalLabel>
          <ConditionalInput
            addVariable={addVariable}
            datasourceMetadata={datasourceMetadata}
            onChange={(updatedVariable) => {
              setElseRule((r) => ({
                ...r,
                output: [updatedVariable],
              }));
            }}
            onTransformData={onTransformData}
            rule={elseRule}
            setShowingModal={setShowingVariableModal}
            tableData={tableData}
            transformDataStatus={transformDataStatus}
            triggerType={triggerType}
            updateVariable={updateVariable}
            variablesMap={variablesMap}
          />
        </Padded>
      </Bordered>

      {!preview ? (
        <div className="flex w-full gap-9 py-6 bottom-0 z-50">
          <Button
            className="!flex-1"
            color="secondary"
            fullWidth
            onClick={saveChanges}
            variant="contained"
          >
            Save changes
          </Button>
          <Button
            className="!flex-1"
            color="secondary"
            fullWidth
            onClick={onCancel}
            variant="outlined"
          >
            cancel
          </Button>
        </div>
      ) : null}
    </div>
  );
}
