import { type Variable } from 'types-shared';

export const formatLabelString = (variableData: Variable): string => {
  const { type } = variableData;
  switch (type) {
    default:
      return JSON.stringify(variableData);
  }
};

export const isUUID = (str: string) => {
  const uuidRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidRegex.test(str);
};
