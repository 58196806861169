import { ExecutionStatusEnum, ExecutionTriggerEnum } from 'types-shared';
import { v4 as uuidv4 } from 'uuid';
import { sendToBackgroundViaRelay } from '@plasmohq/messaging';
export const executeWorkflowWithExtension = ({ workflowId, recordIds, workflowData, tableData, setId, executionIds = [], debugSettings, }) => {
    const executionSetId = setId ?? uuidv4();
    const { nodes, edges, variables, targets, datasourceMetadata } = workflowData;
    const executions = recordIds.map((recordId, index) => {
        return {
            trigger: ExecutionTriggerEnum.Manual,
            createdAt: new Date().toISOString(),
            executionId: executionIds[index] ?? uuidv4(),
            workflowId,
            setId: executionSetId,
            recordId: recordId.toString(),
            status: ExecutionStatusEnum.Running,
            recordData: tableData?.rowData[parseInt(recordId)] || {},
            adminRun: false,
            debugSettings,
        };
    });
    const startExecutionBody = {
        data: {
            [workflowId]: {
                workflowData: {
                    nodes,
                    edges,
                    variables,
                    targets,
                    datasourceMetadata,
                },
            },
        },
        setId: executionSetId,
        executions,
    };
    return sendExecutionMessageToExtension(startExecutionBody);
};
export const sendExecutionMessageToExtension = (startExecutionBody) => {
    return sendToBackgroundViaRelay({
        name: 'onExecution.handler',
        body: startExecutionBody,
    });
};
