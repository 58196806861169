"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.recordActionBackgroundSchema = void 0;
var types_shared_1 = require("types-shared");
exports.recordActionBackgroundSchema = {
    request: types_shared_1.z.object({
        body: types_shared_1.ExtensionAction,
        sender: types_shared_1.z.object({
            frameId: types_shared_1.z.number(),
            tab: types_shared_1.z.object({ id: types_shared_1.z.number(), windowId: types_shared_1.z.number() }),
        }),
    }),
    response: types_shared_1.z.object({
        recorded: types_shared_1.z.boolean(),
    }),
};
