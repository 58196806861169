"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransformSDK = void 0;
var api_types_shared_1 = require("api-types-shared");
var sentry_browser_shared_1 = require("sentry-browser-shared");
var BACKEND_STAGE = process.env.REACT_APP_BACKEND_STAGE ||
    process.env.PLASMO_PUBLIC_BACKEND_STAGE ||
    'development';
var TransformSDK = /** @class */ (function () {
    function TransformSDK(env) {
        if (env === 'development') {
            this.endpoint = 'http://localhost:8000';
        }
        else {
            this.endpoint =
                BACKEND_STAGE === 'production'
                    ? 'https://n33jlrjcr324h6xqmoegwxzdsq0rgsdi.lambda-url.us-east-1.on.aws'
                    : 'https://gxrlpcpma5ffnkfpu5dv3p6daq0iriaz.lambda-url.us-east-1.on.aws';
        }
    }
    TransformSDK.prototype.transform = function (_a) {
        var data = _a.data, prompt = _a.prompt, example = _a.example, _b = _a.cache, cache = _b === void 0 ? true : _b;
        return __awaiter(this, void 0, void 0, function () {
            var request, response;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        request = api_types_shared_1.llmTransformSchema.request.parse({
                            params: {},
                            query: {},
                            body: {
                                data: data,
                                prompt: prompt,
                                cache: cache,
                                example: example,
                            },
                        });
                        return [4 /*yield*/, (0, api_types_shared_1.zodAxios)(api_types_shared_1.llmTransformSchema.response, this.endpoint, 
                            // Todo(rafic): switch to using API Gateway
                            // `${this.endpoint}/llm-process`,
                            {
                                method: 'post',
                                data: request.body,
                            }).catch(function (err) {
                                (0, sentry_browser_shared_1.handleException)(err, {
                                    userMessage: { title: 'Failed to transform data' },
                                });
                                return null;
                            })];
                    case 1:
                        response = _c.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    return TransformSDK;
}());
exports.TransformSDK = TransformSDK;
