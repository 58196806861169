import type {
  GridColDef,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import dayjs from 'dayjs';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FeatureFlag, NoDataFound, Toolbar } from 'dashboard-shared';
import { WorkflowStatusEnum } from 'api-types-shared';
import type { ExecutionBase } from 'types-shared';
import { ExecutionStatusEnum, ExecutionTriggerEnum } from 'types-shared';
import {
  Button,
  Chip,
  Typography,
  Flex,
  ContentContainer,
  ScrollableContainer,
  DataLoader,
  EditIcon,
  SortOrder,
  Checkbox,
  Select,
} from 'ui-kit';
import { Download } from 'assets-shared';
import type { ChangeEvent } from 'react';

import {
  useDownloadExecutionData,
  useFetchWorkflowExecutionDetails,
  useFetchWorkflowMetadata,
  useUpdateWorkflowName,
} from '../hooks';
import { clsx } from 'clsx';
import { downloadLinkData } from '../utils/helper';
import uniq from 'lodash/uniq';
import { useFeatureFlagEnabled } from 'posthog-js/react';

export const getStatusColor = (status: ExecutionStatusEnum) => {
  switch (status) {
    case ExecutionStatusEnum.Queued:
    case ExecutionStatusEnum.Retry:
      return 'warning';
    case ExecutionStatusEnum.Running:
    case ExecutionStatusEnum.PendingUser:
    case ExecutionStatusEnum.PendingSystem:
      return 'primary';
    case ExecutionStatusEnum.Success:
      return 'success';
    case ExecutionStatusEnum.Paused:
      return 'info';
    default:
      return 'error';
  }
};

const statusTitleMapping: Record<string, string> = {
  [ExecutionStatusEnum.PendingUser]: 'Running',
  [ExecutionStatusEnum.PendingSystem]: 'Running',
};

const sortOrder = [SortOrder.DESC, SortOrder.ASC];

const initialState = {
  sorting: {
    sortModel: [{ field: 'createdAt', sort: SortOrder.DESC }],
  },
};

export default function WorkflowDetail() {
  const inputRef = useRef<HTMLInputElement>(null);
  const { workflowId } = useParams();
  if (!workflowId) {
    throw new Error('WorkflowId not found!');
  }

  const { data: workflowMetadata, isLoading: fetchingWorkflowMetadata } =
    useFetchWorkflowMetadata(workflowId);
  const navigate = useNavigate();
  const {
    isLoading: executionsListLoading,
    refetch: refetchExecutions,
    data: executions,
  } = useFetchWorkflowExecutionDetails(workflowId);
  // const { mutateAsync, status } = useDownloadExecutionData(); - TODO: re-enable after M&M sandbox
  const { mutateAsync } = useDownloadExecutionData();
  const hideRunId = useFeatureFlagEnabled(FeatureFlag.HideRunId);

  const { mutateAsync: updateWorkflowMetadata } = useUpdateWorkflowName();
  const [isEditingName, setIsEditingName] = useState<boolean>(false);
  const [workflowName, setWorkflowName] = useState<string>('');
  const [selectedRows, setSelectedRows] = useState<Set<string>>(new Set());
  const [selectedRunId, setSelectedRunId] = useState<string>('');

  const columns: GridColDef<ExecutionBase>[] = useMemo(
    () => [
      {
        width: 60,
        field: 'selection',
        hideable: true,
        sortable: false,
        renderHeader: () => {
          const filteredExecutions = (executions ?? []).filter((execution) =>
            selectedRunId ? execution.setId === selectedRunId : true,
          );

          return (
            <Checkbox
              checked={selectedRows.size === filteredExecutions.length}
              indeterminate={
                selectedRows.size > 0 &&
                selectedRows.size < filteredExecutions.length
              }
              onChange={(_, checked) => {
                if (checked) {
                  setSelectedRows(
                    new Set(filteredExecutions.map((e) => e.executionId)),
                  );
                } else {
                  setSelectedRows(new Set());
                }
              }}
            />
          );
        },
        renderCell: ({ row: { executionId } }) => (
          <Checkbox
            checked={selectedRows.has(executionId)}
            onChange={(_, checked) => {
              const set = new Set(selectedRows);
              if (!checked) {
                set.delete(executionId);
              } else {
                set.add(executionId);
              }
              setSelectedRows(set);
            }}
          />
        ),
      },
      {
        field: 'executionId',
        headerName: 'Execution ID',
        flex: 2,
        valueGetter: (params) => params.row.executionId,
        sortable: false,
      },
      {
        field: 'recordId',
        headerName: 'Record ID',
        flex: 1,
        valueGetter: ({ row }) =>
          row.trigger === ExecutionTriggerEnum.API
            ? 'N/A'
            : Number(row.recordId) + 1,
        sortable: false,
      },
      ...(!hideRunId
        ? [
            {
              field: 'runId',
              headerName: 'Run ID',
              flex: 2,
              valueGetter: (params: GridValueGetterParams<ExecutionBase>) =>
                params.row.setId,
              sortable: false,
            },
          ]
        : []),
      {
        field: 'createdAt',
        headerName: 'Created At',
        flex: 1,
        valueGetter: (params) => [
          params.row.createdAt,
          params.row.setId,
          params.row.recordId,
        ],
        valueFormatter: (params: GridValueFormatterParams<string[]>) =>
          dayjs(new Date(Number(params.value[0]))).format('MM/DD/YY -  h:mm A'),
        sortComparator: (v1: string[], v2: string[]) => {
          const [createdAt1, setId1, recordId1] = v1;
          const [createdAt2, setId2, recordId2] = v2;

          // If same createdAt, sort by setId
          if (createdAt1 === createdAt2) {
            // If same setId, sort by recordId
            if (setId1 === setId2) {
              return Number(recordId1) - Number(recordId2);
            }
            return Number(setId1 < setId2);
          }
          return Number(createdAt1) - Number(createdAt2);
        },
      },
      {
        field: 'trigger',
        headerName: 'Trigger',
        flex: 1,
        valueGetter: (params) => params.row.trigger,
        renderCell: (params) => (
          <Chip
            className="!h-6"
            color="default"
            label={params.row.trigger.toUpperCase()}
            sx={{ '& .MuiChip-label': { textTransform: 'capitalize' } }}
            variant="filled"
          />
        ),
        sortable: false,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        renderCell: (params) => {
          const title =
            statusTitleMapping[params.row.status] ??
            params.row.status.toLowerCase().replace('_', ' ');
          return (
            <Chip
              className="!h-6"
              color={getStatusColor(params.row.status)}
              label={title}
              sx={{ '& .MuiChip-label': { textTransform: 'capitalize' } }}
              variant="filled"
            />
          );
        },
      },
      {
        field: 'action',
        headerName: '',
        align: 'right',
        renderCell: (params) => {
          const show = [
            ExecutionStatusEnum.Success,
            ExecutionStatusEnum.Failed,
            ExecutionStatusEnum.Canceled,
            ExecutionStatusEnum.Terminated,
            ExecutionStatusEnum.Timeout,
            ExecutionStatusEnum.Retry,
          ].includes(params.row.status);

          return show ? (
            <Link
              className="text-primary-light-blue border uppercase font-medium border-primary-light-blue px-3.5 py-1.5 rounded cursor-pointer"
              to={`/execution/${params.row.executionId}`}
            >
              View Details
            </Link>
          ) : null;
        },
        flex: 1,
        sortable: false,
      },
    ],
    [executions, hideRunId, selectedRows, selectedRunId],
  );

  const runIds = useMemo(() => {
    if (!executions) return [];

    // empty string for show all
    const sortedExecutions = executions.sort(
      (a, b) => Number(b.createdAt) - Number(a.createdAt),
    );
    return uniq(['', ...sortedExecutions.map((e) => e.setId)]);
  }, [executions]);

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setWorkflowName(event.target.value);
  };

  const updateWorkflowName = async () => {
    setIsEditingName(false);
    if (inputRef.current) {
      inputRef.current.scrollLeft = 0;
    }
    if (workflowMetadata?.workflowName !== workflowName) {
      await updateWorkflowMetadata({ workflowId, workflowName });
    }
  };

  const downloadZippedOutput = async () => {
    const link = await mutateAsync(Array.from(selectedRows));
    downloadLinkData(link);
  };

  useEffect(() => {
    if (workflowMetadata?.status === WorkflowStatusEnum.ProcessingImport) {
      navigate('/workflows');
    }
  }, [navigate, workflowMetadata]);

  useEffect(() => {
    if (workflowMetadata?.workflowName) {
      setWorkflowName(workflowMetadata.workflowName);
    }
  }, [workflowMetadata]);

  return (
    <ScrollableContainer>
      <Toolbar
        onBack={() => {
          navigate('/workflows', { replace: true });
        }}
        pageInfo={`Workflow ID: ${workflowId}`}
      />
      {workflowMetadata ? (
        <ContentContainer withToolbar>
          <Flex alignItems="flex-start" justifyContent="space-between">
            <Flex alignItems="flex-end">
              <EditIcon
                className="text-indigo-light hover:text-color-grey mb-2 mr-1 cursor-pointer !fill-none"
                onClick={() => {
                  setIsEditingName((e) => !e);
                }}
              />
              <input
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={isEditingName}
                className={clsx(
                  'border-b-2 border-transparent placeholder:text-indigo-light text-4xl font-bold focus:outline-0 !text-ellipsis',
                  {
                    'text-blue-500 !border-primary-blue': isEditingName,
                  },
                )}
                onBlur={updateWorkflowName}
                onChange={onNameChange}
                onClick={() => {
                  setIsEditingName(true);
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    updateWorkflowName();
                    event.currentTarget.blur();
                  }
                }}
                placeholder={workflowMetadata.workflowName}
                readOnly={!isEditingName}
                ref={inputRef}
                value={workflowName}
              />
            </Flex>
            <Button
              color="secondary"
              onClick={() => {
                navigate(`/editor/${workflowId}`);
              }}
              variant="contained"
            >
              Edit workflow
            </Button>
          </Flex>

          <div className="mt-6">
            {executionsListLoading ? (
              <DataLoader loadingText="Fetching workflow execution details" />
            ) : (
              <>
                {executions && executions.length > 0 ? (
                  <>
                    <Flex className="mt-8 mb-4 gap-2" flexDirection="column">
                      <Typography color="text.primary" variant="h5">
                        Run overview
                      </Typography>
                    </Flex>
                    <Flex className="items-center" flexDirection="row">
                      <div className="basis-11/12">
                        <Typography color="text.secondary" variant="body2">
                          Details for all your current and past runs.
                        </Typography>
                      </div>
                      <div
                        className={clsx('basis-1/12 flex space-x-4', {
                          'items-end': selectedRunId,
                          'items-stretch': !selectedRunId,
                        })}
                      >
                        <Button
                          className={clsx({
                            '!mt-2': selectedRunId,
                          })}
                          color="secondary"
                          onClick={() => refetchExecutions()}
                          variant="outlined"
                        >
                          Refresh
                        </Button>

                        {!hideRunId ? (
                          <Select
                            className="!min-w-50 !max-w-50 !min-h-[unset] max-h-[42px]"
                            classes={
                              !selectedRunId
                                ? {
                                    select: '!pt-2',
                                    icon: '!top-2',
                                  }
                                : {}
                            }
                            defaultValue=""
                            getLabel={(opt: string) => {
                              if (!opt) return 'Show all';
                              return opt;
                            }}
                            getValue={(opt: string) => opt}
                            label={selectedRunId ? 'Filter per run' : undefined}
                            labelId="filter-per-run"
                            onChange={(e) => {
                              setSelectedRunId(e.target.value);
                            }}
                            options={runIds}
                            placeholder="Filter per run"
                          />
                        ) : null}
                        <Button
                          className="!border-none"
                          color="secondary"
                          disabled
                          onClick={downloadZippedOutput}
                          variant="outlined"
                        >
                          <Download className="mr-1" fontSize="small" />
                          Export
                        </Button>
                      </div>
                    </Flex>
                    <DataGrid
                      columns={columns}
                      getRowId={(row) => row.executionId}
                      initialState={initialState}
                      rows={
                        selectedRunId
                          ? executions.filter((e) => e.setId === selectedRunId)
                          : executions
                      }
                      sortingOrder={sortOrder}
                    />
                  </>
                ) : (
                  <NoDataFound
                    heading="Run reports"
                    subHeading="The workflow not been executed yet. Edit your workflow, review all the steps, and execute your first run!"
                  />
                )}
              </>
            )}
          </div>
        </ContentContainer>
      ) : null}
      {fetchingWorkflowMetadata ? (
        <DataLoader loadingText="Fetching workflow details" />
      ) : null}
    </ScrollableContainer>
  );
}
