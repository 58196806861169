import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export default function EyeIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.98924 11.4823C4.47211 12.9565 7.05819 14.9999 10.0004 14.9999C12.9426 14.9999 15.5282 12.9565 17.0111 11.4823C17.4021 11.0936 17.5983 10.8985 17.7229 10.5168C17.8117 10.2444 17.8117 9.75561 17.7229 9.48325C17.5983 9.10151 17.4022 8.90642 17.0111 8.51759C15.5282 7.0434 12.9426 5 10.0004 5C7.05819 5 4.47211 7.0434 2.98924 8.51759C2.59786 8.90669 2.40215 9.10137 2.27757 9.48325C2.18873 9.75561 2.18873 10.2444 2.27757 10.5168C2.40215 10.8986 2.59786 11.0932 2.98924 11.4823Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M8.33358 10C8.33358 10.9205 9.07977 11.6667 10.0002 11.6667C10.9207 11.6667 11.6669 10.9205 11.6669 10C11.6669 9.07953 10.9207 8.33333 10.0002 8.33333C9.07977 8.33333 8.33358 9.07953 8.33358 10Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </SvgIcon>
  );
}
