import React from 'react';
import { clsx } from 'clsx';

export function Separator() {
  return <div className="border-t border-gray-300" />;
}

export function Padded({ children }: { children: React.ReactNode }) {
  return <div className="p-6">{children}</div>;
}

export function Bordered({ children }: { children: React.ReactNode }) {
  return <div className="rounded-lg border border-gray-300">{children}</div>;
}

export function ConditionalLabel({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <p
      className={clsx(
        'text-sm text-color-grey-2 font-bold flex justify-between items-center',
        className,
      )}
    >
      {children}
    </p>
  );
}
