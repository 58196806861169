import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';
import React from 'react';
import { ErrorFallbackUI } from './ErrorFallbackUI';

export function ErrorBoundaryLayout() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallbackUI}>
      <Outlet />
    </ErrorBoundary>
  );
}
