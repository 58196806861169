import type { WorkflowAction } from 'types-shared';
import type { SelectedAction } from './ActionsList';
import { EditorStore } from '../../store/EditorState';
import { imageNodeEventChannel } from '../NodeElement/SelectedImageNodeContent';
import { useTransformData } from '../../hooks';
import { useCallback } from 'react';
import { EditActionCore } from 'editor-shared';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { FeatureFlag } from 'dashboard-shared';

interface Props {
  i: number;
  action: WorkflowAction;
  setSelectedAction: (val: null | SelectedAction) => void;
}

function EditAction({ i, action, setSelectedAction }: Props) {
  const {
    datasourceMetadata,
    variables: variablesMap,
    updateVariable,
    tableData,
    addVariable,
    updateImageNodeAction,
    selectedNode,
    nodes,
    setSelectedNode,
    triggerType,
  } = EditorStore();

  const { mutateAsync: transformData, status: transformDataStatus } =
    useTransformData();

  const onTransformData = useCallback(
    async (prompt: string, textToTransform: string) => {
      if (prompt && textToTransform) {
        const value = await transformData({
          data: textToTransform,
          prompt,
        });
        return value?.processedData;
      }
      return undefined;
    },
    [transformData],
  );

  const selectionByConditionsEnabled = useFeatureFlagEnabled(
    FeatureFlag.SelectionByConditions,
  );

  return (
    <EditActionCore
      action={action}
      addVariable={addVariable}
      datasourceMetadata={datasourceMetadata}
      i={i}
      nodes={nodes}
      onActionHover={(targetId: string) => {
        imageNodeEventChannel.emit('onActionHover', targetId);
      }}
      onBlur={() => {
        imageNodeEventChannel.emit('onActionHover', null);
      }}
      onTransformData={onTransformData}
      selectedNode={selectedNode}
      selectionByConditionsEnabled={selectionByConditionsEnabled ?? false}
      setSelectedAction={setSelectedAction}
      setSelectedNode={setSelectedNode}
      tableData={tableData}
      transformDataStatus={transformDataStatus}
      triggerType={triggerType}
      updateImageNodeAction={updateImageNodeAction}
      updateVariable={updateVariable}
      variablesMap={variablesMap}
    />
  );
}

export default EditAction;
