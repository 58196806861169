import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export default function ListIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      fill="none"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66667 11.3346H13.3333M2.66667 10.4582V10.3346C2.66667 9.78235 3.11439 9.33464 3.66667 9.33464H3.6937C4.23106 9.33464 4.66681 9.77026 4.66681 10.3076C4.66681 10.5415 4.5908 10.7692 4.45046 10.9564L2.66667 13.3348L4.66667 13.3346M6.66667 8.0013H13.3333M6.66667 4.66797H13.3333M2.66667 3.33464L4.00001 2.66797V6.66797"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
