import type {
  DatasourceMetadata,
  DatasourceTable,
  Rule,
  SelectVariable,
  Variable,
  VariableRef,
} from 'types-shared';
import { isVariable, VariableTypeEnum, TriggerTypeEnum } from 'types-shared';
import { Select as MultiChoiceSelect } from 'ui-kit';
import React, { useEffect, useMemo, useState } from 'react';
import isNil from 'lodash/isNil';
import type { Item } from '../Select';
import Select from '../Select';
import values from 'lodash/values';
import {
  extractInputLabel,
  extractSelectItems,
  extractVariableData,
} from './conditions.helpers';
import { AddVariable } from './AddVariable';
import { PreviewVariable } from './PreviewVariable';
import { VariableInput } from '../VariableTypes/VariableInput';

interface Props {
  rule: Rule;
  onChange: (variable: Variable) => void;

  tableData: DatasourceTable | null;
  variablesMap: Record<string, Variable>;
  datasourceMetadata: DatasourceMetadata | null;
  addVariable: (variable: Variable) => void;
  updateVariable: (variable: Variable) => void;
  transformDataStatus: 'error' | 'idle' | 'pending' | 'success' | 'loading';
  triggerType?: TriggerTypeEnum;
  setShowingModal?: (showingModal: boolean) => void;
  onTransformData: (
    prompt: string,
    textToTransform: string,
  ) => Promise<string | undefined>;
}

export default function ConditionalInput({
  rule,
  onChange,
  datasourceMetadata,
  variablesMap,
  tableData,
  addVariable,
  updateVariable,
  transformDataStatus,
  onTransformData,
  triggerType,
  setShowingModal,
}: Props) {
  const rawVar = rule.output[0];
  const variable = isVariable(rawVar) ? rawVar : undefined;
  const [addNewVariable, setAddNewVariable] = useState<boolean>(false);
  const [editVariableIndex, setEditVariableIndex] = useState<number | null>(
    null,
  );
  const [previewVariableIndex, setPreviewVariableIndex] = useState<
    number | null
  >(null);

  const variableData = useMemo(() => {
    return variable ? extractSelectItems(variable) : [];
  }, [variable]);

  const variables = useMemo(() => {
    const selectedVariableIds = variableData
      .filter((item: Item) => item.type !== 'string')
      .map((item: Item) => (item.value as VariableRef).id);
    return values(variablesMap).filter(
      ({ id, type }: Variable) =>
        [VariableTypeEnum.Scrape, VariableTypeEnum.Datasource].includes(type) &&
        !selectedVariableIds.includes(id),
    );
  }, [variablesMap, variableData]);

  const onAddNewVariable = () => {
    setAddNewVariable(true);
  };

  const onPreviewVariable = (selectedIndex: number, chosenItem?: Item) => {
    let actualIndex = selectedIndex;
    if (chosenItem) {
      actualIndex = variableData.findIndex((item) => item.id === chosenItem.id);
    }
    setPreviewVariableIndex(actualIndex);
  };

  useEffect(() => {
    if (addNewVariable || editVariableIndex || previewVariableIndex) {
      setShowingModal?.(true);
    } else {
      setShowingModal?.(false);
    }
  }, [
    addNewVariable,
    editVariableIndex,
    previewVariableIndex,
    setShowingModal,
  ]);

  if (!variable) return null;

  const inputLabel = extractInputLabel(variable);

  if (addNewVariable) {
    return (
      <AddVariable
        addVariable={addVariable}
        datasourceMetadata={datasourceMetadata}
        isCondition
        label={inputLabel}
        onCancel={() => {
          setAddNewVariable(false);
        }}
        onTransformData={onTransformData}
        tableData={tableData}
        transformDataStatus={transformDataStatus}
        triggerType={triggerType}
        updateVariable={updateVariable}
        variables={variablesMap}
      />
    );
  }

  if (editVariableIndex) {
    const editVariable = variableData[editVariableIndex];
    return (
      <AddVariable
        addVariable={addVariable}
        datasourceMetadata={datasourceMetadata}
        isCondition
        label={inputLabel}
        onCancel={() => {
          setPreviewVariableIndex(editVariableIndex);
          setEditVariableIndex(null);
        }}
        onTransformData={onTransformData}
        showTitle
        tableData={tableData}
        transformDataStatus={transformDataStatus}
        triggerType={triggerType}
        updateVariable={updateVariable}
        variable={editVariable}
        variables={variablesMap}
      />
    );
  }

  if (previewVariableIndex !== null) {
    const previewVariable = variableData[previewVariableIndex];
    return (
      <PreviewVariable
        datasourceMetadata={datasourceMetadata}
        isCondition
        label={inputLabel}
        onCancel={() => {
          setPreviewVariableIndex(null);
        }}
        onEdit={() => {
          setPreviewVariableIndex(null);
          setEditVariableIndex(previewVariableIndex);
        }}
        showTitle
        tableData={tableData}
        variable={previewVariable}
        variables={variablesMap}
      />
    );
  }

  if (variable.type === VariableTypeEnum.Template) {
    return (
      <VariableInput
        allowAddVariable={
          Boolean(datasourceMetadata) || triggerType === TriggerTypeEnum.API
        }
        className="my-4"
        data={variableData}
        label={variable.name ?? ''}
        onAddNew={onAddNewVariable}
        onChange={(items) => {
          onChange({
            ...variable,
            data: extractVariableData(items),
          });
        }}
        onPreview={onPreviewVariable}
        placeholder="Enter a value"
        previewVariableIndex={previewVariableIndex}
        variables={variables}
        variablesMap={variablesMap}
      />
    );
  }

  if (variable.type === VariableTypeEnum.Select) {
    return (
      <Select
        allowAddVariable={
          Boolean(datasourceMetadata) || triggerType === TriggerTypeEnum.API
        }
        className="my-4"
        data={variableData}
        label={variable.name ?? ''}
        onAddNew={onAddNewVariable}
        onChange={(items) => {
          onChange({
            ...variable,
            data: extractVariableData(items),
          });
        }}
        onPreview={onPreviewVariable}
        options={(variable as SelectVariable).selectOptions}
        previewVariableIndex={previewVariableIndex}
        variables={variables}
        variablesMap={variablesMap}
      />
    );
  }

  if (variable.type === VariableTypeEnum.MultiChoice) {
    const options = variable.multiChoiceOptions
      .map((v) => v.ariaLabel)
      .filter((v) => Boolean(v)) as string[];

    const value = !isNil(variable.selectedChoiceIx)
      ? variable.multiChoiceOptions[variable.selectedChoiceIx].ariaLabel
      : '';

    return (
      <MultiChoiceSelect
        className="w-full my-4"
        getLabel={(opt: string) => opt}
        getValue={(opt: string) => opt}
        label="Options"
        onChange={(p) => {
          const option = p.target.value;

          const selectedIndex = variable.multiChoiceOptions.findIndex(
            (v) => v.ariaLabel === option,
          );

          onChange({
            ...variable,
            selectedChoiceIx: selectedIndex,
            data: [selectedIndex.toString()],
          });
        }}
        options={options}
        placeholder="Select the option"
        value={value}
      />
    );
  }

  return null;
}
