"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkflowData = void 0;
var zod_1 = require("zod");
var workflow_schema_1 = require("./workflow.schema");
exports.WorkflowData = zod_1.z.object({
    nodes: zod_1.z.array(workflow_schema_1.WorkflowNode),
    edges: zod_1.z.array(workflow_schema_1.WorkflowEdge),
    selectedAction: workflow_schema_1.SelectedAction.nullable().optional(),
    selectedNode: zod_1.z.string().nullable().optional(),
    bulkSelectMode: zod_1.z.boolean().optional(),
    workflowId: zod_1.z.string().optional(),
});
