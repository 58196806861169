import {
  type SelectVariable,
  type TemplateVariable,
  type Variable,
  type VariableRef,
  VariableTypeEnum,
  TriggerTypeEnum,
} from 'types-shared';
import { clsx } from 'clsx';
import { Chip } from 'ui-kit';
import { MultiChoicePreview } from '../../VariableTypes/MultipleChoice';
import React from 'react';
import { ListIcon } from 'assets-shared';

type VariableData = string | VariableRef;
interface VariableInputsProps {
  variablesMap: Record<string, Variable>;
  label: string | undefined;
  dataLike: unknown;
}

function VariableInputs({
  dataLike,
  label,
  variablesMap,
}: VariableInputsProps) {
  if (Array.isArray(dataLike as unknown[])) {
    return (
      <div className="px-3 flex items-center flex-wrap space-x-1">
        {(dataLike as VariableData[])
          .filter((val) => val !== '')
          .map((val: VariableData) => {
            const isApiCallChip = () => {
              if (typeof val !== 'string') {
                const variable = variablesMap[val.id];
                return (
                  variable.type === VariableTypeEnum.Datasource &&
                  variable.data.triggerType === TriggerTypeEnum.API
                );
              }
              return false;
            };

            if (typeof val === 'string') {
              return (
                <span key={val}>
                  {label === 'Password' ? val.replace(/\w/g, '*') : val}
                </span>
              );
            }
            return (
              <Chip
                className={clsx(
                  {
                    '!bg-secondary-purple':
                      variablesMap[val.id].type === VariableTypeEnum.Scrape,
                  },
                  {
                    '!bg-transparent !border-secondary-blue !border-2 !text-secondary-blue':
                      isApiCallChip(),
                  },
                )}
                color="secondary"
                key={val.id}
                label={variablesMap[val.id].name}
                size="small"
                variant={isApiCallChip() ? 'outlined' : 'filled'}
              />
            );
          })}
      </div>
    );
  }

  const val = dataLike as { datasourceId: string; key: string };

  return (
    <Chip
      className="!my-auto"
      color="secondary"
      key={val.key}
      label={val.key}
      size="small"
    />
  );
}

interface ActionRendererProps {
  chooseFromList?: boolean;
  variablesMap: Record<string, Variable>;
  label: string | undefined;
  variable: Variable;
}

export function ActionRenderer({
  chooseFromList,
  label,
  variablesMap,
  variable,
}: ActionRendererProps) {
  return (
    <>
      {variable.type === VariableTypeEnum.Scrape ? (
        <Chip
          className="!ml-3 !my-auto !bg-secondary-purple"
          color="secondary"
          label={label}
          size="small"
        />
      ) : null}
      {variable.type === VariableTypeEnum.Template ? (
        <VariableInputs
          dataLike={(variable as TemplateVariable).data}
          label={label}
          variablesMap={variablesMap}
        />
      ) : null}
      {variable.type === VariableTypeEnum.Select ? (
        <VariableInputs
          dataLike={(variable as SelectVariable).data}
          label={label}
          variablesMap={variablesMap}
        />
      ) : null}
      {variable.type === VariableTypeEnum.MultiChoice ? (
        <MultiChoicePreview variable={variable} />
      ) : null}
      {chooseFromList ? (
        <Chip
          className="!-ml-3 !my-auto"
          color="primary"
          label={
            <div className="flex items-center">
              <span className="mr-2 text-xs !normal-case">
                The first result is selected
              </span>
              <ListIcon className="!w-4 !h-4" />
            </div>
          }
          size="small"
        />
      ) : null}
    </>
  );
}
