import { clsx } from 'clsx';
import size from 'lodash/size';
import React, { type MouseEvent, useRef, useState, useMemo } from 'react';
import { ActionsIcon, MoreVertRounded } from 'assets-shared';
import type { WorkflowNodeProps } from 'types-shared';
import { NodeStatusEnum } from 'types-shared';
import { IconButton, Menu, MenuItem } from 'ui-kit';

interface Props {
  workflowData: WorkflowNodeProps;
  showFullContent: boolean;
  includeAdminOnlyActions?: boolean;
  thumbnailData?: Blob | null;
  allowedActions?: ('copyNode' | 'deleteNode' | 'updateImage')[];
  handleAction?: (action: 'copyNode' | 'deleteNode' | 'updateImage') => void;
}

export function ImageNodeCore({
  workflowData,
  showFullContent,
  includeAdminOnlyActions = false,
  thumbnailData,
  allowedActions,
  handleAction,
}: Props) {
  const {
    data: { actionData, actionOrder, nodeStatus: status },
  } = workflowData;

  const imgRef = useRef<HTMLImageElement>(null);

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);

  const actions = useMemo(() => {
    return actionOrder
      .map((id) => actionData[id])
      .filter((action) => !action.options?.adminOnly);
  }, [actionOrder, actionData]);

  const actionDataSize = size(includeAdminOnlyActions ? actionData : actions);

  const openMenu = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  };

  const closeMenu = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setMenuAnchorEl(null);
  };

  return (
    <>
      {showFullContent ? (
        <>
          <div className="relative flex-1 rounded-lg overflow-hidden border border-indigo-light w-full">
            <img
              alt=""
              className={clsx('h-full w-full object-cover', {
                'bg-gray-300': !thumbnailData,
              })}
              ref={imgRef}
              src={
                thumbnailData instanceof Blob
                  ? URL.createObjectURL(thumbnailData)
                  : undefined
              }
            />
          </div>
          <div className="flex items-center space-x-0.5">
            <ActionsIcon className="text-info -ml-1" />
            <span className="text-sm font-bold">
              {actionDataSize}
              {status === NodeStatusEnum.NotViewed
                ? ` ${actionDataSize > 1 ? 'actions' : 'action'} to review`
                : null}
            </span>
          </div>
          {handleAction ? (
            <div className="absolute right-0 bottom-1">
              <IconButton aria-haspopup="true" onClick={openMenu}>
                <MoreVertRounded />
              </IconButton>
              <Menu anchorEl={menuAnchorEl} onClose={closeMenu} open={menuOpen}>
                {allowedActions?.includes('copyNode') ? (
                  <MenuItem
                    key="copy-node"
                    onClick={(e) => {
                      closeMenu(e);
                      handleAction('copyNode');
                    }}
                  >
                    Copy Node
                  </MenuItem>
                ) : null}
                {allowedActions?.includes('deleteNode') ? (
                  <MenuItem
                    key="delete-node"
                    onClick={(e) => {
                      closeMenu(e);
                      handleAction('deleteNode');
                    }}
                  >
                    Delete Node
                  </MenuItem>
                ) : null}

                {allowedActions?.includes('updateImage') ? (
                  <MenuItem
                    key="update-image"
                    onClick={(e) => {
                      closeMenu(e);
                      handleAction('updateImage');
                    }}
                  >
                    Update Image
                  </MenuItem>
                ) : null}
              </Menu>
            </div>
          ) : null}
        </>
      ) : (
        <img
          alt=""
          className={clsx('h-full w-full object-cover', {
            'bg-gray-300': !thumbnailData,
          })}
          ref={imgRef}
          src={
            thumbnailData instanceof Blob
              ? URL.createObjectURL(thumbnailData)
              : undefined
          }
        />
      )}
    </>
  );
}
