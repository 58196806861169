import { ChevronLeft, ChevronRight } from 'assets-shared';
import React, { useMemo, useState, useEffect, useRef } from 'react';
import { clsx } from 'clsx';
import { Chip, DataLoader, IconButton } from 'ui-kit';
import { compareArrays, getUrlFromBlob } from '../utils';

type Screenshot = [string, Blob];

interface Props {
  imageArray: Screenshot[];
  loading: boolean;
}

const getFileUrl = (screenshot: Screenshot): string => screenshot[0];

export function WorkflowScreenshots({ imageArray, loading }: Props) {
  const screenshotListRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [imagesBlob, setImagesBlob] = useState<Screenshot[]>([]);
  const [hasScrolled, setHasScrolled] = useState<boolean>(false);

  useEffect(() => {
    const isEqual = compareArrays(
      imagesBlob.map(getFileUrl),
      imageArray.map(getFileUrl),
    );
    if (!isEqual) {
      setImagesBlob(imageArray);
    }
  }, [imagesBlob, imageArray]);

  useEffect(() => {
    setSelectedIndex((val) => {
      if (val === undefined && imagesBlob.length > 0) {
        return imagesBlob.length - 1;
      }
      return val;
    });
  }, [imagesBlob]);

  const screenshotUrls = useMemo(
    () => imagesBlob.map((screenshot) => getUrlFromBlob(screenshot[1])),
    [imagesBlob],
  );

  useEffect(() => {
    if (
      screenshotListRef.current &&
      screenshotUrls.length > 0 &&
      !hasScrolled
    ) {
      screenshotListRef.current.scrollLeft =
        screenshotListRef.current.scrollWidth;
      setHasScrolled(true);
    }
  }, [
    hasScrolled,
    screenshotListRef.current?.scrollWidth,
    screenshotUrls.length,
  ]);

  return loading ? (
    <DataLoader />
  ) : (
    <div className="flex-1 flex flex-col border border-gray-300 m-4 bg-gradient-to-b from-primary-purple-100 via-transparent to-transparent rounded">
      <div className="flex flex-1 justify-center items-center m-4 max-h-[60vh]">
        <SwipeIcon
          className="mr-6"
          hide={selectedIndex === 0}
          onClick={() => {
            setSelectedIndex((selectedIndex ?? 0) - 1);
          }}
        >
          <ChevronLeft />
        </SwipeIcon>

        <div
          className={clsx(
            'max-w-[70%] h-full border-2 border-primary-purple-200 rounded-xl relative select-none overflow-x-hidden overflow-y-auto',
          )}
        >
          <img
            alt=""
            className="w-full"
            src={screenshotUrls[selectedIndex ?? 0]}
          />

          <Chip
            className="absolute bottom-4 right-4"
            color="success"
            label="Completed"
            size="small"
            variant="filled"
          />
        </div>

        <SwipeIcon
          className="ml-6"
          hide={selectedIndex === imageArray.length - 1}
          onClick={() => {
            setSelectedIndex((selectedIndex ?? 0) + 1);
          }}
        >
          <ChevronRight />
        </SwipeIcon>
      </div>

      <div
        className="flex overflow-x-auto mt-4 mb-5 mx-auto px-4 gap-4 max-w-full"
        ref={screenshotListRef}
      >
        {screenshotUrls.map((imageUrl, index) => {
          return (
            <img
              alt={`Execution detail ${screenshotUrls.length - index}`}
              className={clsx(
                'block object-contain rounded-lg w-12 min-w-12 h-8 cursor-pointer',
                selectedIndex !== index &&
                  'border-2 border-primary-purple-200 hover:border-primary-blue-dark hover:border-2 ease-in-out transition-all',
                selectedIndex === index &&
                  '!border-4 !border-blue-500 border-opacity-60',
              )}
              key={imageUrl}
              onClick={() => {
                setSelectedIndex(index);
              }}
              role="presentation"
              src={imageUrl}
            />
          );
        })}
      </div>
    </div>
  );
}

interface SwipeIconProps {
  hide: boolean;
  onClick: () => void;
  className?: string;
  children: React.ReactNode;
}

function SwipeIcon({ hide, onClick, className, children }: SwipeIconProps) {
  if (hide) {
    return <div className={clsx('w-[40px]', className)} role="none" />;
  }

  return (
    <div className={clsx('rounded-full border border-gray-300', className)}>
      <IconButton className="!bg-white" onClick={onClick}>
        {children}
      </IconButton>
    </div>
  );
}
