import { clsx } from 'clsx';
import React from 'react';
import type {
  WorkflowAction,
  WorkflowNode,
  Variable,
  ScrapeVariable,
} from 'types-shared';
import { ArrowLeftIcon } from 'assets-shared';
import { IconButton } from 'ui-kit';

interface Props {
  isAddNewVariable?: boolean;
  isEditingVariable?: boolean;
  isPreviewingVariable?: boolean;
  selectedAction?: WorkflowAction;
  selectedActionIndex?: number;
  selectedVariable?: Variable | ScrapeVariable | null;
  handleOnClose?: () => void;
  node: WorkflowNode | undefined;
  setSelectedNode: (nodeId: string | null) => void;
}

export function ActionHeader({
  isAddNewVariable = false,
  isEditingVariable = false,
  isPreviewingVariable = false,
  selectedAction,
  selectedActionIndex,
  selectedVariable,
  handleOnClose,
  node,
  setSelectedNode,
}: Props) {
  const onClose = () => {
    if (handleOnClose) {
      handleOnClose();
    } else {
      setSelectedNode(null);
    }
  };
  const paddedActionIndex = selectedActionIndex
    ? `${selectedActionIndex.toString().padStart(2, '0')}.`
    : '';

  const truncateName = (name: string) => {
    return name.length > 12 ? `${name.slice(0, 12 - 3)}...` : name;
  };

  if (!node) {
    return null;
  }

  return (
    <div className="flex items-center space-x-2">
      <IconButton
        className="!border !border-solid !border-info !rounded-lg !h-10 !w-10"
        onClick={onClose}
      >
        <ArrowLeftIcon className="text-info" />
      </IconButton>
      <div className="flex flex-col text-xs !ml-3">
        <span className="text-gray-500">Workflow</span>
        <div className="flex items-center space-x-1">
          <span
            className={clsx('font-semibold', {
              'text-info': !selectedAction,
            })}
          >
            {`Step: ${node.id.slice(0, 5)} ${selectedVariable?.name ? '/' : ''}`}
          </span>
          {selectedVariable?.name ? (
            <span
              className={clsx('font-semibold', {
                'text-info':
                  !isAddNewVariable &&
                  !isEditingVariable &&
                  !isPreviewingVariable,
              })}
            >
              {paddedActionIndex}
              {truncateName(selectedVariable.name)}
              {isEditingVariable || isPreviewingVariable ? ' /' : ''}
            </span>
          ) : null}
          {isAddNewVariable ? (
            <span className="font-semibold text-info"> / New variable</span>
          ) : null}
          {isEditingVariable ? (
            <span className="font-semibold text-info">Editing variable</span>
          ) : null}
          {isPreviewingVariable ? (
            <span className="font-semibold text-info">Previewing variable</span>
          ) : null}
        </div>
      </div>
    </div>
  );
}
