import {
  Typography,
  Flex,
  ScrollableContainer,
  ContentContainer,
} from 'ui-kit';

import NavigationPanel from '../shared/NavigationPanel/NavigationPanel';
import ComingSoonIntegrationTile from './components/ComingSoonIntegrationTile';

export default function Integrations() {
  return (
    <ScrollableContainer>
      <div className="w-full flex">
        <NavigationPanel />
        <ContentContainer>
          <div className="flex justify-between w-full items-center">
            <div>
              <Typography variant="h4">Integrations</Typography>
              <div className="mt-2">
                <span className="font-normal text-sm">
                  Configure external platforms and connect them with your
                  workflows
                </span>
              </div>
            </div>
          </div>
          <div className="mt-11">
            <Flex className="gap-4 flex-wrap">
              <ComingSoonIntegrationTile />
            </Flex>
          </div>
        </ContentContainer>
      </div>
    </ScrollableContainer>
  );
}
