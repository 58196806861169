import React from 'react';
import { Button, Input } from 'ui-kit';

interface Props {
  onCancel: () => void;
  onUpdateInstruction: () => void;
  edgeName: string;
  setEdgeName: (name: string) => void;
  instruction: string;
  setInstruction: (instruction: string) => void;
}
export default function BranchInstruction({
  edgeName,
  setEdgeName,
  onCancel,
  onUpdateInstruction,
  instruction,
  setInstruction,
}: Props) {
  return (
    <div>
      <div className="flex-1 flex flex-col mt-6 px-8 mb-8">
        <p className="font-medium text-base">
          Conditions determined by instructions
        </p>
        <p className="font-normal text-sm mt-2 text-gray-400">
          For complex actions or actions that aren’t yet supported, you can
          provide us instructions or requests so that we can still handle them
          in your workflows.
        </p>
        <p className="font-bold text-sm mt-6">Branch Name</p>
        <div className="relative mt-6">
          <Input
            label={
              <label
                className="text-xs absolute bg-white z-10 top-0 ml-3 px-1 -mt-1 text-gray-400"
                htmlFor="name"
              >
                Name
              </label>
            }
            onChange={setEdgeName}
            placeholder="Name"
            value={edgeName}
          />
        </div>
        <p className="font-bold text-sm mt-6">Take this branch when</p>
        <div className="relative mt-6">
          <Input
            label={
              <label
                className="text-xs absolute bg-white z-10 top-0 ml-3 px-1 -mt-1 text-gray-400"
                htmlFor="name"
              >
                Instructions
              </label>
            }
            multiline
            onChange={setInstruction}
            placeholder="Write instructions for sola to handle it for you"
            rows={5}
            value={instruction}
          />
        </div>
      </div>

      <div className="flex w-full gap-9 px-8 py-6  absolute bottom-0">
        <Button
          className="!flex-1"
          color="secondary"
          fullWidth
          onClick={() => {
            onUpdateInstruction();
          }}
          variant="contained"
        >
          Save changes
        </Button>
        <Button
          className="!flex-1"
          color="secondary"
          fullWidth
          onClick={onCancel}
          variant="outlined"
        >
          cancel
        </Button>
      </div>
    </div>
  );
}
