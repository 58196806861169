import { clsx } from 'clsx';
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Radio,
  EditPencilIcon,
  IconButton,
  Input,
  CheckedCheckmarkIcon,
  Tooltip,
} from 'ui-kit';
import { CloseIcon } from 'assets-shared';
import type {
  BaseQueryPaths,
  MultiChoiceVariable,
  Variable,
} from 'types-shared';
import FormControlLabel from '@mui/material/FormControlLabel';
import { elementText } from 'types-shared';
import isNil from 'lodash/isNil';
import truncate from 'lodash/truncate';
import type { Item } from './VariableInput';

interface PreviewProps {
  variable: MultiChoiceVariable;
}

export function MultiChoicePreview({
  variable: { multiChoiceOptions, selectedChoiceIx },
}: PreviewProps) {
  const previewText = !isNil(selectedChoiceIx)
    ? truncate(elementText(multiChoiceOptions[selectedChoiceIx]) ?? '', {
        length: 50,
      }) || `Option ${selectedChoiceIx + 1}`
    : '';
  return <span className="px-3 flex items-center">{previewText}</span>;
}

interface Props {
  data: Item[];
  disabled?: boolean;
  className?: string;
  options: BaseQueryPaths[];
  onChange: (val: Item[]) => void;
  optionEditable: boolean;
  variable: MultiChoiceVariable;
  updateVariable: (variable: Variable) => void;
}

export function MultipleChoice({
  className,
  data,
  disabled,
  onChange,
  options,
  optionEditable,
  variable,
  updateVariable,
}: Props) {
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [label, setLabel] = useState<string>('');
  const handleItemSelect = (value: number) => {
    const itemId = uuidv4();
    onChange([
      {
        id: itemId,
        type: 'string',
        value: value.toString(),
      },
    ]);
  };

  const updateOptionLabel = (optionIndex: number) => {
    updateVariable({
      ...variable,
      multiChoiceOptions: variable.multiChoiceOptions.map((option, i) => {
        if (i === optionIndex) {
          return {
            ...option,
            innerText: label,
            ariaLabel: label,
          };
        }
        return option;
      }),
    });
    setLabel('');
  };

  return (
    <div
      className={clsx(
        'relative flex flex-col items-start max-w-full rounded',
        className,
      )}
    >
      {options.map((queryPath, i) => {
        const optionText = elementText(queryPath) || `Option ${i + 1}`;

        return (
          <div
            className={clsx(
              'w-full max-w-full pr-5 flex flex-row items-center mb-4',
              editingIndex === i ? '!py-2' : '',
            )}
            key={queryPath.id ?? queryPath.cssPath}
          >
            {editingIndex === i ? (
              <Input
                classes={{ wrapper: '!mx-0 !w-full' }}
                label="Option Label"
                onChange={(val: string) => {
                  setLabel(val);
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter' && label) {
                    setEditingIndex(null);
                    updateOptionLabel(i);
                  }
                }}
                value={label}
              />
            ) : (
              <FormControlLabel
                className={clsx(
                  '!mx-0 !border-b !w-full !min-w-full',
                  optionText.length > 35
                    ? '!items-start !py-2'
                    : '!items-center',
                )}
                control={
                  <Radio
                    checked={i.toString() === data[0]?.value}
                    className={clsx('!-ml-3', {
                      '!-mt-1.5': optionText.length > 35,
                    })}
                    color="secondary"
                    disabled={disabled}
                    onClick={() => {
                      handleItemSelect(i);
                    }}
                  />
                }
                label={<span className="text-sm">{optionText}</span>}
                sx={{
                  '& .MuiFormControlLabel-label': {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  },
                }}
              />
            )}
            {editingIndex === null && optionEditable ? (
              <IconButton
                onClick={() => {
                  setLabel(optionText);
                  setEditingIndex(i);
                }}
              >
                <Tooltip title="Edit Option Label">
                  <EditPencilIcon className="!w-5 !h-5 !text-gray-800" />
                </Tooltip>
              </IconButton>
            ) : null}

            {editingIndex === i ? (
              <div className="flex flex-row items-center">
                <IconButton
                  className="!mt-5"
                  onClick={() => {
                    setEditingIndex(null);
                  }}
                >
                  <Tooltip title="Cancel">
                    <CloseIcon className="!w-6 !h-6 !text-gray-800" />
                  </Tooltip>
                </IconButton>
                <IconButton
                  className={clsx('!mt-5', {
                    '!opacity-50 !cursor-not-allowed': !label,
                  })}
                  disabled={!label}
                  onClick={() => {
                    setEditingIndex(null);
                    updateOptionLabel(i);
                  }}
                >
                  <Tooltip title="Save">
                    <CheckedCheckmarkIcon className="!w-6 !h-6 !text-info" />
                  </Tooltip>
                </IconButton>
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
}
