"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTemporalExecutionDataSchema = exports.getZippedExecutionOutputsSchema = exports.getMultipleExecutionsSchema = exports.getExecutionSchema = void 0;
var types_shared_1 = require("types-shared");
var utils_schema_1 = require("../utils.schema");
exports.getExecutionSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z.object({
            executionId: types_shared_1.uuidSchema,
        }),
        query: types_shared_1.z.object({}).optional(),
        body: types_shared_1.z.object({}).optional(),
    }),
    response: types_shared_1.z.object({
        metadata: types_shared_1.ExecutionBase,
        variablesUrls: types_shared_1.z.array(types_shared_1.z.string()),
        imageUrls: types_shared_1.z.array(types_shared_1.z.string()),
        artifacts: types_shared_1.z.array(utils_schema_1.ExecutionDocument),
    }),
};
exports.getMultipleExecutionsSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z.object({}).optional(),
        query: types_shared_1.z.object({}).optional(),
        body: types_shared_1.z.object({
            executionIds: types_shared_1.z.array(types_shared_1.uuidSchema),
            statusFilters: types_shared_1.z.array(types_shared_1.ExecutionStatus).optional(),
        }),
    }),
    response: types_shared_1.z.array(types_shared_1.ExecutionBase),
};
exports.getZippedExecutionOutputsSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z
            .object({
            executionId: types_shared_1.uuidSchema.optional(),
        })
            .optional(),
        body: types_shared_1.z
            .object({
            executionIds: types_shared_1.z.array(types_shared_1.uuidSchema).optional(),
        })
            .optional(),
        query: types_shared_1.z
            .object({
            includeScreenshots: types_shared_1.z.string().optional(),
        })
            .optional(),
    }),
    response: types_shared_1.z.object({
        url: types_shared_1.z.string(),
    }),
};
exports.getTemporalExecutionDataSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z.object({
            executionId: types_shared_1.uuidSchema,
        }),
        query: types_shared_1.z.object({}).optional(),
        body: types_shared_1.z.object({}).optional(),
    }),
    response: types_shared_1.z.object({
        scrapeData: types_shared_1.z.record(types_shared_1.z.string(), types_shared_1.z.string()),
        artifactUrls: types_shared_1.z.array(types_shared_1.z.string()),
    }),
};
