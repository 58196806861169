import { CloseIcon } from 'assets-shared';
import { Button, FormControlLabel, Radio, RadioGroup } from 'ui-kit';
import type { WorkflowEndingNode } from 'types-shared';
import { NodeStatusEnum } from 'types-shared';
import React from 'react';
import { NodeCheck } from '../NodeCheck';

interface Props {
  node: WorkflowEndingNode;
  updateNodeDataStatus: (status: string) => void;
  updateNodeStatus: (status: NodeStatusEnum) => void;
  onCancel: () => void;
}

export function EndingBlock({
  node,
  onCancel,
  updateNodeDataStatus,
  updateNodeStatus,
}: Props) {
  return (
    <div className="absolute left-2 top-2 bottom-2 w-120 bg-white rounded-lg z-[10] p-8 flex flex-col justify-between space-y-5">
      <div className="overflow-visible">
        <div className="flex justify-between items-center">
          <span className="text-sm text-primary-blue font-medium">
            Ending block
          </span>
          <button
            className="border border-info rounded-lg h-10 w-10 flex justify-center items-center"
            onClick={onCancel}
            type="button"
          >
            <CloseIcon className="text-info" />
          </button>
        </div>
        <div className="my-6">
          <h2 className="text-lg font-medium">Ending block</h2>
          <p className="text-sm font-normal mt-2 text-info-dark">
            Choose a different ending to this branch. By default, reaching the
            end of a branch will result in marking the execution as a success.
          </p>
        </div>

        <RadioGroup
          name="node-types-group-2"
          onChange={(e, v) => {
            updateNodeDataStatus(v);
          }}
          value={node.data.status}
        >
          <FormControlLabel
            classes={{
              root: '!items-start',
            }}
            control={<Radio color="secondary" />}
            label={
              <div className="flex flex-col mt-2">
                <div className="leading-normal">Retry workflow</div>
                <div className="text-zinc-500 text-smeading-tight">
                  Re-run the execution from the start based on timing in the
                  execution settings. By default, workflows retry the following
                  day.
                </div>
              </div>
            }
            value="retry"
          />
          <div className="mt-4" />
          <FormControlLabel
            classes={{
              root: '!items-start',
            }}
            control={<Radio color="secondary" />}
            label={
              <div className="flex flex-col mt-2">
                <div className="leading-normal">Stop and fail workflow</div>
                <div className="text-zinc-500 text-smeading-tight">
                  Terminate the execution and mark as failed.
                </div>
              </div>
            }
            value="failure"
          />
        </RadioGroup>
      </div>
      <div className="flex flex-col space-y-7">
        <NodeCheck
          isChecked={node.data.nodeStatus === NodeStatusEnum.Checked}
          updateNodeStatus={updateNodeStatus}
        />
        <Button
          color="secondary"
          fullWidth
          onClick={onCancel}
          variant="outlined"
        >
          Back to flow view
        </Button>
      </div>
    </div>
  );
}
