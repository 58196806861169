import { useFeatureFlagEnabled } from 'posthog-js/react';
import { FeatureFlag } from 'dashboard-shared';
import { ExecutionDetailContent } from '../components/ExecutionDetailContent';
import { ExecutionDetailContentOld } from '../components/ExecutionDetailContentOld';

export function ExecutionDetail() {
  const newExecutionDetailsEnabled = useFeatureFlagEnabled(
    FeatureFlag.NewExecutionDetails,
  );

  if (newExecutionDetailsEnabled) {
    return <ExecutionDetailContent />;
  }

  return <ExecutionDetailContentOld />;
}
