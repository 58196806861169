import { MoreVert } from 'assets-shared';
import Switch from '@mui/material/Switch';
import { clsx } from 'clsx';
import values from 'lodash/values';
import React, { useEffect, useMemo, useState } from 'react';
import type {
  DatasourceMetadata,
  DatasourceTable,
  MultiChoiceVariable,
  Rule,
  ScrapeVariable,
  SelectVariable,
  Variable,
  VariableMap,
  VariableRef,
  WorkflowAction,
  WorkflowNode,
} from 'types-shared';
import {
  ActionsEnum,
  ActionValueCriteriaEnum,
  VariableTypeEnum,
  TriggerTypeEnum,
} from 'types-shared';
import { Button, Chip, IconButton, Menu, MenuItem } from 'ui-kit';
import { v4 as uuidv4 } from 'uuid';

import { handleException } from 'sentry-browser-shared';

import { type Item, VariableInput } from '../VariableTypes/VariableInput';
import { ActionHeader } from './ActionHeader';
import { MultipleChoice } from '../VariableTypes/MultipleChoice';
import { AddVariable } from '../Conditions/AddVariable';
import { PreviewVariable } from '../Conditions/PreviewVariable';
import { ConditionalField } from '../Conditions/ConditionalField';
import Select from '../Select';

interface Props {
  i: number;
  action: WorkflowAction;
  setSelectedAction: (
    val:
      | null
      | (WorkflowAction & {
          i: number;
        }),
  ) => void;

  datasourceMetadata: DatasourceMetadata | null;
  variablesMap: VariableMap;
  updateVariable: (variable: Variable) => void;
  tableData: DatasourceTable | null;
  addVariable: (variable: Variable) => void;
  updateImageNodeAction: (nodeId: string, action: WorkflowAction) => void;
  selectedNode: string | null | undefined;
  nodes: WorkflowNode[];
  setSelectedNode: (nodeId: string | null) => void;

  transformDataStatus: 'error' | 'idle' | 'pending' | 'success' | 'loading';
  triggerType?: TriggerTypeEnum;
  onTransformData: (
    prompt: string,
    textToTransform: string,
  ) => Promise<string | undefined>;

  onActionHover: (targetId: string) => void;
  onBlur: () => void;
  allowMultiChoiceOptionEdit?: boolean;
  adminMode?: boolean;

  // Feature flags
  selectionByConditionsEnabled?: boolean;
}

export type VariableData = string | VariableRef;

const transformVariableData = (
  variable: Variable,
  actionType: ActionsEnum,
): Item[] =>
  (variable.data as VariableData[]).map((item: VariableData) => ({
    id: uuidv4(),
    type: typeof item === 'string' ? 'string' : 'variable',
    value:
      actionType === ActionsEnum.MultiChoice
        ? (variable as MultiChoiceVariable).selectedChoiceIx?.toString() ?? ''
        : item,
  }));

const actionTypeToTitleMapping: Record<string, string> = {
  [ActionsEnum.MultiChoice]: 'Multiple Choice',
};

export function EditActionCore({
  i,
  action,
  setSelectedAction,
  datasourceMetadata,
  variablesMap,
  updateVariable,
  tableData,
  addVariable,
  updateImageNodeAction,
  nodes,
  selectedNode,
  setSelectedNode,
  transformDataStatus,
  onTransformData,
  triggerType,
  onActionHover,
  onBlur,
  allowMultiChoiceOptionEdit = false,
  adminMode = false,
  selectionByConditionsEnabled = true,
}: Props) {
  const { variableId, actionType, criteria, targetId, rules } = action;
  const hasDatasource = Boolean(datasourceMetadata);
  const variable = variableId ? variablesMap[variableId] : null;
  const [variableData, setVariableData] = useState<Item[]>([]);
  const [previewVariableIndex, setPreviewVariableIndex] = useState<
    number | null
  >(null);
  const [editVariableIndex, setEditVariableIndex] = useState<number | null>(
    null,
  );
  const [openAddNew, setOpenAddNew] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const onMenuClose = () => {
    setAnchorEl(null);
  };

  const [selectionType, setSelectionType] =
    useState<ActionValueCriteriaEnum | null>(null);
  const onDetermineSelectionType =
    (newSelectionType: ActionValueCriteriaEnum | null) => () => {
      setSelectionType(newSelectionType);
      setPreviewVariableIndex(null);
      setEditVariableIndex(null);
      setOpenAddNew(false);
      onMenuClose();
    };

  const { isScrape, isSelect, isMultiChoice, isInput } = useMemo(
    () => ({
      isSelect: actionType === ActionsEnum.Select,
      isScrape: actionType === ActionsEnum.Scrape,
      isMultiChoice: actionType === ActionsEnum.MultiChoice,
      isInput: actionType === ActionsEnum.Input,
    }),
    [actionType],
  );

  const data: Item[] = useMemo(
    () =>
      variable && !isScrape
        ? transformVariableData(variable as Variable, actionType)
        : [],
    [variable, isScrape, actionType],
  );

  const variables = useMemo(() => {
    const selectedVariableIds = variableData
      .filter((item: Item) => item.type !== 'string')
      .map((item: Item) => (item.value as VariableRef).id);
    return values(variablesMap).filter(
      ({ id, type }: Variable) =>
        [VariableTypeEnum.Scrape, VariableTypeEnum.Datasource].includes(type) &&
        !selectedVariableIds.includes(id),
    );
  }, [variablesMap, variableData]);

  const saveVariableChanges = () => {
    const formattedVariableData = variableData.map((item: Item) => item.value);
    let updatedVariable = {
      ...variable,
    };
    if (actionType === ActionsEnum.MultiChoice) {
      updatedVariable = {
        ...updatedVariable,
        selectedChoiceIx: formattedVariableData[0]
          ? Number(formattedVariableData[0])
          : null,
      };
    } else {
      updatedVariable = {
        ...updatedVariable,
        data: formattedVariableData,
      };
    }
    updateVariable(updatedVariable as Variable);
    if (selectedNode && selectionType) {
      updateImageNodeAction(selectedNode, {
        ...action,
        criteria: selectionType,
      });
    }
  };

  useEffect(() => {
    if (targetId) {
      onActionHover(targetId);
    }

    return () => {
      onBlur();
    };
  }, [targetId, onActionHover, onBlur]);

  useEffect(() => {
    setVariableData(data);
  }, [data]);

  const editVariableData = useMemo(() => {
    if (editVariableIndex !== null && !isScrape) {
      const id = (data[editVariableIndex].value as { id: string }).id;
      return variablesMap[id];
    }
    return undefined;
  }, [data, editVariableIndex, variablesMap, isScrape]);

  const toggleOpenAddNew = (state: boolean) => () => {
    setOpenAddNew(state);
  };

  const onAddNewVariable = () => {
    saveVariableChanges();
    setOpenAddNew(true);
  };

  const onPreviewVariable = (selectedIndex: number, chosenItem?: Item) => {
    let actualIndex = selectedIndex;
    if (chosenItem) {
      actualIndex = variableData.findIndex((item) => item.id === chosenItem.id);
    }
    !isScrape && saveVariableChanges();
    setPreviewVariableIndex(actualIndex);
  };

  const onClosePreviewVariable = () => {
    setPreviewVariableIndex(null);
  };

  const handleEditVariable = (index: number) => () => {
    setPreviewVariableIndex(null);
    setEditVariableIndex(index);
  };

  const onCloseEditVariable = () => {
    setPreviewVariableIndex(editVariableIndex);
    setEditVariableIndex(null);
  };

  const onEditClose = () => {
    if (openAddNew) {
      setOpenAddNew(false);
      return;
    }
    if (previewVariableIndex) {
      onClosePreviewVariable();
      return;
    }
    if (editVariableIndex) {
      onCloseEditVariable();
      return;
    }
    setSelectedAction(null);
  };

  const updateVariableData = (variableRef: VariableRef) => {
    if (variableId) {
      const actionVariable = variablesMap[variableId];
      updateVariable({
        ...actionVariable,
        data:
          actionType === ActionsEnum.Select
            ? [variableRef]
            : [...(actionVariable.data as unknown[]), variableRef, ''],
      } as Variable);
    }
  };

  const selectedNodeData: WorkflowNode | undefined = useMemo(() => {
    return nodes.find((_node) => _node.id === selectedNode);
  }, [nodes, selectedNode]);

  const selectedVariable = useMemo(() => {
    let id = variableId;

    if (previewVariableIndex !== null) {
      id = variableData[previewVariableIndex]?.id;
    }

    if (editVariableIndex !== null) {
      id = variableData[editVariableIndex]?.id;
    }

    return id ? variablesMap[id] : (variable as ScrapeVariable);
  }, [
    variableId,
    previewVariableIndex,
    editVariableIndex,
    variablesMap,
    variable,
    variableData,
  ]);

  const isApiCallChip = useMemo(() => {
    return (
      editVariableData?.type === VariableTypeEnum.Datasource &&
      editVariableData.data.triggerType === TriggerTypeEnum.API
    );
  }, [editVariableData]);

  if (!variable) {
    handleException(new Error('Variable not found in action manager'), {});
    return null;
  }

  return (
    <>
      <ActionHeader
        handleOnClose={onEditClose}
        isAddNewVariable={openAddNew}
        isEditingVariable={editVariableIndex !== null}
        isPreviewingVariable={previewVariableIndex !== null}
        node={selectedNodeData}
        selectedAction={action}
        selectedActionIndex={i}
        selectedVariable={selectedVariable}
        setSelectedNode={setSelectedNode}
      />
      <div className="flex-1 flex flex-col">
        <div className="flex justify-between items-center pb-10">
          <h2 className="flex items-center space-x-3">
            <span
              className={clsx(
                'text-xs text-white rounded-full h-6 w-6 flex justify-center items-center',
                {
                  'text-gray-600 bg-gray-300 ': openAddNew,
                  ' bg-gray-800': !openAddNew,
                },
              )}
            >
              {i}
            </span>
            <span
              className={clsx('font-medium text-lg', {
                'text-gray-500': openAddNew,
              })}
            >
              {openAddNew
                ? 'Text entry: Name'
                : `${actionTypeToTitleMapping[actionType] ?? actionType}${variable.name ? `: ${variable.name}` : ''}`}
            </span>
          </h2>
          {!isScrape ? (
            <IconButton
              className="!p-0"
              onClick={(e) => {
                setAnchorEl(e.target as HTMLButtonElement);
              }}
            >
              <MoreVert className="!w-5 !h-5 text-black" />
            </IconButton>
          ) : null}
        </div>
        {!isScrape ? (
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            onClose={onMenuClose}
            open={Boolean(anchorEl)}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem
              className="!font-medium"
              onClick={onDetermineSelectionType(
                ActionValueCriteriaEnum.Variable,
              )}
            >
              <span className="font-medium mr-4">
                Determine selection by variables
              </span>
            </MenuItem>
            <MenuItem
              className="!font-medium"
              disabled={!selectionByConditionsEnabled}
              onClick={onDetermineSelectionType(
                ActionValueCriteriaEnum.Condition,
              )}
            >
              <span className="font-medium mr-4">
                Determine selection by conditions
              </span>
            </MenuItem>
          </Menu>
        ) : null}

        {selectionType === ActionValueCriteriaEnum.Variable ||
        isScrape ||
        (!selectionType && !criteria) ||
        (!selectionType && criteria === ActionValueCriteriaEnum.Variable) ? (
          <>
            <div className="my-10 border rounded-lg px-4 py-6 text-sm flex flex-col">
              {isScrape ? (
                <span className="text-gray-500 mb-6">
                  Content scraped during the run is automatically transformed
                  into a variable. Click to preview its content.
                </span>
              ) : null}

              <span className="font-medium mb-4 text-info">
                {editVariableIndex !== null ? 'Editing variable' : 'Value'}
              </span>

              {!openAddNew &&
              editVariableIndex === null &&
              previewVariableIndex === null &&
              isSelect ? (
                <span className="text-gray-500 mb-6">
                  You can modify the selected option by adding variables that
                  match the items in the dropdown.
                </span>
              ) : null}

              {editVariableIndex === null && isInput ? (
                <VariableInput
                  allowAddVariable={
                    hasDatasource || triggerType === TriggerTypeEnum.API
                  }
                  className="mt-4"
                  data={variableData}
                  disabled={openAddNew || previewVariableIndex !== null}
                  label=""
                  onAddNew={onAddNewVariable}
                  onChange={setVariableData}
                  onPreview={onPreviewVariable}
                  previewVariableIndex={previewVariableIndex}
                  variables={variables}
                  variablesMap={variablesMap}
                />
              ) : null}

              {isMultiChoice ? (
                <MultipleChoice
                  className="mt-4"
                  data={variableData}
                  disabled={openAddNew}
                  onChange={setVariableData}
                  optionEditable={allowMultiChoiceOptionEdit}
                  options={(variable as MultiChoiceVariable).multiChoiceOptions}
                  updateVariable={updateVariable}
                  variable={variable as MultiChoiceVariable}
                />
              ) : null}

              {editVariableIndex === null && isSelect ? (
                <Select
                  allowAddVariable={
                    hasDatasource || triggerType === TriggerTypeEnum.API
                  }
                  className="mt-4"
                  data={variableData}
                  disabled={openAddNew}
                  label={openAddNew ? 'Name' : variable.name ?? ''}
                  onAddNew={onAddNewVariable}
                  onChange={setVariableData}
                  onPreview={onPreviewVariable}
                  options={(variable as SelectVariable).selectOptions}
                  previewVariableIndex={previewVariableIndex}
                  variables={variables}
                  variablesMap={variablesMap}
                />
              ) : null}

              {editVariableIndex !== null || isScrape ? (
                <div className="">
                  <Chip
                    className={clsx(
                      {
                        '!bg-secondary-purple':
                          (isScrape
                            ? variable.type
                            : editVariableData?.type) ===
                          VariableTypeEnum.Scrape,
                      },
                      {
                        '!bg-transparent !border-secondary-blue !border-2 !text-secondary-blue':
                          isApiCallChip,
                      },
                    )}
                    clickable={isScrape}
                    color="secondary"
                    label={isScrape ? variable.name : editVariableData?.name}
                    onClick={() => {
                      if (editVariableIndex !== null) {
                        return;
                      }
                      isScrape && onPreviewVariable(1);
                    }}
                    size="small"
                    variant={isApiCallChip ? 'outlined' : 'filled'}
                  />
                </div>
              ) : null}

              {openAddNew ? (
                <AddVariable
                  addVariable={addVariable}
                  datasourceMetadata={datasourceMetadata}
                  onCancel={toggleOpenAddNew(false)}
                  onSave={updateVariableData}
                  onTransformData={onTransformData}
                  tableData={tableData}
                  transformDataStatus={transformDataStatus}
                  triggerType={triggerType}
                  updateVariable={updateVariable}
                  variables={variablesMap}
                />
              ) : null}
              {previewVariableIndex !== null ? (
                <PreviewVariable
                  datasourceMetadata={datasourceMetadata}
                  onCancel={onClosePreviewVariable}
                  onEdit={handleEditVariable(previewVariableIndex)}
                  tableData={tableData}
                  variable={
                    isScrape
                      ? (variable as ScrapeVariable)
                      : variableData[previewVariableIndex]
                  }
                  variables={variablesMap}
                />
              ) : null}
              {editVariableIndex !== null ? (
                <AddVariable
                  addVariable={addVariable}
                  datasourceMetadata={datasourceMetadata}
                  onCancel={onCloseEditVariable}
                  onTransformData={onTransformData}
                  tableData={tableData}
                  transformDataStatus={transformDataStatus}
                  triggerType={triggerType}
                  updateVariable={updateVariable}
                  variable={
                    isScrape
                      ? (variable as ScrapeVariable)
                      : variableData[editVariableIndex]
                  }
                  variables={variablesMap}
                />
              ) : null}
            </div>

            {!openAddNew &&
            previewVariableIndex === null &&
            editVariableIndex === null &&
            isScrape &&
            adminMode ? (
              <div className="border rounded-lg px-4 py-6 flex justify-between items-center text-sm font-medium">
                <div className="flex flex-col">
                  <span className="text-info">Output of a run</span>
                  <span className="text-gray-500 font-normal">
                    Include this scraped variable in the output of a workflow
                    execution
                  </span>
                </div>
                <Switch size="small" />
              </div>
            ) : null}
          </>
        ) : null}

        {selectionType === ActionValueCriteriaEnum.Condition ||
        (!selectionType && criteria === ActionValueCriteriaEnum.Condition) ? (
          <ConditionalField
            addVariable={addVariable}
            datasourceMetadata={datasourceMetadata}
            defaultRules={rules}
            onCancel={() => {
              setSelectionType(null);
            }}
            onSave={(_rules: Rule[]) => {
              if (!selectedNode) return;
              const updatedAction = {
                ...action,
                rules: _rules,
                criteria: ActionValueCriteriaEnum.Condition,
              };
              updateImageNodeAction(selectedNode, updatedAction);
              setSelectedAction({ ...updatedAction, i });
              setSelectionType(null);
            }}
            onTransformData={onTransformData}
            preview={!selectionType}
            tableData={tableData}
            transformDataStatus={transformDataStatus}
            triggerType={triggerType}
            updateVariable={updateVariable}
            variable={variable}
            variablesMap={variablesMap}
          />
        ) : null}

        {!isScrape && selectionType !== ActionValueCriteriaEnum.Condition ? (
          <>
            <span className="flex-1" />
            <Button
              className="!text-info !border-info !mt-5"
              color="secondary"
              onClick={() => {
                saveVariableChanges();
                setSelectedAction(null);
              }}
              variant="outlined"
            >
              Save & Exit
            </Button>
          </>
        ) : null}
      </div>
    </>
  );
}
