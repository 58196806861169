"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkflowEdge = exports.WorkflowNode = exports.WorkflowContinueNode = exports.WorkflowEndingNode = exports.WorkflowFreeformNode = exports.WorkflowConditionalNode = exports.BranchData = exports.BranchMode = exports.BranchModeEnum = exports.WorkflowNewNode = exports.WorkflowDatasourceNode = exports.WorkflowImageNode = exports.NodeData = exports.NodeImageData = exports.NodeStatus = exports.NodeStatusEnum = exports.ActionMap = exports.SelectedAction = exports.TargetStateData = exports.TargetMap = exports.Target = exports.WorkflowAction = exports.ActionValueCriteria = exports.ActionValueCriteriaEnum = exports.WorkflowActionsOptions = exports.NodeTypes = exports.NodeTypesEnum = void 0;
var zod_1 = require("zod");
var actionTypes_1 = require("../actionTypes");
var datasourceTypes_1 = require("../datasourceTypes");
var logic_schema_1 = require("../logicTypes/logic.schema");
var react_flow_schema_1 = require("./react-flow.schema");
var NodeTypesEnum;
(function (NodeTypesEnum) {
    NodeTypesEnum["Image"] = "image";
    NodeTypesEnum["Datasource"] = "datasource";
    NodeTypesEnum["New"] = "new";
    NodeTypesEnum["Conditional"] = "conditional";
    NodeTypesEnum["Freeform"] = "freeform";
    NodeTypesEnum["Continue"] = "continue";
    NodeTypesEnum["Ending"] = "ending";
})(NodeTypesEnum || (exports.NodeTypesEnum = NodeTypesEnum = {}));
exports.NodeTypes = zod_1.z.nativeEnum(NodeTypesEnum);
exports.WorkflowActionsOptions = zod_1.z.object({
    hidden: zod_1.z.boolean().optional(),
    adminOnly: zod_1.z.boolean().optional(),
    hitl: zod_1.z.boolean().optional(),
    sitl: zod_1.z.boolean().optional(),
    download: zod_1.z.array(actionTypes_1.DownloadData).optional(),
    mfa: zod_1.z.boolean().optional(),
    captcha: zod_1.z.boolean().optional(),
    submitted: zod_1.z.boolean().optional(),
    chooseFromList: zod_1.z.boolean().optional(),
    adminManual: zod_1.z.boolean().optional(),
});
var ActionValueCriteriaEnum;
(function (ActionValueCriteriaEnum) {
    ActionValueCriteriaEnum["Condition"] = "Condition";
    ActionValueCriteriaEnum["Variable"] = "Variable";
})(ActionValueCriteriaEnum || (exports.ActionValueCriteriaEnum = ActionValueCriteriaEnum = {}));
exports.ActionValueCriteria = zod_1.z.nativeEnum(ActionValueCriteriaEnum);
exports.WorkflowAction = zod_1.z.object({
    id: zod_1.z.string(),
    actionType: actionTypes_1.Actions,
    targetId: zod_1.z.string().optional(),
    variableId: zod_1.z.string().optional(),
    title: zod_1.z.string().optional(),
    description: zod_1.z.string().optional(),
    options: exports.WorkflowActionsOptions.optional(),
    rules: zod_1.z.array(logic_schema_1.Rule).optional(),
    criteria: exports.ActionValueCriteria.optional(),
    keyType: actionTypes_1.KeyTypes.optional(),
});
exports.Target = zod_1.z.object({
    id: zod_1.z.string(),
    name: zod_1.z.string().optional(),
    ref: actionTypes_1.QueryPaths,
    coordinates: actionTypes_1.RelativeCoordinates,
});
exports.TargetMap = zod_1.z.record(exports.Target);
exports.TargetStateData = zod_1.z.object({
    targets: exports.TargetMap,
});
exports.SelectedAction = zod_1.z.object({
    nodeId: zod_1.z.string(),
    actionId: zod_1.z.string(),
});
exports.ActionMap = zod_1.z.record(exports.WorkflowAction);
var NodeStatusEnum;
(function (NodeStatusEnum) {
    NodeStatusEnum["NotViewed"] = "NotViewed";
    NodeStatusEnum["Viewed"] = "Viewed";
    NodeStatusEnum["Checked"] = "Checked";
    NodeStatusEnum["Autolinked"] = "Autolinked";
    NodeStatusEnum["Warning"] = "Warning";
    NodeStatusEnum["Error"] = "Error";
})(NodeStatusEnum || (exports.NodeStatusEnum = NodeStatusEnum = {}));
exports.NodeStatus = zod_1.z.nativeEnum(NodeStatusEnum);
exports.NodeImageData = zod_1.z.object({
    imageId: zod_1.z.string(),
});
exports.NodeData = zod_1.z.object({
    imageData: exports.NodeImageData,
    actionData: exports.ActionMap,
    actionOrder: zod_1.z.array(zod_1.z.string()),
    nodeUrls: zod_1.z.array(zod_1.z.string()),
    nodeStatus: exports.NodeStatus,
    selected: zod_1.z.boolean().optional(),
});
exports.WorkflowImageNode = react_flow_schema_1.RfNode.extend({
    type: zod_1.z.literal(NodeTypesEnum.Image),
    data: exports.NodeData,
});
exports.WorkflowDatasourceNode = react_flow_schema_1.RfNode.extend({
    type: zod_1.z.literal(NodeTypesEnum.Datasource),
    data: zod_1.z
        .object({
        meta: datasourceTypes_1.DatasourceMetadata.optional(),
    })
        .merge(exports.NodeData.pick({ nodeStatus: true })),
});
exports.WorkflowNewNode = react_flow_schema_1.RfNode.extend({
    type: zod_1.z.literal(NodeTypesEnum.New),
    data: exports.NodeData.pick({ nodeStatus: true }),
});
var BranchModeEnum;
(function (BranchModeEnum) {
    BranchModeEnum["Rule"] = "Rule";
    BranchModeEnum["Instruction"] = "Instruction";
})(BranchModeEnum || (exports.BranchModeEnum = BranchModeEnum = {}));
exports.BranchMode = zod_1.z.nativeEnum(BranchModeEnum);
exports.BranchData = zod_1.z.object({
    branchId: zod_1.z.string(),
    rule: logic_schema_1.Rule.optional(),
    instruction: zod_1.z.string().optional(),
    selectedMode: exports.BranchMode,
});
exports.WorkflowConditionalNode = react_flow_schema_1.RfNode.extend({
    type: zod_1.z.literal(NodeTypesEnum.Conditional),
    data: exports.NodeData.pick({ nodeStatus: true, selected: true }).extend({
        branchesData: zod_1.z.array(exports.BranchData).optional(),
    }),
});
exports.WorkflowFreeformNode = react_flow_schema_1.RfNode.extend({
    type: zod_1.z.literal(NodeTypesEnum.Freeform),
    data: exports.NodeData.pick({ nodeStatus: true, selected: true }).extend({
        instructions: zod_1.z.string().optional(),
    }),
});
exports.WorkflowEndingNode = react_flow_schema_1.RfNode.extend({
    type: zod_1.z.literal(NodeTypesEnum.Ending),
    data: exports.NodeData.pick({ nodeStatus: true, selected: true }).extend({
        status: zod_1.z.enum(['failure', 'retry']).optional(),
    }),
});
exports.WorkflowContinueNode = react_flow_schema_1.RfNode.extend({
    type: zod_1.z.literal(NodeTypesEnum.Continue),
    data: exports.NodeData.pick({ nodeStatus: true }),
});
exports.WorkflowNode = zod_1.z.union([
    exports.WorkflowImageNode,
    exports.WorkflowDatasourceNode,
    exports.WorkflowNewNode,
    exports.WorkflowConditionalNode,
    exports.WorkflowFreeformNode,
    exports.WorkflowEndingNode,
    exports.WorkflowContinueNode,
]);
exports.WorkflowEdge = react_flow_schema_1.RfEdge.extend({
    data: zod_1.z
        .object({
        actionIndex: zod_1.z.number(),
    })
        .optional(),
});
