import { clsx } from 'clsx';
import isNil from 'lodash/isNil';

import type { DatasourceMetadata, DatasourceTable } from 'types-shared';

import {
  AddCircleOutline,
  CloudDoneOutlined,
  DatabaseIcon,
} from 'assets-shared';
import React from 'react';
import dayjs from '../../config/dayjs';

interface Props {
  showFullContent: boolean;
  datasourceMetadata: DatasourceMetadata | null;
  tableData: DatasourceTable | null;
  openDatasourcePage: () => void;
}

export function DatasourceNodeCore({
  showFullContent,
  datasourceMetadata,
  tableData,
  openDatasourcePage,
}: Props) {
  const hasDatasource = !isNil(datasourceMetadata);

  return (
    <>
      {showFullContent ? (
        <>
          <div
            className={clsx(
              'relative flex-1 rounded-lg overflow-hidden flex justify-center items-center w-full',
              {
                'bg-info-dark text-white hover:justify-start group':
                  hasDatasource,
                'border border-dashed': !hasDatasource,
              },
            )}
          >
            {hasDatasource ? (
              <div className="px-3 flex flex-col items-start space-y-3 font-medium text-sm">
                <span>{datasourceMetadata.name}</span>
                <span className="hidden text-gray-400 text-left group-hover:!flex">
                  Last modification:{' '}
                  {dayjs(datasourceMetadata.updatedAt).format('MM/DD/YY')}
                  <br />
                  Records: {tableData?.rowData.length ?? 0}
                </span>
              </div>
            ) : (
              <button
                className="bg-color-extralight-blue group hover:bg-info rounded-lg overflow-hidden p-4"
                type="button"
              >
                <AddCircleOutline className="text-info group-hover:text-white" />
              </button>
            )}
          </div>
          <div className="flex justify-between items-center w-full">
            <div className="flex items-center space-x-1 text-info-dark font-medium">
              <DatabaseIcon />
              <span>{hasDatasource ? '' : 'Add '}Database</span>
            </div>
            {hasDatasource ? <CloudDoneOutlined /> : null}
          </div>
        </>
      ) : (
        <div
          className={clsx(
            'absolute left-0 right-0 top-0 bottom-0 flex justify-center items-center rounded-lg',
            {
              'bg-info-dark text-white': hasDatasource,
            },
          )}
        >
          <DatabaseIcon className="!w-20 !h-20" />
          {!hasDatasource && (
            <button onClick={openDatasourcePage} type="button">
              <AddCircleOutline className="text-info !w-16 !h-16" />
            </button>
          )}
        </div>
      )}
    </>
  );
}
