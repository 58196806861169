import type { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import type { WorkflowMetadataType } from 'api-types-shared';
import dayjs from 'dayjs';
import { Link, useNavigate } from 'react-router-dom';
import {
  Edit,
  MoreVert,
  ContentCopy,
  InfoOutlined,
  Delete,
} from 'assets-shared';
import { NoDataFound } from 'dashboard-shared';
import {
  Chip,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  ContentContainer,
  ScrollableContainer,
  DataLoader,
  dialogEventChannel,
} from 'ui-kit';

import ImportWorkflowButton from '../components/ImportWorkflowButton';
import NavigationPanel from '../../shared/NavigationPanel/NavigationPanel';
import { useDeleteWorkflow, useFetchWorkflowsList } from '../hooks';
import DuplicateModal, {
  duplicateModalEventChannel,
} from '../components/DuplicateModal';
import { useState, useMemo } from 'react';
import { clsx } from 'clsx';
import { WorkflowStatusEnum } from 'api-types-shared';

interface MenuProps {
  anchorEl: HTMLButtonElement;
  workflowId: string;
}

const getColumns = (
  setMenuProps: (props: MenuProps) => void,
): GridColDef<WorkflowMetadataType>[] => [
  {
    field: 'workflowName',
    headerName: 'Workflow Name',
    flex: 2,
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    valueGetter: (params) => new Date(params.row.createdAt),
    valueFormatter: (params: GridValueFormatterParams<Date>) =>
      dayjs(params.value).format('MM/DD/YY -  h:mm A'),
    flex: 1,
  },
  {
    field: 'updatedAt',
    headerName: 'Last Modification',
    valueGetter: (params) =>
      new Date(params.row.updatedAt ?? params.row.createdAt),
    valueFormatter: (params: GridValueFormatterParams<Date>) =>
      dayjs(params.value).format('MM/DD/YY -  h:mm A'),
    flex: 1,
  },
  {
    field: 'status',
    headerName: 'Status',
    renderCell: (params) => {
      const isWorkflowProcessing =
        params.row.status === WorkflowStatusEnum.ProcessingImport;

      return (
        <Chip
          className={clsx('!h-6', {
            '!bg-info-dark': isWorkflowProcessing,
          })}
          color={isWorkflowProcessing ? 'info' : 'success'}
          label={
            isWorkflowProcessing ? (
              <Tooltip
                arrow
                classes={{
                  tooltip: '!bg-black',
                  arrow: '!text-black',
                }}
                title="We are processing your workflow. You'll get a notification when it's ready!"
              >
                <div className="flex items-center space-x-1">
                  <span>Processing</span>
                  <InfoOutlined className="!h-4 !w-4" />
                </div>
              </Tooltip>
            ) : (
              'Active'
            )
          }
          variant="filled"
        />
      );
    },
    flex: 1,
  },
  {
    field: 'action',
    headerName: '',
    align: 'right',
    renderCell: (params) =>
      params.row.status !== WorkflowStatusEnum.ProcessingImport ? (
        <span className="flex flex-row align-center gap-4">
          <Link
            className="px-3 py-1.5 uppercase rounded border border-primary-light-blue text-primary-light-blue font-medium text-sm"
            to={`/workflows/${params.row.workflowId}`}
          >
            View Details
          </Link>
          <IconButton
            className="!p-0"
            onClick={(e) => {
              setMenuProps({
                anchorEl: e.target as HTMLButtonElement,
                workflowId: params.row.workflowId,
              });
            }}
          >
            <MoreVert className="text-secondary-blue" />
          </IconButton>
        </span>
      ) : null,
    flex: 2,
    sortable: false,
  },
];

export default function WorkflowsList() {
  const navigate = useNavigate();
  const { isLoading, data } = useFetchWorkflowsList();
  const [menuProps, setMenuProps] = useState<MenuProps | null>(null);
  const { mutateAsync: deleteWorkflow } = useDeleteWorkflow();

  const hasWorkflowInProcessingState = useMemo(
    () =>
      data?.some(
        (workflow) => workflow.status === WorkflowStatusEnum.ProcessingImport,
      ),
    [data],
  );

  const onCloseMenu = () => {
    setMenuProps(null);
  };

  const onOpenDuplicateModal = () => {
    onCloseMenu();
    duplicateModalEventChannel.emit('open');
  };

  const onEditWorkflow = () => {
    onCloseMenu();
    if (menuProps?.workflowId) {
      openWorkflowEditor(menuProps.workflowId);
    }
  };

  const openWorkflowEditor = (workflowId: string) => {
    navigate(`/editor/${workflowId}`);
  };

  const onDeleteWorkflow = () => {
    onCloseMenu();
    dialogEventChannel.emit('open', {
      title: 'Delete Workflow',
      onConfirm: async () => {
        dialogEventChannel.emit('close');
        if (menuProps?.workflowId) {
          await deleteWorkflow(menuProps.workflowId);
        }
      },
      hideCloseBtn: true,
      showActions: true,
      children: (
        <p className="text-sm">
          Are you sure, you want to delete this workflow?
        </p>
      ),
    });
  };

  const columns: GridColDef<WorkflowMetadataType>[] = useMemo(
    () => getColumns(setMenuProps),
    [],
  );

  return (
    <ScrollableContainer>
      <div className="w-full flex">
        <NavigationPanel />
        <ContentContainer>
          <div className="flex justify-between w-full items-center">
            <Typography variant="h4">Workflows</Typography>
            <ImportWorkflowButton openEditor={openWorkflowEditor} />
          </div>
          {hasWorkflowInProcessingState ? (
            <div className="mt-10 w-full bg-primary-blue-extralight flex space-x-2 px-4 py-3 rounded -mb-6">
              <InfoOutlined className="!w-5 !h-5 !text-[#0288D1] !mt-0.5" />
              <span className="font-medium text-primary-blue-dark">
                You have workflows actively processing, we’ll ping you when
                they’re ready!
              </span>
            </div>
          ) : null}
          <div className="mt-11">
            {isLoading ? (
              <DataLoader loadingText="Fetching workflow lists" />
            ) : (
              <>
                {data?.length ? (
                  <DataGrid
                    columns={columns}
                    getRowId={(row) => row.workflowId}
                    initialState={{
                      sorting: {
                        sortModel: [{ field: 'createdAt', sort: 'desc' }],
                      },
                    }}
                    rows={data}
                  />
                ) : (
                  <NoDataFound
                    heading="You don’t have workflows yet"
                    subHeading="You don’t have workflows yet. Use the Sola chrome extension to record a new workflow"
                  />
                )}
              </>
            )}
          </div>
        </ContentContainer>
      </div>
      <Menu
        BackdropProps={{
          style: {
            backgroundColor: 'transparent',
          },
        }}
        anchorEl={menuProps?.anchorEl ?? null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        onClose={onCloseMenu}
        open={Boolean(menuProps)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem className="group min-w-72 h-12" onClick={onEditWorkflow}>
          <span className="font-normal leading-6 mr-4 group-hover:text-primary-blue">
            Edit Workflow
          </span>
          <Edit className="!w-4 !h-4 !ml-auto group-hover:text-primary-blue" />
        </MenuItem>
        <MenuItem
          className="group min-w-72 h-12"
          disabled
          onClick={onOpenDuplicateModal}
        >
          <span className="font-normal leading-6 mr-4 group-hover:text-primary-blue">
            Duplicate Workflow
          </span>
          <ContentCopy className="!w-4 !h-4 !ml-auto group-hover:text-primary-blue" />
        </MenuItem>
        <MenuItem className="group min-w-72 h-12" onClick={onDeleteWorkflow}>
          <span className="font-normal leading-6 group-hover:text-primary-blue">
            Delete Workflow
          </span>
          <Delete className="!4-5 !h-4 !w-4 !ml-auto group-hover:text-primary-blue" />
        </MenuItem>
      </Menu>
      <DuplicateModal />
    </ScrollableContainer>
  );
}
