import { Chip, DataLoader, Flex } from 'ui-kit';
import type dayjsType from 'dayjs';
import type { ExecutionBase, TargetMap, WorkflowData } from 'types-shared';
import {
  ExecutionTriggerEnum,
  ExecutionStatusEnum,
  WorkflowImageNode,
} from 'types-shared';
import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import type { ExecutionDocument } from 'api-types-shared';
import { clsx } from 'clsx';
import isEmpty from 'lodash/isEmpty';

interface Props {
  getStatusColor: (
    status: ExecutionStatusEnum,
  ) => 'error' | 'success' | 'warning' | 'primary' | 'info';
  formatDuration: (milliseconds: number) => string;
  dayjs: typeof dayjsType;
  executionDetail: {
    metadata: ExecutionBase;
    artifacts: ExecutionDocument[];
  };
  imageArray: [string, Blob][];
  imagesLoading?: boolean;
  isAdmin?: boolean;
  workflowData?: WorkflowData;
  targetData?: TargetMap;
  children?: ReactNode;
}

const statusTitleMapping: Record<string, string> = {
  [ExecutionStatusEnum.PendingUser]: 'Running',
  [ExecutionStatusEnum.PendingSystem]: 'Running',
};

export function RunOverview({
  executionDetail,
  getStatusColor,
  // formatDuration,
  dayjs,
  imageArray,
  imagesLoading,
  isAdmin,
  workflowData,
  targetData,
  children,
}: Props) {
  const { metadata } = executionDetail;
  const imageUrl = imageArray[imageArray.length - 1]
    ? URL.createObjectURL(imageArray[imageArray.length - 1][1])
    : null;
  const executionDate = dayjs(new Date(metadata.createdAt)).format(
    'MM/DD/YY -  h:mm A',
  );
  const formattedStatus = metadata.status.toLowerCase().replace('_', ' ');
  const statusTitle = isAdmin
    ? formattedStatus
    : statusTitleMapping[metadata.status] ?? formattedStatus;

  // const difference = useMemo(() => {
  //   const { createdAt, lastUpdatedAt } = metadata;
  //   if (!createdAt || !lastUpdatedAt) {
  //     return '-';
  //   }
  //   const duration = dayjs(lastUpdatedAt).diff(dayjs(createdAt));
  //   return formatDuration(duration);
  // }, [dayjs, formatDuration, metadata]);

  const currentStepTarget = useMemo(() => {
    if (isAdmin) {
      const currentStepId = executionDetail.metadata.currentStep?.stepId;
      const imageNodes = workflowData?.nodes.filter(
        (node) => WorkflowImageNode.safeParse(node).success,
      ) as WorkflowImageNode[];
      const imageNodeWithCurrentAction = imageNodes.find((node) =>
        Object.keys(node.data.actionData).includes(currentStepId ?? ''),
      );
      const currentAction = Object.values(
        imageNodeWithCurrentAction?.data.actionData ?? {},
      ).find((actionData) => actionData.id === currentStepId);
      return Object.values(targetData ?? {}).find(
        (t) => t.id === currentAction?.targetId,
      );
    }
    return {};
  }, [executionDetail, workflowData, targetData, isAdmin]);

  return (
    <>
      <Flex className="gap-5 mt-10 items-start" flexDirection="row">
        <div className="flex flex-col space-y-4">
          {imageUrl ? (
            <img
              alt="Execution detail 1"
              className={clsx(
                'block object-contain rounded-lg max-h-full border-2 border-checked-green',
                {
                  '!border-error':
                    executionDetail.metadata.status ===
                    ExecutionStatusEnum.Failed,
                },
              )}
              src={imageUrl}
              style={{ width: 460, height: 340 }}
            />
          ) : null}
          {imagesLoading ? (
            <DataLoader className="!w-[22rem] !h-40 !mt-0" />
          ) : null}
        </div>

        <Flex className="gap-6 flex-1 font-medium" flexDirection="column">
          <Flex
            className="gap-4 flex-1 rounded-lg border border-indigo-light px-8 py-10"
            flexDirection="row"
            justifyContent="space-between"
          >
            <div>
              <p className="text-color-grey text-base mb-1.5">Run ID</p>
              <p className="text-info-dark text-base">
                {metadata.trigger === ExecutionTriggerEnum.API
                  ? 'N/A'
                  : Number(metadata.recordId) + 1}
              </p>
            </div>
            <div>
              <p className="text-color-grey text-base mb-1.5">
                Record Execution ID
              </p>
              <p className="text-info-dark text-base">{metadata.executionId}</p>
            </div>
            <div>
              <p className="text-color-grey text-base mb-1.5">Execution date</p>
              <p className="text-info-dark text-base">{executionDate}</p>
            </div>
            {metadata.attempt !== undefined ? (
              <div>
                <p className="text-color-grey text-base mb-1.5">Attempts</p>
                <p className="text-info-dark text-base">{metadata.attempt}</p>
              </div>
            ) : null}
            <div>
              <p className="text-color-grey text-base mb-1.5">Time length</p>
              <p className="text-info-dark text-base">-</p>
            </div>
            <div>
              <p className="text-color-grey text-base mb-1.5">Status</p>
              <Chip
                className="!h-6"
                color={getStatusColor(metadata.status)}
                label={statusTitle}
                sx={{ '& .MuiChip-label': { textTransform: 'capitalize' } }}
                variant="filled"
              />
            </div>
          </Flex>
          <Flex
            className="gap-4 flex-1 rounded-lg border border-indigo-light px-8 py-10 !hidden"
            flexDirection="row"
            justifyContent="space-between"
          >
            <div>
              <p className="text-info-dark text-5xl mb-3.5">20/20</p>
              <p className="text-info-dark text-base">Completed steps</p>
            </div>
            <div>
              <p className="text-info-dark text-5xl mb-3.5">100%</p>
              <p className="text-info-dark text-base">Completion rate</p>
            </div>
            <div>
              <p className="text-info-dark text-5xl mb-3.5">0</p>
              <p className="text-info-dark text-base">Steps with errors</p>
            </div>
            <div>
              <p className="text-info-dark text-5xl mb-3.5">4</p>
              <p className="text-info-dark text-base">Outputs</p>
            </div>
          </Flex>
        </Flex>
      </Flex>
      {children}
      <div className="mt-8 !hidden">
        <p className="text-info-dark text-2xl font-medium">Steps overview</p>
        <p className="text-sm text-color-grey font-normal">
          All the data entered in the workflow steps
        </p>
        <div className="rounded-lg mt-8 bg-color-light-blue p-9 min-h-[20rem]">
          <pre className="whitespace-pre-wrap text-info-dark">
            {`
    Step 01 {
      Status: Completed
      action 01 : [ Action value: example]
      Action 02 : [ Action value: example]

    Output 01:  [ Output value: example] }
      Status: Completed
      action 01 : [ Action value: example]
      Action 02 : [ Action value: example]
  `}
          </pre>
        </div>
      </div>
      {isAdmin ? (
        <Flex className="space-x-4 mt-8">
          {executionDetail.metadata.completedSteps &&
          !isEmpty(executionDetail.metadata.completedSteps) ? (
            <div className="flex-1 overflow-x-hidden">
              <p className="text-info-dark text-2xl font-medium">
                Completed Steps
              </p>
              <div className="rounded-lg mt-8 bg-color-light-blue p-9 min-h-[30rem] max-h-64 overflow-y-auto">
                <pre className="whitespace-pre-wrap text-info-dark">
                  {JSON.stringify(
                    executionDetail.metadata.completedSteps,
                    null,
                    2,
                  )}
                </pre>
              </div>
            </div>
          ) : null}
          {executionDetail.metadata.currentStep &&
          !isEmpty(executionDetail.metadata.currentStep) ? (
            <div className="flex-1 overflow-x-hidden">
              <p className="text-info-dark text-2xl font-medium">
                Current Step
              </p>
              <div className="rounded-lg mt-8 bg-color-light-blue p-9 min-h-[30rem] max-h-64 overflow-y-auto">
                <pre className="whitespace-pre-wrap text-info-dark">
                  {JSON.stringify(
                    {
                      ...executionDetail.metadata.currentStep,
                      currentStepTarget,
                    },
                    null,
                    2,
                  )}
                </pre>
              </div>
            </div>
          ) : null}
          {executionDetail.metadata.variableData &&
          !isEmpty(executionDetail.metadata.variableData) ? (
            <div className="flex-1 overflow-x-hidden">
              <p className="text-info-dark text-2xl font-medium">
                Variables Data
              </p>
              <div className="rounded-lg mt-8 bg-color-light-blue p-9 min-h-[30rem] max-h-64 overflow-y-auto">
                <pre className="whitespace-pre-wrap text-info-dark">
                  {JSON.stringify(
                    executionDetail.metadata.variableData,
                    null,
                    2,
                  )}
                </pre>
              </div>
            </div>
          ) : null}
        </Flex>
      ) : null}
    </>
  );
}
