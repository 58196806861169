"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageEvent = exports.ExtensionEvent = exports.sourceSchema = exports.ExtensionData = exports.RecordingConfig = exports.ExtensionScreenshotArray = exports.ExtensionDataActions = exports.RecordingAction = exports.extensionDataType = exports.ExtensionScreenshot = exports.BrowserAction = exports.BrowserUrlAction = exports.BrowserDownloadAction = exports.DownloadData = exports.BrowserActionType = exports.BrowserActionTypeEnum = void 0;
var zod_1 = require("zod");
var action_types_1 = require("./action-types");
var BrowserActionTypeEnum;
(function (BrowserActionTypeEnum) {
    BrowserActionTypeEnum["Download"] = "Download";
    BrowserActionTypeEnum["InitialTab"] = "InitialTab";
    BrowserActionTypeEnum["NewTab"] = "NewTab";
    BrowserActionTypeEnum["TabUpdate"] = "TabUpdate";
})(BrowserActionTypeEnum || (exports.BrowserActionTypeEnum = BrowserActionTypeEnum = {}));
exports.BrowserActionType = zod_1.z.nativeEnum(BrowserActionTypeEnum);
exports.DownloadData = zod_1.z.object({
    url: zod_1.z.string().optional(),
    filename: zod_1.z.string().optional(),
    mimeType: zod_1.z.string().optional(),
    finalUrl: zod_1.z.string().optional(),
});
exports.BrowserDownloadAction = zod_1.z.object({
    type: zod_1.z.literal(BrowserActionTypeEnum.Download),
    data: exports.DownloadData,
});
exports.BrowserUrlAction = zod_1.z.object({
    type: zod_1.z.union([
        zod_1.z.literal(BrowserActionTypeEnum.InitialTab),
        zod_1.z.literal(BrowserActionTypeEnum.NewTab),
        zod_1.z.literal(BrowserActionTypeEnum.TabUpdate),
    ]),
    data: zod_1.z.object({
        url: zod_1.z.string(),
        title: zod_1.z.string(),
    }),
});
exports.BrowserAction = zod_1.z.union([exports.BrowserDownloadAction, exports.BrowserUrlAction]);
exports.ExtensionScreenshot = zod_1.z.object({
    dataUrl: zod_1.z.string(),
    y: zod_1.z.number(),
});
exports.extensionDataType = 'Sola-Extension-Upload';
exports.RecordingAction = zod_1.z.union([action_types_1.ExtensionAction, exports.BrowserAction]);
exports.ExtensionDataActions = zod_1.z.array(zod_1.z.array(exports.RecordingAction));
exports.ExtensionScreenshotArray = zod_1.z.array(zod_1.z.array(exports.ExtensionScreenshot));
exports.RecordingConfig = zod_1.z.object({
    replaceNodeId: zod_1.z.string().optional(),
});
exports.ExtensionData = zod_1.z.object({
    actions: exports.ExtensionDataActions,
    scans: exports.ExtensionScreenshotArray,
    capturedUrls: zod_1.z.array(zod_1.z.string()),
    config: exports.RecordingConfig.optional(),
});
exports.sourceSchema = zod_1.z.custom(function (val) { return val instanceof Window && typeof val === typeof globalThis; });
exports.ExtensionEvent = zod_1.z.object({
    data: exports.ExtensionData,
    source: exports.sourceSchema,
});
exports.MessageEvent = zod_1.z.object({
    data: zod_1.z
        .object({
        type: zod_1.z.string().optional(),
    })
        .optional(),
    source: exports.sourceSchema.optional(),
});
