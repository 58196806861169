import { CloseIcon, EditOutlined } from 'assets-shared';
import { Button, Input } from 'ui-kit';
import type { WorkflowFreeformNode } from 'types-shared';
import { NodeStatusEnum } from 'types-shared';
import React, { useState } from 'react';
import { NodeCheck } from '../NodeCheck';

interface Props {
  node: WorkflowFreeformNode;
  updateNodeName: (name: string) => void;
  updateNodeInstructions: (instruction: string) => void;
  updateNodeStatus: (status: NodeStatusEnum) => void;
  onCancel: () => void;
}

export function FreeformBlock({
  node,
  onCancel,
  updateNodeName,
  updateNodeInstructions,
  updateNodeStatus,
}: Props) {
  const [editing, setEditing] = useState(false);

  return (
    <div className="absolute left-2 top-2 bottom-2 w-120 bg-white rounded-lg z-[10] p-8 flex flex-col justify-between space-y-5">
      <div className="overflow-auto">
        <div className="flex justify-between items-center">
          <span className="text-sm text-primary-blue font-medium">
            Freeform Instructions
          </span>
          <button
            className="border border-info rounded-lg h-10 w-10 flex justify-center items-center"
            onClick={onCancel}
            type="button"
          >
            <CloseIcon className="text-info" />
          </button>
        </div>
        <div className="my-6">
          <h2 className="text-lg font-medium">Freeform Instructions</h2>
          <p className="text-sm font-normal mt-2 text-info-dark">
            For complex actions or actions that aren’t yet supported, you can
            provide us instructions or requests so that we can still handle them
            in your workflows.
          </p>
        </div>
        <div className="flex-1 flex flex-col gap-4">
          <div className="relative pt-1">
            <Input
              InputProps={{ className: '!py-1' }}
              label={
                <label
                  className="text-xs absolute bg-white z-10 top-0 ml-3 px-1 mt-0.5 text-gray-400"
                  htmlFor="name"
                >
                  Block Name
                </label>
              }
              onChange={updateNodeName}
              placeholder="Block Name"
              value={node.name ?? ''}
            />
          </div>
          <div className="flex flex-row items-center mt-6 justify-between">
            <p className="font-medium text-info-dark text-sm">Instructions</p>
            {editing ? (
              <button
                className="cursor-pointer"
                onClick={() => {
                  setEditing(true);
                }}
                type="button"
              >
                <EditOutlined className="text-info-dark" />
              </button>
            ) : null}
          </div>
          <div className="relative pt-1">
            <Input
              InputProps={{ className: '!pr-14' }}
              label={
                <label
                  className="text-xs absolute bg-white z-10 top-0 ml-3 px-1 mt-0.5 text-gray-400"
                  htmlFor="name"
                >
                  Instructions
                </label>
              }
              multiline
              onChange={updateNodeInstructions}
              placeholder="Write instructions for a step in your workflow"
              rows={5}
              value={node.data.instructions ?? ''}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col space-y-7">
        <NodeCheck
          isChecked={node.data.nodeStatus === NodeStatusEnum.Checked}
          updateNodeStatus={updateNodeStatus}
        />
        <Button
          color="secondary"
          fullWidth
          onClick={onCancel}
          variant="outlined"
        >
          Back to flow view
        </Button>
      </div>
    </div>
  );
}
