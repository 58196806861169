import {
  Add,
  EditOutlined,
  Error as AssetError,
  DeleteOutlineIcon,
} from 'assets-shared';
import { CheckCircleIcon, SolaModal, Button } from 'ui-kit';
import { clsx } from 'clsx';
import type { ReactNode } from 'react';
import React, { useMemo, useState } from 'react';
import type {
  WorkflowEdge,
  WorkflowNode,
  WorkflowNodeProps,
} from 'types-shared';
import { NodeStatusEnum } from 'types-shared';
import { insertNodeAfter } from '../../utils/helper';
import { CustomHandle } from './Handles/Handle';
import { useEditingNodeId } from '../../hooks/useEditingNodeId';

interface Props {
  nodeSelectionModeEnabled: boolean;
  workflowData: WorkflowNodeProps;
  nodes: WorkflowNode[];
  edges: WorkflowEdge[];
  setNodes: (nodes: WorkflowNode[]) => void;
  setEdges: (edges: WorkflowEdge[]) => void;
  handleNodeClick?: () => void;
  children: ReactNode;

  showAddButton?: boolean;
  showEditButton?: boolean;
  showDeleteButton?: boolean;
  onClickEdit: (nodeId: string) => void;
  onClickDelete?: () => void;
  label?: string;
  leftConnectable?: boolean;
  rightConnectable?: boolean;
}

const actionIconMap: Record<string, ReactNode | undefined> = {
  [NodeStatusEnum.Checked]: <CheckCircleIcon className="!fill-none" />,
  [NodeStatusEnum.Error]: <AssetError className="!text-error" />,
};

export function NodeElementCore({
  workflowData: { data, type, id },
  handleNodeClick,

  nodes,
  edges,
  setEdges,
  setNodes,
  nodeSelectionModeEnabled,
  children,

  showAddButton = false,
  showEditButton = false,
  showDeleteButton = false,
  onClickEdit,
  onClickDelete,
  label = 'Node',
  leftConnectable = false,
  rightConnectable = false,
}: Props) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [aboutToDelete, setAboutToDelete] = useState(false);
  const updateAboutToDelete = (status: boolean) => () => {
    setAboutToDelete(status);
  };
  const status = data.nodeStatus;
  const showIcon = useMemo(
    () => [NodeStatusEnum.Error, NodeStatusEnum.Checked].includes(status),
    [status],
  );
  const actionIcon = actionIconMap[status];
  const { editingNodeId } = useEditingNodeId();

  const insertNode = (sourceId: string) => {
    const node = nodes.find((n) => n.id === sourceId);
    if (!node) {
      throw new Error('node not found');
    }
    const nodeId = insertNodeAfter(node, nodes, edges, {
      setNodes,
      setEdges,
    });
    onClickEdit(nodeId);
  };

  return (
    <div className="group relative">
      {!nodeSelectionModeEnabled && showDeleteButton ? (
        <button
          className="hidden absolute top-0 right-0 !w-6 !h-6 z-10 group-hover:flex w-6 h-6 rounded-full flex justify-center items-center !py-0 !px-0 text-info-dark border-2 border-primary-blue hover:border-red-600 bg-white hover:bg-red-600 hover:text-white !cursor-pointer"
          onClick={() => {
            setShowDeleteModal(true);
          }}
          onMouseEnter={updateAboutToDelete(true)}
          onMouseLeave={updateAboutToDelete(false)}
          type="button"
        >
          <DeleteOutlineIcon className="!w-4 !h-4 fill-none" />
        </button>
      ) : null}
      <div
        className={clsx('p-2 relative', {
          '!bg-gradient-to-br from-info-extralight to-purple-light !shadow-primary-purple drop-shadow-sm hover:from-info-semi-light hover:to-purple-light hover:drop-shadow-xl hover:shadow-inherit !rounded-3xl':
            status === NodeStatusEnum.Autolinked,
        })}
      >
        <div
          className={clsx(
            'relative bg-white flex flex-col space-y-3 overflow-hidden rounded-2xl p-3 w-60 h-50 ring-8 ring-transparent border border-indigo-light hover:border-info hover:drop-shadow-xl',
            {
              'border-2 !ring-info-extralight hover:!ring-info-semi-light':
                type !== 'datasource' &&
                type !== 'new' &&
                (status === NodeStatusEnum.NotViewed ||
                  status === NodeStatusEnum.Viewed ||
                  (!data.selected && nodeSelectionModeEnabled)),
              '!border-error !ring-error-extralight hover:!ring-error-light':
                status === NodeStatusEnum.Error,
              '!border-checked-green !ring-primary-light-green hover:!ring-checked-green-light':
                data.selected,
              '!border-primary-purple': status === NodeStatusEnum.Autolinked,
              '!border-info hover:!border-info shadow-xl': editingNodeId === id,
              '!border-red-600': aboutToDelete || showDeleteModal,
            },
          )}
          onClick={handleNodeClick}
          role="presentation"
        >
          {children}
          {showIcon ? (
            <div
              className={clsx(
                'absolute bottom-3 right-3 flex justify-between items-center bg-white',
                { 'text-error left-3': status === NodeStatusEnum.Error },
              )}
            >
              {status === NodeStatusEnum.Error && <span>Unavailable</span>}
              {actionIcon ? actionIcon : null}
            </div>
          ) : null}
        </div>
        <CustomHandle isConnectable={leftConnectable} type="target" />
        <CustomHandle isConnectable={rightConnectable} type="source" />
      </div>
      <div className="mt-4 w-full flex justify-center nodrag nopan">
        {!nodeSelectionModeEnabled ? (
          <div className="justify-center gap-3 hidden absolute group-hover:flex">
            {showEditButton ? (
              <button
                className="flex justify-center items-center color-primary-blue text-primary-blue py-1 px-3 border-2 border-primary-blue rounded hover:bg-primary-blue hover:text-white !cursor-pointer"
                onClick={() => {
                  onClickEdit(id);
                }}
                type="button"
              >
                <EditOutlined />
              </button>
            ) : null}
            {showAddButton ? (
              <button
                className="flex justify-center items-center py-1 px-3 text-primary-blue border-2 border-primary-blue rounded  hover:bg-primary-blue hover:text-white !cursor-pointer"
                onClick={() => {
                  insertNode(id);
                }}
                type="button"
              >
                <Add />
              </button>
            ) : null}
          </div>
        ) : null}
      </div>

      <SolaModal
        className="flex items-center justify-center max-w-2xl"
        onClose={() => {
          setShowDeleteModal(false);
        }}
        open={showDeleteModal}
      >
        <div className="py-4">
          <p className="text-2xl font-medium">
            Are you sure do you want to delete this {label}?
          </p>
          <div className="flex flex-row gap-4 mt-12">
            <Button
              className="w-40 h-9 !text-nowrap !bg-red-600"
              color="error"
              onClick={() => {
                onClickDelete?.();
                setShowDeleteModal(false);
              }}
              variant="contained"
            >
              Yes, Delete block
            </Button>
            <Button
              className="h-9"
              color="secondary"
              onClick={() => {
                setShowDeleteModal(false);
              }}
              variant="outlined"
            >
              CANCEL
            </Button>
          </div>
        </div>
      </SolaModal>
    </div>
  );
}
