import React from 'react';
import Spinner from './Spinner';

import Flex from './Containers/FlexContainer';
import { clsx } from 'clsx';

interface Props {
  loadingText?: string;
  className?: string;
}
export default function DataLoader({ loadingText, className }: Props) {
  return (
    <Flex
      className={clsx('items-center justify-center space-x-4 mt-20', className)}
    >
      <Spinner className="text-primary-blue" size={24} />
      <span className="text-primary-blue font-medium">{loadingText}</span>
    </Flex>
  );
}
