import {
  ArrowLeftIcon,
  AutoFixActiveIcon,
  AutoFixInactiveIcon,
  WarningAmberOutlined,
} from 'assets-shared';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { clsx } from 'clsx';
import values from 'lodash/values';
import { Button, IconButton, Input, Select } from 'ui-kit';
import {
  VariableTypeEnum,
  TriggerTypeEnum,
  type DatasourceMetadata,
  type DatasourceTable,
  type ScrapeVariable,
  type Variable,
  type VariableRef,
} from 'types-shared';
import { v4 as uuid } from 'uuid';
import type { Item } from '../VariableTypes/VariableInput';
import { Transformations } from '../SidePanelElements/Transformations';

interface FormValues {
  name: string;
  key: string;
  transformQuery?: string | null;
  previewTransform?: string | null;
  initialValue?: string | null;
  valueType?: string;
}

interface Props {
  variable?: Item | ScrapeVariable;
  variables: Record<string, Variable>;
  datasourceMetadata: DatasourceMetadata | null;
  tableData: DatasourceTable | null;
  addVariable: (variable: Variable) => void;
  updateVariable: (variable: Variable) => void;
  onSave?: (variable: VariableRef) => void;
  onCancel: () => void;
  label?: string;
  edgeName?: string;
  isCondition?: boolean;
  showNameError?: boolean;
  showTitle?: boolean;
  transformDataStatus: 'error' | 'idle' | 'pending' | 'success' | 'loading';
  triggerType?: TriggerTypeEnum.API | TriggerTypeEnum.Datasource;
  onTransformData: (
    prompt: string,
    textToTransform: string,
  ) => Promise<string | undefined>;
}

export function AddVariable({
  onCancel,
  datasourceMetadata,
  tableData,
  addVariable,
  updateVariable,
  variables,
  variable,
  label,
  edgeName,
  isCondition = false,
  showTitle = false,
  showNameError = true,
  onTransformData,
  transformDataStatus,
  triggerType = TriggerTypeEnum.Datasource,
  onSave,
}: Props) {
  const { name: datasourceName, datasourceId } = datasourceMetadata ?? {};
  const [defaultRow = {}] = tableData?.rowData ?? [];

  const datasourceColumns = useMemo(
    () =>
      tableData ? tableData.columnDefinitions.map((col) => col.field) : [],
    [tableData],
  );

  const variableData: Variable | null = useMemo(() => {
    if (!variable) {
      return null;
    }
    if ((variable as Item).value) {
      return variables[((variable as Item).value as { id: string }).id];
    }
    return variable as ScrapeVariable;
  }, [variable, variables]);

  const isScrape = variableData?.type === VariableTypeEnum.Scrape;

  const [constantValue, setConstantValue] = useState<string>('');
  const [hasMadeChanges, setHasMadeChanges] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<FormValues>({
    name: '',
    key: '',
    transformQuery: '',
    previewTransform: '',
    initialValue: '',
    valueType: '',
  });

  const dataSourceNameOptions = useMemo(() => {
    if (
      (!variableData && triggerType === TriggerTypeEnum.API) ||
      (variableData &&
        (variableData.data as { triggerType: TriggerTypeEnum }).triggerType ===
          TriggerTypeEnum.API)
    ) {
      return ['API Call'];
    }
    return datasourceName ? [datasourceName] : [];
  }, [datasourceName, triggerType, variableData]);

  const isApiCall = dataSourceNameOptions[0] === 'API Call';

  // useEffect(() => {
  //   setConstantValue(formValues.previewTransform || '');
  // }, [formValues.previewTransform]);

  useEffect(() => {
    if (variableData) {
      const { name = '', data } = variableData;
      const transformedValue = variableData.transformData?.transformedValue;
      const valueType =
        variableData.type === VariableTypeEnum.Datasource
          ? variableData.data.valueType
          : '';
      const query = variableData.transformData?.query;
      const initialValue = variableData.transformData?.initialValue;
      if (isApiCall && initialValue) {
        setConstantValue(initialValue);
      }
      setFormValues({
        name,
        key: (data as { key: string }).key || '',
        previewTransform: transformedValue,
        transformQuery: query,
        initialValue,
        valueType: valueType || '',
      });
    }
  }, [variableData, isScrape, variable, isApiCall]);

  const [showTransformations, setShowTransformations] =
    useState<boolean>(false);
  const toggleTransformations = () => {
    setShowTransformations(!showTransformations);
  };

  const createVariable = () => {
    const { name, key, transformQuery, previewTransform, initialValue } =
      formValues;
    if (isScrape) {
      updateVariable({
        ...variableData,
        transformData: {
          query: transformQuery,
          transformedValue: previewTransform,
          initialValue,
        },
        name: formValues.name,
      });
      onCancel();
      return;
    }
    if (!datasourceId && !isApiCall) {
      throw new Error('Datasource not found!');
    }
    const variableId = uuid();
    const datasourceVariable: Variable = {
      id: variableData ? variableData.id : variableId,
      name,
      type: VariableTypeEnum.Datasource,
      transformData: {
        query: transformQuery,
        transformedValue: previewTransform,
        initialValue: isApiCall ? constantValue : initialValue,
      },
      data: {
        datasourceId: datasourceId || '',
        key: isApiCall ? name : key,
        valueType: isApiCall ? formValues.valueType : '',
        triggerType: isApiCall
          ? TriggerTypeEnum.API
          : TriggerTypeEnum.Datasource,
      },
    };
    if (variableData) {
      updateVariable(datasourceVariable);
    } else {
      addVariable(datasourceVariable);
      onSave?.({ id: datasourceVariable.id });
    }

    onCancel();
  };

  const variableStringified = useMemo((): string => {
    if (isScrape) {
      // eslint-disable-next-line @typescript-eslint/non-nullable-type-assertion-style
      return variableData.data.scrapedText as string;
    }
    return dataSourceNameOptions[0] === 'API Call'
      ? constantValue
      : (defaultRow[formValues.key] as string);
  }, [
    constantValue,
    dataSourceNameOptions,
    defaultRow,
    formValues.key,
    isScrape,
    variableData,
  ]);

  const handleTransformData = useCallback(
    async (prompt: string) => {
      const response = await onTransformData(prompt, variableStringified);
      return response;
    },
    [onTransformData, variableStringified],
  );

  const hasError = useMemo(() => {
    const hasVariableWithName = values(variables).some((v) => {
      if (variableData && variableData.name === formValues.name) {
        return false;
      }
      return v.name === formValues.name;
    });

    return hasVariableWithName && showNameError;
  }, [formValues.name, showNameError, variableData, variables]);

  return (
    <div
      className={clsx({
        'absolute left-0 top-2 bottom-2 w-120 bg-white rounded-lg z-[100] flex flex-col justify-between space-y-5':
          isCondition,
      })}
    >
      <div className={clsx({ 'overflow-auto': isCondition })}>
        {isCondition ? (
          <>
            <div className="flex-col justify-between items-center pt-8 px-8">
              <div className="flex items-center">
                <span
                  className="flex !border !border-solid !border-info !rounded-lg cursor-pointer mr-2"
                  onClick={onCancel}
                  role="presentation"
                >
                  <ArrowLeftIcon className="text-info !h-7 !w-7" />
                </span>
                <span className="text-xs font-medium">Conditional block</span>
                <span className="text-xs">&nbsp;/&nbsp;Branch</span>
                <span className="text-xs">
                  &nbsp;/&nbsp;{edgeName ?? 'Assigning conditions'}&nbsp;/&nbsp;
                </span>
                <span className="text-xs text-primary-blue">
                  {!variableData && !isScrape
                    ? 'New variable'
                    : 'Edit Variable'}
                </span>
              </div>
            </div>
            <div className="py-6 px-8 mb-6 flex justify-between items-center bg-white z-50 sticky top-0">
              <h2 className="text-lg font-medium text-info-dark">{label}</h2>
              <hr className="border-b border-color-grey absolute bottom-0 left-0 w-full" />
            </div>
          </>
        ) : null}

        <div className={clsx({ 'px-8 mb-6': isCondition })}>
          {!isCondition ? <div className="border my-6 -mx-4" /> : null}
          <div className="text-sm flex flex-col">
            {!isCondition ? (
              <>
                {!variableData && !isScrape ? (
                  <span className="font-medium mb-5 text-info">
                    New Variable
                  </span>
                ) : null}
                {isScrape ? (
                  <span className="font-medium mb-5 text-info">
                    Variable Preview
                  </span>
                ) : null}
              </>
            ) : null}

            {showTitle ? (
              <p className="text-sm mb-5 font-bold text-info">
                {!variableData ? 'New variable' : 'Edit Variable'}
              </p>
            ) : null}

            <div className="flex flex-col">
              <div className={clsx({ 'order-3 my-3': isCondition })}>
                <Input
                  classes={{ wrapper: 'flex flex-col' }}
                  floatingLabel
                  label="Variable Name"
                  onChange={(name: string) => {
                    setFormValues((form) => ({
                      ...form,
                      name,
                    }));
                    setHasMadeChanges(true);
                  }}
                  placeholder="Name"
                  value={formValues.name}
                />
                {hasError ? (
                  <span className="-mt-2 text-red-500">
                    Variable with this name already exists!
                  </span>
                ) : null}
              </div>
              {!isScrape ? (
                <>
                  <div
                    className={clsx('flex flex-col mt-3', {
                      'order-1 mt-0': isCondition,
                    })}
                  >
                    <Select
                      classes={{ select: '!py-4' }}
                      defaultValue={dataSourceNameOptions[0] || datasourceName}
                      disabled
                      getLabel={(opt: string) => opt}
                      getValue={(opt: string) => opt}
                      label="Variable Source"
                      labelId="template-select-variable-source"
                      options={dataSourceNameOptions}
                      value={dataSourceNameOptions[0] || datasourceName}
                    />
                  </div>
                  <div
                    className={clsx('flex flex-col my-3', {
                      'order-2 mb-0': isCondition,
                    })}
                  >
                    {dataSourceNameOptions[0] === 'API Call' ? (
                      <Select
                        classes={{ select: '!py-4' }}
                        getLabel={(opt: string) => opt}
                        getValue={(opt: string) => opt}
                        label="Variable Type"
                        labelId="template-select-variable-source"
                        onChange={(e) => {
                          setFormValues((form) => ({
                            ...form,
                            valueType: e.target.value,
                          }));
                          setHasMadeChanges(true);
                        }}
                        options={['String', 'Date']}
                        value={formValues.valueType}
                      />
                    ) : (
                      <Select
                        classes={{ select: '!py-4' }}
                        getLabel={(opt: string) => opt}
                        getValue={(opt: string) => opt}
                        label="Associated column"
                        labelId="template-select-variable-source"
                        onChange={(e) => {
                          setFormValues((form) => ({
                            ...form,
                            key: e.target.value,
                          }));
                          setHasMadeChanges(true);
                        }}
                        options={datasourceColumns}
                        value={formValues.key}
                      />
                    )}
                  </div>
                </>
              ) : null}
            </div>

            {formValues.key || formValues.valueType || isScrape ? (
              <>
                <span className="text-gray-500 text-sm py-7">
                  Preview the variable value and format it using our GPT
                  transformation tool.
                </span>
                {variableData?.transformData?.query ? (
                  <>
                    <span className="text-black text-sm pb-0.5">
                      Transformation applied
                    </span>
                    <span className="text-gray-400 text-sm pb-7">
                      {variableData.transformData.query}
                    </span>
                  </>
                ) : null}

                <div className="flex flex-row gap-4 mb-7 items-center">
                  {dataSourceNameOptions[0] === 'API Call' && !isScrape ? (
                    <p className="grow flex-col w-4/5">
                      <Input
                        classes={{ wrapper: 'flex flex-col truncate' }}
                        floatingLabel
                        label="Value (Constant value from the recording)"
                        onChange={(newConstantValue: string) => {
                          setConstantValue(newConstantValue);
                          setHasMadeChanges(true);
                        }}
                        placeholder="Enter in sample value"
                        value={constantValue}
                      />
                    </p>
                  ) : (
                    <div className="grow flex-col pt-1.5 bg-gray-100 rounded-tl-lg rounded-tr-lg w-4/5">
                      <p className="text-xs text-gray-500 px-3">
                        Value preview
                      </p>
                      <p className="text-base text-gray-500 mt-1 px-3 mb-1.5 truncate">
                        {isScrape
                          ? formValues.previewTransform ||
                            variableData.data.scrapedText
                              ?.split('\n')
                              .map((line: string) => (
                                <Fragment key={line}>
                                  {line}
                                  <br />
                                </Fragment>
                              ))
                          : formValues.previewTransform ||
                            (defaultRow[formValues.key] as string)}
                      </p>
                      {!formValues.transformQuery ? (
                        <div className="border border-dashed border-slate-300" />
                      ) : (
                        <div className="!bg-gradient-to-r from-primary-blue to-primary-purple pb-0.5" />
                      )}
                    </div>
                  )}
                  <IconButton
                    className="flex-none h-6 w-6"
                    disabled={
                      !showTransformations &&
                      dataSourceNameOptions[0] === 'API Call' &&
                      !constantValue
                    }
                    onClick={toggleTransformations}
                  >
                    {showTransformations ? (
                      <AutoFixActiveIcon fontSize="small" />
                    ) : (
                      <AutoFixInactiveIcon fontSize="small" />
                    )}
                  </IconButton>
                </div>
              </>
            ) : null}
          </div>
          {showTransformations &&
          !(dataSourceNameOptions[0] === 'API Call' && !constantValue) ? (
            <Transformations
              formValues={formValues}
              hasMadeChanges={
                dataSourceNameOptions[0] === 'API Call' && hasMadeChanges
              }
              onApply={(transformQuery: string, previewTransform: string) => {
                setFormValues((form) => ({
                  ...form,
                  initialValue: variableStringified,
                  transformQuery,
                  previewTransform,
                }));
                setHasMadeChanges(true);
              }}
              onClose={toggleTransformations}
              onTransformData={handleTransformData}
              transformDataStatus={transformDataStatus}
            />
          ) : null}

          {variableData ? (
            <div className="flex flex-row justify-between gap-4 my-6 px-4 py-3.5 rounded-lg bg-warning-light">
              <WarningAmberOutlined className="text-warning" />
              <div className="flex flex-col">
                <p className="text-base text-warning-dark font-medium">
                  Variable changes are global
                </p>
                <p className="text-sm text-warning-dark">
                  The changes you make over existing variables will impact all
                  the places where the variable is used.
                </p>
              </div>
            </div>
          ) : null}

          <div className="flex flex-row justify-between gap-4 mt-auto">
            <Button
              className="flex-1"
              color="secondary"
              disabled={
                !formValues.name ||
                showTransformations ||
                (!isScrape && !(formValues.key || formValues.valueType)) ||
                hasError ||
                !hasMadeChanges
              }
              onClick={createVariable}
              variant="contained"
            >
              {variableData || isScrape ? 'SAVE CHANGES' : 'ADD VARIABLE'}
            </Button>
            <Button
              className="flex-1"
              color="secondary"
              onClick={onCancel}
              variant="outlined"
            >
              CANCEL
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
