// Feature flags are configured in Posthog
// See https://us.posthog.com/project/42697/feature_flags
export enum FeatureFlag {
  LocalRuns = 'localRuns',
  NewExecutionDetails = 'newExecutionDetails',
  RemoteExecutionApiTrigger = 'remoteExecutionApiTrigger',
  IntegrationsPage = 'integrationsPage',
  EndingStatusBlock = 'endingStatusBlock',
  Datasource = 'datasource',
  AutolinkDemo = 'autolinkDemo',
  SelectionByConditions = 'selectionByConditions',
  HideRunId = 'hideRunID',
}
