import type { Item } from '../VariableTypes/VariableInput';
import { Button } from 'ui-kit';
import React, { Fragment, useMemo } from 'react';
import { clsx } from 'clsx';
import { VariableTypeEnum, TriggerTypeEnum } from 'types-shared';
import {
  type ScrapeVariable,
  type DatasourceMetadata,
  type DatasourceTable,
  type Variable,
} from 'types-shared';
import { ArrowLeftIcon } from 'assets-shared';

interface Props {
  onCancel: () => void;
  onEdit: () => void;
  variable: Item | ScrapeVariable;
  variables: Record<string, Variable>;
  datasourceMetadata: DatasourceMetadata | null;
  tableData: DatasourceTable | null;
  label?: string;
  edgeName?: string;
  isCondition?: boolean;
  showTitle?: boolean;
}

export function PreviewVariable({
  variable,
  onCancel,
  onEdit,
  variables,
  datasourceMetadata,
  tableData,
  edgeName,
  label,
  isCondition = false,
  showTitle = false,
}: Props) {
  const variableData: Variable | null = useMemo(() => {
    if ((variable as Item).value) {
      return variables[((variable as Item).value as { id: string }).id];
    }
    return variable as ScrapeVariable;
  }, [variable, variables]);

  const isApiCall = useMemo(() => {
    return (
      variableData.type === VariableTypeEnum.Datasource &&
      variableData.data.triggerType === TriggerTypeEnum.API
    );
  }, [variableData]);

  const isScrape = variableData.type === VariableTypeEnum.Scrape;

  const variableExample = useMemo(() => {
    if (!isScrape) {
      const [defaultRow = {}] = tableData?.rowData ?? [];
      return defaultRow[(variableData.data as { key: string }).key] as string;
    }
    return null;
  }, [isScrape, tableData?.rowData, variableData.data]);

  const scrapedText = useMemo(() => {
    if (isScrape && (variableData as ScrapeVariable).data.scrapedText) {
      return (variableData.data as { scrapedText: string }).scrapedText
        .split('\n')
        .map((line: string) => (
          <Fragment key={line}>
            {line}
            <br />
          </Fragment>
        ));
    }
    return null;
  }, [isScrape, variableData]);

  return (
    <div
      className={clsx({
        'absolute left-0 top-2 bottom-2 w-120 bg-white rounded-lg z-[100] flex flex-col justify-between space-y-5':
          isCondition,
      })}
    >
      <div className={clsx({ 'overflow-auto': isCondition })}>
        {isCondition ? (
          <>
            <div className="flex-col justify-between items-center pt-8 px-8">
              <div className="flex items-center">
                <span
                  className="flex !border !border-solid !border-info !rounded-lg cursor-pointer mr-2"
                  onClick={onCancel}
                  role="presentation"
                >
                  <ArrowLeftIcon className="text-info !h-7 !w-7" />
                </span>
                <span className="text-xs font-medium">Conditional block</span>
                <span className="text-xs">&nbsp;/&nbsp;Branch</span>
                <span className="text-xs">
                  &nbsp;/&nbsp;{edgeName ?? 'Assigning conditions'}
                </span>
                <span className="text-xs text-primary-blue">
                  &nbsp;/&nbsp;Preview Variable
                </span>
              </div>
            </div>
            <div className="py-6 px-8 mb-6 flex justify-between items-center bg-white z-50 sticky top-0">
              <h2 className="text-lg font-medium text-info-dark">{label}</h2>
              <hr className="border-b border-color-grey absolute bottom-0 left-0 w-full" />
            </div>
          </>
        ) : null}

        <div className={clsx('flex-1', { 'px-8 mb-6': isCondition })}>
          {!isCondition ? <div className="border my-6 -mx-4" /> : null}
          {showTitle ? (
            <p className="text-sm mb-8 font-bold text-info">Preview variable</p>
          ) : null}
          {!isCondition &&
          !(isApiCall && !variableData.transformData?.transformedValue) ? (
            <div className="text-sm mb-5">
              <p className="font-medium mb-2 text-info">Variable Preview</p>
              <p className="text-gray-500">
                {variableData.transformData?.transformedValue ||
                  (isScrape ? scrapedText : variableExample)}
              </p>
            </div>
          ) : null}
          <div className="text-sm mb-5">
            <p className="font-medium mb-2 text-info">Variable Name</p>
            <p className="text-gray-500">{variableData.name}</p>
          </div>
          <div className="text-sm mb-5">
            <p className="font-medium mb-2 text-info">Source</p>
            <p className="text-gray-500">
              {isScrape
                ? 'Scraped during the run'
                : `${isApiCall ? 'API Call' : datasourceMetadata?.name}`}
            </p>
          </div>

          {!isScrape ? (
            <div className="text-sm mb-5">
              <p className="font-medium mb-2 text-info">
                {isApiCall ? 'Value Type' : 'Column name'}
              </p>
              <p className="text-gray-500">
                {isApiCall
                  ? (variableData.data as { valueType: string }).valueType
                  : (variableData.data as { key: string }).key}
              </p>
            </div>
          ) : null}

          {variableData.transformData?.query ? (
            <div className="text-sm mb-5">
              <p className="font-medium mb-2 text-info">
                Transformation applied
              </p>
              <p className="text-gray-500">
                {variableData.transformData.query}
              </p>
            </div>
          ) : null}

          {isCondition ? (
            <div className="text-sm mb-5">
              <p className="font-medium mb-2 text-info">Value example</p>
              <p className="text-gray-500">
                {variableData.transformData?.transformedValue ||
                  (isScrape ? scrapedText : variableExample)}
              </p>
            </div>
          ) : null}

          {/* <div className="text-sm mb-14">
            <p className="font-medium mb-2 text-info">Value example</p>
            <p className="text-gray-500">
              {isScrape
                ? variable.data.scrapedText?.split('\n').map((line: string) => (
                    <Fragment key={line}>
                      {line}
                      <br />
                    </Fragment>
                  ))
                : variableExample}
            </p>
          </div> */}

          <div className="flex flex-row justify-between gap-4 mt-auto">
            <Button
              className="flex-1"
              color="secondary"
              onClick={onEdit}
              variant="contained"
            >
              EDIT VARIABLE
            </Button>
            <Button
              className="flex-1"
              color="secondary"
              onClick={onCancel}
              variant="outlined"
            >
              CANCEL
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
