"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SeleniumGQLClient = void 0;
var graphql_request_1 = require("graphql-request");
var queries_1 = require("./queries");
var SeleniumGQLClient = /** @class */ (function () {
    function SeleniumGQLClient(gqlEndpoint) {
        this._client = new graphql_request_1.GraphQLClient(gqlEndpoint, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }
    SeleniumGQLClient.prototype.queryGridInfo = function () {
        return this._client.request(queries_1.QUERY_GRID_INFO);
    };
    SeleniumGQLClient.prototype.queryNodesInfo = function () {
        return this._client.request(queries_1.QUERY_NODES_INFO);
    };
    SeleniumGQLClient.prototype.querySessionInfo = function (sessionId) {
        return this._client.request(queries_1.QUERY_SESSION_INFO_BY_ID, {
            sessionId: sessionId,
        });
    };
    return SeleniumGQLClient;
}());
exports.SeleniumGQLClient = SeleniumGQLClient;
