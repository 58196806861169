import { Button, IconButton, Input, Spinner, CheckCircleIcon } from 'ui-kit';
import { clsx } from 'clsx';
import React, { useEffect, useState, useCallback } from 'react';
import { SendIcon, InfoOutlined } from 'assets-shared';

interface FormValues {
  name: string;
  key?: string;
  transformQuery?: string | null;
  previewTransform?: string | null;
}
interface Props {
  formValues: FormValues;
  hasMadeChanges?: boolean | null;
  transformDataStatus: 'error' | 'idle' | 'pending' | 'success' | 'loading';
  onTransformData: (prompt: string) => Promise<string | undefined>;
  onClose: () => void;
  onApply: (transformQuery: string, previewTransform: string) => void;
}

export function Transformations({
  formValues,
  hasMadeChanges = null,
  onClose,
  onApply,
  transformDataStatus,
  onTransformData,
}: Props) {
  const isLoading =
    transformDataStatus === 'pending' || transformDataStatus === 'loading';
  const { transformQuery, previewTransform } = formValues;
  const [hasEdited, setHasEdited] = useState<boolean>(false);
  const [prompt, setPrompt] = useState<string>('');
  const [transformedValue, setTransformedValue] = useState<string>('');
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    if (hasMadeChanges) {
      setHasEdited(hasMadeChanges);
      setIsSuccess(false);
    }
  }, [hasMadeChanges]);

  useEffect(() => {
    if (transformDataStatus === 'success' && transformedValue) {
      setIsSuccess(true);
    }
    if (transformDataStatus === 'error') {
      setIsError(true);
    }
  }, [transformDataStatus, transformedValue]);

  const transformData = useCallback(async () => {
    if (prompt) {
      const transformedText = await onTransformData(prompt);
      if (transformedText) {
        setTransformedValue(transformedText);
      } else {
        setIsError(true);
      }
    }
  }, [prompt, onTransformData]);

  const updatePrompt = (newPrompt: string) => {
    setPrompt(newPrompt);
    setHasEdited(true);
    setTransformedValue('');
    setIsSuccess(false);
    setIsError(false);
  };

  useEffect(() => {
    if (transformQuery) {
      setPrompt(transformQuery);
    }
    if (previewTransform) {
      setTransformedValue(previewTransform);
    }
    if (previewTransform && transformQuery) {
      setIsSuccess(true);
    }
  }, [transformQuery, previewTransform]);

  return (
    <div className="rounded-lg mb-9 mt-2 p-px !bg-gradient-to-r from-primary-blue to-primary-purple">
      <div className="rounded-lg p-7 text-sm flex flex-col bg-white">
        <span className="font-medium mb-1 text-info">GPT Transformations</span>
        <span className="mb-5 text-gray-400">
          Preview the variable value and format it using our GPT transformation
          tool.
        </span>
        <div className="relative flex flex-col mt-3 pt-1">
          <Input
            InputProps={{ className: '!pr-14' }}
            error={isError}
            label={
              <label
                className="text-xs absolute bg-white z-10 top-0 ml-3 px-1"
                htmlFor="prompt"
              >
                Prompt
              </label>
            }
            multiline
            onChange={updatePrompt}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                transformData();
              }
            }}
            placeholder="Write the instructions"
            rows={5}
            value={prompt}
          />
          <IconButton
            className="!absolute top-0 right-0 !mx-1 !mt-4"
            disabled={isSuccess || isError || status === 'pending'}
            onClick={transformData}
          >
            {isLoading ? <Spinner size={16} /> : null}
            {isSuccess && !isError ? (
              <CheckCircleIcon className="text-transparent" />
            ) : null}
            {isError ? <InfoOutlined className="text-error" /> : null}
            {!isSuccess && !isError && transformDataStatus !== 'pending' ? (
              <SendIcon className="text-white" />
            ) : null}
          </IconButton>
        </div>
        {isError ? (
          <p className="px-1 pt-1 text-error text-xs">
            We couldn’t execute that instructions, please try with another one.
          </p>
        ) : null}

        <span className="font-medium mt-8 text-info">
          Transformation result
        </span>
        <div className="relative flex flex-col mt-1">
          <Input
            InputProps={{ className: 'bg-white !border-0', readOnly: true }}
            classes={{
              input: clsx({
                '!bg-gradient-to-r from-primary-blue to-primary-purple !text-info !p-0.5 !rounded':
                  transformedValue && !isLoading,
              }),
            }}
            placeholder="Transformation Result"
            value={transformedValue}
          />
        </div>

        <div className="flex flex-row justify-between gap-4 mt-8">
          <Button
            className="flex-1"
            color="secondary"
            disabled={
              Boolean(!transformedValue && prompt) || isLoading || !hasEdited
            }
            onClick={() => {
              if (transformedValue || (!transformedValue && !prompt)) {
                onApply(prompt || '', transformedValue || '');
                onClose();
              }
            }}
            variant="contained"
          >
            APPLY
          </Button>
          <Button
            className="flex-1"
            color="secondary"
            onClick={onClose}
            variant="outlined"
          >
            CANCEL
          </Button>
        </div>
      </div>
    </div>
  );
}
