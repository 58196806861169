import { NoDataFound } from 'dashboard-shared';
import { useNavigate, useParams } from 'react-router-dom';

import DatasourcePreviewTable from '../DatasourcePreviewTable';
import { EditorStore } from '../../Editor/store/EditorState';

export default function DatasourcePreview() {
  const { workflowId } = useParams<{ workflowId: string }>();
  if (!workflowId) {
    throw new Error('workflowId not found!');
  }

  const { datasourceMetadata } = EditorStore();
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(`/editor/${workflowId}`); // navigate back
  };

  if (!datasourceMetadata) {
    return (
      <NoDataFound
        heading="No datasource"
        subHeading="No datasource to added to this workflow"
      />
    );
  }

  return (
    <DatasourcePreviewTable
      datasourceId={datasourceMetadata.datasourceId}
      fileName={datasourceMetadata.name}
      onBack={handleGoBack}
    />
  );
}
