import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import { clsx } from 'clsx';
import {
  type Variable,
  type SelectOption,
  VariableTypeEnum,
  TriggerTypeEnum,
} from 'types-shared';
import Chip from '@mui/material/Chip';

interface Props {
  anchorEl: Element | null;
  open: boolean;
  onClose: () => void;
  onSelect: (val: Variable | string) => void;
  onAddNew?: () => void;
  options: SelectOption[];
  variables: Variable[];
  allowAddVariable: boolean;
}

function SelectMenu({
  anchorEl,
  open,
  onClose,
  onSelect,
  onAddNew,
  variables = [],
  options = [],
  allowAddVariable = true,
}: Props) {
  return (
    <Menu
      anchorEl={anchorEl}
      classes={{
        paper: '!max-w-none',
        list: '!p-0',
      }}
      disablePortal
      onClose={onClose}
      open={open}
      slotProps={{
        paper: {
          sx: {
            width: `${anchorEl?.clientWidth}px`,
          },
        },
      }}
      sx={{ '& .MuiBackdrop-root': { backgroundColor: 'transparent' } }}
    >
      {options.map(({ text, value }: SelectOption) => (
        <MenuItem
          className="!px-3 !py-2.5"
          divider
          key={value}
          onClick={() => {
            onSelect(text);
            onClose();
          }}
        >
          {text}
        </MenuItem>
      ))}
      {variables.map((variable: Variable, i) => (
        <MenuItem
          className="!px-3 !py-2.5"
          divider={i === variables.length - 1}
          key={variable.name}
          onClick={() => {
            onSelect(variable);
            onClose();
          }}
        >
          <Chip
            className={clsx(
              {
                '!bg-secondary-purple':
                  variable.type === VariableTypeEnum.Scrape,
              },
              {
                '!bg-transparent !border-secondary-blue !border-2 !text-secondary-blue':
                  variable.type === VariableTypeEnum.Datasource &&
                  variable.data.triggerType === TriggerTypeEnum.API,
              },
            )}
            color="secondary"
            label={variable.name}
            size="small"
            variant={
              variable.type === VariableTypeEnum.Datasource &&
              variable.data.triggerType === TriggerTypeEnum.API
                ? 'outlined'
                : 'filled'
            }
          />
        </MenuItem>
      ))}
      {allowAddVariable ? (
        <MenuItem
          className="!border-t !border-t-gray-300 !text-blue-600 !px-3 !py-2.5"
          onClick={() => onAddNew?.()}
        >
          Add New Variable
        </MenuItem>
      ) : null}
    </Menu>
  );
}

export default SelectMenu;
