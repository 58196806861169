import React, { useMemo } from 'react';
import type { WorkflowImageNode, WorkflowNodeProps } from 'types-shared';
import { NodeTypesEnum } from 'types-shared';
import { useStore } from 'types-shared/reactflow';

import { minZoomLevelForFullContent } from '../../utils/constants';
import NodeElement from './index';
import { EditorStore, type EditorStoreProps } from '../../store/EditorState';
import { useShallow } from 'zustand/react/shallow';
import { ImageNodeCore } from 'editor-shared';
import { AlertVariant, notify } from 'ui-kit';

const zoomSelector = (s: { transform: number[] }) =>
  s.transform[2] >= minZoomLevelForFullContent;

export default function ImageNode(workflowData: WorkflowNodeProps) {
  const { id } = workflowData;
  const {
    nodes,
    // setNodes,
    setSelectedNode,
    thumbnails,
    // edges,
    // setEdges,
  } = EditorStore(
    useShallow((state: EditorStoreProps) => ({
      nodes: state.nodes,
      setNodes: state.setNodes,
      setSelectedNode: state.setSelectedNode,
      thumbnails: state.thumbnails,
      edges: state.edges,
      setEdges: state.setEdges,
    })),
  );

  const selectedNode: WorkflowImageNode = useMemo(() => {
    const workflowNode = nodes.find((node) => node.id === id);
    if (workflowNode?.type === NodeTypesEnum.Image) {
      return workflowNode as WorkflowImageNode;
    }

    notify({
      message: 'Selected node is not an image node',
      variant: AlertVariant.ERROR,
    });
    throw new Error('Selected node is not an image node');
  }, [id, nodes]);

  const showFullContent = useStore(zoomSelector);

  // const handleAction = (action: 'copyNode' | 'deleteNode' | 'updateImage') => {
  //   if (action === 'deleteNode') {
  //     const filteredNodes = nodes.filter((node) => node.id !== id);
  //     const deletedNodeIndex = nodes.findIndex((node) => node.id === id);
  //     const nextNodeId = nodes[deletedNodeIndex + 1]?.id;
  //     const filteredEdges = edges
  //       .filter((edge) => edge.source !== id)
  //       .map((edge) => {
  //         if (edge.target === id) {
  //           return {
  //             ...edge,
  //             target: nextNodeId,
  //           };
  //         }
  //         return edge;
  //       });
  //     setNodes(filteredNodes);
  //     setEdges(filteredEdges);
  //     setSelectedNode(null);
  //   } else {
  //     throw new Error('Action not supported');
  //   }
  // };

  return (
    <NodeElement
      allowBulkCheck
      onClick={() => {
        setSelectedNode(workflowData.id);
      }}
      showAddButton
      workflowData={workflowData}
    >
      <ImageNodeCore
        // handleAction={handleAction}
        showFullContent={showFullContent}
        thumbnailData={thumbnails[selectedNode.data.imageData.imageId]}
        workflowData={workflowData}
      />
    </NodeElement>
  );
}
