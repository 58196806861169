import { Add } from 'assets-shared';
import { AlertVariant, Button, notify } from 'ui-kit';

import { useQuery } from '../../../hooks/useQuery';
import { useCreateWorkflow } from '../hooks';
import type {
  RecordingImportRequest,
  RecordingImportResponse,
} from 'api-types-shared';
import { sendToBackgroundViaRelay } from '@plasmohq/messaging';
import type { ExtensionData } from 'types-shared/actionTypes';

import { v4 as uuidv4 } from 'uuid';
import { KNOWN_WORKFLOW_IDS } from '../../Editor/utils/constants';
import { useState } from 'react';

interface MmMetadata {
  'onRecordingImport.handler': {
    req: RecordingImportRequest;
    res: RecordingImportResponse;
  };
}
type MessageTypes = {
  [K in keyof MmMetadata]: MmMetadata[K] extends { req: infer R; res: infer S }
    ? { request: R; response: S }
    : never;
};

declare function sendToBackgroundViaRelayType<
  TName extends keyof MessageTypes,
>(request: { name: TName }): Promise<MessageTypes[TName]['response']>;

interface Props {
  openEditor: (workflowId: string) => void;
}

const getExtensionData = (): Promise<ExtensionData | null> => {
  return new Promise((resolve) => {
    let resolveFn: CallableFunction | null = resolve;

    setTimeout(() => {
      if (resolveFn) {
        resolveFn(null);
        resolveFn = null;
      }
    }, 4000);
    void (sendToBackgroundViaRelay as typeof sendToBackgroundViaRelayType)({
      name: 'onRecordingImport.handler',
    }).then((res) => {
      if (resolveFn) {
        resolveFn(res as ExtensionData | null);
        resolveFn = null;
      }
    });
  });
};

function ImportWorkflowButton({ openEditor }: Props) {
  const query = useQuery();
  const { mutateAsync: createWorkflow } = useCreateWorkflow();
  const [loading, setLoading] = useState<boolean>(false);

  let workflowName = query.get('workflowName');

  const handleOnClickImport = async () => {
    setLoading(true);
    try {
      if (!workflowName) {
        workflowName = uuidv4();
      }

      const extensionData = await getExtensionData();

      if (!extensionData) {
        notify({
          message:
            'No recording found – begin a new workflow by recording from the extension!',
          variant: AlertVariant.ERROR,
        });
        setLoading(false);
        return;
      }

      const workflowId = await createWorkflow({
        workflowName,
        extensionData,
      });

      notify({
        message: `Workflow created successfully.`,
        variant: AlertVariant.SUCCESS,
      });

      if (KNOWN_WORKFLOW_IDS.includes(workflowId)) {
        openEditor(workflowId);
      }
    } catch (e) {
      // eslint-disable-next-line
      console.log('Import failed', e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      color="secondary"
      loading={loading}
      onClick={handleOnClickImport}
      variant="contained"
    >
      <Add />
      Import Workflow
    </Button>
  );
}

export default ImportWorkflowButton;
