import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React from 'react';

export default function APICallIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      fill="none"
      viewBox="0 0 60 60"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35 27.5L40 32.5L35 37.5M25 37.5L20 32.5L25 27.5M7.5 15V42C7.5 44.8003 7.5 46.1994 8.04497 47.269C8.52433 48.2098 9.28868 48.9762 10.2295 49.4556C11.298 50 12.6975 50 15.4923 50H44.5077C47.3025 50 48.7 50 49.7685 49.4556C50.7093 48.9762 51.4762 48.2103 51.9556 47.2695C52.5005 46.1999 52.5005 44.7998 52.5005 41.9995L52.5005 22.9994C52.5005 20.1992 52.5005 18.7991 51.9556 17.7295C51.4762 16.7887 50.7098 16.0243 49.769 15.545C48.6994 15 47.3003 15 44.5 15H30M7.5 15H30M7.5 15C7.5 12.2386 9.73858 10 12.5 10H21.6863C22.9092 10 23.5222 10 24.0976 10.1382C24.6078 10.2606 25.0946 10.4632 25.542 10.7373C26.0464 11.0464 26.4795 11.4795 27.3438 12.3438L30 15"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
      />
    </SvgIcon>
  );
}
