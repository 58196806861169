import ActionsManager from './ActionsManager';
import { useParams } from 'react-router-dom';
import SelectedImageNodeContent from './NodeElement/SelectedImageNodeContent';
import { EditorStore, type EditorStoreProps } from '../store/EditorState';
import { useShallow } from 'zustand/react/shallow';
import { ChevronRight, ChevronLeft } from 'assets-shared';
import { useMemo, useState } from 'react';
import { WorkflowDatasourceNode, WorkflowImageNode } from 'types-shared';
import { AlertVariant, notify, IconButton } from 'ui-kit';

export default function ActionView() {
  const { workflowId } = useParams();
  if (!workflowId) {
    throw new Error('Workflow ID not provided');
  }

  const {
    selectedNode: selectedNodeId,
    nodes,
    edges,
    setSelectedNode,
  } = EditorStore(
    useShallow((state: EditorStoreProps) => ({
      selectedNode: state.selectedNode,
      nodes: state.nodes,
      edges: state.edges,
      setSelectedNode: state.setSelectedNode,
    })),
  );
  const { filteredNodes, datasourceNodeId } = useMemo(() => {
    let dataNodeId = '';
    const nonDatasourceNodes = nodes.filter((n) => {
      if (WorkflowDatasourceNode.safeParse(n).success) {
        dataNodeId = n.id;
        return false;
      }
      return true;
    });
    return {
      datasourceNodeId: dataNodeId,
      filteredNodes: nonDatasourceNodes,
    };
  }, [nodes]); // skip the datasource node
  const filteredEdges = useMemo(
    () =>
      edges.filter(
        (e) => e.source !== datasourceNodeId && e.target !== datasourceNodeId,
      ),
    [datasourceNodeId, edges],
  );
  const selectedNode = useMemo(() => {
    const _selectedNode = selectedNodeId
      ? filteredNodes.find((n) => n.id === selectedNodeId)
      : null;
    const parsedSelectedNode = WorkflowImageNode.safeParse(_selectedNode);
    if (!parsedSelectedNode.success) {
      notify({
        message: `Selected node is not a valid image node: ${JSON.stringify(
          parsedSelectedNode.error,
        )}`,
        variant: AlertVariant.ERROR,
        debug: true,
      });
      return null;
    }
    return parsedSelectedNode.data;
  }, [selectedNodeId, filteredNodes]);

  const { isFirst, isLast, nextNodeId, prevNodeId } = useMemo(() => {
    const forwardEdge = filteredEdges.find((e) => e.source === selectedNodeId);
    const backwardEdge = filteredEdges.find((e) => e.target === selectedNodeId);
    return {
      isFirst: !backwardEdge,
      isLast: !forwardEdge,
      nextNodeId: forwardEdge?.target,
      prevNodeId: backwardEdge?.source,
    };
  }, [filteredEdges, selectedNodeId]);

  const [hasSelectedAction, setHasSelectedAction] = useState<boolean>(false);

  if (!selectedNode) return null;

  return (
    <div className="fixed inset-0">
      <div className="relative w-full h-full flex">
        <ActionsManager setHasSelectedAction={setHasSelectedAction} />
        <div className="w-full flex justify-center items-center dotted-bg">
          {!isFirst && !hasSelectedAction ? (
            <IconButton
              className="!bg-white !mr-6"
              onClick={() => {
                prevNodeId && setSelectedNode(prevNodeId);
              }}
            >
              <ChevronLeft />
            </IconButton>
          ) : (
            <div className="w-[40px] mr-6" role="none" />
          )}
          <div className="relative !w-4/5">
            <span className="absolute left-1/2 -top-12 -translate-x-1/2 text-sm font-semibold bg-white px-3 py-1 rounded-lg min-w-max">
              {selectedNode.id}
            </span>
            <SelectedImageNodeContent
              className="!w-full max-h-[80vh] h-auto"
              nodeData={selectedNode.data}
            />
          </div>
          {!isLast && !hasSelectedAction ? (
            <IconButton
              className="!bg-white !ml-6"
              onClick={() => {
                nextNodeId && setSelectedNode(nextNodeId);
              }}
            >
              <ChevronRight />
            </IconButton>
          ) : (
            <div className="w-[40px] ml-6" role="none" />
          )}
        </div>
      </div>
    </div>
  );
}
