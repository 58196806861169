import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

export const getRelativeTime = (date: Dayjs) => dayjs(date).fromNow();

export const downloadLinkData = (url: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('target', '_blank');
  link.setAttribute('download', 'true');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const formatDuration = (milliseconds: number) => {
  const seconds = Math.floor((milliseconds / 1000) % 60);
  const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
  const hours = Math.floor(milliseconds / (1000 * 60 * 60));

  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = seconds.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds} hs`;
};
