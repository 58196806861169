"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.llmTransformSchema = void 0;
var types_shared_1 = require("types-shared");
exports.llmTransformSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z.object({}).nullable(),
        query: types_shared_1.z.object({}).nullable(),
        body: types_shared_1.z.object({
            data: types_shared_1.z.string(),
            prompt: types_shared_1.z.string(),
            example: types_shared_1.z.object({ input: types_shared_1.z.string(), output: types_shared_1.z.string() }).optional(),
            cache: types_shared_1.z.boolean().default(true),
        }),
    }),
    response: types_shared_1.z.object({
        processedData: types_shared_1.z.string(),
    }),
};
