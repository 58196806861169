import MuiAutocomplete from '@mui/material/Autocomplete';
import type { TextFieldProps } from '@mui/material/TextField/TextField';
import React, { useState } from 'react';
import type { ReactNode } from 'react';
import Input from '../Input';

interface Classes {
  input?: string;
  wrapper?: string;
}
type Props = Omit<TextFieldProps, 'onChange' | 'className'> & {
  classes?: Classes;
  label?: string | ReactNode;
  className?: string;
  placeholder?: string;
  floatingLabel?: boolean;
  value?: string | null;
  options: string[];
  onChange?: (val: string | null) => void;
};

export default function Autocomplete({
  classes,
  label,
  floatingLabel = false,
  placeholder,
  value,
  onChange,
  options,
  className,
}: Props) {
  const [searchText, setSearchText] = useState<string>('');
  return (
    <MuiAutocomplete
      className={className}
      inputValue={searchText}
      onChange={(_event, newValue: string | null) => {
        onChange?.(newValue);
      }}
      onInputChange={(_event, newInputValue) => {
        setSearchText(newInputValue);
      }}
      options={options}
      renderInput={(params) => (
        <Input
          {...params}
          classes={classes}
          floatingLabel={floatingLabel}
          label={label}
          onChange={(text: string) => {
            setSearchText(text);
          }}
          placeholder={placeholder}
          value={searchText}
        />
      )}
      value={value}
    />
  );
}
