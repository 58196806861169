import type {
  ExtensionData,
  TargetMap,
  VariableMap,
  WorkflowData,
  WorkflowImageNode,
} from 'types-shared';
import { NodeStatus, NodeTypesEnum, Position } from 'types-shared';
import { calculateMd5, createThumbnail } from './imageHelpers';
import { v4 as uuidv4 } from 'uuid';
import { extensionDataParse } from './parseActions';
import { combineDownloadActions } from './cleanActions';

export interface LoadExtensionDataReturnType {
  workflowData: WorkflowData;
  newImages: { imageId: string; blob: Blob; thumbnailBlob: Blob }[];
  variableStore: VariableMap;
  targetStore: TargetMap;
}

export const loadExtensionData = async (
  extensionData: ExtensionData,
): Promise<LoadExtensionDataReturnType> => {
  const { states, actions } = await extensionDataParse(extensionData);
  const cleanedActionDataArray = combineDownloadActions(
    actions.actionDataArray,
  );
  const newImages: { imageId: string; blob: Blob; thumbnailBlob: Blob }[] = [];
  const newNodesPromises: Promise<WorkflowImageNode>[] = states.map(
    async ({ blob }, index) => {
      const nodeId = uuidv4();
      const imageId = await calculateMd5(blob);
      const thumbnailBlob = await createThumbnail(blob);
      newImages.push({ imageId, blob, thumbnailBlob });
      const { actionData, nodeUrls, actionOrder } =
        cleanedActionDataArray[index];
      return {
        id: nodeId,
        type: NodeTypesEnum.Image as const,
        data: {
          imageData: {
            imageId,
          },
          actionData,
          nodeUrls,
          actionOrder,
          nodeStatus: NodeStatus.enum.NotViewed,
        },
        position: { x: index * 400, y: 0 },
        width: 256,
        height: 232,
      };
    },
  );
  const newNodes: WorkflowImageNode[] = await Promise.all(newNodesPromises);
  const newEdges = states.slice(0, -1).map((action, index) => {
    const id = uuidv4();
    const source = newNodes[index].id;
    const target = newNodes[index + 1].id;
    return {
      id,
      source,
      target,
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    };
  });

  const workflowData: WorkflowData = {
    nodes: newNodes,
    edges: newEdges,
    selectedAction: null,
    selectedNode: null,
    bulkSelectMode: false,
  };

  const { variableStore, targetStore } = actions;

  return { workflowData, newImages, variableStore, targetStore };
};
