import { type WorkflowNodeProps } from 'types-shared';
import NodeElement from './index';
import { EditorStore, type EditorStoreProps } from '../../store/EditorState';
import { useShallow } from 'zustand/react/shallow';
import { EndingNodeCore, useEditingNodeId } from 'editor-shared';
import isNil from 'lodash/isNil';
import { useMemo } from 'react';

export default function EndingNode(workflowData: WorkflowNodeProps) {
  const { setEditingNodeId } = useEditingNodeId();
  const { nodes } = EditorStore(
    useShallow((state: EditorStoreProps) => ({
      nodes: state.nodes,
    })),
  );

  const node = useMemo(
    () => nodes.find((n) => n.id === workflowData.id),
    [nodes, workflowData.id],
  );

  if (isNil(node)) {
    return null;
  }

  return (
    <NodeElement
      allowBulkCheck
      label="Ending block"
      rightConnectable={false}
      showDeleteButton
      showEditButton
      workflowData={workflowData}
    >
      <EndingNodeCore
        node={node}
        onClick={() => {
          setEditingNodeId(workflowData.id);
        }}
      />
    </NodeElement>
  );
}
