"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.putExecutionDataSchema = exports.UploadType = exports.UploadTypeEnum = void 0;
var types_shared_1 = require("types-shared");
var UploadTypeEnum;
(function (UploadTypeEnum) {
    UploadTypeEnum["Image"] = "image";
    UploadTypeEnum["Variables"] = "variables";
    UploadTypeEnum["Artifact"] = "artifact";
    UploadTypeEnum["Other"] = "other";
})(UploadTypeEnum || (exports.UploadTypeEnum = UploadTypeEnum = {}));
exports.UploadType = types_shared_1.z.nativeEnum(UploadTypeEnum);
exports.putExecutionDataSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z.object({
            executionId: types_shared_1.uuidSchema,
        }),
        query: types_shared_1.z.object({
            uploadType: exports.UploadType,
        }),
        body: types_shared_1.z
            .object({
            name: types_shared_1.z.string().optional(),
        })
            .optional(),
    }),
    response: types_shared_1.z.object({
        uploadUrl: types_shared_1.z.string(),
    }),
};
