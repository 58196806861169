"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.uploadVideoSchema = void 0;
var types_shared_1 = require("types-shared");
exports.uploadVideoSchema = {
    request: types_shared_1.z.object({
        body: types_shared_1.z.object({
            workflowId: types_shared_1.z.string(),
            videoUpload: types_shared_1.z.object({
                uploadId: types_shared_1.z.string(),
                urls: types_shared_1.z.array(types_shared_1.z.string()),
            }),
        }),
    }),
    response: types_shared_1.z.boolean(),
};
