import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React from 'react';

export default function DataCopyIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      fill="none"
      viewBox="0 0 25 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.48633 9.00049V6.20068C9.48633 5.08058 9.48633 4.52011 9.70432 4.09229C9.89606 3.71596 10.2018 3.41022 10.5781 3.21848C11.0059 3.00049 11.5664 3.00049 12.6865 3.00049H18.2865C19.4066 3.00049 19.9664 3.00049 20.3942 3.21848C20.7705 3.41022 21.0768 3.71596 21.2686 4.09229C21.4865 4.52011 21.4865 5.08016 21.4865 6.20027V11.8003C21.4865 12.9204 21.4865 13.4805 21.2686 13.9083C21.0768 14.2846 20.7702 14.591 20.3939 14.7827C19.9665 15.0005 19.4073 15.0005 18.2894 15.0005H15.4863M9.48633 9.00049H6.68652C5.56642 9.00049 5.00595 9.00049 4.57812 9.21848C4.2018 9.41022 3.89606 9.71596 3.70432 10.0923C3.48633 10.5201 3.48633 11.0806 3.48633 12.2007V17.8007C3.48633 18.9208 3.48633 19.4806 3.70432 19.9084C3.89606 20.2847 4.2018 20.591 4.57812 20.7827C5.00553 21.0005 5.56532 21.0005 6.68324 21.0005H12.2899C13.4078 21.0005 13.9668 21.0005 14.3942 20.7827C14.7705 20.591 15.0768 20.2844 15.2686 19.9081C15.4863 19.4807 15.4863 18.9215 15.4863 17.8036V15.0005M9.48633 9.00049H12.2865C13.4066 9.00049 13.9664 9.00049 14.3942 9.21848C14.7705 9.41022 15.0768 9.71596 15.2686 10.0923C15.4863 10.5197 15.4863 11.0795 15.4863 12.1974L15.4863 15.0005"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </SvgIcon>
  );
}
