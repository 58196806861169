"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.signalTemporalExecutionSchema = exports.SignalType = exports.SignalTypeEnum = void 0;
var types_shared_1 = require("types-shared");
var SignalTypeEnum;
(function (SignalTypeEnum) {
    SignalTypeEnum["Resume"] = "Resume";
    SignalTypeEnum["Pause"] = "Pause";
    SignalTypeEnum["Stop"] = "Stop";
    SignalTypeEnum["Done"] = "Done";
    SignalTypeEnum["Screenshot"] = "Screenshot";
    SignalTypeEnum["Terminate"] = "Terminate";
})(SignalTypeEnum || (exports.SignalTypeEnum = SignalTypeEnum = {}));
exports.SignalType = types_shared_1.z.nativeEnum(SignalTypeEnum);
exports.signalTemporalExecutionSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z.object({
            executionId: types_shared_1.uuidSchema,
        }),
        body: types_shared_1.z.object({
            signalTypeBatch: types_shared_1.z.array(types_shared_1.z.object({
                signal: exports.SignalType,
                payload: types_shared_1.z.object({}),
            })),
        }),
    }),
    response: types_shared_1.z.object({
        executionId: types_shared_1.z.string(),
    }),
};
