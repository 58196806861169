import { clsx } from 'clsx';
import { Link, useLocation } from 'react-router-dom';

interface Props {
  text: string;
  href: string;
  className?: string;
  disabled: boolean;
}
export default function NavigationItem({
  text,
  href,
  className,
  disabled,
}: Props) {
  const location = useLocation();
  const active = location.pathname === href;

  return (
    <li
      className={clsx(
        'w-full h-12 transition rounded hover:bg-primary-blue hover:bg-opacity-10',
        active && 'bg-primary-blue bg-opacity-10',
        className,
        disabled && 'pointer-events-none opacity-50',
      )}
    >
      <Link
        className={clsx(
          'w-full h-full px-4 flex items-center',
          active && 'font-bold',
        )}
        to={href}
      >
        <span>{text}</span>
      </Link>
    </li>
  );
}
