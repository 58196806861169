import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export default function SendIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.59013 11.4101L12.6286 7.37166M16.7597 4.90707L13.3509 15.9859C13.0454 16.9787 12.8925 17.4754 12.629 17.64C12.4005 17.7828 12.1175 17.8067 11.8686 17.7034C11.5816 17.5843 11.3486 17.1193 10.8839 16.1898L8.72481 11.8716C8.65107 11.7241 8.61416 11.6507 8.5649 11.5868C8.52119 11.53 8.47074 11.479 8.41404 11.4352C8.35158 11.3871 8.27938 11.351 8.13847 11.2806L3.81026 9.11646C2.88084 8.65175 2.41609 8.41918 2.297 8.13216C2.19371 7.88325 2.21727 7.60003 2.36007 7.37148C2.52472 7.10795 3.02135 6.95486 4.01453 6.64927L15.0933 3.2404C15.8741 3.00015 16.2647 2.88012 16.5284 2.97695C16.7582 3.06128 16.9393 3.24221 17.0236 3.47193C17.1204 3.73553 17.0002 4.12589 16.7602 4.90591L16.7597 4.90707Z"
        stroke="#103D61"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </SvgIcon>
  );
}
