import type { ChipProps } from '@mui/material/Chip';
import MuiChip from '@mui/material/Chip';
import React from 'react';

export default function Chip(props: ChipProps) {
  return (
    <MuiChip
      {...props}
      sx={{
        '& .MuiChip-label': {
          textTransform: 'capitalize',
          overflow: 'visible',
          lineHeight: '100%',
        },
      }}
    />
  );
}
