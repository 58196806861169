"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.recordActionSchema = void 0;
var types_shared_1 = require("types-shared");
exports.recordActionSchema = {
    request: types_shared_1.z.object({
        body: types_shared_1.ExtensionAction,
        sender: types_shared_1.z.object({ tab: types_shared_1.z.object({ id: types_shared_1.z.number() }) }),
    }),
    response: types_shared_1.z.object({
        recorded: types_shared_1.z.boolean(),
    }),
};
