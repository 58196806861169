import { clsx } from 'clsx';
import type { ReactNode } from 'react';
import React from 'react';
import { IconButton, Typography, Flex } from 'ui-kit';

import { LeftChevron } from 'assets-shared';
import { Link } from 'react-router-dom';

interface Props {
  onBack: () => void;
  pageInfo?: string | ReactNode;
  children?: ReactNode;
  className?: string;
  title?: string;
}
export function Toolbar({
  onBack,
  title = 'Home',
  pageInfo,
  children,
  className,
}: Props) {
  return (
    <div className={clsx('px-6 py-5 !h-20 flex', className)}>
      <Flex alignItems="center">
        <Link to="/">
          <Flex className="p-0 mr-6 cursor-pointer">
            <img alt="Logo" className="w-8 min-w-8" src="/logo.png" />
          </Flex>
        </Link>
        <IconButton
          className="!border !border-solid !border-info !rounded-lg !p-0"
          onClick={onBack}
        >
          <LeftChevron className="text-info !h-10 !w-10" />
        </IconButton>
        <Flex className="ml-2" flexDirection="column">
          <Typography className="!font-medium !text-xs text-gray-500">
            {title}
          </Typography>
          <Typography className="!font-medium !text-xs text-primary-blue">
            {pageInfo}
          </Typography>
        </Flex>
      </Flex>
      {children}
    </div>
  );
}
