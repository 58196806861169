import { NodeTypesEnum } from 'types-shared';
import type {
  WorkflowAction,
  SelectedAction,
  WorkflowData,
  WorkflowEdge,
  WorkflowNode,
} from 'types-shared';
import type {
  Connection,
  EdgeChange,
  NodeChange,
  OnConnect,
  OnEdgesChange,
  OnNodesChange,
} from 'types-shared/reactflow';
import {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
} from 'types-shared/reactflow';
import type { StateCreator } from 'zustand';

import { defaultMarker } from '../utils/constants';

export const initialWorkflowState: WorkflowData = {
  nodes: [],
  edges: [],
  selectedAction: null,
  selectedNode: null,
  bulkSelectMode: false,
  workflowId: undefined,
};

export interface WorkflowStateActions {
  addNodes: (nodes: WorkflowNode[]) => void;
  addEdges: (edges: WorkflowEdge[]) => void;
  setNodes: (nodes: WorkflowNode[]) => void;
  updateNode: (node: WorkflowNode) => void;
  updateImageNodeAction: (nodeId: string, action: WorkflowAction) => void;
  setEdges: (edges: WorkflowEdge[]) => void;
  setSelectedAction: (action: SelectedAction | null) => void;
  setWorkflowId: (workflowId: string | undefined) => void;
  setSelectedNode: (nodeId: string | null) => void;
  onNodesChange: OnNodesChange;
  onEdgesChange: OnEdgesChange;
  onConnect: OnConnect;
  resetWorkflow: () => void;
}

export const WorkflowState: StateCreator<
  WorkflowData & WorkflowStateActions
> = (set, get) => ({
  ...initialWorkflowState,
  addNodes: (nodes: WorkflowNode[]) => {
    set({ nodes: [...get().nodes, ...nodes] });
  },
  updateNode: (node: WorkflowNode) => {
    const oldNodes = [...get().nodes];
    set({
      nodes: oldNodes.map((_node) => {
        if (_node.id === node.id) {
          return node;
        }
        return _node;
      }),
    });
  },
  updateImageNodeAction: (nodeId: string, action: WorkflowAction) => {
    const oldNodes = [...get().nodes];
    set({
      nodes: oldNodes.map((_node) => {
        if (_node.id === nodeId && _node.type === NodeTypesEnum.Image) {
          return {
            ..._node,
            data: {
              ..._node.data,
              actionData: {
                ..._node.data.actionData,
                [action.id]: {
                  ...action,
                },
              },
            },
          };
        }
        return _node;
      }),
    });
  },
  addEdges: (edges: WorkflowEdge[]) => {
    set({ edges: [...get().edges, ...edges] });
  },
  setNodes: (nodes: WorkflowNode[] = []) => {
    set({ nodes });
  },
  setEdges: (edges: WorkflowEdge[] = []) => {
    set({ edges });
  },
  onNodesChange: (changes: NodeChange[]) => {
    set({ nodes: applyNodeChanges(changes, get().nodes) as WorkflowNode[] });
  },
  onEdgesChange: (changes: EdgeChange[]) => {
    set({ edges: applyEdgeChanges(changes, get().edges) as WorkflowEdge[] });
  },
  onConnect: (connection: Connection) => {
    set({
      edges: addEdge(
        {
          ...connection,
          markerEnd: defaultMarker,
        },
        get().edges,
      ),
    });
  },
  setSelectedAction: (selectedAction: SelectedAction | null) => {
    set({ selectedAction });
  },
  setSelectedNode: (selectedNode: string | null) => {
    set({ selectedNode });
  },
  setWorkflowId: (workflowId: string | undefined) => {
    set({ workflowId });
  },
  resetWorkflow: () => {
    set(initialWorkflowState);
  },
});
