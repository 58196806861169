"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommitWorkflowState = void 0;
var zod_1 = require("zod");
var datasourceTypes_1 = require("../datasourceTypes");
var variable_schema_1 = require("./variable.schema");
var workflow_schema_1 = require("./workflow.schema");
var variable_helper_1 = require("./variable.helper");
exports.CommitWorkflowState = zod_1.z
    .object({
    nodes: zod_1.z.array(workflow_schema_1.WorkflowNode),
    edges: zod_1.z.array(workflow_schema_1.WorkflowEdge),
})
    .merge(variable_schema_1.VariableStateData)
    .merge(workflow_schema_1.TargetStateData)
    .merge(datasourceTypes_1.DatasourceStateData.pick({ datasourceMetadata: true }))
    .transform(function (data, ctx) {
    var datasourceNodeIds = data.nodes
        .filter(function (node) { return node.type === workflow_schema_1.NodeTypesEnum.Datasource; })
        .map(function (node) { return node.id; });
    if (!datasourceNodeIds.length) {
        ctx.addIssue({
            code: zod_1.z.ZodIssueCode.custom,
            message: 'No datasource node',
        });
        return zod_1.z.NEVER;
    }
    data.nodes = data.nodes.filter(function (node) {
        return workflow_schema_1.WorkflowImageNode.safeParse(node).success ||
            workflow_schema_1.WorkflowConditionalNode.safeParse(node).success ||
            workflow_schema_1.WorkflowFreeformNode.safeParse(node).success ||
            workflow_schema_1.WorkflowNewNode.safeParse(node).success ||
            workflow_schema_1.WorkflowDatasourceNode.safeParse(node).success ||
            workflow_schema_1.WorkflowEndingNode.safeParse(node).success;
    });
    return data;
})
    .refine(function (data) {
    return data.nodes.filter(function (node) { return workflow_schema_1.WorkflowImageNode.safeParse(node).success; })
        .length > 0;
}, {
    message: 'No image node found',
})
    .refine(function (data) {
    return data.nodes.every(function (node) {
        return workflow_schema_1.WorkflowDatasourceNode.safeParse(node).success ||
            workflow_schema_1.WorkflowNewNode.safeParse(node).success ||
            node.data.nodeStatus === workflow_schema_1.NodeStatusEnum.Checked;
    });
}, {
    message: 'Not all nodes are checked.',
})
    .transform(function (data) {
    // remove edges that are connected to non-existent nodes
    data.edges = data.edges.filter(function (edge) {
        var sourceNode = data.nodes.find(function (node) { return node.id === edge.source; });
        var targetNode = data.nodes.find(function (node) { return node.id === edge.target; });
        return sourceNode && targetNode;
    });
    return data;
})
    .refine(function (data) {
    var targets = data.targets, nodes = data.nodes;
    var imageNodes = nodes.filter(function (node) { return workflow_schema_1.WorkflowImageNode.safeParse(node).success; });
    var actionTargets = imageNodes.flatMap(function (node) {
        return Object.values(node.data.actionData)
            .map(function (action) { return action.targetId; })
            .filter(Boolean);
    });
    var targetIds = Object.keys(targets);
    return actionTargets.every(function (targetId) { return !targetId || targetIds.includes(targetId); });
}, {
    message: 'Missing targets in target data',
})
    .superRefine(function (data, ctx) {
    var variables = data.variables, nodes = data.nodes, datasourceMetadata = data.datasourceMetadata;
    var variableIds = Object.keys(variables);
    var usedVariables = (0, variable_helper_1.parseVariablesFromWorkflow)(nodes, variables);
    var invalidVariables = Array.from(usedVariables).filter(function (variableId) { return !variableIds.includes(variableId); });
    if (invalidVariables.length > 0) {
        ctx.addIssue({
            code: zod_1.z.ZodIssueCode.custom,
            message: 'Missing variables in variable data',
            path: ['variables'],
        });
        return zod_1.z.NEVER;
    }
    // TODO: Make this check more robust, and check for datasourceId in variable data and connected datasources
    var datasources = (0, variable_helper_1.getDatasources)(variableIds, variables);
    if ((datasourceMetadata === null || datasourceMetadata === void 0 ? void 0 : datasourceMetadata.datasourceId) &&
        !datasources.includes(datasourceMetadata.datasourceId)) {
        // ctx.addIssue({
        //   code: z.ZodIssueCode.custom,
        //   message: 'Datasource not found in variable data',
        //   path: ['datasources'],
        // });
        // TODO(neil): Readd this back in when we have a way to handle this
        // return z.NEVER;
    }
})
    // .transform((data) => {
    //   data.datasourceMetadata = null;
    //   return data;
    // })
    .transform(function (data) {
    // remove branchIds within conditional nodes that don't have corresponding edge
    data.nodes = data.nodes.map(function (node) {
        var _a;
        if (workflow_schema_1.WorkflowConditionalNode.safeParse(node).success) {
            var conditionalNode = workflow_schema_1.WorkflowConditionalNode.parse(node);
            var branches = conditionalNode.data.branchesData;
            conditionalNode.data.branchesData =
                (_a = branches === null || branches === void 0 ? void 0 : branches.filter(function (branch) {
                    return branch.branchId &&
                        data.edges.some(function (edge) {
                            return edge.id === branch.branchId && edge.source === node.id;
                        });
                })) !== null && _a !== void 0 ? _a : [];
            return conditionalNode;
        }
        return node;
    });
    return data;
})
    .transform(function (data) {
    return {
        nodes: data.nodes,
        edges: data.edges,
        variables: data.variables,
        targets: data.targets,
        datasourceMetadata: data.datasourceMetadata,
    };
});
