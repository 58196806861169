import { Button, Select, Input, SolaModal } from 'ui-kit';
import React, { useEffect, useState } from 'react';
import {
  type FormValues,
  contactModalEventChannel,
  defaultFormValue,
  problemTypes,
} from '../ContactModalTypes';

interface Props {
  workflowId: string | undefined;
  onSendMessage: (formValues: FormValues) => void;
  sendMessageStatus: 'error' | 'idle' | 'pending' | 'success';
}

export function ContactModal({
  onSendMessage,
  workflowId,
  sendMessageStatus,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<FormValues>(defaultFormValue);

  const setFormValue = (key: string, value: string) => {
    setFormValues((form) => ({
      ...form,
      [key]: value,
    }));
  };

  const onSubmit = () => {
    if (workflowId) {
      onSendMessage(formValues);
      setFormValues(defaultFormValue);
      setOpen(false);
    }
  };

  useEffect(() => {
    const unsubscribe = contactModalEventChannel.on(
      'open',
      (problemType = 'General Issue') => {
        setOpen(true);
        setFormValue('problemType', problemType);
      },
    );

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <SolaModal
      className="flex item-center justify-items-center max-w-[62rem]"
      onClose={() => {
        setOpen(false);
      }}
      open={open}
    >
      <div className="text-sm flex flex-row gap-20">
        <div className="flex-1 flex flex-col">
          <p className="font-medium mb-7 text-black">Contact support</p>
          <p className="font-medium mb-2.5 text-lg text-black">
            Contact support
          </p>
          <p className="text-gray-600">
            Send us a message if you are experiencing issues with actions or
            looking for any additional functionality! We will be in touch within
            24 hours.
          </p>
        </div>
        <div className="flex-1 max-w-md">
          <div className="mt-14 flex flex-col">
            <Select
              classes={{ select: '!py-4' }}
              disabled
              getLabel={(opt: string) => opt}
              getValue={(opt: string) => opt}
              label="Select the workflow"
              labelId="template-select-variable-source"
              options={workflowId ? [workflowId] : []}
              value={workflowId}
            />
          </div>

          <div className="flex flex-col mt-8">
            <Select
              classes={{ select: '!py-4' }}
              getLabel={(opt: string) => opt}
              getValue={(opt: string) => opt}
              label="Problem type"
              labelId="template-select-variable-source"
              onChange={(e) => {
                setFormValue('problemType', e.target.value);
              }}
              options={problemTypes}
              value={formValues.problemType}
            />
          </div>

          <div className="relative flex flex-col mt-11">
            <p className="mb-5 text-black">
              Upload a screenshot/image of your issue
            </p>
            <Button className="w-40 h-9" color="secondary" variant="outlined">
              UPLOAD IMAGE
            </Button>
          </div>

          <Input
            classes={{ wrapper: 'flex flex-col mt-10' }}
            floatingLabel
            label="Description"
            multiline
            onChange={(description: string) => {
              setFormValue('description', description);
            }}
            placeholder="Describe your issue in detail"
            rows={5}
            value={formValues.description}
          />

          <div className="flex flex-row gap-7 mt-10">
            <Button
              className="w-40 h-9"
              color="secondary"
              loading={sendMessageStatus === 'pending'}
              onClick={onSubmit}
              variant="contained"
            >
              SUBMIT
            </Button>
            <Button
              className="h-9"
              color="secondary"
              onClick={() => {
                setOpen(false);
              }}
              variant="text"
            >
              CANCEL
            </Button>
          </div>
        </div>
      </div>
    </SolaModal>
  );
}
