import { Flex, Button, notify, AlertVariant, Input } from 'ui-kit';
import { clsx } from 'clsx';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import React, { useState, useCallback, useMemo } from 'react';
import { DataCopyIcon } from 'assets-shared';
import type { DatasourceVariable } from 'types-shared';
import { useNavigate } from 'react-router-dom';
import { identity, pickBy } from 'lodash';

interface CopyDataProps {
  data: string;
  queryMethod?: string;
  className?: string;
  dataType: 'url' | 'json';
}

export function CopyData({
  data,
  queryMethod,
  dataType,
  className,
}: CopyDataProps) {
  const copyToClipboard = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    navigator.clipboard.writeText(data).then(() => {
      notify({
        message: 'Copied to clipboard',
        variant: AlertVariant.SUCCESS,
      });
    });
  }, [data]);

  return (
    <Flex
      className={clsx(
        'pl-7 pr-2 rounded-lg bg-primary-blue-extralight-2 text-sm text-info-dark',
        { 'max-h-[32rem]': dataType === 'json' },
        className,
      )}
      flexDirection="row"
    >
      {queryMethod ? (
        <div className="border-r border-indigo-light py-4 pr-7">
          <span className="font-medium ">{queryMethod}</span>
        </div>
      ) : null}

      <Flex
        className="px-6 rounded-lg bg-primary-blue-extralight-2 text-sm text-info-dark flex-1 max-w-[100%] relative"
        flexDirection="row"
        justifyContent="space-between"
      >
        <div className="my-4 overflow-scroll">
          {dataType === 'url' ? (
            <span className="font-normal">{data}</span>
          ) : null}
          {dataType === 'json' ? (
            <pre className="whitespace-pre-wrap font-normal pt-11 pb-2">
              {data}
            </pre>
          ) : null}
        </div>
        <div className="absolute top-1.5 right-1">
          <Button
            className="!py-2 !px-6 !text-sm !font-medium"
            color="secondary"
            onClick={copyToClipboard}
            startIcon={<DataCopyIcon className="!text-info" fontSize="small" />}
            variant="outlined"
          >
            COPY
          </Button>
        </div>
      </Flex>
    </Flex>
  );
}

interface ApiCallMethodsProps {
  hasRetryNode?: boolean;
  settings: {
    retryInterval: string | number | null;
    maxAttempts: string | number | null;
    maxRunLength: string | number | null;
  };
  payloadData: Record<string, string | null>;
  workflowId: string;
  inputFields: [string, DatasourceVariable][];
  formData: Record<string, string | null>;
  setFormData: React.Dispatch<
    React.SetStateAction<Record<string, string | null>>
  >;
  executionId: string;
  setExecutionId: React.Dispatch<React.SetStateAction<string>>;
  solaApiUrl: string;
  isAdmin: boolean;
}

type ExecutionApiEndpoint = 'trigger' | 'status' | 'outputs';

export function ApiCallMethods({
  settings,
  payloadData,
  workflowId,
  inputFields,
  formData,
  setFormData,
  solaApiUrl,
  executionId,
  setExecutionId,
  isAdmin,
}: ApiCallMethodsProps) {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedEndpoint, setSelectedEndpoint] =
    useState<ExecutionApiEndpoint>('trigger');

  const retryConfig = useMemo(() => pickBy(settings, identity), [settings]);

  const apiConfig: Record<
    ExecutionApiEndpoint,
    {
      label: string;
      buttonLabel?: string;
      description: string;
      requestDescription: string;
      responseDescription: string;
      endpoint: string;
      method: string;
      sampleResponse: Record<string, unknown>;
      sampleCurl: string;
    }
  > = {
    trigger: {
      label: 'Trigger workflow',
      buttonLabel: 'Trigger Workflow',
      description:
        'Execute the following API call from your app to trigger the workflow.',
      requestDescription:
        'To execute the workflow, include data values for the variables in the payload.',
      responseDescription:
        'Check the status of the triggered execution in the API response.',
      endpoint: `${solaApiUrl}/v1/execution/queue-remote/${workflowId}`,
      method: 'PUT',
      sampleResponse: {
        executionId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
      },
      sampleCurl: `curl -X PUT "${solaApiUrl}/v1/execution/queue-remote/${workflowId}" \\
-H "Content-Type: application/json" \\
-d '${JSON.stringify({ variableData: payloadData }, null, 2)}'`,
    },
    status: {
      label: 'Get workflow status',
      description:
        'Execute the following API call from your app to get the status of a workflow.',
      requestDescription:
        'To get status details for a workflow execution, include an execution ID in the payload.',
      responseDescription:
        'Receive details on the execution via the API response.',
      endpoint: `${solaApiUrl}/v1/execution/status-remote/${executionId || '<EXECUTION_ID>'}`,
      method: 'GET',
      sampleResponse: {
        executionId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
        workflowId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
        setId: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
        status: 'QUEUED',
        createdAt: 'YYYY-MM-TT',
        variableData: { 'Variable 1': 'Value', 'Variable 2': 'Value' },
      },
      sampleCurl: `curl -X GET "${solaApiUrl}/v1/execution/status-remote/${executionId || '<EXECUTION_ID>'}"`,
    },
    outputs: {
      label: 'Get workflow outputs',
      description:
        'Execute the following API call from your app to get the outputs of a workflow.',
      requestDescription:
        'To get the outputs for a workflow execution, include an execution ID in the payload.',
      responseDescription:
        'Receive output details on the execution via the API response.',
      endpoint: `${solaApiUrl}/v1/execution/outputs-remote/${executionId || '<EXECUTION_ID>'}`,
      method: 'GET',
      sampleResponse: {
        variableData: { 'Variable 1': 'Value', 'Variable 2': 'Value' },
        artifactUrls: [
          'https://sola-execution-data.s3.us-east-1.amazonaws.com/xxxxx',
          'https://sola-execution-data.s3.us-east-1.amazonaws.com/xxxxx',
        ],
      },
      sampleCurl: `curl -X GET "${solaApiUrl}/v1/execution/outputs-remote/${executionId || '<EXECUTION_ID>'}"`,
    },
  };

  const adminRun = isAdmin ? 'true' : undefined;

  const payloadJsonString = JSON.stringify(
    {
      variableData: formData,
      retryConfig,
      adminRun,
    },
    null,
    2,
  );

  const triggerSnippets = useMemo(
    () => ({
      json: payloadJsonString,
      curl: `curl -X PUT "${solaApiUrl}/v1/execution/queue-remote/${workflowId}" \\
-H "Content-Type: application/json" \\
-d '${payloadJsonString}'`,
      python: `import requests
url = "${solaApiUrl}/v1/execution/queue-remote/${workflowId}"
payload = ${payloadJsonString}
headers = {"Content-Type": "application/json"}
response = requests.request("PUT", url, json=payload, headers=headers)
print(response.text)`,
      bash: `#!/bin/bash
curl -X PUT "${solaApiUrl}/v1/execution/queue-remote/${workflowId}" \\
-H "Content-Type: application/json" \\
-d '${payloadJsonString}'`,
      ts: `import axios from 'axios';
const url = "${solaApiUrl}/v1/execution/queue-remote/${workflowId}";
const payload = ${payloadJsonString};
axios.put(url, payload, { headers: { "Content-Type": "application/json" } })
  .then(response => console.log(response.data));`,
    }),
    [payloadJsonString, solaApiUrl, workflowId],
  );

  const statusAndOutputSnippets = useMemo(() => {
    const id = executionId || '<EXECUTION_ID>';
    const remotePath =
      selectedEndpoint === 'outputs' ? 'outputs-remote' : 'status-remote';
    return {
      curl: `curl -X GET "${solaApiUrl}/v1/execution/${remotePath}/${id}"`,
      python: `import requests
url = "${solaApiUrl}/v1/execution/${remotePath}/${id}"
headers = {"Content-Type": "application/json"}
response = requests.get(url, headers=headers)
print(response.text)`,
      bash: `#!/bin/bash
curl -X GET "${solaApiUrl}/v1/execution/${remotePath}/${id}"`,
      ts: `import axios from 'axios';
const url = \`${solaApiUrl}/v1/execution/${remotePath}/${id}\`;
axios.get(url)
  .then(response => console.log(response.data));`,
    };
  }, [executionId, selectedEndpoint, solaApiUrl]);

  const selectedSnippetData = useMemo(() => {
    return Object.values(
      selectedEndpoint === 'trigger'
        ? triggerSnippets
        : statusAndOutputSnippets,
    ).find((_s, idx) => idx === selectedTab);
  }, [selectedEndpoint, triggerSnippets, statusAndOutputSnippets, selectedTab]);

  const onTabChange = (_event: React.SyntheticEvent, index: number) => {
    setSelectedTab(index);
  };

  const updateForm = (name: string) => (value: string) => {
    setFormData((prevState) => ({ ...prevState, [name]: value || '' }));
  };

  return (
    <Flex className="gap-12 mt-8" flexDirection="row">
      <Flex className="flex flex-col pl-1" flexDirection="column">
        {Object.entries(apiConfig).map(([endpointType, info]) => (
          <Button
            className={clsx(
              '!py-2 !pl-6 !pr-10 flex w-full !text-nowrap !font-medium !leading-6 !text-sm !justify-start !rounded-none hover:!text-primary-light-blue',
              selectedEndpoint === endpointType
                ? '!text-primary-light-blue !border-2 !border-primary-light-blue !border-l-0 !border-y-0'
                : '!text-secondary-text !border-0',
            )}
            color="secondary"
            key={endpointType}
            onClick={() => {
              setSelectedEndpoint(endpointType as ExecutionApiEndpoint);
              setSelectedTab(0);
              setExecutionId('');
            }}
            variant="outlined"
          >
            {info.buttonLabel ?? info.label}
          </Button>
        ))}
      </Flex>

      <Flex className="gap-6 flex-1 font-medium" flexDirection="column">
        <div>
          <p className="text-info-dark text-lg font-medium mb-1.5">
            {apiConfig[selectedEndpoint].label}
          </p>
          <p className="text-sm text-color-grey font-normal mb-5">
            {apiConfig[selectedEndpoint].description}
          </p>
          <CopyData
            data={apiConfig[selectedEndpoint].endpoint}
            dataType="url"
            queryMethod={apiConfig[selectedEndpoint].method}
          />
        </div>

        <Flex className="gap-8 flex-1 font-medium mt-12" flexDirection="row">
          <div className="flex-1">
            <p className="text-info-dark text-lg font-medium mb-1.5">
              {selectedEndpoint === 'trigger'
                ? 'Test API trigger with variable values'
                : 'Test API endpoint with a workflow execution'}
            </p>
            {selectedEndpoint === 'trigger' ? (
              <>
                {!inputFields.length ? (
                  <div className="flex-1 flex flex-col items-center justify-center px-9 pt-20 pb-40 bg-[#F9FAFA]">
                    <p className="text-sm text-color-grey font-normal text-center mb-8">
                      Once you add API variables to your workflow, you can enter
                      values in this section to test API requests with different
                      input data.
                    </p>
                    <Button
                      className="!py-2 !px-6 !text-sm !font-medium"
                      color="secondary"
                      onClick={() => {
                        navigate(`/editor/${workflowId}`);
                      }}
                      variant="outlined"
                    >
                      Add API variables in the workflow
                    </Button>
                  </div>
                ) : (
                  <>
                    <p className="text-sm text-color-grey font-normal flex-1">
                      Fill in values for the workflow variables to automatically
                      populate the data in the API request.
                    </p>
                    {inputFields.map(([id, variable]) => (
                      <div className="mt-5" key={id}>
                        <Input
                          floatingLabel
                          id={variable.data.key}
                          label={variable.data.key}
                          onChange={updateForm(variable.data.key)}
                          placeholder="Enter value"
                          value={formData[variable.data.key] ?? ''}
                        />
                      </div>
                    ))}
                  </>
                )}
              </>
            ) : null}
            {selectedEndpoint !== 'trigger' ? (
              <>
                <p className="text-sm text-color-grey font-normal flex-1">
                  Enter an execution ID to automatically configure the sample
                  API request. The execution ID is returned in the response of
                  an API workflow trigger.
                </p>
                <div className="mt-6">
                  <Input
                    floatingLabel
                    id="execution-Id"
                    label="Execution ID"
                    onChange={setExecutionId}
                    placeholder="Enter value"
                    value={executionId}
                  />
                </div>
              </>
            ) : null}
          </div>

          <div className="flex-1 max-w-[50%]">
            <p className="text-info-dark text-lg font-medium mb-1.5">
              Sample API request
            </p>
            <p className="text-sm text-color-grey font-normal mb-5">
              {apiConfig[selectedEndpoint].requestDescription}
            </p>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                onChange={onTabChange}
                sx={{
                  '& .MuiTabs-indicator': {
                    backgroundColor: '#2196F3',
                  },
                  '& .Mui-selected': {
                    color: '#2196F3 !important',
                  },
                }}
                value={selectedTab}
              >
                {Object.keys(
                  selectedEndpoint === 'trigger'
                    ? triggerSnippets
                    : statusAndOutputSnippets,
                ).map((lang, index) => (
                  <Tab key={lang} label={lang.toUpperCase()} value={index} />
                ))}
              </Tabs>
            </Box>
            <CopyData
              className="flex-1 !rounded-t-none"
              data={selectedSnippetData || ''}
              dataType="json"
            />

            <Flex
              className="flex-1 mt-14"
              flexDirection="column"
              justifyContent="space-between"
            >
              <p className="text-info-dark text-lg font-medium mb-1.5">
                Sample response
              </p>
              <p className="text-sm text-color-grey font-normal mb-5">
                {apiConfig[selectedEndpoint].responseDescription}
              </p>
              <CopyData
                data={JSON.stringify(
                  apiConfig[selectedEndpoint].sampleResponse,
                  null,
                  2,
                )}
                dataType="json"
              />
            </Flex>
          </div>
        </Flex>
      </Flex>
    </Flex>
  );
}
