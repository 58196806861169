import { useEffect, type ReactNode, useState, useMemo } from 'react';
import { NodeTypesEnum } from 'types-shared';
import type { WorkflowNodeProps, WorkflowNode } from 'types-shared';
import { NodeElementCore, useEditingNodeId } from 'editor-shared';
import { EditorStore, type EditorStoreProps } from '../../store/EditorState';
import { useShallow } from 'zustand/react/shallow';
import { nodeSelectEventChannel } from '../ActionsHeader';
import Checkbox from '@mui/material/Checkbox';
import { AlertVariant, CheckCircleIcon, notify } from 'ui-kit';
import { RadioButtonUncheckedOutlined } from 'assets-shared';

interface Props {
  workflowData: WorkflowNodeProps;
  children: ReactNode;
  showAddButton?: boolean;
  showEditButton?: boolean;
  showDeleteButton?: boolean;
  onClick?: () => void;
  label?: string;
  allowBulkCheck?: boolean;
  leftConnectable?: boolean;
  rightConnectable?: boolean;
}

const BulkCheckableNodeTypes = [
  NodeTypesEnum.Image,
  NodeTypesEnum.Freeform,
  NodeTypesEnum.Conditional,
  NodeTypesEnum.Ending,
];

function NodeElement({
  workflowData,
  children,
  showAddButton,
  showEditButton,
  showDeleteButton,
  onClick,
  label,
  allowBulkCheck = false,
  leftConnectable = false,
  rightConnectable = false,
}: Props) {
  const [nodeSelectionModeEnabled, setNodeSelectionModeEnabled] =
    useState<boolean>(false);
  const { nodes, edges, setEdges, setNodes, setSelectedNode } = EditorStore(
    useShallow((state: EditorStoreProps) => ({
      nodes: state.nodes,
      edges: state.edges,
      setEdges: state.setEdges,
      setNodes: state.setNodes,
      setSelectedNode: state.setSelectedNode,
    })),
  );
  const { setEditingNodeId } = useEditingNodeId();

  const handleDelete = () => {
    const id = workflowData.id;
    const filteredNodes = nodes.filter((node) => node.id !== id);
    const outgoingEdge = edges.find((e) => e.source === id);
    const filteredEdges = edges
      .filter((edge) => edge.source !== id)
      .filter((edge) => outgoingEdge || edge.target !== id)
      .map((edge) => {
        if (edge.target === id && outgoingEdge) {
          return {
            ...edge,
            target: outgoingEdge.target,
          };
        }
        return edge;
      });

    setNodes(filteredNodes);
    setEdges(filteredEdges);
    setSelectedNode(null);
    setEditingNodeId(undefined);
  };

  const selectedNode: WorkflowNode | undefined = useMemo(() => {
    if (!allowBulkCheck) return undefined;
    const workflowNode = nodes.find((node) => node.id === workflowData.id);
    if (
      workflowNode?.type &&
      BulkCheckableNodeTypes.includes(workflowNode.type)
    ) {
      return workflowNode;
    }
    notify({
      message: 'Selected node can not be checked',
      variant: AlertVariant.ERROR,
    });
    throw new Error('Selected node can not be checked');
  }, [allowBulkCheck, workflowData.id, nodes]);

  const toggleNodeSelection = () => {
    const filteredNodes = nodes.map((node) => {
      if (
        node.id !== workflowData.id ||
        !BulkCheckableNodeTypes.includes(node.type)
      )
        return node;

      return {
        ...node,
        data: {
          ...node.data,
          selected: !(node.data as { selected: boolean }).selected,
        },
      };
    });
    setNodes(filteredNodes as WorkflowNode[]);
  };

  useEffect(() => {
    const unsubscribe = nodeSelectEventChannel.on(
      'onToggleSelectionMode',
      (isEnabled) => {
        setNodeSelectionModeEnabled(isEnabled);
      },
    );

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div>
      <NodeElementCore
        edges={edges}
        handleNodeClick={onClick}
        label={label}
        leftConnectable={leftConnectable}
        nodeSelectionModeEnabled={nodeSelectionModeEnabled}
        nodes={nodes}
        onClickDelete={handleDelete}
        onClickEdit={(nodeId) => {
          setEditingNodeId(nodeId);
        }}
        rightConnectable={rightConnectable}
        setEdges={setEdges}
        setNodes={setNodes}
        showAddButton={showAddButton}
        showDeleteButton={showDeleteButton}
        showEditButton={showEditButton}
        workflowData={workflowData}
      >
        {children}
      </NodeElementCore>

      {allowBulkCheck ? (
        <div className="mt-4 w-full flex justify-center nodrag nopan">
          {nodeSelectionModeEnabled ? (
            <div
              className="bg-white aspect-square rounded-xl absolute !cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                toggleNodeSelection();
              }}
              role="presentation"
            >
              <Checkbox
                checked={
                  (selectedNode?.data as { selected: boolean }).selected ||
                  false
                }
                checkedIcon={
                  <CheckCircleIcon
                    className="!fill-none"
                    strokeColor="#246bfa"
                  />
                }
                className="!p-4"
                icon={<RadioButtonUncheckedOutlined />}
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

export default NodeElement;
