import { type WorkflowNodeProps } from 'types-shared';
import NodeElement from './index';
import { EditorStore, type EditorStoreProps } from '../../store/EditorState';
import { useShallow } from 'zustand/react/shallow';
import { ConditionalNodeCore, useEditingNodeId } from 'editor-shared';
import isNil from 'lodash/isNil';
import { useMemo } from 'react';

export default function ConditionalNode(workflowData: WorkflowNodeProps) {
  const { setEditingNodeId } = useEditingNodeId();
  const { nodes, edges } = EditorStore(
    useShallow((state: EditorStoreProps) => ({
      nodes: state.nodes,
      edges: state.edges,
    })),
  );

  const node = nodes.find((n) => n.id === workflowData.id);
  const nodeEdges = useMemo(() => {
    if (isNil(node)) {
      return [];
    }
    return edges
      .filter((edge) => edge.source === node.id)
      .filter((e) => !isNil(e));
  }, [edges, node]);

  if (isNil(node)) {
    return null;
  }

  return (
    <NodeElement
      allowBulkCheck
      label="Conditional block"
      showDeleteButton={nodeEdges.length <= 1}
      showEditButton
      workflowData={workflowData}
    >
      <ConditionalNodeCore
        node={node}
        onClick={() => {
          setEditingNodeId(workflowData.id);
        }}
      />
    </NodeElement>
  );
}
