import 'types-shared/reactflow/dist/style.css';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  AlertVariant,
  Button,
  dialogEventChannel,
  IconButton,
  notify,
  Tooltip,
  useEnvironment,
  Typography,
  Menu,
  MenuItem,
} from 'ui-kit';
import {
  ArrowLeftIcon,
  Logo,
  AutoFixHigh,
  Save,
  CloudSync,
  ExpandLessOutlined,
  ExpandMoreOutlined,
  DatabaseIcon,
  APITriggerIcon,
} from 'assets-shared';
import type { RunType } from 'editor-shared';
import { ConfirmRunAutomation } from 'editor-shared';

import { useUpdateWorkflow, useQueueAutolinkTask } from '../hooks';
import SolaModal from '../../shared/SolaModal';
import { EditorStore } from '../store/EditorState';
import { ExecutionRunner } from 'execution-shared';
import { TriggerTypeEnum } from 'types-shared';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { FeatureFlag } from 'dashboard-shared';

interface Props {
  autolinkLoading?: boolean;
  workflowId: string;
  workflowName?: string;
  setAutolinkTaskId: React.Dispatch<React.SetStateAction<string | undefined>>;
}

function Toolbar({
  autolinkLoading,
  workflowId,
  workflowName,
  setAutolinkTaskId,
}: Props): JSX.Element {
  const { state, pathname } = useLocation() as {
    pathname: string;
    state: {
      databaseCreated: boolean;
    } | null;
  };
  const navigate = useNavigate();
  const databaseCreated = state?.databaseCreated;
  const {
    addWatchedExecutionIds,
    datasourceMetadata,
    tableData,
    nodes,
    edges,
    variables,
    targets,
    triggerType,
    setTriggerType,
  } = EditorStore();

  const localRunsEnabled = useFeatureFlagEnabled(FeatureFlag.LocalRuns);
  const autolinkEnabled = useFeatureFlagEnabled(FeatureFlag.AutolinkDemo);

  const [loading, setLoading] = useState<boolean>(false);
  const [recordIds, setRecordIds] = useState<number[]>([0]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmittedModalOpen, setIsSubmittedModalOpen] = useState(false);
  const { selectedEnv } = useEnvironment();
  const executionRunner = useMemo(
    () => new ExecutionRunner(selectedEnv),
    [selectedEnv],
  );
  const { mutateAsync: updateWorkflow } = useUpdateWorkflow();
  const { mutateAsync: queueAutolink, status } = useQueueAutolinkTask();

  const confirmRunHandler = useCallback(
    async (runType: RunType) => {
      setLoading(true);
      try {
        const { queuedExecutionIds } = await executionRunner.run({
          runType: runType.valueOf(),
          workflowId,
          recordIds: recordIds.map((id) => id.toString()),
          workflowData: {
            nodes,
            edges,
            variables,
            targets,
          },
          datasourceMetadata,
          tableData,
        });
        addWatchedExecutionIds(queuedExecutionIds);
        setIsModalOpen(false);
        setIsSubmittedModalOpen(true);
      } catch (e) {
        notify({
          message:
            e instanceof Error
              ? e.message
              : 'Failed to run the workflow. Please try again.',
          variant: AlertVariant.ERROR,
        });
      } finally {
        setLoading(false);
      }
    },
    [
      addWatchedExecutionIds,
      executionRunner,
      workflowId,
      recordIds,
      nodes,
      edges,
      variables,
      targets,
      tableData,
      datasourceMetadata,
    ],
  );

  const isLoading = status === 'pending' || autolinkLoading;

  const goToWorkflowDetail = () => {
    setIsSubmittedModalOpen(false);
    navigate(`/workflows/${workflowId}`);
  };

  const downloadWorkflow = () => {
    dialogEventChannel.emit('open', {
      title: 'Unsaved Changes',
      confirmText: 'Download Changes',
      onConfirm: () => {
        dialogEventChannel.emit('close');
        localStorage.removeItem(workflowId);
        window.location.reload();
      },
      onCancel: () => {
        dialogEventChannel.emit('close');
      },
      hideCloseBtn: true,
      showActions: true,
      children: (
        <p className="text-sm">
          Some changes may not have been saved, are you sure you want to
          download changes?
        </p>
      ),
    });
  };

  const onAutoLink = useCallback(async () => {
    const taskId: string = await queueAutolink({
      datasourceId: datasourceMetadata?.datasourceId ?? null,
      variables: Object.values(variables),
    });
    setAutolinkTaskId(taskId);
  }, [
    queueAutolink,
    datasourceMetadata?.datasourceId,
    setAutolinkTaskId,
    variables,
  ]);

  useEffect(() => {
    if (databaseCreated) {
      notify({
        message:
          'Database connected! You can now Auto-link your data source to variables/actions',
        variant: AlertVariant.SUCCESS,
        action: (
          <Button
            className="!text-white !border-white"
            onClick={onAutoLink}
            variant="outlined"
          >
            Autolink
          </Button>
        ),
      });
      navigate(pathname, { replace: true });
    }
  }, [pathname, navigate, databaseCreated, onAutoLink]);

  const remoteExecutionApiEnabled = useFeatureFlagEnabled(
    FeatureFlag.RemoteExecutionApiTrigger,
  );
  const datasourceEnabled = useFeatureFlagEnabled(FeatureFlag.Datasource);
  const isAPITrigger = triggerType === TriggerTypeEnum.API;

  const triggerRef = useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const onMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleApiTrigger = () => {
    if (isAPITrigger) {
      setTriggerType(TriggerTypeEnum.Datasource);
    } else {
      setTriggerType(TriggerTypeEnum.API);
    }
    onMenuClose();
  };

  return (
    <>
      <header className="py-5 flex justify-between items-center !h-20 px-6">
        <div className="flex items-center space-x-6">
          <Link to="/">
            <Logo className="!w-8 !h-8 !cursor-pointer" />
          </Link>
          <Link
            className="flex !border !border-solid !border-info !rounded-lg"
            replace
            to={`/workflows/${workflowId}`}
          >
            <ArrowLeftIcon className="text-info !h-10 !w-10" />
          </Link>
          <div className="flex flex-col text-xs font-medium !ml-6">
            <span className="text-gray-500">{workflowName || 'Workflow'}</span>
            <span>{workflowId}</span>
          </div>

          {remoteExecutionApiEnabled ? (
            <>
              <Button
                className="group !mr-2 !px-2 !text-nowrap !font-medium !leading-6 !text-sm !text-info-dark hover:!text-info !min-w-[140px]"
                color="secondary"
                endIcon={
                  anchorEl ? (
                    <ExpandLessOutlined
                      className="!text-info-dark group-hover:!text-info"
                      fontSize="small"
                    />
                  ) : (
                    <ExpandMoreOutlined
                      className="!text-info-dark group-hover:!text-info"
                      fontSize="small"
                    />
                  )
                }
                onClick={() => {
                  setAnchorEl(triggerRef.current);
                }}
                ref={triggerRef}
                startIcon={
                  isAPITrigger ? (
                    <APITriggerIcon
                      className="!text-info-dark group-hover:!text-info"
                      fontSize="small"
                    />
                  ) : (
                    <DatabaseIcon
                      className="!text-info-dark group-hover:!text-info"
                      fontSize="small"
                    />
                  )
                }
                variant="text"
              >
                {isAPITrigger ? 'API CALL' : 'DATABASE'}
              </Button>
              <Menu
                BackdropProps={{
                  style: {
                    backgroundColor: 'transparent',
                  },
                }}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                onClose={onMenuClose}
                open={Boolean(anchorEl)}
                sx={{
                  '& .MuiPaper-root': {
                    borderRadius: '4px',
                  },
                  '& .MuiMenu-list': {
                    padding: '0',
                  },
                  '& .MuiMenu-paper': {
                    minWidth: '140px',
                  },
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem
                  className="!font-medium flex-row items-center"
                  disabled={datasourceEnabled ? false : isAPITrigger}
                  onClick={toggleApiTrigger}
                >
                  {!isAPITrigger ? (
                    <APITriggerIcon
                      className="!text-info-dark"
                      fontSize="small"
                    />
                  ) : (
                    <DatabaseIcon
                      className="!text-info-dark"
                      fontSize="small"
                    />
                  )}
                  <span className="font-medium text-sm text-info-dark ml-2 mr-4">
                    {!isAPITrigger ? 'API CALL' : 'DATABASE'}
                  </span>
                </MenuItem>
              </Menu>
            </>
          ) : null}

          {autolinkEnabled ? (
            <span className="rounded p-px !bg-gradient-to-r from-primary-blue to-primary-purple">
              <Button
                className="!uppercase bg-transparent !text-black !text-sm !bg-white !border-0 !rounded"
                color="secondary"
                endIcon={
                  !isLoading ? (
                    <AutoFixHigh className="text-xs text-black" />
                  ) : null
                }
                loading={isLoading}
                onClick={onAutoLink}
                variant="outlined"
              >
                Autolink
              </Button>
            </span>
          ) : null}

          {/* <div className="flex space-x-1 !ml-9 items-center">
            <span className="text-sm font-medium">Database already </span>
            <span className="text-sm font-medium text-secondary-purple">
              auto-linked
            </span>
            <AutoLinkIcon className="!h-4 !w-4 !ml-2" />
          </div> */}
        </div>
        <div className="flex items-center space-x-2">
          <Tooltip title="Save Changes">
            <IconButton
              onClick={() =>
                updateWorkflow({
                  workflowId,
                  editorState: {
                    nodes,
                    edges,
                    variables,
                    targets,
                    datasourceMetadata,
                    tableData,
                  },
                })
              }
            >
              <Save className="!text-info" fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Download Changes">
            <IconButton onClick={downloadWorkflow}>
              <CloudSync className="!text-green-600" fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip
            hidden={!isAPITrigger}
            title="Dashboard runs disabled for API triggers"
          >
            <Button
              className="!uppercase !ml-6 disabled:!text-white"
              color="secondary"
              disabled={isAPITrigger}
              onClick={() => {
                setIsModalOpen(true);
              }}
              variant="contained"
            >
              Run Automation
            </Button>
          </Tooltip>
        </div>
      </header>
      <SolaModal
        className="!max-w-2xl"
        onClose={() => {
          setIsModalOpen(false);
        }}
        open={isModalOpen}
        showCloseIcon={false}
      >
        <ConfirmRunAutomation
          datasourceState={{ datasourceMetadata, tableData }}
          loading={loading}
          localRunsEnabled={localRunsEnabled}
          onCancel={() => {
            setIsModalOpen(false);
          }}
          onChange={setRecordIds}
          onConfirmRun={confirmRunHandler}
          recordIds={recordIds}
        />
      </SolaModal>
      <SolaModal
        className="!max-w-2xl -top-20"
        onClose={() => {
          setIsSubmittedModalOpen(false);
        }}
        open={isSubmittedModalOpen}
        showCloseIcon
      >
        <img alt="logo" className="w-32" src="/logo-blue.png" />
        <div className="ml-1">
          <Typography className="!font-medium !mt-7" variant="h5">
            Your run is in progress!
          </Typography>
          <Typography className="!mt-4 !text-info-dark">
            Your workflow is running remotely; the execution(s) can take a few
            minutes. We'll notify you of progress through <b>Slack</b> and in
            the workflow executions page!
          </Typography>
        </div>
        <Button
          className="!text-info !border-info !my-10"
          onClick={() => {
            goToWorkflowDetail();
          }}
          variant="outlined"
        >
          GO TO WORKFLOW DETAILS
        </Button>
      </SolaModal>
    </>
  );
}

export default Toolbar;
