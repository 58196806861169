"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VariableStateData = exports.VariableMap = exports.Variable = exports.MultiChoiceVariable = exports.SelectVariable = exports.TemplateVariable = exports.VariableRef = exports.ScrapeVariable = exports.DatasourceVariable = exports.VariableBase = exports.VariableType = exports.VariableTypeEnum = void 0;
var zod_1 = require("zod");
var actionTypes_1 = require("../actionTypes");
var triggerTypes_1 = require("../triggerTypes");
var VariableTypeEnum;
(function (VariableTypeEnum) {
    VariableTypeEnum["Datasource"] = "Datasource";
    VariableTypeEnum["Scrape"] = "Scrape";
    VariableTypeEnum["Template"] = "Template";
    VariableTypeEnum["Select"] = "Select";
    VariableTypeEnum["MultiChoice"] = "MultiChoice";
})(VariableTypeEnum || (exports.VariableTypeEnum = VariableTypeEnum = {}));
exports.VariableType = zod_1.z.nativeEnum(VariableTypeEnum);
exports.VariableBase = zod_1.z.object({
    id: zod_1.z.string(),
    name: zod_1.z.string().optional(),
    transformData: zod_1.z
        .object({
        query: zod_1.z.string().nullable().optional(),
        initialValue: zod_1.z.string().nullable().optional(),
        transformedValue: zod_1.z.string().nullable().optional(),
    })
        .optional(),
    executionData: zod_1.z
        .object({
        initialValue: zod_1.z.string().nullable().optional(),
        transformedValue: zod_1.z.string().nullable().optional(),
    })
        .optional(),
});
exports.DatasourceVariable = exports.VariableBase.extend({
    type: zod_1.z.literal(VariableTypeEnum.Datasource),
    data: zod_1.z.object({
        datasourceId: zod_1.z.string(),
        key: zod_1.z.string(),
        triggerType: triggerTypes_1.TriggerType.nullable().optional(),
        valueType: zod_1.z.string().nullable().optional(),
    }),
});
exports.ScrapeVariable = exports.VariableBase.required({ name: true }).extend({
    type: zod_1.z.literal(VariableTypeEnum.Scrape),
    data: zod_1.z.object({
        selector: actionTypes_1.QueryPaths,
        scrapedText: zod_1.z.string().optional(),
    }),
});
exports.VariableRef = zod_1.z.object({
    id: zod_1.z.string(),
});
exports.TemplateVariable = exports.VariableBase.extend({
    type: zod_1.z.literal(VariableTypeEnum.Template),
    data: zod_1.z.array(zod_1.z.union([zod_1.z.string(), exports.VariableRef])),
});
exports.SelectVariable = exports.TemplateVariable.extend({
    type: zod_1.z.literal(VariableTypeEnum.Select),
    selectOptions: zod_1.z.array(actionTypes_1.SelectOption),
});
exports.MultiChoiceVariable = exports.TemplateVariable.extend({
    type: zod_1.z.literal(VariableTypeEnum.MultiChoice),
    multiChoiceOptions: zod_1.z.array(actionTypes_1.BaseQueryPaths),
    selectedChoiceIx: zod_1.z.number().nullable(),
});
exports.Variable = zod_1.z.union([
    exports.DatasourceVariable,
    exports.ScrapeVariable,
    exports.TemplateVariable,
    exports.SelectVariable,
    exports.MultiChoiceVariable,
]);
exports.VariableMap = zod_1.z.record(zod_1.z.string(), exports.Variable);
exports.VariableStateData = zod_1.z.object({
    variables: exports.VariableMap,
});
