import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export default function AutoFixActiveIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7 3L7.5 2L8.5 1.5L7.5 1L7 0L6.5 1L5.5 1.5L6.5 2L7 3ZM2.5 5L3.33313 3.33344L5 2.5L3.33313 1.66656L2.5 0L1.66688 1.66656L0 2.5L1.66688 3.33344L2.5 5ZM13.5 9L12.6669 10.6666L11 11.5L12.6669 12.3334L13.5 14L14.3331 12.3334L16 11.5L14.3331 10.6666L13.5 9ZM15.7069 2.94469L13.0553 0.293125C12.8603 0.0975 12.6044 0 12.3484 0C12.0925 0 11.8366 0.0975 11.6412 0.293125L0.293125 11.6413C-0.0975 12.0319 -0.0975 12.665 0.293125 13.0553L2.94469 15.7069C3.14 15.9022 3.39594 15.9997 3.65156 15.9997C3.9075 15.9997 4.16344 15.9022 4.35875 15.7069L15.7069 4.35844C16.0975 3.96844 16.0975 3.335 15.7069 2.94469ZM11.2328 6.35813L9.64188 4.76719L12.3481 2.06094L13.9391 3.65188L11.2328 6.35813Z"
        fill="url(#paint0_linear_717_20757)"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_717_20757"
          x1="8"
          x2="8"
          y1="0"
          y2="15.9997"
        >
          <stop stopColor="#D900FC" />
          <stop offset="1" stopColor="#0056FC" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}
