import React, { useState, useEffect, useMemo } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Button,
  Input,
  notify,
  AlertVariant,
} from 'ui-kit';

interface Settings {
  retryInterval: number | string | null;
  maxAttempts: number | string | null;
  maxRunLength: number | string | null;
}

interface Props {
  settings: Settings;
  setSettings: (data: Settings) => void;
  hasRetryNode?: boolean;
  setMaxAttempts: (maxAttempts: number | null) => void;
  setRetryInterval: (retryInterval: number | null) => void;
  setMaxRunLength: (maxRunLength: number | null) => void;
  apiSettings: {
    retryInterval: number | null;
    maxAttempts: number | null;
    maxRunLength: number | null;
  };
  extractDaysHoursMinutes: (minutes: number) => {
    days: number;
    hours: number;
    minutes: number;
  };
  getTotalMinutes: ({
    days,
    hours,
    minutes,
  }: {
    days: number;
    hours: number;
    minutes: number;
  }) => number;
}

const defaultRetryConfig = {
  retryInterval: 5,
  maxAttempts: 1,
  maxRunLength: 1,
};

export function ApiCallSettings({
  settings,
  setSettings,
  apiSettings,
  setMaxAttempts,
  setRetryInterval,
  setMaxRunLength,
  extractDaysHoursMinutes,
  getTotalMinutes,
}: Props) {
  const [durationHasError, setDurationHasError] = useState<boolean>(false);
  const [hasUpdated, setHasUpdated] = useState<boolean>(false);

  const [duration, setDuration] = useState<{
    days: number | string;
    hours: number | string;
    minutes: number | string;
  }>({
    days: 0,
    hours: 0,
    minutes: 0,
  });

  const { retryInterval, maxRunLength, maxAttempts } = settings;

  useEffect(() => {
    if (retryInterval !== null) {
      const { days, hours, minutes } = extractDaysHoursMinutes(
        Number(retryInterval) || 5,
      );
      setDuration({ days, hours, minutes });
    }
  }, [extractDaysHoursMinutes, retryInterval]);

  useEffect(() => {
    if (retryInterval !== null) {
      const totalMinutes: number = getTotalMinutes({
        days: Number(duration.days),
        hours: Number(duration.hours),
        minutes: Number(duration.minutes),
      });
      setDurationHasError(totalMinutes < 5);
    } else {
      setDurationHasError(false);
    }
  }, [duration, getTotalMinutes, retryInterval]);

  const onCancel = () => {
    setSettings(apiSettings);
    setHasUpdated(false);
  };

  const onSave = () => {
    if (retryInterval !== null) {
      const totalMinutes: number = getTotalMinutes({
        days: Number(duration.days),
        hours: Number(duration.hours),
        minutes: Number(duration.minutes),
      });
      setRetryInterval(totalMinutes);
    } else {
      setRetryInterval(null);
    }
    setMaxAttempts(maxAttempts === null ? null : Number(maxAttempts));
    setMaxRunLength(maxRunLength === null ? null : Number(maxRunLength));
    setHasUpdated(false);
    notify({
      message: 'API settings changes were saved successfully.',
      variant: AlertVariant.SUCCESS,
    });
  };

  const toggleCheckbox =
    (key: 'retryInterval' | 'maxAttempts' | 'maxRunLength') => () => {
      setSettings({
        ...settings,
        [key]: settings[key] === null ? defaultRetryConfig[key] : null,
      });
      setHasUpdated(true);
    };

  const updateSettings =
    (key: 'retryInterval' | 'maxAttempts' | 'maxRunLength') =>
    (value: string) => {
      if (
        Number(value) < 0 ||
        ['e', 'E', '-', '.'].some((char) => value.includes(char))
      ) {
        return;
      }
      setSettings({ ...settings, [key]: value });
      setHasUpdated(true);
    };

  const updateDuration =
    (key: 'days' | 'hours' | 'minutes') => (value: string) => {
      if (
        Number(value) < 0 ||
        (key === 'minutes' && Number(value) > 59) ||
        (key === 'hours' && Number(value) > 23) ||
        ['e', 'E', '-', '.'].some((char) => value.includes(char))
      ) {
        return;
      }
      setDuration({ ...duration, [key]: value });
      setHasUpdated(true);
    };

  const disableSaveButton = useMemo(() => {
    return (
      !hasUpdated ||
      (maxAttempts !== null && Number(maxAttempts) < 1) ||
      (retryInterval !== null &&
        getTotalMinutes({
          days: Number(duration.days),
          hours: Number(duration.hours),
          minutes: Number(duration.minutes),
        }) < 5) ||
      (maxRunLength !== null && Number(maxRunLength) < 1)
    );
  }, [
    duration,
    getTotalMinutes,
    hasUpdated,
    maxRunLength,
    maxAttempts,
    retryInterval,
  ]);

  return (
    <div className="mt-8 p-8 border border-indigo-light rounded-lg">
      <p className="text-info-dark text-lg font-medium">
        Orchestration settings
      </p>
      <div className="mt-8">
        <FormControlLabel
          control={
            <Checkbox
              checked={retryInterval !== null}
              color="secondary"
              onChange={toggleCheckbox('retryInterval')}
            />
          }
          label={
            <span className="text-info-dark text-sm font-medium">
              Automatic retry interval
            </span>
          }
        />
        <div className="flex flex-row">
          <Input
            InputProps={{ className: 'w-24 mr-4', inputMode: 'numeric' }}
            disabled={retryInterval === null}
            floatingLabel
            id="retry-days"
            label="Days"
            onChange={updateDuration('days')}
            type="number"
            value={duration.days}
          />
          <Input
            InputProps={{ className: 'w-24 mr-4', inputMode: 'numeric' }}
            disabled={retryInterval === null}
            floatingLabel
            id="retry-hours"
            label="Hours"
            onChange={updateDuration('hours')}
            type="number"
            value={duration.hours}
          />
          <Input
            InputProps={{ className: 'w-24', inputMode: 'numeric' }}
            disabled={retryInterval === null}
            error={durationHasError}
            floatingLabel
            id="retry-minutes"
            label="Minutes"
            onChange={updateDuration('minutes')}
            type="number"
            value={duration.minutes}
          />
        </div>
      </div>
      {durationHasError ? (
        <p className="text-red-500 text-sm font-normal mt-3">
          Automatic retry interval time must be at least 5 minutes.
        </p>
      ) : null}
      <div className="mt-8">
        <FormControlLabel
          control={
            <Checkbox
              checked={maxRunLength !== null}
              color="secondary"
              onChange={toggleCheckbox('maxRunLength')}
            />
          }
          label={
            <span className="text-info-dark text-sm font-medium">
              Schedule automatic termination if the job does not succeed after
            </span>
          }
        />
        <Input
          InputProps={{ className: 'w-24', inputMode: 'numeric' }}
          disabled={maxRunLength === null}
          error={maxRunLength !== null && Number(maxRunLength) === 0}
          floatingLabel
          id="max-run-length"
          label="Days"
          onChange={updateSettings('maxRunLength')}
          type="number"
          value={maxRunLength}
        />
      </div>
      {maxRunLength !== null && Number(maxRunLength) === 0 ? (
        <p className="text-red-500 text-sm font-normal mt-3">
          Scheduled automatic termination must be at least after 1 day.
        </p>
      ) : null}
      <div className="mt-8">
        <FormControlLabel
          control={
            <Checkbox
              checked={maxAttempts !== null}
              color="secondary"
              onChange={toggleCheckbox('maxAttempts')}
            />
          }
          label={
            <span className="text-info-dark text-sm font-medium">
              Terminate when consecutive job execution fail count reaches
            </span>
          }
        />
        <Input
          InputProps={{ className: 'w-24', inputMode: 'numeric' }}
          disabled={maxAttempts === null}
          error={maxAttempts !== null && Number(maxAttempts) === 0}
          floatingLabel
          id="retry-count"
          label="Fail times"
          onChange={updateSettings('maxAttempts')}
          type="number"
          value={maxAttempts}
        />
      </div>
      {maxAttempts !== null && Number(maxAttempts) === 0 ? (
        <p className="text-red-500 text-sm font-normal mt-3">
          Number of failures to trigger termination must be at least 1
        </p>
      ) : null}
      <div className="flex-row justify-between gap-4 mt-14">
        <Button
          className="!mr-4"
          color="secondary"
          disabled={disableSaveButton}
          onClick={onSave}
          variant="contained"
        >
          SAVE CHANGES
        </Button>
        <Button
          color="secondary"
          disabled={!hasUpdated}
          onClick={onCancel}
          variant="outlined"
        >
          CANCEL
        </Button>
      </div>
    </div>
  );
}
