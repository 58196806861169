import {
  Button,
  Chip,
  Typography,
  Flex,
  ContentContainer,
  ScrollableContainer,
  Spinner,
} from 'ui-kit';
import { AddBoxOutlined } from 'assets-shared';
import { DataGrid } from '@mui/x-data-grid';
import { Toolbar } from 'dashboard-shared';
import { useFetchDatasourceTable } from './hooks';
import { useFetchWorkflowMetadata } from '../Workflows/hooks';
import { EditorStore } from '../Editor/store/EditorState';
import { useNavigate, useParams } from 'react-router-dom';

interface Props {
  datasourceId: string;
  fileName?: string;
  onBack: () => void;
  onSubmit?: () => void;
  isSubmitDisabled?: boolean;
  previewMode?: boolean;
}

export default function DatasourcePreviewTable({
  datasourceId,
  fileName,
  onBack,
  onSubmit,
  isSubmitDisabled,
  previewMode = false,
}: Props) {
  const navigate = useNavigate();
  const { workflowId } = useParams<{ workflowId: string }>();
  const { setDatasourceTable } = EditorStore();
  const { data: workflowMetadata } = useFetchWorkflowMetadata(workflowId || '');
  const { data: tableData, isFetching } = useFetchDatasourceTable(
    datasourceId,
    true,
  );
  const handleOnSubmit = () => {
    if (!tableData) {
      throw new Error('Table data not found');
    }

    if (onSubmit) {
      onSubmit();
      setDatasourceTable(tableData);
    }
  };

  return (
    <ScrollableContainer>
      <Toolbar
        className="flex items-center justify-between"
        onBack={onBack}
        pageInfo="Datasource preview"
        title={workflowMetadata?.workflowName}
      >
        <Flex className="gap-4">
          {previewMode ? (
            <>
              <Button
                className="!min-w-36"
                color="secondary"
                onClick={onBack}
                variant="outlined"
              >
                Back
              </Button>
              <Button
                color="secondary"
                disabled={isSubmitDisabled || !tableData?.rowData.length}
                onClick={handleOnSubmit}
                variant="contained"
              >
                Finish and connect
              </Button>
            </>
          ) : null}
        </Flex>
      </Toolbar>

      <ContentContainer withToolbar>
        {isFetching || tableData === null || tableData === undefined ? (
          <Spinner />
        ) : (
          <>
            <Flex
              alignItems="center"
              className="flex-1"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="h4">{fileName}</Typography>
              {tableData.rowData.length && !previewMode ? (
                <Button
                  className="!min-w-36"
                  color="secondary"
                  onClick={() => {
                    navigate(`/datasource/${workflowId}`);
                  }}
                  startIcon={
                    <AddBoxOutlined className="!w-5 !h-5 !text-white" />
                  }
                  variant="contained"
                >
                  Replace Datasource
                </Button>
              ) : null}
            </Flex>
            <div className="mt-2">
              <span className="font-normal text-sm">
                This is a preview of your datasource. Please ensure that your
                data is formatted such that each row represents a new run for
                your automation.
              </span>
            </div>
            {fileName ? (
              <div className="mt-6 p-4 rounded-lg bg-color-light-blue">
                <Chip
                  className="!h-6"
                  color="default"
                  label={fileName}
                  variant="filled"
                />
              </div>
            ) : null}
            <div className="mt-11">
              {tableData.rowData.length ? (
                <DataGrid
                  columns={tableData.columnDefinitions.map((item) => {
                    return { flex: 1, minWidth: 150, ...item };
                  })}
                  rows={tableData.rowData.map((item, i) => {
                    return { id: i, ...item };
                  })}
                />
              ) : (
                <div className="flex flex-col items-center justify-between mt-11">
                  <p className="font-medium text-lg pb-2">No data found!</p>
                  {previewMode ? (
                    <>
                      <p className="font-normal text-sm pb-6">
                        We couldn't find any data in the uploaded file.
                        Double-check your CSV and try uploading again.
                      </p>
                      <Button
                        color="secondary"
                        onClick={onBack}
                        variant="contained"
                      >
                        Reupload CSV
                      </Button>
                    </>
                  ) : null}
                </div>
              )}
            </div>
          </>
        )}
      </ContentContainer>
    </ScrollableContainer>
  );
}
