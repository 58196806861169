import isNil from 'lodash/isNil';
import { useNavigate } from 'react-router-dom';
import type { WorkflowNodeProps } from 'types-shared';
import { useStore } from 'types-shared/reactflow';

import { EditorStore } from '../../store/EditorState';
import { minZoomLevelForFullContent } from '../../utils/constants';
import NodeElement from './index';
import { APICallNodeCore, DatasourceNodeCore } from 'editor-shared';
import { TriggerTypeEnum } from 'types-shared';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { FeatureFlag } from 'dashboard-shared';

const zoomSelector = (s: { transform: number[] }) =>
  s.transform[2] >= minZoomLevelForFullContent;

export default function DatasourceNode(workflowData: WorkflowNodeProps) {
  const showFullContent = useStore(zoomSelector);
  const { datasourceMetadata, workflowId, tableData, triggerType } =
    EditorStore();

  const datasourceEnabled = useFeatureFlagEnabled(FeatureFlag.Datasource);
  const isAPITrigger = datasourceEnabled
    ? triggerType === TriggerTypeEnum.API
    : true;

  const navigate = useNavigate();

  const openDatasourcePage = () => {
    navigate(`/datasource/${workflowId}`);
  };

  const handleNodeClick = () => {
    if (isAPITrigger) {
      navigate(`/api-trigger-settings/${workflowId}`);
    } else {
      if (isNil(datasourceMetadata)) {
        openDatasourcePage();
        return;
      }
      navigate(`/datasource/${workflowId}/preview`);
    }
  };

  return (
    <NodeElement onClick={handleNodeClick} workflowData={workflowData}>
      {isAPITrigger ? (
        <APICallNodeCore />
      ) : (
        <DatasourceNodeCore
          datasourceMetadata={datasourceMetadata}
          openDatasourcePage={openDatasourcePage}
          showFullContent={showFullContent}
          tableData={tableData}
        />
      )}
    </NodeElement>
  );
}
