import type { EventMap } from 'ui-kit';
import { createEventBus } from 'ui-kit';

export const problemTypes: string[] = [
  'General Issue',
  'Recording Issue',
  'Execution Issue',
  'Incorrect Action',
];

export interface ContactModalEvents extends EventMap {
  open: (problemType?: string) => void;
}

export const contactModalEventChannel = createEventBus<ContactModalEvents>();

export interface FormValues {
  problemType: string;
  file: File | null;
  description: string;
}

export const defaultFormValue: FormValues = {
  problemType: 'General Issue',
  file: null,
  description: '',
};
