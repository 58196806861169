import React from 'react';
import { Button, Flex, SolaModal, Typography } from 'ui-kit';
import { contactModalEventChannel } from '../../ContactModalTypes';

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function ActionsHandledBySolaModal({ open, onClose }: Props) {
  const onClickContactUs = () => {
    contactModalEventChannel.emit('open', 'General Issue');
    onClose();
  };

  return (
    <SolaModal className="!max-w-3xl" onClose={onClose} open={open}>
      <Typography className="font-bold" variant="h5">
        Actions handled by Sola
      </Typography>
      <div className="mt-8">
        <Typography className="!mt-4 !text-info-dark">
          Our team can take care of some manually handled actions for you,
          including Captchas and basic approvals.
        </Typography>

        <Typography className="!mt-4 !text-info-dark">
          Chat with us to setup the timing and pricing that works for you!
        </Typography>
      </div>
      <Flex className="gap-4 mt-9">
        <Button
          color="secondary"
          onClick={onClickContactUs}
          variant="contained"
        >
          Contact Us
        </Button>
        <Button color="secondary" onClick={onClose} variant="text">
          Cancel
        </Button>
      </Flex>
    </SolaModal>
  );
}
