import React, { useState } from 'react';
import type { WorkflowAction, WorkflowActionsOptions } from 'types-shared';
import {
  IconButton,
  Menu,
  MenuItem,
  PersonIcon,
  HideEyeIcon,
  QuestionAnswerIcon,
  DesktopIcon,
  EyeIcon,
  DeleteIcon,
  EditIcon,
} from 'ui-kit';
import {
  MoreVert,
  LowPriority,
  Logo,
  // ArrowCircleDownOutlined,
  // ArrowCircleUpOutlined,
  Publish,
  ClosedCaption,
  List,
  PhonelinkLock,
} from 'assets-shared';

import { contactModalEventChannel } from '../../ContactModalTypes';
import ActionsHandledBySolaModal from './ActionsHandledBySolaModal';

interface Props {
  action: WorkflowAction;
  allowDeleteAction: boolean;
  onEditTarget?: () => void;
  onMoveAction?: () => void;
  onDeleteAction?: () => void;
  onAddApprovalStep?: (position: 'before' | 'after') => void;
  onUpdateActionOptions: (
    action: WorkflowAction,
    options: WorkflowActionsOptions,
  ) => void;
  showAdminToggles?: boolean;
  showManualHandleOption?: boolean;
}

function ActionMenu({
  action,
  allowDeleteAction,
  onUpdateActionOptions,
  onEditTarget,
  onDeleteAction,
  onMoveAction,
  showAdminToggles,
  showManualHandleOption,
}: Props) {
  const [modalOpen, setModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const {
    options: {
      hidden,
      hitl,
      sitl,
      adminOnly,
      submitted,
      captcha,
      chooseFromList,
      mfa,
      adminManual,
    } = {},
  } = action;
  const HideIcon = hidden ? EyeIcon : HideEyeIcon;
  const AdminIcon = adminOnly ? EyeIcon : HideEyeIcon;
  const SkipIcon = hitl ? DesktopIcon : PersonIcon;

  const isMfaOrCaptcha = mfa || captcha;

  const onClose = () => {
    setAnchorEl(null);
  };

  const toggleActionOption = (name: keyof WorkflowActionsOptions) => {
    const exclusive = [
      'hidden',
      'adminManual',
      'adminOnly',
      'sitl',
      'hitl',
    ].includes(name);
    let newHitl =
      name === 'hitl' ? !action.options?.hitl : action.options?.hitl;
    let newSitl =
      name === 'sitl' ? !action.options?.sitl : action.options?.sitl;

    if (name === 'captcha' || name === 'mfa') {
      newSitl = !action.options?.[name];
      setModalOpen(!action.options?.[name]);
    }

    if (name === 'hitl') {
      newSitl = false;
    }

    if (name === 'sitl') {
      newHitl = false;
    }
    onUpdateActionOptions(action, {
      ...(exclusive ? action.options : {}),
      hidden: action.options?.hidden,
      adminManual: action.options?.adminManual,
      [name]: !action.options?.[name],
      hitl: newHitl,
      sitl: newSitl,
    });
    onClose();
  };

  return (
    <>
      <IconButton
        className="!p-0"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setAnchorEl(e.target as HTMLButtonElement);
        }}
      >
        <MoreVert className="!w-5 !h-5 text-black" />
      </IconButton>
      <Menu
        BackdropProps={{
          style: {
            backgroundColor: 'transparent',
          },
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClose();
        }}
        onClose={onClose}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {!isMfaOrCaptcha && showManualHandleOption ? (
          <MenuItem
            className="min-w-64 h-8 !px-4"
            onClick={() => {
              toggleActionOption('hitl');
            }}
          >
            <span className="font-normal mr-4">
              {hitl ? 'Handle action automatically' : 'Handle action manually'}
            </span>
            <SkipIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
          </MenuItem>
        ) : null}

        {isMfaOrCaptcha && sitl && showManualHandleOption ? (
          <MenuItem
            className="min-w-64 h-8 !px-4"
            onClick={() => {
              toggleActionOption('hitl');
            }}
          >
            <span className="font-normal mr-4">Handle action manually</span>
            <PersonIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
          </MenuItem>
        ) : null}

        {!sitl ? (
          <MenuItem
            className="min-w-64 h-8 !px-4"
            onClick={() => {
              toggleActionOption('sitl');
              setModalOpen(true);
            }}
          >
            <span className="font-normal mr-4">
              I want Sola to handle this action
            </span>
            <Logo className="!w-5 !h-5 !ml-auto" />
          </MenuItem>
        ) : null}

        {showAdminToggles ? (
          <MenuItem
            className="min-w-64 h-8 !px-4"
            onClick={() => {
              toggleActionOption('adminManual');
            }}
          >
            <span className="font-normal mr-4">
              {adminManual
                ? 'Unmark as Manually handled by Admin'
                : 'Mark as Manually handled by Admin'}
            </span>
            <SkipIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
          </MenuItem>
        ) : null}

        <MenuItem
          className="min-w-64 h-8 !px-4"
          onClick={() => {
            toggleActionOption('hidden');
          }}
        >
          <span className="font-normal">{hidden ? 'Show' : 'Hide'} Action</span>
          <HideIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
        </MenuItem>
        <MenuItem
          className="min-w-64 h-8 !px-4"
          onClick={() => {
            onClose();
            contactModalEventChannel.emit('open', 'Incorrect Action');
          }}
        >
          <span className="font-normal">Report Inconsistency</span>
          <QuestionAnswerIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
        </MenuItem>
        {onEditTarget ? (
          <MenuItem
            className="min-w-64 h-8 !px-4"
            onClick={() => {
              onClose();
              onEditTarget();
            }}
          >
            <span className="font-normal">Edit Target</span>
            <EditIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
          </MenuItem>
        ) : null}
        {allowDeleteAction ? (
          <MenuItem
            className="min-w-64 h-8 !px-4"
            onClick={() => {
              onClose();
              onDeleteAction?.();
            }}
          >
            <span className="font-normal">Delete Action</span>
            <DeleteIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
          </MenuItem>
        ) : null}
        {onMoveAction ? (
          <MenuItem
            className="min-w-64 h-8 !px-4"
            onClick={() => {
              onClose();
              onMoveAction();
            }}
          >
            <span className="font-normal">Move Action</span>
            <LowPriority className="!w-5 !h-5 !ml-auto !text-info-dark" />
          </MenuItem>
        ) : null}
        {showAdminToggles ? (
          <MenuItem
            className="min-w-64 h-8 !px-4"
            onClick={() => {
              toggleActionOption('adminOnly');
            }}
          >
            <span className="font-normal">
              {adminOnly ? 'Show Action To' : 'Hide Action From'} Users
            </span>
            <AdminIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
          </MenuItem>
        ) : null}

        {showAdminToggles ? (
          <MenuItem
            className="min-w-64 h-8 !px-4"
            onClick={() => {
              toggleActionOption('submitted');
            }}
          >
            <span className="font-normal">
              {submitted ? 'Unmark as Submit' : 'Mark as Submit'}
            </span>
            <Publish className="!w-5 !h-5 !ml-auto !text-info-dark" />
          </MenuItem>
        ) : null}
        {showAdminToggles ? (
          <MenuItem
            className="min-w-64 h-8 !px-4"
            onClick={() => {
              toggleActionOption('captcha');
            }}
          >
            <span className="font-normal">
              {captcha ? 'Unmark as Captcha' : 'Mark as Captcha'}
            </span>
            <ClosedCaption className="!w-5 !h-5 !ml-auto !text-info-dark" />
          </MenuItem>
        ) : null}
        {showAdminToggles ? (
          <MenuItem
            className="min-w-64 h-8 !px-4"
            onClick={() => {
              toggleActionOption('chooseFromList');
            }}
          >
            <span className="font-normal">
              {chooseFromList
                ? 'Unmark as Choose from List'
                : 'Mark as Choose from List'}
            </span>
            <List className="!w-5 !h-5 !ml-auto !text-info-dark" />
          </MenuItem>
        ) : null}
        {showAdminToggles ? (
          <MenuItem
            className="min-w-64 h-8 !px-4"
            onClick={() => {
              toggleActionOption('mfa');
            }}
          >
            <span className="font-normal">
              {mfa ? 'Unmark as MFA' : 'Mark as MFA'}
            </span>
            <PhonelinkLock className="!w-5 !h-5 !ml-auto !text-info-dark" />
          </MenuItem>
        ) : null}

        {/*{onAddApprovalStep ? (*/}
        {/*  <MenuItem*/}
        {/*    className="min-w-64 h-8 !px-4"*/}
        {/*    onClick={() => {*/}
        {/*      onClose();*/}
        {/*      onAddApprovalStep('before');*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <span className="font-normal">Add an approval step before</span>*/}
        {/*    <ArrowCircleUpOutlined className="!w-5 !h-5 !ml-auto !text-info-dark" />*/}
        {/*  </MenuItem>*/}
        {/*) : null}*/}
        {/*{onAddApprovalStep ? (*/}
        {/*  <MenuItem*/}
        {/*    className="min-w-64 h-8 !px-4"*/}
        {/*    onClick={() => {*/}
        {/*      onClose();*/}
        {/*      onAddApprovalStep('after');*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <span className="font-normal">Add an approval step after</span>*/}
        {/*    <ArrowCircleDownOutlined className="!w-5 !h-5 !ml-auto !text-info-dark" />*/}
        {/*  </MenuItem>*/}
        {/*) : null}*/}
      </Menu>
      <ActionsHandledBySolaModal
        onClose={() => {
          setModalOpen(false);
        }}
        open={modalOpen}
      />
    </>
  );
}

export default ActionMenu;
