import { clsx } from 'clsx';
import isEmpty from 'lodash/isEmpty';
import type { ReactNode } from 'react';
import React from 'react';

import Label from './Label';

interface Props {
  className?: string;
  id?: string;
  label?: ReactNode | string;
  required?: boolean;
  children?: ReactNode;
  error?: boolean;
  errorText?: string;
  showErrorText?: boolean;
  floatingLabel?: boolean;
}

export default function Wrapper({
  className,
  id,
  label,
  required,
  children,
  error,
  errorText,
  showErrorText = true,
  floatingLabel = false,
}: Props) {
  const classes = clsx(
    'flex flex-col space-y-1',
    { 'relative pt-1': floatingLabel },
    className,
  );

  return (
    <div className={classes}>
      {typeof label === 'string' ? (
        <Label
          error={error}
          floatingLabel={floatingLabel}
          id={id}
          label={label}
          required={required}
        />
      ) : (
        label
      )}
      <div className="w-full relative">{children}</div>
      {!isEmpty(error) && showErrorText ? (
        <span className="text-xs text-red-500 font-medium">
          {errorText ?? 'Invalid Entry'}
        </span>
      ) : null}
    </div>
  );
}
