"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Comparator = exports.DateComparator = exports.DateComparatorEnum = exports.NumberComparator = exports.NumberComparatorEnum = exports.StringComparator = exports.StringComparatorEnum = void 0;
var zod_1 = require("zod");
var StringComparatorEnum;
(function (StringComparatorEnum) {
    StringComparatorEnum["Is"] = "Is";
    StringComparatorEnum["IsNot"] = "IsNot";
    StringComparatorEnum["Contains"] = "Contains";
    StringComparatorEnum["NotContains"] = "NotContains";
    StringComparatorEnum["Exists"] = "Exists";
    StringComparatorEnum["DoesNotExist"] = "DoesNotExist";
})(StringComparatorEnum || (exports.StringComparatorEnum = StringComparatorEnum = {}));
exports.StringComparator = zod_1.z.nativeEnum(StringComparatorEnum);
var NumberComparatorEnum;
(function (NumberComparatorEnum) {
    NumberComparatorEnum["Equal"] = "Equal";
    NumberComparatorEnum["NotEqual"] = "NotEqual";
    NumberComparatorEnum["GreaterThan"] = "GreaterThan";
    NumberComparatorEnum["GreaterThanOrEqual"] = "GreaterThanOrEqual";
    NumberComparatorEnum["LessThan"] = "LessThan";
    NumberComparatorEnum["LessThanOrEqual"] = "LessThanOrEqual";
})(NumberComparatorEnum || (exports.NumberComparatorEnum = NumberComparatorEnum = {}));
exports.NumberComparator = zod_1.z.nativeEnum(NumberComparatorEnum);
var DateComparatorEnum;
(function (DateComparatorEnum) {
    DateComparatorEnum["On"] = "On";
    DateComparatorEnum["NotOn"] = "NotOn";
    DateComparatorEnum["After"] = "After";
    DateComparatorEnum["AfterOrOn"] = "AfterOrOn";
    DateComparatorEnum["Before"] = "Before";
    DateComparatorEnum["BeforeOrOn"] = "BeforeOrOn";
})(DateComparatorEnum || (exports.DateComparatorEnum = DateComparatorEnum = {}));
exports.DateComparator = zod_1.z.nativeEnum(DateComparatorEnum);
exports.Comparator = zod_1.z.union([
    exports.StringComparator,
    exports.NumberComparator,
    exports.DateComparator,
]);
