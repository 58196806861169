import isNil from 'lodash/isNil';

export const getCaretPosition = () => {
  const selection = window.getSelection();
  const range = selection?.getRangeAt(0);
  const cursorAtStart = range?.startOffset === 0;
  const cursorAtEnd =
    range?.endOffset === range?.endContainer.textContent?.length;

  return {
    cursorAtStart,
    cursorAtEnd,
  };
};

export const setCaretPosition = (
  element: Element,
  position: 'start' | 'end',
) => {
  const range = document.createRange();
  const selection = window.getSelection();
  if (selection) {
    if (position === 'start') {
      range.setStart(element, 0);
      range.collapse(true);
    } else {
      const lastChild = element.lastChild;
      if (lastChild) {
        range.setStart(lastChild, (lastChild.textContent || '').length);
        range.collapse(true);
      }
    }
    selection.removeAllRanges();
    selection.addRange(range);
  }
};

export const focusItemAfterDelete = (
  containerEl: HTMLElement | null,
  index: number,
) => {
  setTimeout(() => {
    const inputs = Array.from(
      containerEl?.querySelectorAll('div[contenteditable]') ?? [],
    );
    const leftInput = inputs[index - 1];
    const rightInput = inputs[index];

    if (!isNil(leftInput)) {
      (leftInput as HTMLElement).focus();
      setCaretPosition(leftInput, 'end');
      return;
    }

    if (!isNil(rightInput)) {
      (rightInput as HTMLElement).focus();
      setCaretPosition(rightInput, 'start');
    }
  }, 0);
};

export const focusInput = (
  containerEl: Element | null,
  position: 'left' | 'right',
) => {
  setTimeout(() => {
    const inputs = Array.from(
      containerEl?.querySelectorAll('div[contenteditable]') ?? [],
    );

    const focusedElement = document.activeElement;
    if (!focusedElement) return;
    const focusedIndex = inputs.indexOf(focusedElement);

    const inputToFocus =
      position === 'left' ? inputs[focusedIndex - 1] : inputs[focusedIndex + 1];

    if (isNil(inputToFocus)) {
      return;
    }

    (inputToFocus as HTMLElement).focus();

    setCaretPosition(inputToFocus, position === 'left' ? 'end' : 'start');
  }, 0);
};
