import { sendToBackgroundViaRelay } from '@plasmohq/messaging';
import * as Sentry from '@sentry/react';
import type { Integration } from '@sentry/types';
import { usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';
import { defaultBrowserOptions } from 'sentry-browser-shared';
import { useAuth0, useEnvironment } from 'ui-kit';

/**
 * Sets up analytics. Only call this file ONCE.
 */
export const useAnalytics = (): void => {
  const { selectedEnv } = useEnvironment();

  // Google Analytics
  // useEffect(() => {
  //   window.gtag?.('event', 'page_view', {
  //     page_path: location.pathname + location.search,
  //     page_location: location.key ?? window.location.href,
  //     page_title: document.title,
  //   });
  // }, [location.key, location.pathname, location.search]);
  const { user } = useAuth0();

  const posthog = usePostHog();

  useEffect(() => {
    // TODO(michael): Set Sentry user to userId after auth
    Sentry.getCurrentScope().setUser(null);
  }, []);

  useEffect(() => {
    Sentry.setTag('environment', selectedEnv);
    Sentry.setUser({ ...user });
  }, [selectedEnv, user]);

  useEffect(() => {
    if (user) {
      posthog.identify(user.email, user);
      if (user.email) {
        posthog.group('domain', user.email.split('@')[1], {});
      }
      void sendToBackgroundViaRelay({
        name: 'onDashboardSync.handler' as never,
        body: {
          userData: {
            id: user.email,
            name: user.name,
            email: user.email,
          },
          logout: false,
        },
      });
    }
  }, [posthog, user]);

  useEffect(() => {
    if (process.env.REACT_APP_SENTRY_DSN) {
      const sentryCfg = defaultBrowserOptions();
      Sentry.init({
        tracesSampleRate: 0.2,
        release: selectedEnv,
        ...sentryCfg,
        integrations: [
          ...(sentryCfg.integrations as Integration[]),
          new posthog.SentryIntegration(
            posthog,
            'sola-solutions',
            Number(sentryCfg.dsn?.split('/').pop() ?? 0), // The last part of the DSN is the project ID
          ),
        ],
      });
    }
  }, [posthog, selectedEnv]);
};
