import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import React from 'react';
import { ErrorFallbackUI } from './ErrorFallbackUI';

export function ErrorBoundary({ children }: { children: React.ReactNode }) {
  return (
    <ReactErrorBoundary FallbackComponent={ErrorFallbackUI}>
      {children}
    </ReactErrorBoundary>
  );
}
