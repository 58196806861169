import type { AxiosResponse } from 'api-types-shared';
import { axios } from 'api-types-shared';
import intersection from 'lodash/intersection';

export const uploadBlobToS3 = async (
  blob: Blob,
  url: string,
): Promise<AxiosResponse> => {
  return axios.put(url, blob, {
    headers: {
      'Content-Type': blob.type,
      'Content-Length': blob.size.toString(),
    },
  });
};

export const compareArrays = (arr1: unknown[], arr2: unknown[]) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  const commonEls = intersection(arr1, arr2);
  return commonEls.length === arr1.length;
};

export const getUrlFromBlob = (blob: Blob): string => URL.createObjectURL(blob);

export const getBlobFromS3 = async (url: string): Promise<Blob> => {
  const response = await axios.get(url, {
    responseType: 'blob',
  });
  return new Blob([response.data]);
};

export const blobToDataUrl = async (blob: Blob): Promise<string> => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = () => {
      const dataUrl = reader.result;
      if (typeof dataUrl === 'string') {
        resolve(dataUrl);
      } else {
        reject(new Error('No dataUrl found'));
      }
    };
    reader.readAsDataURL(blob);
  });
};

export function dataUrlToBlob(
  dataUrl: string,
  contentType = 'application/octet-stream',
): Blob {
  const base64 = dataUrl.split(',')[1];
  const sliceSize = 512;
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
}
