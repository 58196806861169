import 'types-shared/reactflow/dist/style.css';

import { clsx } from 'clsx';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import type {
  EdgeTypes,
  NodeTypes,
  ReactFlowInstance,
} from 'types-shared/reactflow';
import {
  Background,
  ConnectionLineType,
  ReactFlow,
  ReactFlowProvider,
  SelectionMode,
} from 'types-shared/reactflow';
import { Spinner, useEnvironment } from 'ui-kit';
import { useShallow } from 'zustand/react/shallow';

import {
  useFetchDatasourceTable,
  useGetDatasourceForWorkflow,
} from '../Datasource/hooks';
import Container from './components/Container';
import CustomEdge from './components/EdgeElement/CustomEdge';
import DatasourceNode from './components/NodeElement/DatasourceNode';
import ImageNode from './components/NodeElement/ImageNode';
import PlaceholderNode from './components/NodeElement/PlaceholderNode';
import Toolbar from './components/Toolbar';
import {
  useAutolinkTaskPoller,
  useGetAllThumbnails,
  useGetRefData,
  useGetWorkflowData,
  useSendSlackMessage,
  useTransformData,
  useUpdateStoreAutolinkData,
  useUpdateWorkflow,
} from './hooks';
import type { EditorStoreProps } from './store/EditorState';
import { EditorStore } from './store/EditorState';
import {
  CONTACT_SLACK_CHANNEL_ID,
  editorMaxZoomLevel,
  editorMinZoomLevel,
} from './utils/constants';
import ActionView from './components/ActionView';
import ActionsHeader from './components/ActionsHeader';
import type { WorkflowImageNode, WorkflowNode } from 'types-shared';
import { NodeStatusEnum, NodeTypesEnum, TriggerTypeEnum } from 'types-shared';
import { useFetchWorkflowMetadata } from '../Workflows/hooks';
import { WorkflowStatusEnum } from 'api-types-shared';
import ConditionalNode from './components/NodeElement/ConditionalNode';
import FreeformNode from './components/NodeElement/FreeformNode';
import {
  appendDatasourceNode,
  ContactModal,
  contactModalEventChannel,
  EditNodePanel,
  FlowViewControls,
  type FormValues,
  useEditingNodeId,
} from 'editor-shared';
import Connector from './components/EdgeElement/Connector';
import usePrompt from '../../hooks/usePrompt';

import { ExecutionRunner } from 'execution-shared';
import DocumentNode from './components/NodeElement/DocumentNode';
import EndingNode from './components/NodeElement/EndingNode';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { FeatureFlag } from 'dashboard-shared';
import { useQueryClient } from '@tanstack/react-query';

const nodeTypes: NodeTypes = {
  image: ImageNode,
  datasource: DatasourceNode,
  new: PlaceholderNode,
  conditional: ConditionalNode,
  freeform: FreeformNode,
  document: DocumentNode,
  ending: EndingNode,
};

const BulkCheckableNodeTypes = [
  NodeTypesEnum.Image,
  NodeTypesEnum.Freeform,
  NodeTypesEnum.Conditional,
  NodeTypesEnum.Ending,
];

const edgeTypes: EdgeTypes = {
  default: CustomEdge,
};
const DEFAULT_ZOOM = 0.7;

function Editor(): JSX.Element {
  const { workflowId } = useParams();
  if (!workflowId) {
    throw new Error('Workflow ID not provided');
  }

  const queryClient = useQueryClient();
  const { selectedEnv } = useEnvironment();

  const { data: workflowMetadata } = useFetchWorkflowMetadata(workflowId);
  const navigate = useNavigate();

  const {
    nodes,
    edges,
    datasourceMetadata,
    tableData,
    setNodes,
    setEdges,
    updateNode,
    addNodes,
    setWorkflowId,
    resetWorkflow,
    setTargets,
    resetTargets,
    setVariables,
    resetVariables,
    setDatasourceMetadata,
    setDatasourceTable,
    resetDatasource,
    setThumbnails,
    onNodesChange,
    onEdgesChange,
    onConnect,
    selectedNode,
    setSelectedNode,
    variables: variablesMap,
    targets,
    updateVariable,
    addVariable,
    triggerType,
    setTriggerType,
  } = EditorStore(
    useShallow((state: EditorStoreProps) => ({
      selectedNode: state.selectedNode,
      setSelectedNode: state.setSelectedNode,
      nodes: state.nodes,
      edges: state.edges,
      datasourceMetadata: state.datasourceMetadata,
      tableData: state.tableData,

      setNodes: state.setNodes,
      setEdges: state.setEdges,
      setWorkflowId: state.setWorkflowId,
      resetWorkflow: state.resetWorkflow,

      updateNode: state.updateNode,
      addNodes: state.addNodes,

      setTargets: state.setTargets,
      resetTargets: state.resetTargets,

      setVariables: state.setVariables,
      resetVariables: state.resetVariables,

      setThumbnails: state.setThumbnails,

      setDatasourceMetadata: state.setDatasourceMetadata,
      setDatasourceTable: state.setDatasourceTable,
      resetDatasource: state.resetDatasource,

      onNodesChange: state.onNodesChange,
      onEdgesChange: state.onEdgesChange,
      onConnect: state.onConnect,
      variables: state.variables,
      targets: state.targets,
      updateVariable: state.updateVariable,
      addVariable: state.addVariable,
      triggerType: state.triggerType,
      setTriggerType: state.setTriggerType,
    })),
  );
  const { mutateAsync: updateWorkflow } = useUpdateWorkflow();

  const hasPersistedData = workflowId
    ? Boolean(localStorage.getItem(workflowId))
    : false;
  const { editingNodeId, setEditingNodeId } = useEditingNodeId();

  useEffect(() => {
    if (EditorStore.persist.getOptions().name !== workflowId) {
      EditorStore.persist.setOptions({
        name: 'root',
      });

      resetWorkflow();
      resetTargets();
      resetVariables();
      resetDatasource();

      void EditorStore.persist.rehydrate();

      EditorStore.persist.setOptions({
        name: workflowId,
        skipHydration: false,
      });
    }
  }, [
    workflowId,
    setNodes,
    setWorkflowId,
    resetWorkflow,
    resetTargets,
    resetVariables,
    resetDatasource,
  ]);

  const reactFlowRef = useRef<HTMLDivElement>(null);
  const [navMode, setNavMode] = useState<'pan' | 'trackpad'>('pan');
  usePrompt({
    when: (args) => {
      return args.nextLocation.pathname.startsWith('/api-trigger-settings');
    },
    message:
      'This workflow is saved on your device. To maintain these changes across devices and run the workflow remotely, you must save your workflow changes to the cloud.',
    onLeave: (blocker) => {
      if (selectedNode) {
        setSelectedNode(null);
      } else if (editingNodeId) {
        setEditingNodeId(undefined);
      } else {
        blocker.proceed();
      }
    },
    onSave: async () => {
      await updateWorkflow({
        workflowId,
        editorState: {
          nodes,
          edges,
          variables: variablesMap,
          targets,
          datasourceMetadata,
          tableData,
        },
      });
    },
  });

  const handleDefaultViewport = (instance: ReactFlowInstance) => {
    if (nodes.length > 0) {
      const firstNode = nodes[0];
      const nodeWidth = firstNode.width || 0;
      const nodeHeight = firstNode.height || 0;
      const x = -firstNode.position.x + window.innerWidth / 2 - nodeWidth / 2;
      const y = -firstNode.position.y + window.innerHeight / 2 - nodeHeight / 2;
      instance.setViewport({ x, y, zoom: DEFAULT_ZOOM });
    } else {
      instance.setViewport({ x: 0, y: 0, zoom: DEFAULT_ZOOM });
    }
  };

  const { data: workflowData, isFetching: isFetchingWorkflowData } =
    useGetWorkflowData(workflowId, hasPersistedData);

  const { data: nodeViewData, isFetching: isFetchingNodeData } = useGetRefData(
    workflowId,
    hasPersistedData,
  );

  const imageIds = useMemo(() => {
    const allNodes = workflowData?.nodes ?? nodes;
    return allNodes
      .filter((n): n is WorkflowImageNode => n.type === NodeTypesEnum.Image)
      .map((n) => n.data.imageData.imageId);
  }, [workflowData?.nodes, nodes]);
  const { data: allThumbnails, isFetching: isFetchingThumbnails } =
    useGetAllThumbnails(workflowId, imageIds);

  const onApplyChanges = () => {
    const filteredNodes = nodes.map((node) => {
      if (!BulkCheckableNodeTypes.includes(node.type)) return node;

      return {
        ...node,
        data: {
          ...node.data,
          selected: false,
          nodeStatus: (node.data as { selected: boolean }).selected
            ? NodeStatusEnum.Checked
            : NodeStatusEnum.NotViewed,
        },
      };
    }) as WorkflowNode[];
    setNodes(filteredNodes);
  };

  const onEnableSelectionMode = () => {
    const filteredNodes = nodes.map((node) => {
      if (!BulkCheckableNodeTypes.includes(node.type)) return node;

      return {
        ...node,
        data: {
          ...node.data,
          selected: node.data.nodeStatus === NodeStatusEnum.Checked, // select the checked nodes by default
        },
      };
    }) as WorkflowNode[];
    setNodes(filteredNodes);
  };

  const onCancelNodeSelection = () => {
    const filteredNodes = nodes.map((node) => ({
      ...node,
      data: {
        ...node.data,
        selected: false,
      },
    })) as WorkflowNode[];
    setNodes(filteredNodes);
  };

  useEffect(() => {
    if (allThumbnails) {
      setThumbnails(allThumbnails);
    }
  }, [allThumbnails, setThumbnails]);

  useEffect(() => {
    if (workflowData) {
      const updatedWorkflowData = appendDatasourceNode(workflowData);
      setNodes(updatedWorkflowData.nodes);
      setEdges(updatedWorkflowData.edges);
    }
  }, [workflowData, setNodes, setEdges, resetWorkflow]);

  useEffect(() => {
    if (nodeViewData) {
      setTargets(nodeViewData.targetData);
      setVariables(nodeViewData.variableData);
    }
  }, [nodeViewData, setTargets, setVariables, resetTargets, resetVariables]);

  const {
    data: datasourceMetadataResponse,
    isFetching: isFetchingDatasourceMetadata,
  } = useGetDatasourceForWorkflow(workflowId);

  useEffect(() => {
    const datasourceMeta = datasourceMetadataResponse?.at(0);
    if (datasourceMeta) {
      setDatasourceMetadata(datasourceMeta);
      setTriggerType(TriggerTypeEnum.Datasource);
    } else {
      setTriggerType(TriggerTypeEnum.API);
    }
  }, [datasourceMetadataResponse, setDatasourceMetadata, setTriggerType]);

  const {
    data: datasourceTableData,
    isFetching: isFetchingDatasourceTableData,
  } = useFetchDatasourceTable(
    datasourceMetadata?.datasourceId,
    Boolean(datasourceMetadata) && tableData === null,
  );

  useEffect(() => {
    if (datasourceTableData) {
      setDatasourceTable(datasourceTableData);
    }
  }, [datasourceTableData, setDatasourceTable]);

  const [autolinkTaskId, setAutolinkTaskId] = useState<string | undefined>();
  const { data: autolinkData } = useAutolinkTaskPoller(autolinkTaskId);
  useUpdateStoreAutolinkData(autolinkData?.data);

  const continueRecording = useCallback(
    (nodeId: string) => {
      if (workflowId) {
        const executionRunner = new ExecutionRunner(selectedEnv);
        void executionRunner.run({
          runType: 'LocalDebug',
          workflowId,
          recordIds: ['0'],
          workflowData: { nodes, edges, variables: variablesMap, targets },
          tableData,
          datasourceMetadata,
          debugSettings: {
            toNodeId: nodeId,
            directOpen: true,
          },
        });
      }
    },
    [
      workflowId,
      selectedEnv,
      nodes,
      edges,
      variablesMap,
      targets,
      tableData,
      datasourceMetadata,
    ],
  );

  useEffect(() => {
    if (
      autolinkData?.data.status &&
      ['finished', 'expired'].includes(autolinkData.data.status)
    ) {
      setAutolinkTaskId(undefined);
    }
  }, [autolinkData?.data]);

  useEffect(() => {
    if (workflowMetadata?.status === WorkflowStatusEnum.ProcessingImport) {
      navigate('/workflows');
    }
  }, [navigate, workflowMetadata]);

  useEffect(() => {
    return () => {
      void queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === 'workflowData' ||
          query.queryKey[0] === 'nodeData',
      });
    };
  }, [queryClient]);

  const { mutateAsync: transformData, status: transformDataStatus } =
    useTransformData();

  const onTransformData = useCallback(
    async (prompt: string, textToTransform: string) => {
      if (prompt && textToTransform) {
        const value = await transformData({
          data: textToTransform,
          prompt,
        });
        return value?.processedData;
      }
      return undefined;
    },
    [transformData],
  );

  const { mutateAsync: sendMessage, status: sendMessageStatus } =
    useSendSlackMessage();
  const { workflowId: editorWorkflowId } = EditorStore();

  const onSendMessage = useCallback(
    async (formValues: FormValues) => {
      if (editorWorkflowId) {
        await sendMessage({
          workflowId: editorWorkflowId,
          channelId: CONTACT_SLACK_CHANNEL_ID,
          text: `Type: ${formValues.problemType} | Description: ${formValues.description}`,
        });
      }
    },
    [sendMessage, editorWorkflowId],
  );

  const endingStatusBlockFeatureEnabled = useFeatureFlagEnabled(
    FeatureFlag.EndingStatusBlock,
  );
  const endingStatusBlockEnabled = useMemo(() => {
    if (!editingNodeId) {
      return endingStatusBlockFeatureEnabled;
    }
    return (
      !edges.some((e) => e.source === editingNodeId) &&
      endingStatusBlockFeatureEnabled
    );
  }, [edges, editingNodeId, endingStatusBlockFeatureEnabled]);

  return (
    <Container
      loading={
        isFetchingWorkflowData ||
        isFetchingNodeData ||
        isFetchingDatasourceMetadata ||
        isFetchingDatasourceTableData
      }
    >
      <ReactFlowProvider>
        <div className={clsx('flex-1 h-full w-full relative flex flex-col')}>
          <Toolbar
            autolinkLoading={Boolean(autolinkTaskId)}
            setAutolinkTaskId={setAutolinkTaskId}
            workflowId={workflowId}
            workflowName={workflowMetadata?.workflowName}
          />
          {isFetchingWorkflowData ||
          isFetchingNodeData ||
          isFetchingThumbnails ||
          isFetchingDatasourceMetadata ||
          isFetchingDatasourceTableData ? (
            <div className="w-full h-full flex items-center justify-center">
              <Spinner className="!text-black" size={32} />
            </div>
          ) : (
            <ReactFlow
              attributionPosition="top-right"
              className="relative"
              connectionLineComponent={Connector}
              connectionLineType={ConnectionLineType.Bezier}
              defaultEdgeOptions={{ deletable: false, focusable: false }}
              edgeTypes={edgeTypes}
              edges={edges}
              elementsSelectable={false}
              maxZoom={editorMaxZoomLevel}
              minZoom={editorMinZoomLevel}
              nodeTypes={nodeTypes}
              nodes={nodes}
              nodesConnectable={false}
              onConnect={onConnect}
              onEdgesChange={onEdgesChange}
              onInit={(instance) => {
                handleDefaultViewport(instance);
              }}
              onNodesChange={onNodesChange}
              panOnDrag={navMode !== 'trackpad'}
              panOnScroll={navMode === 'trackpad'}
              ref={reactFlowRef}
              selectionMode={SelectionMode.Partial}
              selectionOnDrag={navMode === 'trackpad'}
            >
              <Background className="bg-flow-view" />
              <ActionsHeader
                nodes={nodes}
                onApplyChanges={onApplyChanges}
                onCancel={onCancelNodeSelection}
                onEnableSelectionMode={onEnableSelectionMode}
              />
              {!selectedNode ? (
                <FlowViewControls
                  edges={edges}
                  navMode={navMode}
                  nodes={nodes}
                  openContactModal={() => {
                    contactModalEventChannel.emit('open');
                  }}
                  setNavMode={setNavMode}
                  setNodes={setNodes}
                />
              ) : null}
              {editingNodeId ? (
                <EditNodePanel
                  addNodes={addNodes}
                  addVariable={addVariable}
                  continueRecording={continueRecording}
                  datasourceMetadata={datasourceMetadata}
                  edges={edges}
                  endingStatusBlockEnabled={endingStatusBlockEnabled}
                  nodeId={editingNodeId}
                  nodes={nodes}
                  onCancel={() => {
                    setEditingNodeId(undefined);
                  }}
                  onTransformData={onTransformData}
                  setEdges={setEdges}
                  setNodes={setNodes}
                  tableData={tableData}
                  transformDataStatus={transformDataStatus}
                  triggerType={triggerType}
                  updateNode={updateNode}
                  updateVariable={updateVariable}
                  variablesMap={variablesMap}
                />
              ) : null}
            </ReactFlow>
          )}
        </div>
      </ReactFlowProvider>
      {selectedNode ? <ActionView /> : null}
      <ContactModal
        onSendMessage={onSendMessage}
        sendMessageStatus={sendMessageStatus}
        workflowId={editorWorkflowId}
      />
    </Container>
  );
}

export default Editor;
