import type { BlockerFunction } from 'react-router-dom';
import { useBlocker } from 'react-router-dom';
import React, { useEffect } from 'react';
import { dialogEventChannel } from 'ui-kit';

interface Props {
  when: boolean | BlockerFunction;
  message: string;
  onLeave?: (blocker: { proceed: () => void }) => void;
  onSave?: () => Promise<void>;
}

export default function usePrompt({ when, message, onLeave, onSave }: Props) {
  const blocker = useBlocker(when);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      dialogEventChannel.emit('open', {
        title: 'Save your workflow changes to cloud',
        confirmText: onSave ? 'SAVE CHANGES IN CLOUD' : 'Leave',
        cancelButtonVariant: 'text',
        confirmButtonVariant: 'outlined',
        closeButtonVariant: 'outlined',
        buttonColorType: 'secondary',
        actionClassName: 'justify-start !flex-row-reverse gap-4',
        titleClassName: 'mt-10',
        contentClassName: 'mb-4',
        onConfirm: async () => {
          dialogEventChannel.emit('close');
          if (onSave) {
            await onSave();
          }
          if (onLeave) {
            onLeave(blocker);
          } else {
            blocker.proceed();
          }
        },
        onCancel: () => {
          blocker.reset();
        },
        hideCloseBtn: false,
        showActions: true,
        children: (
          <p className="text-info-dark leading-7 text-base max-w-2xl">
            {message}
          </p>
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocker, message]);
}
