import { Toolbar, ApiCallMethods, ApiCallSettings } from 'dashboard-shared';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
  ContentContainer,
  DataLoader,
  ScrollableContainer,
  Flex,
  IconButton,
  Typography,
} from 'ui-kit';
import { useNavigate, useParams } from 'react-router-dom';
import { useFetchWorkflowMetadata } from '../Workflows/hooks';
import { HelpOutlineOutlined } from 'assets-shared';
import { useState, useCallback, useMemo, useEffect } from 'react';
import { useGetRefData, useSendSlackMessage } from '../Editor/hooks';
import { CONTACT_SLACK_CHANNEL_ID } from '../Editor/utils/constants';
import {
  contactModalEventChannel,
  ContactModal,
  extractDaysHoursMinutes,
  getTotalMinutes,
  type FormValues,
} from 'editor-shared';
import type { DatasourceVariable, WorkflowEndingNode } from 'types-shared';
import {
  TriggerTypeEnum,
  VariableTypeEnum,
  parseVariablesFromWorkflow,
  NodeTypesEnum,
} from 'types-shared';
import { EditorStore } from '../Editor/store/EditorState';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';

const BACKEND_STAGE = process.env.REACT_APP_BACKEND_STAGE || 'development';

const solaApiUrl =
  BACKEND_STAGE === 'development'
    ? 'https://api.dev.sola-solutions.com'
    : 'https://api.sola-solutions.com';

interface Settings {
  retryInterval: number | string | null;
  maxAttempts: number | string | null;
  maxRunLength: number | string | null;
}

export default function APITriggerSettings() {
  const { nodes } = EditorStore();
  const navigate = useNavigate();

  const [formData, setFormData] = useState<Record<string, string | null>>({});
  const [executionId, setExecutionId] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<'methods' | 'settings'>(
    'methods',
  );

  const { workflowId } = useParams();
  if (!workflowId) {
    throw new Error('workflowId not found!');
  }

  const hasRetryNode = useMemo(() => {
    const node = nodes.find(
      (_node) =>
        _node.type === NodeTypesEnum.Ending &&
        (_node as WorkflowEndingNode).data.status === 'retry',
    );
    return Boolean(node);
  }, [nodes]);

  const { data: workflowMetadata, isFetching: isFetchingWorkflowMetadata } =
    useFetchWorkflowMetadata(workflowId);

  const { data: nodeViewData, isFetching: isFetchingNodeViewData } =
    useGetRefData(workflowId, false);

  const filteredVariables = useMemo(() => {
    if (!nodeViewData?.variableData) {
      return undefined;
    }
    const usedVariables = parseVariablesFromWorkflow(
      nodes,
      nodeViewData.variableData,
    );
    return pickBy(
      nodeViewData.variableData,
      (value, key) =>
        usedVariables.has(key) &&
        value.type === VariableTypeEnum.Datasource &&
        value.data.triggerType &&
        (value.data.triggerType as TriggerTypeEnum) === TriggerTypeEnum.API,
    );
  }, [nodeViewData?.variableData, nodes]);

  const { apiSettings, setMaxAttempts, setRetryInterval, setMaxRunLength } =
    EditorStore();
  const defaultSettings = {
    retryInterval: hasRetryNode ? 1440 : null,
    maxAttempts: hasRetryNode ? 3 : null,
    maxRunLength: hasRetryNode ? 1 : null,
  };
  const [settings, setSettings] = useState<Settings>(defaultSettings);

  const payloadData = useMemo(() => {
    if (filteredVariables) {
      const generatedPayloadData: Record<string, string | null> =
        Object.entries(filteredVariables).reduce(
          (acc: Record<string, string | null>, [_key, variable]) => {
            if (variable.name) {
              acc[variable.name] = '';
            }
            return acc;
          },
          {},
        );

      return generatedPayloadData;
    }
    return {};
  }, [filteredVariables]);

  const inputFields = useMemo(() => {
    if (filteredVariables) {
      return Object.entries(filteredVariables).map(
        (item) => item as [string, DatasourceVariable],
      );
    }
    return [];
  }, [filteredVariables]);

  const { mutateAsync: sendMessage, status: sendMessageStatus } =
    useSendSlackMessage();

  const onSendMessage = useCallback(
    async (formValues: FormValues) => {
      await sendMessage({
        workflowId,
        channelId: CONTACT_SLACK_CHANNEL_ID,
        text: `Type: ${formValues.problemType} | Description: ${formValues.description}`,
      });
    },
    [sendMessage, workflowId],
  );

  const onTabChange = (
    _event: React.SyntheticEvent,
    newValue: 'methods' | 'settings',
  ) => {
    setSelectedTab(newValue);
    setExecutionId('');
  };

  useEffect(() => {
    if (isEmpty(formData) && !isEmpty(payloadData)) {
      setFormData(payloadData);
    }
  }, [payloadData, formData]);

  useEffect(() => {
    if (
      apiSettings.retryInterval ||
      apiSettings.maxAttempts ||
      apiSettings.maxRunLength
    ) {
      setSettings(apiSettings);
    }
  }, [apiSettings]);

  return (
    <ScrollableContainer>
      {isFetchingWorkflowMetadata || isFetchingNodeViewData ? (
        <DataLoader loadingText="Fetching API call details" />
      ) : (
        <>
          <Flex
            alignItems="center"
            className="flex-1 pr-8"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Toolbar
              onBack={() => {
                navigate(`/editor/${workflowId}`);
              }}
              pageInfo="API Call Details"
              title={workflowMetadata?.workflowName}
            />
            <IconButton
              className="order-5 ml-2 rounded-lg group"
              onClick={() => {
                contactModalEventChannel.emit('open');
              }}
            >
              <HelpOutlineOutlined className="!w-5 !h-5 text-info" />
            </IconButton>
          </Flex>

          <ContentContainer withToolbar>
            <Typography
              className="!font-medium !text-2xl text-info-dark"
              variant="h4"
            >
              API Call
            </Typography>
            <p className="text-color-grey text-sm mb-6">
              Manage remote workflow executions programmatically
            </p>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                onChange={onTabChange}
                sx={{
                  '& .MuiTabs-indicator': {
                    backgroundColor: '#2196F3',
                  },
                  '& .Mui-selected': {
                    color: '#2196F3 !important',
                  },
                }}
                value={selectedTab}
              >
                <Tab label="METHODS" value="methods" />
                <Tab label="SETTINGS" value="settings" />
              </Tabs>
            </Box>
            {selectedTab === 'methods' ? (
              <ApiCallMethods
                executionId={executionId}
                formData={formData}
                hasRetryNode={hasRetryNode}
                inputFields={inputFields}
                isAdmin={false}
                payloadData={payloadData}
                setExecutionId={setExecutionId}
                setFormData={setFormData}
                settings={settings}
                solaApiUrl={solaApiUrl}
                workflowId={workflowId}
              />
            ) : null}
            {selectedTab === 'settings' ? (
              <ApiCallSettings
                apiSettings={apiSettings}
                extractDaysHoursMinutes={extractDaysHoursMinutes}
                getTotalMinutes={getTotalMinutes}
                hasRetryNode={hasRetryNode}
                setMaxAttempts={setMaxAttempts}
                setMaxRunLength={setMaxRunLength}
                setRetryInterval={setRetryInterval}
                setSettings={setSettings}
                settings={settings}
              />
            ) : null}
          </ContentContainer>

          <ContactModal
            onSendMessage={onSendMessage}
            sendMessageStatus={sendMessageStatus}
            workflowId={workflowId}
          />
        </>
      )}
    </ScrollableContainer>
  );
}
