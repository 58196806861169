"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDatasourceSchema = void 0;
var types_shared_1 = require("types-shared");
var upload_schema_1 = require("./upload.schema");
exports.getDatasourceSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.DatasourceMetadata.pick({ datasourceId: true }),
        query: types_shared_1.z.object({ uploadUrlContentType: upload_schema_1.UploadUrlContentType }),
    }),
    response: types_shared_1.z.object({
        datasourceMetadata: types_shared_1.DatasourceMetadata,
        contentType: upload_schema_1.UploadUrlContentType,
        url: types_shared_1.z.string().optional(),
    }),
};
