import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export default function HideEyeIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33324 3.33331L16.6666 16.6666M13.7499 13.9632C12.6227 14.5704 11.3488 14.9999 9.99991 14.9999C7.0577 14.9999 4.47187 12.9565 2.989 11.4823C2.59759 11.0932 2.40191 10.8986 2.27733 10.5167C2.18849 10.2444 2.18847 9.75545 2.27733 9.4831C2.40194 9.1012 2.59803 8.90619 2.99022 8.51637C3.73763 7.77349 4.76501 6.88631 5.97683 6.18894M16.2499 12.1945C16.5275 11.9504 16.7816 11.7103 17.0097 11.4835L17.0121 11.481C17.4027 11.0928 17.5985 10.8982 17.7228 10.5172C17.8117 10.2448 17.8115 9.75568 17.7227 9.48331C17.5981 9.10154 17.4022 8.90643 17.0111 8.51757C15.5282 7.04338 12.9421 4.99998 9.99991 4.99998C9.71866 4.99998 9.44066 5.01865 9.16658 5.05371M11.1023 11.25C10.8085 11.5093 10.4226 11.6666 9.99991 11.6666C9.07944 11.6666 8.33324 10.9205 8.33324 9.99998C8.33324 9.55039 8.51126 9.14255 8.80064 8.84275"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </SvgIcon>
  );
}
