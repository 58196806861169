import { Button, IconButton, Select } from 'ui-kit';
import { CloseCircle } from 'assets-shared';
import React, { useEffect, useMemo, useState } from 'react';
import { type Item, VariableInput } from '../VariableTypes/VariableInput';
import type {
  Comparator,
  Condition,
  DatasourceMetadata,
  DatasourceTable,
  Variable,
  VariableMap,
  VariableRef,
} from 'types-shared';
import {
  StringComparatorEnum,
  getLogicTypes,
  stringifyTemplateString,
  typeComparatorMap,
  VariableTypeEnum,
  TriggerTypeEnum,
} from 'types-shared';
import { values as _values } from 'lodash';
import { v4 as uuid } from 'uuid';
import { AddVariable } from './AddVariable';
import { PreviewVariable } from './PreviewVariable';
import { ConditionalLabel } from './UiElements';
import { comparatorToLabel } from '../../utils/helper';

interface Props {
  condition: Condition;
  updateCondition: (condition: Partial<Condition>) => void;
  variablesMap: VariableMap;
  datasourceMetadata: DatasourceMetadata | null;
  tableData: DatasourceTable | null;
  triggerType?: TriggerTypeEnum;

  label?: string;
  edgeName?: string;

  showDelete?: boolean;
  showAdd?: boolean;
  onDelete?: () => void;
  onAdd?: () => void;
  addVariable: (variable: Variable) => void;
  updateVariable: (variable: Variable) => void;
  setShowingModal?: (showingModal: boolean) => void;
  transformDataStatus: 'error' | 'idle' | 'pending' | 'success' | 'loading';
  onTransformData: (
    prompt: string,
    textToTransform: string,
  ) => Promise<string | undefined>;
}

const getItems = (condition: Condition, key: 'field' | 'value'): Item[] => {
  const field = condition[key];
  if (field?.type === VariableTypeEnum.Template) {
    return field.data.map((data) => {
      if (typeof data === 'string') {
        return { id: uuid(), type: 'string', value: data };
      }
      return { id: data.id, type: 'variable', value: { id: data.id } };
    });
  }
  return [
    {
      id: uuid(),
      type: 'string',
      value: '',
    },
  ];
};

const getDataValue = (items: Item[]) => {
  return items
    .filter((data) => Boolean(data.value))
    .map((data) => {
      if (typeof data.value === 'string') return data.value;
      return { id: data.value.id };
    });
};

const getComparatorOptions = async (
  items: Item[],
  variablesMap: VariableMap,
  tableData: DatasourceTable | null,
) => {
  const variableDataValue = getDataValue(items);

  const strings = await stringifyTemplateString(
    variableDataValue,
    variablesMap,
    tableData?.rowData[0],
  );

  const logicTypes = getLogicTypes(strings);

  return logicTypes.flatMap((type) => {
    return typeComparatorMap[type];
  });
};

export default function SingleCondition({
  condition,
  updateCondition,
  variablesMap,
  tableData,
  datasourceMetadata,

  label,
  edgeName,

  showAdd,
  showDelete,
  onDelete,
  onAdd,
  addVariable,
  updateVariable,
  onTransformData,
  transformDataStatus,
  triggerType,
  setShowingModal,
}: Props) {
  const [variableData, setVariableData] = useState<Item[]>(
    getItems(condition, 'field'),
  );
  const [valueData, setValueData] = useState<Item[]>(
    getItems(condition, 'value'),
  );
  const [comparatorOptions, setComparatorOptions] = useState<Comparator[]>([]);

  useEffect(() => {
    const fetchComparatorOptions = async () => {
      const options = await getComparatorOptions(
        variableData,
        variablesMap,
        tableData,
      );
      setComparatorOptions(options);
    };
    void fetchComparatorOptions();
  }, [variableData, variablesMap, tableData]);

  const [selectedComparator, setSelectedComparator] = useState<Comparator | ''>(
    condition.comparator || '',
  );
  const hasDatasource = Boolean(datasourceMetadata);
  const [addNewVariable, setAddNewVariable] = useState<string>();
  const [previewVariable, setPreviewVariable] = useState<Item>();
  const [editingVariable, setEditingVariable] = useState<Item>();

  const updateVariableData = (newVariableData: Item[]) => {
    setVariableData(newVariableData);

    const variableDataValue = getDataValue(newVariableData);

    updateCondition({
      field: {
        id: uuid(),
        type: VariableTypeEnum.Template,
        data: variableDataValue,
      },
    });
  };

  const updateComparatorData = (comparatorData: Comparator) => {
    setSelectedComparator(comparatorData);
    updateCondition({
      comparator: comparatorData,
    });
  };

  const updateValueData = (newValueData: Item[]) => {
    setValueData(newValueData);
    const valueDataValue = newValueData
      .filter((data) => Boolean(data.value))
      .map((data) => {
        if (typeof data.value === 'string') return data.value;
        return { id: data.value.id };
      });
    updateCondition({
      value: {
        id: uuid(),
        type: VariableTypeEnum.Template,
        data: valueDataValue,
      },
    });
  };

  const updateBranchData = (variableRef: VariableRef) => {
    const newItems: Item[] = [
      ...(addNewVariable === 'variable' ? variableData : valueData),
      {
        id: uuid(),
        type: 'variable',
        value: variableRef,
      },
    ];
    const updater =
      addNewVariable === 'variable' ? updateVariableData : updateValueData;
    updater(newItems);
  };

  const variables = useMemo(() => {
    const selectedVariableIds = variableData
      .filter((item: Item) => item.type !== 'string')
      .map((item: Item) => (item.value as VariableRef).id);
    return _values(variablesMap).filter(
      ({ id, type }: Variable) =>
        [VariableTypeEnum.Scrape, VariableTypeEnum.Datasource].includes(type) &&
        !selectedVariableIds.includes(id),
    );
  }, [variablesMap, variableData]);

  const values = useMemo(() => {
    const selectedValueIds = valueData
      .filter((item: Item) => item.type !== 'string')
      .map((item: Item) => (item.value as VariableRef).id);
    return _values(variablesMap).filter(
      ({ id, type }: Variable) =>
        [VariableTypeEnum.Scrape, VariableTypeEnum.Datasource].includes(type) &&
        !selectedValueIds.includes(id),
    );
  }, [variablesMap, valueData]);

  const handlePreview =
    (itemType: 'value' | 'variable') => (_index: number, chosenItem: Item) => {
      const itemsList = itemType === 'value' ? valueData : variableData;
      const selected: Item | undefined = itemsList.find(
        (item) => item.id === chosenItem.id,
      );
      setPreviewVariable(selected);
    };

  useEffect(() => {
    if (addNewVariable || editingVariable || previewVariable) {
      setShowingModal?.(true);
    } else {
      setShowingModal?.(false);
    }
  }, [addNewVariable, editingVariable, previewVariable, setShowingModal]);

  if (addNewVariable) {
    return (
      <AddVariable
        addVariable={addVariable}
        datasourceMetadata={datasourceMetadata}
        edgeName={edgeName}
        isCondition
        label={label}
        onCancel={() => {
          setAddNewVariable(undefined);
        }}
        onSave={updateBranchData}
        onTransformData={onTransformData}
        showTitle
        tableData={tableData}
        transformDataStatus={transformDataStatus}
        triggerType={triggerType}
        updateVariable={updateVariable}
        variables={variablesMap}
      />
    );
  }

  if (editingVariable) {
    return (
      <AddVariable
        addVariable={addVariable}
        datasourceMetadata={datasourceMetadata}
        edgeName={edgeName}
        isCondition
        label={label}
        onCancel={() => {
          setPreviewVariable({ ...editingVariable });
          setEditingVariable(undefined);
        }}
        onSave={updateBranchData}
        onTransformData={onTransformData}
        showTitle
        tableData={tableData}
        transformDataStatus={transformDataStatus}
        triggerType={triggerType}
        updateVariable={updateVariable}
        variable={editingVariable}
        variables={variablesMap}
      />
    );
  }

  if (previewVariable) {
    return (
      <PreviewVariable
        datasourceMetadata={datasourceMetadata}
        edgeName={edgeName}
        isCondition
        label={label}
        onCancel={() => {
          setPreviewVariable(undefined);
        }}
        onEdit={() => {
          setEditingVariable({ ...previewVariable });
          setPreviewVariable(undefined);
        }}
        showTitle
        tableData={tableData}
        variable={previewVariable}
        variables={variablesMap}
      />
    );
  }

  return (
    <div>
      <div className="flex justify-between items-center">
        {label ? <ConditionalLabel>{label}</ConditionalLabel> : null}
        {onDelete && showDelete ? (
          <IconButton
            className="!text-info-dark cursor-pointer"
            onClick={onDelete}
          >
            <CloseCircle />
          </IconButton>
        ) : null}
      </div>
      <div className="pt-6">
        <VariableInput
          allowAddVariable={
            hasDatasource || triggerType === TriggerTypeEnum.API
          }
          data={variableData}
          label="Field 1"
          onAddNew={() => {
            setAddNewVariable('variable');
          }}
          onChange={updateVariableData}
          onPreview={handlePreview('variable')}
          placeholder="Select a variable"
          variables={variables}
          variablesMap={variablesMap}
        />
      </div>
      <div className="pt-6">
        <Select
          className="w-full"
          getLabel={(opt: Comparator) => {
            return comparatorToLabel(opt);
          }}
          getValue={(opt: Comparator) => opt}
          label="Condition"
          onChange={(p) => {
            updateComparatorData(p.target.value as Comparator);
          }}
          options={comparatorOptions}
          placeholder="Select a condition"
          value={selectedComparator}
        />
      </div>
      {condition.comparator !== StringComparatorEnum.DoesNotExist &&
        condition.comparator !== StringComparatorEnum.Exists && (
          <div className="pt-6">
            <VariableInput
              allowAddVariable={hasDatasource}
              data={valueData}
              label="Field 2"
              onAddNew={() => {
                setAddNewVariable('value');
              }}
              onChange={updateValueData}
              onPreview={handlePreview('value')}
              placeholder="Select value"
              variables={values}
              variablesMap={variablesMap}
            />
          </div>
        )}
      {onAdd && showAdd ? (
        <div className="pt-6">
          <Button color="secondary" onClick={onAdd} variant="text">
            Add condition
          </Button>
        </div>
      ) : null}
    </div>
  );
}
