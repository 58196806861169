import { type WorkflowNodeProps } from 'types-shared';
import NodeElement from './index';
import { DocumentNodeCore } from 'editor-shared';

export default function DocumentNode(workflowData: WorkflowNodeProps) {
  return (
    <NodeElement workflowData={workflowData}>
      <DocumentNodeCore />
    </NodeElement>
  );
}
