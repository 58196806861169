"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dataToGraph = exports.getRootEndNodes = void 0;
var graphlib_1 = require("@dagrejs/graphlib");
var getRootEndNodes = function (nodes, edges) {
    var graph = (0, exports.dataToGraph)(nodes, edges);
    var sources = graph.sources();
    var sinks = graph.sinks();
    var sourceNodes = nodes.filter(function (node) { return sources.includes(node.id); });
    var sinkNodes = nodes.filter(function (node) { return sinks.includes(node.id); });
    return { sourceNodes: sourceNodes, sinkNodes: sinkNodes };
};
exports.getRootEndNodes = getRootEndNodes;
var dataToGraph = function (nodes, edges, reverseEdges) {
    if (reverseEdges === void 0) { reverseEdges = false; }
    var graph = new graphlib_1.Graph();
    nodes.forEach(function (node) {
        graph.setNode(node.id);
    });
    var nodeIds = nodes.map(function (node) { return node.id; });
    edges.forEach(function (edge) {
        if (!nodeIds.includes(edge.source) && !nodeIds.includes(edge.target)) {
            return;
        }
        if (reverseEdges) {
            graph.setEdge(edge.target, edge.source, edge.id);
        }
        else {
            graph.setEdge(edge.source, edge.target, edge.id);
        }
    });
    return graph;
};
exports.dataToGraph = dataToGraph;
