import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export default function EditIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.9987 6.66684L3.33203 13.3335V16.6668L6.66536 16.6668L13.332 10.0002M9.9987 6.66684L12.3892 4.2763L12.3907 4.27488C12.7197 3.94581 12.8846 3.78099 13.0746 3.71925C13.2419 3.66487 13.4222 3.66487 13.5896 3.71925C13.7795 3.78094 13.9441 3.94558 14.2727 4.27419L15.7225 5.72402C16.0525 6.05403 16.2176 6.21911 16.2795 6.40939C16.3338 6.57676 16.3338 6.75704 16.2794 6.92441C16.2177 7.11455 16.0528 7.27938 15.7233 7.60893L15.7226 7.60963L13.332 10.0002M9.9987 6.66684L13.332 10.0002"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </SvgIcon>
  );
}
