import React, { useEffect, useState } from 'react';
import isNil from 'lodash/isNil';
import type {
  BranchData,
  DatasourceMetadata,
  DatasourceTable,
  Group,
  Variable,
  WorkflowEdge,
  WorkflowFreeformNode,
  WorkflowNode,
  WorkflowEndingNode,
  TriggerTypeEnum,
} from 'types-shared';
import { BranchModeEnum, NodeTypesEnum } from 'types-shared';
import { ConditionalBlock } from './ConditionalBlock';
import { OptionsBlock } from './OptionsBlock';
import { insertNodeAfter } from '../../utils/helper';
import { FreeformBlock } from './FreeformBlock';
import { autoFormat } from '../../utils/autoformat';
import { EndingBlock } from './EndingBlock';
import { clone, setWith } from 'lodash';

interface Props {
  allowBranchReordering?: boolean;
  endingStatusBlockEnabled?: boolean;
  nodeId: string;
  nodes: WorkflowNode[];
  edges: WorkflowEdge[];
  setNodes: (nodes: WorkflowNode[]) => void;
  setEdges: (edges: WorkflowEdge[]) => void;
  updateNode: (node: WorkflowNode) => void;
  addNodes: (nodes: WorkflowNode[]) => void;
  onCancel: () => void;
  variablesMap: Record<string, Variable>;
  datasourceMetadata: DatasourceMetadata | null;
  tableData: DatasourceTable | null;
  addVariable: (variable: Variable) => void;
  updateVariable: (variable: Variable) => void;
  transformDataStatus: 'error' | 'idle' | 'pending' | 'success' | 'loading';
  triggerType?: TriggerTypeEnum;
  onTransformData: (
    prompt: string,
    textToTransform: string,
  ) => Promise<string | undefined>;
  continueRecording?: (nodeId: string) => void;
}

export function EditNodePanel({
  allowBranchReordering,
  endingStatusBlockEnabled,
  nodeId,
  nodes,
  edges,
  setEdges,
  updateNode,
  setNodes,
  onCancel,
  datasourceMetadata,
  variablesMap,
  tableData,
  addVariable,
  updateVariable,
  onTransformData,
  transformDataStatus,
  triggerType,
  continueRecording,
}: Props) {
  const [currentNodeType, setCurrentNodeType] = useState<string | null>(null);
  const [editingEdge, setEditingEdge] = useState<WorkflowEdge>();
  const selectedNode = nodes.find((node) => node.id === nodeId);

  const insertNode = (sourceId: string) => {
    const sourceNode = nodes.find((node) => node.id === sourceId);

    if (!sourceNode) {
      throw Error('sourceNode not found');
    }

    insertNodeAfter(
      sourceNode,
      nodes,
      edges,
      {
        setNodes,
        setEdges,
      },
      true,
    );
  };

  const setNodeType = (nodeType: string) => {
    if (isNil(selectedNode)) {
      throw Error('selectedNode node not found!');
    }

    if (selectedNode.type !== NodeTypesEnum.New) {
      throw Error('selectedNode is not of type new');
    }

    switch (nodeType) {
      case 'conditional': {
        updateNode({
          ...selectedNode,
          type: NodeTypesEnum.Conditional,
          name: 'New Conditional Block',
        });
        const filteredEdges = edges.map((e) => {
          if (e.source === selectedNode.id) {
            return {
              ...e,
              labelStyle: { display: 'block' },
            };
          }
          return e;
        });
        setEdges(filteredEdges);
        break;
      }
      case 'freeform': {
        updateNode({
          ...selectedNode,
          type: NodeTypesEnum.Freeform,
          name: 'New Freeform Block',
        });
        break;
      }
      case 'ending': {
        updateNode({
          ...selectedNode,
          type: NodeTypesEnum.Ending,
          data: {
            ...selectedNode.data,
            status: 'failure',
          },
        });
        break;
      }
      case 'continue-recording': {
        if (continueRecording) {
          continueRecording(nodeId);
        }
        break;
      }
    }

    setCurrentNodeType(nodeType);
  };

  const updateNodeProps = (key: string, value: string) => {
    setNodes(
      nodes.map((_node) => {
        if (_node.id === selectedNode?.id) {
          return setWith(clone(_node), key, value, clone);
        }
        return _node;
      }),
    );
  };

  const updateEdge = ({
    name,
    group,
    instruction,
  }: Partial<{ name: string; group: Group; instruction: string }>) => {
    if (!editingEdge) {
      throw Error('editingEdge not found!');
    }

    if (name) {
      // add the label on edge
      setEdges(
        edges.map((edge) => {
          if (edge.id === editingEdge.id) {
            return {
              ...edge,
              label: name,
            };
          }
          return edge;
        }),
      );
    }

    const updateBranchData = (
      branchesData: BranchData[],
      newGroup?: Group,
      newInstruction?: string,
    ) => {
      const branchData = branchesData.find(
        (b) => b.branchId === editingEdge.id,
      );

      if (!branchData) {
        return [
          ...branchesData,
          {
            branchId: editingEdge.id,
            rule: newGroup
              ? { data: newGroup, output: [{ id: editingEdge.id }] }
              : undefined,
            instruction: newInstruction,
            selectedMode: newGroup
              ? BranchModeEnum.Rule
              : BranchModeEnum.Instruction,
          },
        ];
      }
      return branchesData.map((b) => {
        if (b.branchId === editingEdge.id) {
          return {
            ...b,
            rule: newGroup
              ? { data: newGroup, output: [{ id: editingEdge.id }] }
              : b.rule,
            instruction: newInstruction ?? b.instruction,
            selectedMode: newGroup
              ? BranchModeEnum.Rule
              : BranchModeEnum.Instruction,
          };
        }
        return b;
      });
    };

    setNodes(
      nodes.map((_node) => {
        if (
          _node.id === selectedNode?.id &&
          _node.type === NodeTypesEnum.Conditional
        ) {
          return {
            ..._node,
            data: {
              ..._node.data,
              branchesData: updateBranchData(
                _node.data.branchesData ?? [],
                group,
                instruction,
              ),
            },
          };
        }
        return _node;
      }),
    );

    setEditingEdge(undefined);
  };

  const deleteBranch = () => {
    if (!editingEdge || !selectedNode) return;

    const updatedEdges = edges.filter((edge) => edge.id !== editingEdge.id);
    setEdges(updatedEdges);
    setEditingEdge(undefined);

    const updatedNodes = nodes.filter((n) => n.id !== editingEdge.target);

    autoFormat(updatedNodes, updatedEdges, setNodes);
  };

  useEffect(() => {
    const currentNode = nodes.find((n) => n.id === nodeId);
    setCurrentNodeType(currentNode?.type ?? 'continue');
  }, [nodeId, nodes]);

  const handleOnCancel = () => {
    onCancel();
    setCurrentNodeType(null);
  };

  if (!nodeId) return;

  if (selectedNode && selectedNode.type === NodeTypesEnum.Conditional) {
    return (
      <ConditionalBlock
        addVariable={addVariable}
        allowBranchReordering={allowBranchReordering}
        datasourceMetadata={datasourceMetadata}
        deleteBranch={deleteBranch}
        edges={edges}
        editingEdge={editingEdge}
        insertNode={insertNode}
        node={selectedNode}
        nodes={nodes}
        onCancel={handleOnCancel}
        onTransformData={onTransformData}
        onUpdateEdge={updateEdge}
        setEdges={setEdges}
        setEditingEdge={setEditingEdge}
        setNodes={setNodes}
        tableData={tableData}
        transformDataStatus={transformDataStatus}
        triggerType={triggerType}
        updateNodeName={(val: string) => {
          updateNodeProps('name', val);
        }}
        updateNodeStatus={(status) => {
          updateNodeProps('data.nodeStatus', status);
        }}
        updateVariable={updateVariable}
        variablesMap={variablesMap}
      />
    );
  } else if (currentNodeType === 'freeform' && selectedNode) {
    return (
      <FreeformBlock
        node={selectedNode as WorkflowFreeformNode}
        onCancel={handleOnCancel}
        updateNodeInstructions={(val: string) => {
          updateNodeProps('data.instructions', val);
        }}
        updateNodeName={(val: string) => {
          updateNodeProps('name', val);
        }}
        updateNodeStatus={(status) => {
          updateNodeProps('data.nodeStatus', status);
        }}
      />
    );
  } else if (currentNodeType === 'ending' && selectedNode) {
    return (
      <EndingBlock
        node={selectedNode as WorkflowEndingNode}
        onCancel={handleOnCancel}
        updateNodeDataStatus={(val: string) => {
          updateNodeProps('data.status', val);
        }}
        updateNodeStatus={(status) => {
          updateNodeProps('data.nodeStatus', status);
        }}
      />
    );
  }
  return (
    <OptionsBlock
      endingStatusBlockEnabled={endingStatusBlockEnabled}
      onCancel={handleOnCancel}
      onContinue={setNodeType}
    />
  );
}
