import {
  Toolbar,
  RunOverview,
  WorkflowScreenshots,
  RecordOutputs,
} from 'dashboard-shared';
import { useState, useCallback, useMemo } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Flex,
  ScrollableContainer,
  Typography,
  IconButton,
  DataLoader,
} from 'ui-kit';
import { getStatusColor } from '../pages/WorkflowDetail';
import {
  useDownloadExecutionData,
  useFetchExecutionDetail,
  useFetchExecutionVariables,
  useFetchExecutionScreenshots,
  useFetchWorkflowMetadata,
} from '../hooks';
import dayjs from '../../../config/dayjs';
import { downloadLinkData, formatDuration } from '../utils/helper';
import { HelpOutlineOutlined } from 'assets-shared';
import {
  contactModalEventChannel,
  ContactModal,
  type FormValues,
} from 'editor-shared';
import { useSendSlackMessage } from '../../Editor/hooks';
import { CONTACT_SLACK_CHANNEL_ID } from '../../Editor/utils/constants';
import truncate from 'lodash/truncate';
import { ExecutionTriggerEnum } from 'types-shared';

export function ExecutionDetailContent() {
  const [selectedTab, setSelectedTab] = useState<
    'old' | 'overview' | 'screenshots' | 'outputs'
  >('overview');
  const { executionId } = useParams();

  if (!executionId) {
    throw Error('executionId not found!');
  }

  const navigate = useNavigate();
  const {
    data: executionDetail,
    isLoading: executionDetailLoading,
    refetch: refetchExecutionDetails,
  } = useFetchExecutionDetail(executionId);
  const { data: imageArray = [], isLoading: executionScreenshotsLoading } =
    useFetchExecutionScreenshots(executionId, executionDetail?.imageUrls ?? []);
  const { data: variables = {} } = useFetchExecutionVariables(
    executionId,
    executionDetail?.variablesUrls ?? [],
  );
  const executionDate = dayjs(
    new Date(executionDetail?.metadata.createdAt ?? ''),
  ).format('MM/DD/YY -  h:mm A');
  const { mutateAsync: getExecutionZippedFile, status } =
    useDownloadExecutionData();

  const { data: workflowMetadata, isLoading } = useFetchWorkflowMetadata(
    executionDetail?.metadata.workflowId ?? '',
  );

  const downloadZippedOutput = async () => {
    const link = await getExecutionZippedFile([executionId]);
    downloadLinkData(link);
  };

  const { mutateAsync: sendMessage, status: sendMessageStatus } =
    useSendSlackMessage();

  const onSendMessage = useCallback(
    async (formValues: FormValues) => {
      if (executionDetail?.metadata.workflowId) {
        await sendMessage({
          workflowId: executionDetail.metadata.workflowId,
          channelId: CONTACT_SLACK_CHANNEL_ID,
          text: `Type: ${formValues.problemType} | Description: ${formValues.description}`,
        });
      }
    },
    [sendMessage, executionDetail?.metadata.workflowId],
  );

  const onTabChange = (
    _event: React.SyntheticEvent,
    newValue: 'overview' | 'screenshots' | 'outputs',
  ) => {
    setSelectedTab(newValue);
  };

  const runId = useMemo(() => {
    if (executionDetail) {
      const { setId } = executionDetail.metadata;
      return truncate(setId, { length: 5 });
    }
    return '';
  }, [executionDetail]);

  return (
    <>
      {isLoading || executionDetailLoading ? (
        <DataLoader />
      ) : (
        <ScrollableContainer>
          <Flex
            alignItems="center"
            className="flex-1 pr-8 "
            flexDirection="row"
            justifyContent="space-between"
          >
            <Toolbar
              onBack={() => {
                if (executionDetail?.metadata) {
                  navigate(
                    `/workflows/${executionDetail.metadata.workflowId}`,
                    {
                      replace: true,
                    },
                  );
                }
              }}
              pageInfo={
                <>
                  <span className="text-xs text-info-dark">Run {runId}</span>
                  <span className="text-xs">&nbsp;/&nbsp;Record details</span>
                </>
              }
              title={workflowMetadata?.workflowName ?? 'Run Details'}
            />
            <IconButton
              className="order-5 ml-2 rounded-lg group"
              onClick={() => {
                contactModalEventChannel.emit('open');
              }}
            >
              <HelpOutlineOutlined className="!w-5 !h-5 text-info" />
            </IconButton>
          </Flex>

          <div className="pt-12 pb-8 px-8 overflow-y-auto relative flex-grow h-[calc(100vh_-_92px)] flex flex-col">
            {executionDetail ? (
              <>
                <Typography
                  className="!font-bold text-info-dark !mb-4"
                  variant="h4"
                >
                  {executionDetail.metadata.trigger === ExecutionTriggerEnum.API
                    ? `API Run: ${executionDate}`
                    : `Record ${Number(executionDetail.metadata.recordId) + 1}`}
                </Typography>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs
                    onChange={onTabChange}
                    sx={{
                      '& .MuiTabs-indicator': {
                        backgroundColor: '#2196F3',
                      },
                      '& .Mui-selected': {
                        color: '#2196F3 !important',
                      },
                    }}
                    value={selectedTab}
                  >
                    <Tab label="Overview" value="overview" />
                    <Tab label="Screenshots" value="screenshots" />
                    <Tab label="Outputs" value="outputs" />
                  </Tabs>
                </Box>
                {selectedTab === 'overview' ? (
                  <RunOverview
                    dayjs={dayjs}
                    executionDetail={executionDetail}
                    formatDuration={formatDuration}
                    getStatusColor={getStatusColor}
                    imageArray={imageArray}
                    imagesLoading={executionScreenshotsLoading}
                  />
                ) : null}

                {selectedTab === 'screenshots' ? (
                  <WorkflowScreenshots
                    imageArray={imageArray}
                    loading={executionScreenshotsLoading}
                  />
                ) : null}

                {selectedTab === 'outputs' ? (
                  <RecordOutputs
                    artifacts={executionDetail.artifacts}
                    artifactsLoading={executionDetailLoading}
                    downloadLinkData={downloadLinkData}
                    downloadZippedOutput={downloadZippedOutput}
                    downloadZippedOutputStatus={status}
                    fetchExecutionArtifacts={refetchExecutionDetails}
                    variables={variables}
                  />
                ) : null}

                <ContactModal
                  onSendMessage={onSendMessage}
                  sendMessageStatus={sendMessageStatus}
                  workflowId={executionDetail.metadata.workflowId}
                />
              </>
            ) : null}
          </div>
        </ScrollableContainer>
      )}
    </>
  );
}
