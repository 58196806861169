import type { ExecutionVariables, VariableBase } from 'types-shared';
import {
  Button,
  Flex,
  IconButton,
  AlertVariant,
  notify,
  DataLoader,
} from 'ui-kit';
import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import {
  Download,
  DescriptionOutlined,
  ContentCopyOutlined,
} from 'assets-shared';
import type { ExecutionDocument } from 'api-types-shared';
import { useParams } from 'react-router-dom';

interface Props {
  downloadZippedOutput: () => Promise<void>;
  downloadLinkData: (url: string) => void;
  downloadZippedOutputStatus: 'error' | 'success' | 'pending' | 'idle';
  variables?: ExecutionVariables;
  artifacts: ExecutionDocument[];
  artifactsLoading: boolean;
  fetchExecutionArtifacts: () => void;
}

export function RecordOutputs({
  artifacts,
  variables,
  downloadZippedOutput,
  downloadZippedOutputStatus,
  downloadLinkData,
  fetchExecutionArtifacts,
  artifactsLoading,
}: Props) {
  const { executionId } = useParams();
  const filteredVariables: string[][] = useMemo(() => {
    return Object.entries(variables ?? {})
      .map(([key, value]) => {
        const isStringValue = typeof value === 'string';
        const executionVariable = value as VariableBase;
        const title = isStringValue ? key : executionVariable.name;
        const description = isStringValue
          ? value
          : executionVariable.executionData?.transformedValue ??
            executionVariable.executionData?.initialValue;

        return [title, description];
      })
      .filter(([key, value]) => key && value) as string[][];
  }, [variables]);

  const onDownloadLinkData = useCallback(
    (uri: string) => {
      downloadLinkData(uri);
    },
    [downloadLinkData],
  );

  const isEmpty = filteredVariables.length === 0 && artifacts.length === 0;

  if (!executionId) {
    throw Error('executionId not found!');
  }

  useEffect(() => {
    fetchExecutionArtifacts();
  }, [fetchExecutionArtifacts]);

  const getFileName = (filePath: string) => {
    const parts = filePath.split('/');
    return parts[parts.length - 1];
  };

  return artifactsLoading ? (
    <DataLoader />
  ) : (
    <div>
      <Flex alignItems="center" className="my-8" justifyContent="space-between">
        <div>
          <h2 className="text-cyan-900 text-2xl font-medium leading-9 tracking-tight">
            Outputs details
          </h2>
          <span className="text-slate-500 text-sm leading-normal">
            Review, copy or download the record outputs.
          </span>
        </div>

        <div>
          <Button
            className="!border-none"
            color="secondary"
            disabled={downloadZippedOutputStatus === 'pending'}
            onClick={downloadZippedOutput}
            variant="outlined"
          >
            <Download className="mr-1" fontSize="small" />
            Export
          </Button>
        </div>
      </Flex>

      <div className="flex flex-col gap-6 my-8 items-start">
        {filteredVariables.map(([key, value]) => (
          <Output
            action="copy"
            description={value}
            key={key}
            onDownloadLinkData={onDownloadLinkData}
            title={key}
          />
        ))}
        {artifacts.map(({ s3Key, uri, mediaType }, index) => (
          <Output
            action={
              mediaType === 'application/octet-stream' ? 'download' : 'copy'
            }
            description={getFileName(s3Key) || s3Key}
            key={s3Key}
            onDownloadLinkData={onDownloadLinkData}
            title={`Document ${index + 1}`}
            uri={uri}
          />
        ))}
        {isEmpty ? (
          <div className="bg-gray-100 rounded-lg py-8 px-12 space-y-3 flex flex-col w-full">
            <p className="text-lg font-medium">
              The workflow doesn’t have outputs
            </p>
            <p className="text-sm text-gray-600 md:w-1/2">
              Documents and scraped values are outputs that you can see in this
              section. For now, this workflow has none.
            </p>
          </div>
        ) : null}
      </div>
    </div>
  );
}

interface OutputProps {
  title: string;
  description: string;
  onDownloadLinkData: (url: string) => void;
  action: 'download' | 'copy';
  uri?: string;
  rightIcon?: React.ReactNode;
  leftIcon?: React.ReactNode;
}
export function Output({
  title,
  description,
  action = 'copy',
  uri,
  onDownloadLinkData,
}: OutputProps) {
  const copyToClipboard = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    navigator.clipboard.writeText(description).then(() => {
      notify({
        message: 'Copied to clipboard',
        variant: AlertVariant.SUCCESS,
      });
    });
  }, [description]);

  const downloadFile = useCallback(() => {
    if (uri) {
      onDownloadLinkData(uri);
    }
  }, [onDownloadLinkData, uri]);

  const descriptionText = useMemo(() => {
    const parts = description.split('\n');
    if (parts.length === 1) {
      return parts[0];
    }
    return parts.map((line: string) => (
      <Fragment key={line}>
        {line}
        <br />
      </Fragment>
    ));
  }, [description]);

  const cookiesTitle =
    descriptionText === 'cookies.json'
      ? 'Workflow Execution Cookies'
      : undefined;

  return (
    <div className="px-4 py-2 bg-neutral-50 rounded-lg border border-slate-300 flex items-start gap-2 max-w-[60%]">
      {action === 'download' ? (
        <DescriptionOutlined color="action" fontSize="small" />
      ) : null}
      <div className="flex flex-col mr-6">
        <div className="text-gray-500 text-xs leading-3 tracking-tight">
          {cookiesTitle ?? title}
        </div>
        <div className="text-cyan-900 text-base leading-normal tracking-tight">
          {descriptionText}
        </div>
      </div>
      <IconButton
        className="mt-2"
        onClick={action === 'download' ? downloadFile : copyToClipboard}
      >
        {action === 'download' ? (
          <Download color="secondary" fontSize="small" />
        ) : (
          <ContentCopyOutlined color="secondary" fontSize="small" />
        )}
      </IconButton>
    </div>
  );
}
