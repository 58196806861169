"use strict";
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.comparatorFuncMap = exports.comparatorTypeMap = exports.typeComparatorMap = void 0;
var zod_1 = require("zod");
var comparator_schema_1 = require("./comparator.schema");
var comparator_functions_1 = require("./comparator-functions");
var logic_schema_1 = require("./logic.schema");
// Map of logic data type to comparator enums, type LogicDataType -> Comparator[]
exports.typeComparatorMap = (_a = {},
    _a[logic_schema_1.LogicDataType.enum.String] = Object.values(comparator_schema_1.StringComparatorEnum),
    _a[logic_schema_1.LogicDataType.enum.Number] = Object.values(comparator_schema_1.NumberComparatorEnum),
    _a[logic_schema_1.LogicDataType.enum.Date] = Object.values(comparator_schema_1.DateComparatorEnum),
    _a);
// Map of comparator enum to logic data type, type Comparator -> LogicDataType
// Have to enumerate all the comparators here because the type of the map is not inferred easily
exports.comparatorTypeMap = (_b = {},
    _b[comparator_schema_1.StringComparatorEnum.Is] = logic_schema_1.LogicDataType.enum.String,
    _b[comparator_schema_1.StringComparatorEnum.IsNot] = logic_schema_1.LogicDataType.enum.String,
    _b[comparator_schema_1.StringComparatorEnum.Contains] = logic_schema_1.LogicDataType.enum.String,
    _b[comparator_schema_1.StringComparatorEnum.NotContains] = logic_schema_1.LogicDataType.enum.String,
    _b[comparator_schema_1.StringComparatorEnum.Exists] = logic_schema_1.LogicDataType.enum.String,
    _b[comparator_schema_1.StringComparatorEnum.DoesNotExist] = logic_schema_1.LogicDataType.enum.String,
    _b[comparator_schema_1.NumberComparatorEnum.Equal] = logic_schema_1.LogicDataType.enum.Number,
    _b[comparator_schema_1.NumberComparatorEnum.NotEqual] = logic_schema_1.LogicDataType.enum.Number,
    _b[comparator_schema_1.NumberComparatorEnum.GreaterThan] = logic_schema_1.LogicDataType.enum.Number,
    _b[comparator_schema_1.NumberComparatorEnum.GreaterThanOrEqual] = logic_schema_1.LogicDataType.enum.Number,
    _b[comparator_schema_1.NumberComparatorEnum.LessThan] = logic_schema_1.LogicDataType.enum.Number,
    _b[comparator_schema_1.NumberComparatorEnum.LessThanOrEqual] = logic_schema_1.LogicDataType.enum.Number,
    _b[comparator_schema_1.DateComparatorEnum.On] = logic_schema_1.LogicDataType.enum.Date,
    _b[comparator_schema_1.DateComparatorEnum.NotOn] = logic_schema_1.LogicDataType.enum.Date,
    _b[comparator_schema_1.DateComparatorEnum.After] = logic_schema_1.LogicDataType.enum.Date,
    _b[comparator_schema_1.DateComparatorEnum.AfterOrOn] = logic_schema_1.LogicDataType.enum.Date,
    _b[comparator_schema_1.DateComparatorEnum.Before] = logic_schema_1.LogicDataType.enum.Date,
    _b[comparator_schema_1.DateComparatorEnum.BeforeOrOn] = logic_schema_1.LogicDataType.enum.Date,
    _b);
var ComparatorFunction = zod_1.z
    .function()
    .args(logic_schema_1.LogicDataObj, logic_schema_1.LogicDataObj)
    .returns(zod_1.z.boolean());
exports.comparatorFuncMap = {
    // String functions
    Is: comparator_functions_1.equalFunc,
    IsNot: comparator_functions_1.notEqualFunc,
    Contains: comparator_functions_1.containsFunc,
    NotContains: comparator_functions_1.notContainsFunc,
    Exists: comparator_functions_1.existsFunc,
    DoesNotExist: comparator_functions_1.doesNotExistFunc,
    // Number functions
    Equal: comparator_functions_1.equalFunc,
    NotEqual: comparator_functions_1.notEqualFunc,
    GreaterThan: comparator_functions_1.greaterThanFunc,
    GreaterThanOrEqual: comparator_functions_1.greaterThanOrEqualFunc,
    LessThan: comparator_functions_1.lessThanFunc,
    LessThanOrEqual: comparator_functions_1.lessThanOrEqualFunc,
    // Date functions
    On: comparator_functions_1.equalFunc,
    NotOn: comparator_functions_1.notEqualFunc,
    After: comparator_functions_1.greaterThanFunc,
    AfterOrOn: comparator_functions_1.greaterThanOrEqualFunc,
    Before: comparator_functions_1.lessThanFunc,
    BeforeOrOn: comparator_functions_1.lessThanOrEqualFunc,
};
