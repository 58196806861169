import type { StateCreator } from 'zustand';
import { produce } from 'immer';

export interface ImagesStateData {
  fullImages: Record<string, Blob>;
  thumbnails: Record<string, Blob | null>;
}

export const initialImagesState: ImagesStateData = {
  fullImages: {},
  thumbnails: {},
};

export interface ImagesStateActions {
  setImage: (imageId: string, image: Blob) => void;
  setThumbnails: (thumbnails: Record<string, Blob | null>) => void;
}

export const ImagesState: StateCreator<ImagesStateData & ImagesStateActions> = (
  set,
) => ({
  ...initialImagesState,
  setImage: (imageId: string, image: Blob) => {
    set(
      produce((state: ImagesStateData) => {
        state.fullImages[imageId] = image;
      }),
    );
  },
  setThumbnails: (thumbnails: Record<string, Blob | null>) => {
    set(
      produce((state: ImagesStateData) => {
        state.thumbnails = thumbnails;
      }),
    );
  },
});
