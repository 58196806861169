import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React from 'react';

export default function FlagIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      fill="none"
      height="60"
      viewBox="0 0 60 60"
      width="60"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 52.5V39.2177M10 39.2177C24.5455 27.8443 35.4545 50.5907 50 39.2172V10.7837C35.4545 22.1571 24.5455 -0.590258 10 10.7832V39.2177Z"
        fill="none"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
      />
    </SvgIcon>
  );
}
