import { notify, AlertVariant } from 'ui-kit';
import { v4 as uuidv4 } from 'uuid';
import { CommitWorkflowState } from 'types-shared';
import { executeWorkflowWithExtension } from './execute-with-extension';
import { ExecutionSDK } from './execution-sdk';
export class ExecutionRunner {
    env;
    _sdk;
    constructor(env) {
        this.env = env;
        this._sdk = new ExecutionSDK(env);
    }
    run = async ({ runType, workflowId, recordIds, workflowData, datasourceMetadata, tableData, debugSettings, adminRun = false, }) => {
        if (runType === 'Remote') {
            return this.runRemote(workflowId, recordIds, adminRun);
        }
        const commitWorkflowData = CommitWorkflowState.safeParse({
            ...workflowData,
            datasourceMetadata,
        });
        if (!commitWorkflowData.success) {
            const errorMsg = commitWorkflowData.error.message;
            throw new Error(errorMsg.includes('Not all nodes are checked.')
                ? 'Review and mark each workflow step as ready to run before running'
                : errorMsg);
        }
        const commitWorkflowDataParsed = commitWorkflowData.data;
        if (runType === 'Local') {
            return this.runLocal(workflowId, recordIds, commitWorkflowDataParsed, tableData, adminRun);
        }
        if (runType === 'LocalDebug') {
            return this.runLocalDebug(workflowId, recordIds, commitWorkflowDataParsed, tableData, debugSettings);
        }
        notify({
            message: `Unsupported run type: ${runType}`,
            variant: AlertVariant.ERROR,
        });
        throw new Error(`Unsupported run type: ${runType}`);
    };
    runLocal = async (workflowId, recordIds, workflowData, tableData, adminRun = false) => {
        if (recordIds.length !== 1) {
            throw new Error('Local runs only support one record at a time!');
        }
        if (!workflowData) {
            throw new Error('WorkflowData must be provided for local run');
        }
        const resp = await this._sdk.queueExecutions({
            params: {
                workflowId,
            },
            body: {
                setRecordKeys: recordIds,
                bypassSQS: true,
                adminRun,
            },
        });
        await executeWorkflowWithExtension({
            workflowId,
            recordIds,
            workflowData,
            tableData,
            setId: resp.executionSetId,
            executionIds: resp.queuedExecutionIds,
        });
        return resp;
    };
    runRemote = (workflowId, recordIds, adminRun = false) => {
        return this._sdk.queueExecutions({
            params: {
                workflowId,
            },
            body: {
                setRecordKeys: recordIds,
                bypassSQS: false,
                adminRun,
            },
        });
    };
    runLocalDebug = async (workflowId, recordIds, workflowData, tableData, debugSettings) => {
        if (recordIds.length !== 1) {
            throw new Error('Local runs only support one record at a time!');
        }
        if (!workflowData) {
            throw new Error('WorkflowData must be provided for local run');
        }
        await executeWorkflowWithExtension({
            workflowId,
            recordIds,
            workflowData,
            tableData,
            setId: uuidv4(),
            executionIds: [uuidv4()],
            debugSettings,
        });
        return {
            executionSetId: uuidv4(),
            queuedExecutionIds: [uuidv4()],
        };
    };
    fetchExecutionMetas = (executionIds) => {
        return this._sdk.getMultipleExecutions({
            body: { executionIds },
        });
    };
}
