import { Add, CloseIcon, EditBranchIcon, DragIndicator } from 'assets-shared';
import { Button, Input, Select } from 'ui-kit';
import { clsx } from 'clsx';
import { EditBranch } from './EditBranch';
import { NodeStatusEnum, NodeTypesEnum } from 'types-shared';
import type {
  BranchData,
  DatasourceMetadata,
  DatasourceTable,
  Group,
  Variable,
  WorkflowConditionalNode,
  WorkflowEdge,
  WorkflowNode,
  TriggerTypeEnum,
} from 'types-shared';
import React, { useMemo } from 'react';
import { NodeCheck } from '../NodeCheck';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, MouseSensor, useSensor, useSensors } from '@dnd-kit/core';
import {
  arrayMove,
  verticalListSortingStrategy,
  SortableContext,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';

interface Props {
  node: WorkflowConditionalNode;
  nodes: WorkflowNode[];

  editingEdge: WorkflowEdge | undefined;
  setEditingEdge: (val: undefined | WorkflowEdge) => void;
  edges: WorkflowEdge[];
  setEdges: (edges: WorkflowEdge[]) => void;
  setNodes: (nodes: WorkflowNode[]) => void;

  deleteBranch: () => void;
  insertNode: (sourceId: string) => void;
  updateNodeName: (name: string) => void;
  onUpdateEdge: (
    data: Partial<{ name: string; group: Group; instruction: string }>,
  ) => void;
  onCancel: () => void;

  variablesMap: Record<string, Variable>;
  datasourceMetadata: DatasourceMetadata | null;
  tableData: DatasourceTable | null;
  transformDataStatus: 'error' | 'idle' | 'pending' | 'success' | 'loading';
  triggerType?: TriggerTypeEnum;
  onTransformData: (
    prompt: string,
    textToTransform: string,
  ) => Promise<string | undefined>;
  addVariable: (variable: Variable) => void;
  updateVariable: (variable: Variable) => void;
  updateNodeStatus: (status: NodeStatusEnum) => void;
  allowBranchReordering?: boolean;
}
const typeOptions: string[] = [
  'Run first branch that holds true',
  'Run all branches that hold true',
];

export function ConditionalBlock({
  edges,
  node,
  editingEdge,
  onCancel,
  insertNode,
  updateNodeName,
  onUpdateEdge,
  deleteBranch,
  setEditingEdge,
  updateVariable,
  addVariable,
  variablesMap,
  datasourceMetadata,
  tableData,
  onTransformData,
  transformDataStatus,
  triggerType,
  updateNodeStatus,
  allowBranchReordering = false,
  nodes,
  setNodes,
  setEdges,
}: Props) {
  const branchesOrder = useMemo(
    () => (node.data.branchesData ?? []).map((branch) => branch.branchId),
    [node.data.branchesData],
  );

  const nodeEdges = useMemo(() => {
    const outgoingEdges = edges.filter((edge) => edge.source === node.id);
    return outgoingEdges
      .filter((edge) => branchesOrder.includes(edge.id))
      .sort((e1, e2) => {
        const e1Index = branchesOrder.indexOf(e1.id);
        const e2Index = branchesOrder.indexOf(e2.id);
        return e1Index - e2Index;
      })
      .concat(outgoingEdges.filter((edge) => !branchesOrder.includes(edge.id)));
  }, [edges, node.id, branchesOrder]);

  const branchData: BranchData | undefined = useMemo(
    () =>
      node.data.branchesData?.find(
        (b: BranchData) => b.branchId === editingEdge?.id,
      ),
    [editingEdge?.id, node.data.branchesData],
  );

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });
  const sensors = useSensors(mouseSensor);

  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id === over?.id) {
      return;
    }
    const tempEdgesOrder = [...nodeEdges.map((edge) => edge.id)];
    const activeIndex = tempEdgesOrder.findIndex((id) => id === active.id);
    const overIndex = tempEdgesOrder.findIndex((id) => id === over?.id);
    const newEdgesOrder = arrayMove(tempEdgesOrder, activeIndex, overIndex);
    const newEdges = [...edges].sort((e1, e2) => {
      const e1Index = newEdgesOrder.indexOf(e1.id);
      const e2Index = newEdgesOrder.indexOf(e2.id);
      return e1Index - e2Index;
    });
    const newNodes = nodes.map((_node) => {
      if (_node.id === node.id && _node.type === NodeTypesEnum.Conditional) {
        return {
          ..._node,
          data: {
            ..._node.data,
            branchesData: [...(_node.data.branchesData ?? [])].sort(
              (b1, b2) => {
                const b1Index = newEdgesOrder.indexOf(b1.branchId);
                const b2Index = newEdgesOrder.indexOf(b2.branchId);
                return b1Index - b2Index;
              },
            ),
          },
        };
      }
      return _node;
    });
    setEdges(newEdges);
    setNodes(newNodes);
  };

  return (
    <>
      {!editingEdge ? (
        <div className="absolute left-2 top-2 bottom-2 w-120 bg-white rounded-lg z-[10] p-8 flex flex-col justify-between space-y-5">
          <div className="overflow-auto">
            <div className="flex justify-between items-center">
              <span className="text-sm text-primary-blue font-medium">
                Conditional logic
              </span>
              <button
                className="border border-info rounded-lg h-10 w-10 flex justify-center items-center"
                onClick={onCancel}
                type="button"
              >
                <CloseIcon className="text-info" />
              </button>
            </div>
            <div className="my-6">
              <h2 className="text-lg font-medium">Conditional logic</h2>
              <p className="text-sm font-normal text-info-dark">
                Create multiple branches and add conditions for them to execute.
              </p>
            </div>
            <div className="flex-1 flex flex-col gap-4">
              <Input
                floatingLabel
                label="Block Name"
                onChange={updateNodeName}
                placeholder="Block Name"
                value={node.name ?? ''}
              />

              <Select
                classes={{ select: 'w-100' }}
                disabled
                getLabel={(opt: string) => opt}
                getValue={(opt: string) => opt}
                label="Condition Type"
                labelId="type"
                options={typeOptions}
                value={typeOptions[0]}
              />
              <div className="mt-8">
                <p className="font-bold text-sm">Branches</p>
                <div className="mt-2">
                  <DndContext
                    modifiers={[restrictToVerticalAxis]}
                    onDragEnd={onDragEnd}
                    sensors={sensors}
                  >
                    <SortableContext
                      disabled={!allowBranchReordering}
                      items={nodeEdges}
                      strategy={verticalListSortingStrategy}
                    >
                      {nodeEdges.map((edge) => {
                        return (
                          <Branch
                            allowBranchReordering={allowBranchReordering}
                            id={edge.id}
                            key={edge.id}
                            label={edge.label as string}
                            onEdit={() => {
                              setEditingEdge(edge);
                            }}
                          />
                        );
                      })}
                    </SortableContext>
                  </DndContext>
                  <Button
                    className="!mt-6 !mb-2"
                    color="secondary"
                    onClick={() => {
                      insertNode(node.id);
                    }}
                    startIcon={<Add />}
                    variant="text"
                  >
                    Add Branch
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col space-y-7">
            <NodeCheck
              description="Mark as reviewed if ready to run this step"
              isChecked={node.data.nodeStatus === NodeStatusEnum.Checked}
              updateNodeStatus={updateNodeStatus}
            />
            <Button
              color="secondary"
              fullWidth
              onClick={onCancel}
              variant="outlined"
            >
              Back to flow view
            </Button>
          </div>
        </div>
      ) : (
        <EditBranch
          addVariable={addVariable}
          branchData={branchData}
          datasourceMetadata={datasourceMetadata}
          edge={editingEdge}
          onCancel={() => {
            setEditingEdge(undefined);
          }}
          onDelete={() => {
            deleteBranch();
          }}
          onTransformData={onTransformData}
          onUpdateEdge={onUpdateEdge}
          tableData={tableData}
          transformDataStatus={transformDataStatus}
          triggerType={triggerType}
          updateVariable={updateVariable}
          variablesMap={variablesMap}
        />
      )}
    </>
  );
}

interface BranchProps {
  label: string;
  id: string;
  onEdit: () => void;
  allowBranchReordering: boolean;
}
function Branch({ label, onEdit, id, allowBranchReordering }: BranchProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });
  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
  };

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      className={clsx(
        'px-3 py-4 mt-6 rounded-lg border bg-gray-100 flex justify-between items-center hover:shadow-primary transition',
        {
          'border-gray-800 cursor-grab': isDragging,
          'hover:border-primary-blue': !isDragging,
        },
      )}
      style={style}
    >
      <span>{label}</span>
      {allowBranchReordering ? (
        <div
          className={clsx(
            'rotate-90 text-gray-400 cursor-grab hover:text-gray-800',
            isDragging && 'text-gray-800',
          )}
        >
          <DragIndicator />
        </div>
      ) : null}
      <div
        className="flex justify-center items-center text-primary-blue cursor-pointer"
        onClick={onEdit}
        role="presentation"
      >
        <EditBranchIcon className="!w-5 !h-5 bg-transparent" />
      </div>
    </div>
  );
}
