import React from 'react';
import { clsx } from 'clsx';
import { Handle, Position } from 'types-shared/reactflow';

interface Props {
  type: string;
  isConnectable: boolean;
}

export function CustomHandle({ type, isConnectable = false }: Props) {
  const isSource = type === 'source';

  return (
    <Handle
      className={clsx('h-50 w-10 rounded-none', {
        'hover:!bg-primary-blue hover:!h-3 hover:!w-3': isConnectable,
      })}
      isConnectable={isConnectable}
      position={isSource ? Position.Right : Position.Left}
      type={isSource ? 'source' : 'target'}
    />
  );
}
