import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import type { ChangeEvent } from 'react';
import React, { useMemo, useState } from 'react';
import { clsx } from 'clsx';
import { type Variable, VariableTypeEnum, TriggerTypeEnum } from 'types-shared';
import Chip from '@mui/material/Chip';

interface Props {
  anchorEl: Element | null;
  open: boolean;
  onClose: () => void;
  onSelect: (val: Variable) => void;
  onAddNew?: () => void;
  variables: Variable[];
  allowAddVariable: boolean;
}

function VariablesMenu({
  anchorEl,
  open,
  onClose,
  onSelect,
  onAddNew,
  variables = [],
  allowAddVariable = true,
}: Props) {
  const [search, setSearch] = useState<string>('');

  const filteredVariables = useMemo(() => {
    if (search === '') {
      return variables;
    }
    return variables.filter((variable) =>
      variable.name?.toLowerCase().includes(search.toLowerCase()),
    );
  }, [search, variables]);

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  return (
    <Menu
      anchorEl={anchorEl}
      classes={{
        paper: '!max-w-none',
        list: '!p-0',
      }}
      disablePortal
      onClose={onClose}
      open={open}
      slotProps={{
        paper: {
          sx: {
            width: `${anchorEl?.clientWidth}px`,
          },
        },
      }}
      sx={{ '& .MuiBackdrop-root': { backgroundColor: 'transparent' } }}
    >
      {variables.length > 0 ? (
        <MenuItem className="!bg-white !px-3 !py-2.5" disableRipple divider>
          <input
            className="w-full outline-none border rounded p-1 text-sm"
            onChange={onSearchChange}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
            placeholder="Enter Variable Name"
            value={search}
          />
        </MenuItem>
      ) : (
        <MenuItem className="!px-3 !py-2.5 pointer-events-none text-sm" divider>
          No Variables found
        </MenuItem>
      )}
      {filteredVariables.map((variable: Variable, i) => (
        <MenuItem
          className="!px-3 !py-2.5"
          divider={i === filteredVariables.length - 1}
          key={variable.name}
          onClick={() => {
            onSelect(variable);
            onClose();
          }}
        >
          <Chip
            className={clsx(
              {
                '!bg-secondary-purple':
                  variable.type === VariableTypeEnum.Scrape,
              },
              {
                '!bg-transparent !border-secondary-blue !border-2 !text-secondary-blue':
                  variable.type === VariableTypeEnum.Datasource &&
                  variable.data.triggerType === TriggerTypeEnum.API,
              },
            )}
            color="secondary"
            label={variable.name}
            size="small"
            variant={
              variable.type === VariableTypeEnum.Datasource &&
              variable.data.triggerType === TriggerTypeEnum.API
                ? 'outlined'
                : 'filled'
            }
          />
        </MenuItem>
      ))}
      {allowAddVariable ? (
        <MenuItem
          className="!border-t !border-t-gray-300 !text-blue-600 !px-3 !py-2.5"
          onClick={() => onAddNew?.()}
        >
          Add New Variable
        </MenuItem>
      ) : null}
    </Menu>
  );
}

export default VariablesMenu;
