import { TriggerTypeEnum } from 'types-shared';
import type { StateCreator } from 'zustand';
import { produce } from 'immer';

export interface TriggerStateData {
  triggerType: TriggerTypeEnum;
  apiSettings: {
    retryInterval: number | null;
    maxAttempts: number | null;
    maxRunLength: number | null;
  };
}

export const initialTriggerState: TriggerStateData = {
  triggerType: TriggerTypeEnum.Datasource,
  apiSettings: {
    retryInterval: null,
    maxAttempts: null,
    maxRunLength: null,
  },
};

export interface TriggerStateActions {
  setRetryInterval: (retryInterval: number | null) => void;
  setMaxAttempts: (maxAttempts: number | null) => void;
  setMaxRunLength: (maxRunLength: number | null) => void;
  setTriggerType: (triggerType: TriggerTypeEnum) => void;
  resetTriggerState: () => void;
}

export const TriggerState: StateCreator<
  TriggerStateData & TriggerStateActions
> = (set) => ({
  ...initialTriggerState,
  setRetryInterval: (retryInterval: number | null) => {
    set((state) =>
      produce(state, (draft) => {
        draft.apiSettings.retryInterval = retryInterval;
      }),
    );
  },
  setMaxAttempts: (maxAttempts: number | null) => {
    set((state) =>
      produce(state, (draft) => {
        draft.apiSettings.maxAttempts = maxAttempts;
      }),
    );
  },
  setMaxRunLength: (maxRunLength: number | null) => {
    set((state) =>
      produce(state, (draft) => {
        draft.apiSettings.maxRunLength = maxRunLength;
      }),
    );
  },
  setTriggerType: (triggerType: TriggerTypeEnum) => {
    set((state) =>
      produce(state, (draft) => {
        draft.triggerType = triggerType;
      }),
    );
  },
  resetTriggerState: () => {
    set(() => initialTriggerState);
  },
});
