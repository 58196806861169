import { AddCircleOutlineOutlined } from 'assets-shared';
import Chip from '@mui/material/Chip';
import { clsx } from 'clsx';
import React, { useMemo, useRef, useState } from 'react';
import {
  type Variable,
  type VariableRef,
  VariableTypeEnum,
  TriggerTypeEnum,
} from 'types-shared';
import { v4 as uuidv4 } from 'uuid';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, MouseSensor, useSensor, useSensors } from '@dnd-kit/core';
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';

import Input from './Input';
import VariablesMenu from './Menu';
import { focusInput, focusItemAfterDelete } from './variable.helpers';
import isNil from 'lodash/isNil';

export interface Item {
  id: string;
  type: 'string' | 'variable';
  value: string | VariableRef;
}

const mergeStrings = (arr: Item[]) => {
  const mergedArr: Item[] = [];
  let currentString = '';
  let currentId = '';

  arr.forEach((obj) => {
    if (obj.type === 'string') {
      currentString += `${obj.value as string} `;
      if (!currentId) {
        currentId = uuidv4();
      }
    } else {
      if (currentString !== '') {
        mergedArr.push({
          id: currentId,
          type: 'string',
          value: currentString.trim(),
        });
        currentString = '';
        currentId = '';
      }
      mergedArr.push(obj);
    }
  });

  if (currentString) {
    mergedArr.push({
      id: currentId,
      type: 'string',
      value: currentString.trim(),
    });
  }

  return mergedArr;
};

interface VariableProps {
  item: Item;
  disabled?: boolean;
  index: number;
  previewVariableIndex?: number | null;
  itemsLength: number;
  itemDetail: Variable | Item;
  handleInputChange: (id: string) => (val: string) => void;
  handleDeleteItem: (id: string) => void;
  handlePreview?: (index: number, chosenItem: Item) => void;
  placeholder?: string;
  onDeletePrevious?: (currentItemValue: string) => void;
  onMoveLeft?: () => void;
  onMoveRight?: () => void;
}

function SortableItem({
  item,
  itemDetail,
  index,
  itemsLength,
  handleInputChange,
  handlePreview,
  disabled,
  previewVariableIndex,
  placeholder,
  onDeletePrevious,
  onMoveLeft,
  onMoveRight,
}: VariableProps) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: item.id });
  const { id, value, type } = item;
  const [localValue, setLocalValue] = useState<string>(value as string);

  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
    ...(index === itemsLength - 1 ? { flex: 1 } : {}),
  };

  const chipDisabled = disabled && index !== previewVariableIndex;

  const isApiCallChip = useMemo(() => {
    return (
      (itemDetail as Variable).type === VariableTypeEnum.Datasource &&
      ((itemDetail as Variable).data as { triggerType: TriggerTypeEnum })
        .triggerType === TriggerTypeEnum.API
    );
  }, [itemDetail]);

  return (
    <span ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {type === 'string' ? (
        <Input
          className="min-w-2"
          disabled={disabled}
          id={id}
          onBlur={(val) => {
            handleInputChange(id)(val);
          }}
          onChange={setLocalValue}
          onDeletePrevious={onDeletePrevious}
          onMoveLeft={onMoveLeft}
          onMoveRight={onMoveRight}
          placeholder={placeholder}
          value={localValue}
        />
      ) : (
        <Chip
          className={clsx(
            {
              '!bg-secondary-purple':
                (itemDetail as Variable).type === VariableTypeEnum.Scrape,
            },
            {
              '!bg-transparent !border-secondary-blue !border-2 !text-secondary-blue':
                isApiCallChip,
            },
          )}
          clickable
          color="secondary"
          disabled={chipDisabled}
          label={(itemDetail as Variable).name}
          onClick={() => {
            handlePreview?.(index, item);
          }}
          size="small"
          sx={{
            '& .MuiChip-deleteIcon': {
              display:
                index === previewVariableIndex || disabled ? 'none' : 'block',
            },
          }}
          variant={isApiCallChip ? 'outlined' : 'filled'}
        />
      )}
    </span>
  );
}

interface Props {
  label: string;
  data: Item[];
  disabled?: boolean;
  previewVariableIndex?: number | null;
  className?: string;
  variables: Variable[];
  onChange: (val: Item[]) => void;
  allowAddVariable?: boolean;
  onAddNew?: () => void;
  onPreview?: (index: number, chosenItem: Item) => void;
  variablesMap: Record<string, Variable>;
  placeholder?: string;
}

export function VariableInput({
  className,
  data,
  label,
  onChange,
  onPreview,
  onAddNew,
  disabled = false,
  previewVariableIndex,
  allowAddVariable = true,
  variables,
  variablesMap,
  placeholder,
}: Props) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [showVariableMenu, setShowVariableMenu] = useState<boolean>(false);

  const createStringVariable = (): Item => ({
    id: uuidv4(),
    type: 'string',
    value: '',
  });

  const transformedData = useMemo(() => {
    const needsUpdate =
      data.length === 0 ||
      data[0]?.type === 'variable' ||
      data[data.length - 1]?.type === 'variable';

    if (needsUpdate) {
      const newData = [...data];
      if (newData[0]?.type === 'variable') {
        newData.unshift(createStringVariable());
      }
      if (
        newData.length === 0 ||
        newData[newData.length - 1]?.type === 'variable'
      ) {
        newData.push(createStringVariable());
      }
      return mergeStrings(newData);
    }
    return data;
  }, [data]);
  const itemsLength = transformedData.length;

  const handleAddVariable = (variable: Variable) => {
    const variableItemId = uuidv4();
    const inputId = uuidv4();
    onChange([
      ...(itemsLength === 1 && transformedData[0].value === ''
        ? []
        : transformedData),
      {
        id: variableItemId,
        type: 'variable',
        value: variable,
      },
      {
        id: inputId,
        type: 'string',
        value: '',
      },
    ]);
  };

  const handleInputChange = (id: string) => (val: string) => {
    onChange(
      transformedData.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            value: val,
          };
        }
        return item;
      }),
    );
  };

  const handleDeleteItem = (id: string) => {
    const filteredItems = transformedData.filter((item) => item.id !== id);
    onChange(mergeStrings(filteredItems));
  };

  const handleDeleteItems = (ids: string[]) => {
    const filteredItems = transformedData.filter(
      (item) => !ids.includes(item.id),
    );
    onChange(mergeStrings(filteredItems));
  };

  const handleAddNew = () => {
    setShowVariableMenu(false);
    onAddNew?.();
  };

  const handleCloseMenu = () => {
    setShowVariableMenu(false);
  };

  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id === over?.id) {
      return;
    }
    const newData = [...transformedData];
    const oldIndex = newData.findIndex((item) => item.id === active.id);
    const newIndex = newData.findIndex((item) => item.id === over?.id);
    onChange(mergeStrings(arrayMove(newData, oldIndex, newIndex)));
  };

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });
  const sensors = useSensors(mouseSensor);
  const stringItems = useMemo(() => {
    return transformedData.filter((item) => item.type === 'string');
  }, [transformedData]);

  return (
    <div
      className={clsx(
        'relative flex border px-3 py-2.5 max-w-full rounded min-h-14',
        className,
      )}
      ref={containerRef}
    >
      <span className="absolute -top-2 left-3 text-xs text-gray-500 bg-white px-1">
        {label}
      </span>
      <div className="relative flex flex-1 items-center w-max overflow-x-auto space-x-1 mr-6 no-scrollbar">
        <DndContext
          modifiers={[restrictToHorizontalAxis]}
          onDragEnd={onDragEnd}
          sensors={sensors}
        >
          <SortableContext
            disabled={disabled}
            items={transformedData}
            strategy={horizontalListSortingStrategy}
          >
            {transformedData.map((item, index) => (
              <SortableItem
                disabled={disabled}
                handleDeleteItem={handleDeleteItem}
                handleInputChange={handleInputChange}
                handlePreview={onPreview}
                index={index}
                item={item}
                itemDetail={
                  item.type === 'string'
                    ? item
                    : variablesMap[(item.value as VariableRef).id]
                }
                itemsLength={itemsLength}
                key={(item.value as { id: string } | null)?.id || item.id}
                onDeletePrevious={(currentItemValue: string) => {
                  const currentItem = transformedData[index];
                  const prevItem = transformedData[index - 1];
                  const currentStrItemIndex = stringItems.findIndex(
                    (i) => i.id === item.id,
                  );

                  if (
                    !isNil(currentItem) &&
                    !isNil(prevItem) &&
                    prevItem.type === 'variable' &&
                    currentItem.type === 'string'
                  ) {
                    if (!currentItemValue) {
                      handleDeleteItems([prevItem.id, item.id]);
                    } else {
                      handleDeleteItem(prevItem.id);
                    }
                    focusItemAfterDelete(
                      containerRef.current,
                      currentStrItemIndex,
                    );
                  }
                }}
                onMoveLeft={() => {
                  focusInput(containerRef.current, 'left');
                }}
                onMoveRight={() => {
                  focusInput(containerRef.current, 'right');
                }}
                placeholder={
                  transformedData.length === 1 &&
                  transformedData[0].type === 'string' &&
                  !transformedData[0].value
                    ? placeholder
                    : undefined
                }
                previewVariableIndex={previewVariableIndex}
              />
            ))}
          </SortableContext>
        </DndContext>
      </div>
      <button
        className={clsx(
          'absolute right-1 top-1/2 -translate-y-1/2 flex p-1.5 rounded',
          {
            'text-gray-400  !cursor-not-allowed': disabled,
            'text-blue-500 hover:bg-blue-500 hover:text-white': !disabled,
          },
        )}
        disabled={disabled}
        onClick={() => {
          setShowVariableMenu(true);
        }}
        type="button"
      >
        <AddCircleOutlineOutlined className="!w-5 !h-5" />
      </button>
      {showVariableMenu ? (
        <VariablesMenu
          allowAddVariable={allowAddVariable}
          anchorEl={containerRef.current}
          onAddNew={handleAddNew}
          onClose={handleCloseMenu}
          onSelect={handleAddVariable}
          open={showVariableMenu}
          variables={variables}
        />
      ) : null}
    </div>
  );
}
