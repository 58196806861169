import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React from 'react';

export default function ApiTriggerIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 5.00004H2.51599M2.51599 5.00004H17.4841M2.51599 5.00004C2.5 5.26202 2.5 5.58497 2.5 6.0002V14.0002C2.5 14.9336 2.5 15.3997 2.68166 15.7562C2.84144 16.0698 3.09623 16.3254 3.40983 16.4852C3.76601 16.6667 4.2325 16.6667 5.16412 16.6667L14.8359 16.6667C15.7675 16.6667 16.2333 16.6667 16.5895 16.4852C16.9031 16.3254 17.1587 16.0698 17.3185 15.7562C17.5 15.4 17.5 14.9342 17.5 14.0026L17.5 5.99746C17.5 5.58354 17.5 5.26144 17.4841 5.00004M2.51599 5.00004C2.53595 4.67311 2.58081 4.44112 2.68166 4.2432C2.84144 3.9296 3.09623 3.67482 3.40983 3.51503C3.76635 3.33337 4.23341 3.33337 5.16683 3.33337H14.8335C15.7669 3.33337 16.233 3.33337 16.5895 3.51503C16.9031 3.67482 17.1587 3.9296 17.3185 4.2432C17.4194 4.44112 17.4642 4.67311 17.4841 5.00004M17.4841 5.00004H17.5M11.6667 9.16671L13.3333 10.8334L11.6667 12.5M8.33333 12.5L6.66667 10.8334L8.33333 9.16671"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </SvgIcon>
  );
}
