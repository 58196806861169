import React, { useEffect, useState, useRef, useMemo } from 'react';
import { VncScreen } from 'react-vnc';

import type { SessionInfo } from 'selenium-gql';
import { SeleniumGQLClient } from 'selenium-gql';

interface LiveViewProps {
  seleniumRemoteUrl: string;
  sessionId: string;
}

export function LiveView({ seleniumRemoteUrl, sessionId }: LiveViewProps) {
  const canvasRef = useRef(null);
  const [vncUrl, setVncUrl] = useState<string>();
  const [sessionInfo, setSessionInfo] = useState<SessionInfo>();

  const gqlClient = useMemo(
    () => new SeleniumGQLClient(`${seleniumRemoteUrl}/graphql`),
    [seleniumRemoteUrl],
  );

  useEffect(() => {
    const fetchSessionInfo = async () => {
      const resp = await gqlClient.querySessionInfo(sessionId);
      setSessionInfo(resp.session);
    };
    void fetchSessionInfo();
  }, [gqlClient, sessionId]);

  useEffect(() => {
    if (!sessionInfo) {
      return;
    }
    const capabilities = JSON.parse(sessionInfo.capabilities) as {
      'se:vnc': string;
    };
    const vnc = capabilities['se:vnc'];
    if (!vnc) {
      throw new Error('Vnc not found');
    }
    const url = new URL(seleniumRemoteUrl);
    const newVncUrl = new URL(vnc);
    url.pathname = newVncUrl.pathname;
    url.protocol = url.protocol === 'https:' ? 'wss:' : 'ws:';
    setVncUrl(url.href);
  }, [seleniumRemoteUrl, sessionId, sessionInfo]);

  return vncUrl ? (
    <VncScreen
      background="#000000"
      ref={canvasRef}
      scaleViewport
      style={{
        width: '100%',
        height: '100%',
      }}
      url={vncUrl}
    />
  ) : (
    <div>loading ...</div>
  );
}
