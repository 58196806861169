import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export default function StopIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0001 9V15M10.0001 9V15M12.0001 21C7.0295 21 3.00006 16.9706 3.00006 12C3.00006 7.02944 7.0295 3 12.0001 3C16.9706 3 21.0001 7.02944 21.0001 12C21.0001 16.9706 16.9706 21 12.0001 21Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
    </SvgIcon>
  );
}
