import type {
  Condition,
  Group,
  TemplateVariable,
  Variable,
  VariableRef,
} from 'types-shared';
import { isGroup, OperatorEnum, VariableTypeEnum } from 'types-shared';
import { v4 as uuid } from 'uuid';
import { filter } from 'lodash';
import type { Item } from '../Select';

export const initialGroup = {
  id: uuid(),
  operator: OperatorEnum.Or,
  elements: [
    {
      id: uuid(),
    },
  ],
};

export const addConditionHelper = (
  prevGroup: Group,
  groupId: string,
  operator: OperatorEnum,
): Group => {
  const newConditionToAdd: Condition = {
    id: uuid(),
  };

  switch (operator) {
    case OperatorEnum.And: {
      const addAndCondition: (g: Group) => Group = (g: Group) => {
        if (g.id === groupId) {
          return {
            ...g,
            elements: [...g.elements, newConditionToAdd],
            operator: OperatorEnum.And,
          };
        }

        const newElements = g.elements.map((element) => {
          if (isGroup(element)) {
            return addAndCondition(element);
          }
          return element;
        });

        return {
          ...g,
          elements: newElements,
        };
      };

      return addAndCondition(prevGroup);
    }

    case OperatorEnum.Or: {
      const addOrCondition: (g: Group) => Group = (g: Group) => {
        if (g.id === groupId) {
          const newOrGroup = {
            id: uuid(),
            operator: OperatorEnum.And,
            elements: [newConditionToAdd],
          };

          if (g.operator === OperatorEnum.And) {
            return {
              id: uuid(),
              operator: OperatorEnum.Or,
              elements: [g, newOrGroup],
            };
          }

          return {
            ...g,
            elements: [...g.elements, newOrGroup],
          };
        }

        const newElements = g.elements.map((element) => {
          if (isGroup(element)) {
            return addOrCondition(element);
          }
          return element;
        });

        return {
          ...g,
          elements: newElements,
        };
      };

      return addOrCondition(prevGroup);
    }
    default: {
      return prevGroup;
    }
  }
};

export const deleteConditionHelper = (
  prevGroup: Group,
  conditionId: string,
): Group => {
  const _deleteCondition: (g: Group) => Group | null = (g) => {
    const _newElements: (Group | Condition | null)[] = g.elements.map(
      (groupOrCondition) => {
        if (isGroup(groupOrCondition)) {
          return _deleteCondition(groupOrCondition);
        }
        const found = groupOrCondition.id === conditionId;
        return found ? null : groupOrCondition;
      },
    );

    // Filter out null elements (using type guard for type correction)
    const newElements: (Group | Condition)[] = filter(
      _newElements,
      (data: unknown): data is Group | Condition => {
        return data !== null;
      },
    );

    // clean nested groups, if there is only one nested group
    const firstElement = newElements[0];
    if (newElements.length === 1 && isGroup(firstElement)) {
      return firstElement;
    }

    if (newElements.length === 0) {
      return null;
    }

    return {
      ...g,
      operator: newElements.length === 1 ? OperatorEnum.Or : g.operator,
      elements: newElements,
    };
  };

  return _deleteCondition(prevGroup) ?? prevGroup;
};

export const updateConditionHelper = (
  prevGroup: Group,
  conditionId: string,
  condition: Partial<Condition>,
): Group => {
  const _updateCondition: (
    g: Group,
    _conditionId: string,
    data: Partial<Condition>,
  ) => Group = (g: Group, _conditionId: string, data: Partial<Condition>) => {
    const newElements = g.elements.map((element) => {
      if (isGroup(element)) {
        return _updateCondition(element, _conditionId, data);
      }

      if (element.id === _conditionId) {
        return {
          ...element,
          ...data,
        };
      }

      return element;
    });

    return {
      ...g,
      elements: newElements,
    };
  };

  return _updateCondition(prevGroup, conditionId, condition);
};

export const extractLabel = (variable: Variable) => {
  if (
    variable.type === VariableTypeEnum.MultiChoice ||
    variable.type === VariableTypeEnum.Select
  ) {
    return 'Select this option';
  }

  return 'Enter this value';
};

export const extractSelectItems = (variable: Variable): Item[] => {
  if (
    variable.type === VariableTypeEnum.Select ||
    variable.type === VariableTypeEnum.Template
  ) {
    return variable.data.map((item) => {
      if (typeof item === 'string') {
        return {
          id: uuid(),
          type: 'string',
          value: item,
        };
      }

      return {
        id: variable.id,
        type: 'variable',
        value: { id: item.id },
      };
    });
  }
  return [];
};

export const extractVariableData = (
  items: Item[],
): TemplateVariable['data'] => {
  return items.map((item) => {
    return item.type === 'string'
      ? item.value
      : { id: (item.value as VariableRef).id };
  });
};

export const extractInputLabel = (variable: Variable) => {
  if (variable.type === VariableTypeEnum.MultiChoice) {
    return 'Multiple Choice';
  }

  if (variable.type === VariableTypeEnum.Template) {
    return 'Input';
  }
  if (variable.type === VariableTypeEnum.Select) {
    return 'Select';
  }
  return '';
};
