import { ArrowLeftIcon, MoreVert } from 'assets-shared';
import React, { useState } from 'react';
import {
  Button,
  DeleteIcon,
  IconButton,
  Input,
  Menu,
  MenuItem,
  SolaModal,
  Typography,
} from 'ui-kit';
import type {
  BranchData,
  Condition,
  DatasourceMetadata,
  DatasourceTable,
  Group,
  OperatorEnum,
  Variable,
  WorkflowEdge,
  TriggerTypeEnum,
} from 'types-shared';
import { BranchModeEnum } from 'types-shared';
import BranchInstruction from './BranchInstruction';
import GroupBlock from '../Conditions/GroupBlock';
import {
  addConditionHelper,
  deleteConditionHelper,
  updateConditionHelper,
  initialGroup,
} from '../Conditions/conditions.helpers';

interface Props {
  edge: WorkflowEdge;
  branchData?: BranchData;
  onCancel: () => void;
  onDelete: () => void;
  onUpdateEdge: (
    data: Partial<{ name: string; group: Group; instruction: string }>,
  ) => void;
  variablesMap: Record<string, Variable>;
  datasourceMetadata: DatasourceMetadata | null;
  tableData: DatasourceTable | null;
  addVariable: (variable: Variable) => void;
  updateVariable: (variable: Variable) => void;
  transformDataStatus: 'error' | 'idle' | 'pending' | 'success' | 'loading';
  triggerType?: TriggerTypeEnum;
  onTransformData: (
    prompt: string,
    textToTransform: string,
  ) => Promise<string | undefined>;
}

export function EditBranch({
  branchData,
  edge,
  onCancel,
  onDelete,
  onUpdateEdge,
  datasourceMetadata,
  variablesMap,
  tableData,
  addVariable,
  updateVariable,
  onTransformData,
  transformDataStatus,
  triggerType,
}: Props) {
  const [edgeName, setEdgeName] = useState<string>(
    typeof edge.label === 'string' ? edge.label : '',
  );
  const [instruction, setInstruction] = useState(branchData?.instruction ?? '');
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState<boolean>(false);
  const [isInstructionsOpen, setIsInstructionsOpen] = useState<boolean>(
    branchData?.selectedMode === BranchModeEnum.Instruction &&
      Boolean(branchData) &&
      Boolean(branchData.instruction),
  );
  const menuOpen = Boolean(menuAnchorEl);

  const updateInstruction = () => {
    if (!edgeName) return;
    onUpdateEdge({ name: edgeName, instruction });
  };

  const goBack = () => {
    setIsInstructionsOpen(false);
  };

  const [group, setGroup] = useState<Group>(
    branchData?.rule?.data ?? initialGroup,
  );

  const addCondition = (groupId: string, operator: OperatorEnum) => {
    setGroup((prevGroup) => {
      return addConditionHelper(prevGroup, groupId, operator);
    });
  };

  const deleteCondition = (conditionId: string) => {
    setGroup((prevGroup) => {
      return deleteConditionHelper(prevGroup, conditionId);
    });
  };

  const updateCondition = (conditionId: string, data: Partial<Condition>) => {
    setGroup((prevGroup) => {
      return updateConditionHelper(prevGroup, conditionId, data);
    });
  };

  const saveChanges = () => {
    if (!edgeName) return;
    onUpdateEdge({ name: edgeName, group });
  };

  return (
    <>
      <div className="absolute left-2 top-2 bottom-2 w-120 bg-white rounded-lg z-[10] flex flex-col justify-between space-y-5">
        <div className="overflow-auto h-full mb-20">
          <div className="flex justify-between items-center pt-8 px-8">
            <div className="flex items-center">
              <span
                className="flex !border !border-solid !border-info !rounded-lg cursor-pointer mr-3.5"
                onClick={isInstructionsOpen ? goBack : onCancel}
                role="presentation"
              >
                <ArrowLeftIcon className="text-info !h-7 !w-7" />
              </span>
              <span className="text-sm font-medium text-info-dark">
                Conditional block&nbsp;
              </span>
              <span className="text-sm font-medium text-primary-blue">
                / Branch
              </span>
            </div>
          </div>
          <div className="py-6 px-8 flex justify-between items-center bg-white z-50 sticky top-0">
            <h2 className="text-lg font-medium text-info-dark">
              Branch: {edge.label}
            </h2>
            <div>
              <IconButton
                aria-controls={menuOpen ? 'basic-menu' : undefined}
                aria-expanded={menuOpen ? 'true' : undefined}
                aria-haspopup="true"
                aria-label="more"
                onClick={(event) => {
                  setMenuAnchorEl(event.currentTarget);
                }}
              >
                <MoreVert />
              </IconButton>
              <Menu
                anchorEl={menuAnchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                onClose={() => {
                  setMenuAnchorEl(null);
                }}
                open={menuOpen}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem
                  className="flex justify-between items-center"
                  onClick={() => {
                    setMenuAnchorEl(null);
                    setIsDeletePopupOpen(true);
                  }}
                >
                  <p className="text-error max-w-50 min-w-50">Delete branch</p>
                  <DeleteIcon className="!w-4 !h-4 text-error !fill-none" />
                </MenuItem>
                {!isInstructionsOpen ? (
                  <MenuItem
                    onClick={() => {
                      setMenuAnchorEl(null);
                      setIsInstructionsOpen(true);
                    }}
                  >
                    <p className="max-w-50 min-w-50 text-wrap">
                      Determine conditions by giving instructions to Sola
                    </p>
                  </MenuItem>
                ) : null}
              </Menu>
            </div>
            <hr className="border-b border-color-gray-200 border-2 absolute bottom-0 left-0 w-full" />
          </div>

          {!isInstructionsOpen ? (
            <div>
              <div className="flex-1 flex flex-col gap-4 mt-6 px-8 mb-8">
                <p className="font-bold text-sm">Branch Name</p>
                <Input
                  floatingLabel
                  label="Branch Name"
                  onChange={(e) => {
                    setEdgeName(e);
                  }}
                  placeholder="Branch Name"
                  value={edgeName}
                />
              </div>
              <div className="px-8 pb-12">
                <GroupBlock
                  addVariable={addVariable}
                  datasourceMetadata={datasourceMetadata}
                  edgeName={edgeName}
                  group={group}
                  label="Take this branch when"
                  onAddCondition={addCondition}
                  onDeleteCondition={deleteCondition}
                  onTransformData={onTransformData}
                  onUpdateCondition={updateCondition}
                  tableData={tableData}
                  transformDataStatus={transformDataStatus}
                  triggerType={triggerType}
                  updateVariable={updateVariable}
                  variablesMap={variablesMap}
                />
              </div>
              <div className="flex w-full gap-9 px-8 py-6 absolute bottom-0 bg-white drop-shadow shadow-top-15">
                <Button
                  className="!flex-1"
                  color="secondary"
                  fullWidth
                  onClick={saveChanges}
                  variant="contained"
                >
                  Save changes
                </Button>
                <Button
                  className="!flex-1"
                  color="secondary"
                  fullWidth
                  onClick={onCancel}
                  variant="outlined"
                >
                  cancel
                </Button>
              </div>
            </div>
          ) : (
            <BranchInstruction
              edgeName={edgeName}
              instruction={instruction}
              onCancel={() => {
                setIsInstructionsOpen(false);
              }}
              onUpdateInstruction={updateInstruction}
              setEdgeName={setEdgeName}
              setInstruction={setInstruction}
            />
          )}
        </div>
      </div>
      {isDeletePopupOpen ? (
        <SolaModal
          className="!max-w-2xl"
          onClose={() => {
            setIsDeletePopupOpen(false);
            setMenuAnchorEl(null);
          }}
          open={isDeletePopupOpen}
          showCloseIcon={false}
        >
          <Typography className="font-bold" variant="h6">
            Are you sure you want to delete this branch?
          </Typography>
          <p className="mt-11 mb-5 text-info-dark">Branch Name: {edge.label}</p>
          <p className="font-xs">
            The steps in this branch will be permanently deleted, including any
            actions and variable configurations. Any executions going forward
            will not include these steps, though they will still exist in
            completed and ongoing executions.
          </p>
          <div className="flex mt-11 gap-4">
            <Button
              className="max-w-max"
              color="error"
              fullWidth
              onClick={() => {
                setIsDeletePopupOpen(false);
                onDelete();
              }}
              variant="contained"
            >
              yes, Delete branch
            </Button>
            <Button
              className="max-w-max"
              color="secondary"
              fullWidth
              onClick={onCancel}
              variant="text"
            >
              cancel
            </Button>
          </div>
        </SolaModal>
      ) : null}
    </>
  );
}
