import { useMemo } from 'react';
import type {
  NodeData,
  WorkflowAction,
  WorkflowActionsOptions,
  WorkflowNode,
} from 'types-shared';
import { WorkflowImageNode, NodeStatusEnum, ActionsEnum } from 'types-shared';
import { EditorStore } from '../../store/EditorState';
import { imageNodeEventChannel } from '../NodeElement/SelectedImageNodeContent';
import { NodeCheck, Action, ActionHeader } from 'editor-shared';
import { v4 as uuid } from 'uuid';
import omit from 'lodash/omit';
import { FeatureFlag } from 'dashboard-shared';
import { useFeatureFlagEnabled } from 'posthog-js/react';

export type SelectedAction = WorkflowAction & {
  i: number;
};

interface Props {
  node: WorkflowImageNode;
  onClose: () => void;
  setSelectedAction: (actionData: SelectedAction) => void;
}

function ActionsList({ node, setSelectedAction }: Props) {
  const editorData = EditorStore();
  const { nodes, setNodes, variables, selectedNode, setSelectedNode } =
    editorData;
  const localRunsEnabled = useFeatureFlagEnabled(FeatureFlag.LocalRuns);

  const actions = useMemo(() => {
    const { actionData = {}, actionOrder = [] } = node.data;
    return actionOrder
      .map((id) => actionData[id])
      .filter((action) => !action.options?.adminOnly);
  }, [node]);

  const updateNodeData = (data: Partial<NodeData>) => {
    setNodes(
      nodes.map((_node) => {
        if (_node.id === node.id) {
          const updateNode = WorkflowImageNode.parse(_node);
          return {
            ...updateNode,
            data: {
              ...updateNode.data,
              ...data,
            },
          };
        }
        return _node;
      }),
    );
  };

  const updateAction = (
    action: WorkflowAction,
    options: WorkflowActionsOptions,
  ) => {
    updateNodeData({
      actionData: {
        ...node.data.actionData,
        [action.id]: {
          ...action,
          options,
        },
      },
    });
  };

  const onDeleteAction = (action: WorkflowAction) => {
    const actionData = omit(node.data.actionData, [action.id]);

    const actionOrder = node.data.actionOrder.filter((id) => id !== action.id);

    updateNodeData({
      actionData,
      actionOrder,
    });
  };

  const selectedNodeData: WorkflowNode | undefined = useMemo(() => {
    return nodes.find((_node) => _node.id === selectedNode);
  }, [nodes, selectedNode]);

  const handleAddApprovalStep = (
    position: 'before' | 'after',
    index: number,
  ) => {
    const actionId = uuid();
    const targetId = uuid();
    const action: WorkflowAction = {
      id: actionId,
      targetId,
      actionType: ActionsEnum.ManualApproval,
      options: { hitl: true },
    };
    const newActionOrder = [...node.data.actionOrder];
    newActionOrder.splice(
      position === 'before' ? index : index + 1,
      0,
      actionId,
    );
    updateNodeData({
      actionOrder: newActionOrder,
      actionData: {
        ...node.data.actionData,
        [actionId]: action,
      },
    });
  };

  return (
    <>
      <ActionHeader node={selectedNodeData} setSelectedNode={setSelectedNode} />
      <h2 className="font-medium text-lg">Actions</h2>
      {actions.map((action, i) => (
        <Action
          action={action}
          allowDeleteAction={action.actionType === ActionsEnum.ManualApproval}
          i={i + 1}
          imageNodeEventChannel={imageNodeEventChannel}
          key={action.id}
          onAddApprovalStep={(position: 'before' | 'after') => {
            handleAddApprovalStep(position, i);
          }}
          onDeleteAction={() => {
            onDeleteAction(action);
          }}
          onEditClick={
            action.actionType === ActionsEnum.ManualApproval
              ? undefined
              : () => {
                  setSelectedAction({
                    ...action,
                    i: i + 1,
                  });
                }
          }
          onUpdateActionOptions={updateAction}
          showManualHandleOption={localRunsEnabled}
          variablesMap={variables}
        />
      ))}
      <span className="flex-1" />
      <NodeCheck
        isChecked={node.data.nodeStatus === NodeStatusEnum.Checked}
        updateNodeStatus={(status: NodeStatusEnum) => {
          updateNodeData({ nodeStatus: status });
        }}
      />
    </>
  );
}

export default ActionsList;
