import { clsx } from 'clsx';
import React, { useMemo, useRef, useState } from 'react';
import {
  type Variable,
  type VariableRef,
  type SelectOption,
  VariableTypeEnum,
  TriggerTypeEnum,
} from 'types-shared';
import { v4 as uuidv4 } from 'uuid';
import Menu from './Menu';
import Chip from '@mui/material/Chip';
import { ArrowDropDown } from 'assets-shared';

export interface Item {
  id: string;
  type: 'string' | 'variable';
  value: string | VariableRef;
}

interface Props {
  label: string;
  data: Item[];
  disabled?: boolean;
  previewVariableIndex?: number | null;
  className?: string;
  options: SelectOption[];
  variables: Variable[];
  onChange: (val: Item[]) => void;
  allowAddVariable?: boolean;
  onAddNew?: () => void;
  onPreview?: (index: number) => void;
  variablesMap: Record<string, Variable>;
}

function Select({
  className,
  data,
  label,
  disabled,
  onChange,
  onAddNew,
  variables,
  variablesMap,
  options,
  onPreview,
}: Props) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [showVariableMenu, setShowVariableMenu] = useState<boolean>(false);
  const selectedItem = data[0]?.value;
  const selectedVariable = useMemo(() => {
    const item = selectedItem as VariableRef | undefined;
    return item?.id ? variablesMap[item.id] : undefined;
  }, [selectedItem, variablesMap]);

  const handleItemSelect = (value: Variable | string) => {
    const itemId = uuidv4();
    onChange([
      {
        id: itemId,
        type: typeof value === 'string' ? 'string' : 'variable',
        value,
      },
    ]);
  };

  const handleAddNew = () => {
    setShowVariableMenu(false);
    onAddNew?.();
  };

  const handleCloseMenu = () => {
    setShowVariableMenu(false);
  };

  const isApiCallChip = useMemo(() => {
    return (
      selectedVariable &&
      selectedVariable.type === VariableTypeEnum.Datasource &&
      selectedVariable.data.triggerType === TriggerTypeEnum.API
    );
  }, [selectedVariable]);

  return (
    <div
      className={clsx(
        'relative flex border px-3 !pr-6 py-2.5 max-w-full rounded',
        className,
      )}
      ref={containerRef}
    >
      <span className="absolute -top-2 left-3 text-xs text-gray-500 bg-white px-1">
        {label}
      </span>
      <button
        className={clsx('absolute top-1/2 right-2 -translate-y-1/2', {
          'rotate-180': showVariableMenu,
          '!cursor-not-allowed': disabled,
        })}
        onClick={() => {
          setShowVariableMenu((val) => !val);
        }}
        type="button"
      >
        <ArrowDropDown
          className={clsx(disabled ? 'text-gray-400' : 'text-gray-600')}
        />
      </button>
      {typeof selectedItem === 'string' ? (
        <span
          className="flex-1 cursor-pointer"
          onClick={() => {
            setShowVariableMenu((val) => !val);
          }}
          role="presentation"
        >
          {selectedItem}
        </span>
      ) : null}
      {selectedVariable ? (
        <Chip
          className={clsx(
            {
              '!bg-secondary-purple':
                selectedVariable.type === VariableTypeEnum.Scrape,
            },
            {
              '!bg-transparent !border-secondary-blue !border-2 !text-secondary-blue':
                isApiCallChip,
            },
          )}
          clickable
          color="secondary"
          label={selectedVariable.name}
          onClick={() => {
            onPreview?.(0);
          }}
          size="small"
          variant={isApiCallChip ? 'outlined' : 'filled'}
        />
      ) : null}
      {showVariableMenu ? (
        <Menu
          allowAddVariable
          anchorEl={containerRef.current}
          onAddNew={handleAddNew}
          onClose={handleCloseMenu}
          onSelect={handleItemSelect}
          open={showVariableMenu}
          options={options}
          variables={variables}
        />
      ) : null}
    </div>
  );
}

export default Select;
